import { CheckmarkIcon } from '@adsk/alloy-react-icon';
import {
  TableCell,
  TableHeader,
  TableHeaderGroup,
  TableRow,
  TableSkeletonLoadingRow,
  useSkeletonLoadingRows,
  useTable,
} from '@adsk/alloy-react-table';
import { OverflowTooltip } from '@adsk/alloy-react-tooltip';
import { Oasis, Optionull } from '@oasis/sdk';
import { ArrayUtils, DateUtils } from '@oasis/utils';
import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { memo, useMemo, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { TablePagination } from '~/shared/components/base/table-pagination';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useLookupTable } from '~/shared/hooks/use-lookup-table';
import { STATUS_COLOR_CLASSES } from '~/shared/utils/const.issues';
import { IssueColoredStatus } from './issue-colored-status';

interface Row {
  id: string;
  displayId: string | number;
  title: string;
  status: string;
  category: Optionull<string>;
  type: IssueType | undefined;
  description: Optionull<string>;
  assignedTo: Optionull<string>;
  dueDate: Optionull<string>;
  startDate: Optionull<string>;
  placement?: { urn: string; name: string };
  locationName?: Optionull<string>;
  locationDetails?: Optionull<string>;
}

interface IssueType {
  code?: string;
  title: string;
}

const columnHelper = createColumnHelper<Row>();

interface Props {
  filteredModelId?: string; // The id/urn of the model we want to filter issues by.
  query: string;
}

/**
 * Notes:
 *  - `pageSize` is set at 50 because we cannot fetch more than 50 documents in the `ListItems` command.
 */

export const IssuesTable = memo(function _IssuesTable({ filteredModelId, query }: Props) {
  const $env = Oasis.Env.useStore();
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId, platform } = useProjectContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const centerTableRef = useRef<HTMLTableElement>(null);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([{ id: 'displayId', desc: true }]);
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 50 });

  const listIssueQuery = Queries.Issues.useListIssues({
    projectId,
    platform,
    sorting,
    pagination: {
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
    },
    filter: {
      modelId: filteredModelId,
      search: query,
    },
  });
  const projectUsersQuery = Queries.Projects.useListProjectUsers({
    projectId,
    opts: { enabled: listIssueQuery.isSuccess },
  });
  const issueTypesQuery = Queries.Issues.useListIssueTypes({
    projectId,
    platform,
    opts: { enabled: listIssueQuery.isSuccess },
  });
  const locationsQuery = Queries.Projects.useListLocations({
    projectId,
    platform,
    opts: { enabled: listIssueQuery.isSuccess },
  });
  const documentsQuery = Queries.Files.useListDocumentsById({
    projectId,
    urns: listIssueQuery.data?.results
      ? listIssueQuery.data.results.map(issue => issue.linkedDocuments[0]?.urn).filter(ArrayUtils.truthy)
      : [],
    opts: { enabled: listIssueQuery.isSuccess },
  });

  const usersLookup = useLookupTable({
    data: projectUsersQuery.data?.results,
    getId: item => item.autodeskId,
    getValue: item => item.email,
  });

  const issueTypeLookup = useMemo(() => {
    const lookup = new Map<string, IssueType>();

    if (issueTypesQuery.data?.results) {
      for (const item of issueTypesQuery.data.results) {
        lookup.set(item.id, { code: '✓', title: item.title });

        if (item.subtypes) {
          for (const subType of item.subtypes) {
            lookup.set(subType.id, { code: subType.code, title: subType.title });
          }
        }
      }
    }

    return lookup;
  }, [issueTypesQuery.data]);

  const placementLookup = useLookupTable({
    data: documentsQuery.data,
    getId: item => item.id,
    getValue: item => item.displayName,
  });

  const locationLookup = useLookupTable({
    data: locationsQuery.data?.results,
    getId: item => item.id,
    getValue: item => item.name,
  });

  const data: Row[] = useMemo(() => {
    if (!listIssueQuery.data) {
      return [];
    }

    return listIssueQuery.data.results.map(issue => {
      let placement;
      const placementUrn = issue.linkedDocuments[0]?.urn;
      const placementName = placementUrn && placementLookup.get(placementUrn);

      if (placementName && placementUrn) {
        placement = {
          urn: placementUrn,
          name: placementName,
        };
      }

      return {
        id: issue.id,
        displayId: issue.displayId,
        title: issue.title,
        status: issue.status,
        category: issueTypeLookup.get(issue.issueTypeId)?.title,
        type: issueTypeLookup.get(issue.issueSubtypeId),
        description: issue.description,
        assignedTo: issue.assignedTo && usersLookup.get(issue.assignedTo),
        dueDate: issue.dueDate,
        startDate: issue.startDate && DateUtils.getFriendlyFormat(issue.startDate),
        placement,
        locationName: issue.locationId && locationLookup.get(issue.locationId),
        locationDetails: issue.locationDetails,
      };
    });
  }, [listIssueQuery.data, usersLookup, issueTypeLookup, placementLookup, locationLookup]);

  const columns = useMemo(() => {
    const cols = [
      // columnHelper.display({
      //   id: 'select',
      //   size: 60,
      //   enableResizing: false,
      //   header: ({ table }) => {
      //     const checked = table.getIsAllRowsSelected()
      //       ? true
      //       : table.getIsSomeRowsSelected()
      //         ? Checkbox.INDETERMINATE
      //         : false;

      //     return (
      //       <Checkbox
      //         checked={checked}
      //         onChange={(_state, event) => {
      //           table.getToggleAllRowsSelectedHandler()(event);
      //         }}
      //       />
      //     );
      //   },
      //   cell: ({ row }) => (
      //     <Checkbox
      //       checked={row.getIsSelected()}
      //       onClick={e => e.stopPropagation()}
      //       onChange={(_state, event) => {
      //         row.getToggleSelectedHandler()(event);
      //       }}
      //     />
      //   ),
      // }),

      columnHelper.accessor('title', {
        size: 280,
        minSize: 180,
        header: 'Title',
        cell: info => <p className="text-label-md truncate">{info.getValue()}</p>,
      }),

      columnHelper.accessor('displayId', {
        size: 180,
        minSize: 120,
        header: 'ID',
        cell: info => <p className="text-label-md">#{info.getValue()}</p>,
      }),

      columnHelper.accessor('status', {
        size: 180,
        minSize: 120,
        header: 'Status',
        cell: info => <IssueColoredStatus status={info.getValue()} />,
      }),

      !$env.isVr &&
        columnHelper.accessor('category', {
          size: 220,
          minSize: 140,
          header: 'Category',
          enableSorting: false,
          cell: info => <p className="text-label-md truncate">{info.getValue()}</p>,
        }),

      columnHelper.accessor('type', {
        size: 220,
        minSize: 160,
        header: 'Type',
        enableSorting: false,
        cell: info => {
          const value = info.getValue();
          const colorClasses = STATUS_COLOR_CLASSES[info.row.original.status] || STATUS_COLOR_CLASSES.default;

          if (!value) {
            return <p>&mdash;</p>;
          }

          return (
            <p className="flex items-center truncate text-label-md">
              {platform === 'acc' && (
                <span
                  className={clsx(
                    'inline-flex items-center justify-center w-7 h-7 rounded-full mr-2',
                    value.code && value.code.length < 3 ? 'text-body-lg' : 'text-label-sm',
                    colorClasses
                  )}
                >
                  {value.code === '✓' ? <CheckmarkIcon /> : value.code}
                </span>
              )}
              {value.title}
            </p>
          );
        },
      }),

      !$env.isVr &&
        columnHelper.accessor('description', {
          size: 180,
          minSize: 120,
          header: 'Description',
          enableSorting: false,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return <p>&mdash;</p>;
            }

            return (
              <div className="w-full">
                <OverflowTooltip content={value}>{value}</OverflowTooltip>
              </div>
            );
          },
        }),

      !$env.isVr &&
        columnHelper.accessor('assignedTo', {
          size: 180,
          minSize: 120,
          header: 'Assigned to',
          enableSorting: false,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return <p>&mdash;</p>;
            }

            return (
              <div className="w-full">
                <OverflowTooltip content={value}>{value}</OverflowTooltip>
              </div>
            );
          },
        }),

      !$env.isVr &&
        columnHelper.accessor('locationName', {
          size: 220,
          minSize: 140,
          header: 'Location',
          enableSorting: false,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return <p>&mdash;</p>;
            }

            return (
              <div className="inline-block max-w-full">
                <OverflowTooltip content={value}>{value}</OverflowTooltip>
              </div>
            );
          },
        }),

      !$env.isVr &&
        columnHelper.accessor('locationDetails', {
          size: 220,
          minSize: 140,
          header: 'Location details',
          enableSorting: false,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return <p>&mdash;</p>;
            }

            return (
              <div className="inline-block max-w-full">
                <OverflowTooltip content={value}>{value}</OverflowTooltip>
              </div>
            );
          },
        }),

      !$env.isVr &&
        columnHelper.accessor('dueDate', {
          size: 180,
          minSize: 120,
          header: 'Due date',
          cell: info => {
            const value = info.getValue();
            const isPast = value && new Date(value).getTime() < Date.now();

            return (
              <p className={clsx(isPast && 'text-red-500')}>
                {value ? DateUtils.getFriendlyFormat(value) : <>&mdash;</>}
              </p>
            );
          },
        }),

      !$env.isVr &&
        columnHelper.accessor('startDate', {
          size: 180,
          minSize: 120,
          header: 'Start date',
          cell: info => <p>{info.getValue() || <>&mdash;</>}</p>,
        }),

      !$env.isVr &&
        columnHelper.accessor('placement', {
          size: 220,
          minSize: 140,
          header: 'Placement',
          enableSorting: false,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return <p>&mdash;</p>;
            }

            return (
              <OverflowTooltip content={value.name}>
                <Link
                  to={`/projects/${projectId}/files/${value.urn}`}
                  state={{ from: 'Issues', backUrl: window.location.href }}
                  onClick={e => e.stopPropagation()}
                  className="inline-flex flex-1 max-w-full text-blue-700 hover:text-blue-500"
                >
                  {value.name}
                </Link>
              </OverflowTooltip>
            );
          },
        }),

      // !$env.isVr && columnHelper.display({
      //   id: 'menu',
      //   size: 50,
      //   meta: { sticky: 'right' },
      //   enableResizing: false,
      //   enableSorting: false,
      //   cell: () => {
      //     return (
      //       <Popover.Trigger
      //         onClick={e => { e.stopPropagation(); }}
      //         className="w-14 h-full inline-flex items-center justify-center opacity-60 text-charcoal-700 group-hover:opacity-100 hover:text-blue-500"
      //       >
      //         <MoreVerticalIcon />
      //       </Popover.Trigger>
      //     );
      //   },
      // }),
    ];

    return cols.filter(ArrayUtils.truthy);
  }, [$env, platform, projectId]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      sorting,
      pagination,
      columnPinning: {
        left: ['title'],
      },
    },
    manualPagination: true,
    pageCount: Math.ceil((listIssueQuery?.data?.pagination.totalResults || 0) / pagination.pageSize),
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { getTableHeaderProps, getTableRowProps, getTableCellProps } = useTable({ table });

  const { getSkeletonLoadingRows, getSkeletonLoadingRowProps } = useSkeletonLoadingRows({
    // @ts-expect-error
    table,
    numberOfRows: 8,
  });

  if (listIssueQuery.error) {
    return <OasisErrorState oasisError={listIssueQuery.error} />;
  }

  if (!listIssueQuery.isLoading && !data.length && !query.length && !filteredModelId) {
    return (
      <OasisErrorState
        illustration="folderEmptyGrey"
        title="No issues in this project"
        description={
          <span>
            All issues created for this project will appear here. <br /> Create new issues from this page.
          </span>
        }
      />
    );
  }

  if (!listIssueQuery.isLoading && (query.length || filteredModelId) && !table.getRowModel().rows.length) {
    return (
      <OasisErrorState
        illustration="folderPhotoGrey"
        title="No issues found"
        description="Check your search and filters and try again."
      />
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div ref={containerRef} className="flex items-start w-full h-full overflow-auto">
        <table ref={centerTableRef} className="min-w-full">
          <thead className="sticky top-0 z-20">
            <TableHeaderGroup>
              {table.getLeftHeaderGroups().map(headerGroup => {
                let totalPinnedWidth = 0;

                return headerGroup.headers.map(header => {
                  const stickyLeftPos = totalPinnedWidth;
                  totalPinnedWidth += header.column.getSize();

                  return (
                    <TableHeader
                      key={header.id}
                      {...getTableHeaderProps(header)}
                      className="!sticky z-20 scroll-shadow scroll-shadow--right"
                      style={{ left: stickyLeftPos }}
                    />
                  );
                });
              })}
              {table
                .getCenterHeaderGroups()
                .map(headerGroup =>
                  headerGroup.headers.map(header => <TableHeader key={header.id} {...getTableHeaderProps(header)} />)
                )}
            </TableHeaderGroup>
          </thead>
          <tbody>
            {listIssueQuery.isLoading &&
              getSkeletonLoadingRows().map(skeletonRow => (
                <TableSkeletonLoadingRow key={skeletonRow.id} {...getSkeletonLoadingRowProps(skeletonRow)} />
              ))}

            {table.getRowModel().rows.map(row => {
              let totalPinnedWidth = 0;

              return (
                <TableRow
                  key={row.id}
                  {...getTableRowProps(row)}
                  onClick={() => {
                    searchParams.set('issueId', row.original.id);
                    setSearchParams(searchParams);
                  }}
                >
                  {row.getLeftVisibleCells().map(cell => {
                    const leftStickyPos = totalPinnedWidth;
                    totalPinnedWidth += +cell.column.getSize();

                    return (
                      <TableCell
                        key={cell.id}
                        {...getTableCellProps(cell)}
                        className="!sticky z-10 scroll-shadow scroll-shadow--right"
                        style={{ left: leftStickyPos }}
                      />
                    );
                  })}
                  {row.getCenterVisibleCells().map(cell => (
                    <TableCell key={cell.id} {...getTableCellProps(cell)} />
                  ))}
                </TableRow>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        table={table}
        tableBodyRef={tableBodyRef}
        totalRows={listIssueQuery.data?.pagination.totalResults}
        isLoading={listIssueQuery.isLoading}
      />
    </div>
  );
});
