import { MouseEvent } from 'react';

export const BrowserUtils = {
  simulateClick(el: HTMLElement) {
    el.dispatchEvent(new window.MouseEvent('mousedown', { view: window, bubbles: true, cancelable: true, buttons: 1 }));
  },

  tryOpenInNewTab(e: MouseEvent<HTMLElement, Event>, url: string) {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
      return true;
    }
    return false;
  },
};
