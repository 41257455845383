import { FileUploadState, Oasis, Ok } from '@oasis/sdk';
import { CryptoUtils } from '@oasis/utils';
import { useMemo } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { MiniUploadMenu } from './components/mini-uploader';
import { UploadModal } from './components/upload-file-modal';

/**
 * NOTE:
 * It's gross, and I don't like it, but when you try to access a `File` inside of a `Proxy`
 * you get an `Illegal invocation` error.. so we store the actual files inside of `fileCache`
 * and grab them whenever we need the actual file reference.
 */

export interface QueueItem {
  uploadUid: string;
  filename: string;
  projectId: string;
  parentFolderUrn: string;
  status: FileUploadState['status'];
  progress: number;
  isCancelled: boolean;
  isProcessing: boolean;
  message: string | null;
  update: (attrs: Partial<QueueItem>) => void;
  cancel: () => void;
}

export interface QueueItemWithFile extends QueueItem {
  file: File;
}

interface UploadManagerStore {
  state: 'HIDDEN' | 'MODAL' | 'MINI';
  isProcessing: boolean;
  activeUploads: number;
  queue: QueueItem[];
}

const store = proxy<UploadManagerStore>({
  state: 'HIDDEN',
  activeUploads: 0,
  isProcessing: false,
  queue: [],
});

const fileCache: Map<string, File> = new Map();

interface Props {
  projectId?: string;
  folderId?: string;
}

export function UploadManager(props: Props) {
  const $store = useProxy(store);

  return (
    <>
      {$store.state === 'MODAL' && <UploadModal {...props} />}
      {$store.state === 'MINI' && <MiniUploadMenu {...props} />}
    </>
  );
}

UploadManager.store = store;
UploadManager.fileCache = fileCache;
UploadManager.useStore = () => useProxy(store);
UploadManager.useQueue = () => {
  const $store = useProxy(store);

  return useMemo(() => {
    const items: QueueItemWithFile[] = [];

    for (const item of $store.queue) {
      const file = fileCache.get(item.uploadUid);
      if (file) items.push({ ...item, file });
    }

    return items;
  }, [$store.queue]);
};

UploadManager.pushFileToQueue = (params: {
  file: File;
  projectId: string;
  parentFolderUrn: string;
  onStateChange?: (state: FileUploadState) => void;
}) => {
  const uploadUid = CryptoUtils.randomUUID();
  const filename = params.file.name;

  const item: QueueItem = {
    uploadUid,
    filename,
    projectId: params.projectId,
    parentFolderUrn: params.parentFolderUrn,
    status: 'QUEUED',
    progress: 0,
    isCancelled: false,
    isProcessing: true,
    message: null,
    update: attrs => UploadManager.updateQueueItem(uploadUid, attrs),
    cancel: () => _cancelQueueItem(uploadUid),
  };

  store.queue.push(item);
  fileCache.set(uploadUid, params.file);

  if (!UploadManager.store.isProcessing) {
    _processQueue(params.onStateChange);
  }

  return Ok(true);
};

UploadManager.updateQueueItem = (uploadUid: string, params: Partial<QueueItem>) => {
  for (let index = 0; index < store.queue.length; index++) {
    const item = store.queue[index];

    if (item?.uploadUid === uploadUid) {
      const queue = [...store.queue];
      queue[index] = { ...item, ...params, isProcessing: params.status !== 'DONE' };
      store.queue = queue;
      continue;
    }
  }

  return Ok(true);
};

function _processQueue(onStateChange?: (state: FileUploadState) => void) {
  const next = store.queue.find(item => item.status === 'QUEUED');
  const file = next && fileCache.get(next.uploadUid);

  if (!next || !file || store.activeUploads > 2) {
    return;
  }

  next.status = 'INITIALIZING';

  Oasis.Files.uploadFile({
    file,
    projectId: next.projectId,
    parentFolderUrn: next.parentFolderUrn,
    onStateChange(state) {
      next.update(state);
      onStateChange?.(state);

      if (state.status === 'DONE') {
        store.activeUploads--;
        _processQueue();
      }
    },
    getIsCancelled() {
      return store.queue.some(item => item.uploadUid === next.uploadUid && item.isCancelled);
    },
  });

  store.activeUploads++;

  _processQueue();
}

function _cancelQueueItem(uploadUid: string) {
  fileCache.delete(uploadUid);
  UploadManager.updateQueueItem(uploadUid, { isCancelled: true });
  return Ok(true);
}
