import { Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { useMatch } from 'react-router-dom';
import { Queries } from '~/shared/hooks/queries';

export function ActiveWorkshopSpoofer() {
  const $session = Oasis.Session.useStore();
  const match = useMatch('/projects/:projectId/*');
  const workshops = Queries.Workshops.useListAllWorkshops(match?.params.projectId || '');

  if (!workshops.data || workshops.data.results.length === 0) {
    return null;
  }

  const activeId = $session.activeWorkshop?.id;

  return (
    <>
      <div className="border-b border-charcoal-800 w-full my-4" />
      <p className="text-label-sm font-bold mb-2">Active Workshop Override:</p>

      <select
        value={activeId}
        onChange={e => {
          const workshop = workshops.data.results.find(w => w.id === e.target.value) || null;

          if (workshop?.id) {
            Oasis.Debug.setActiveWorkshop(workshop.id);
          } else {
            Oasis.Debug.clearActiveWorkshop();
          }
        }}
        className="appearance-none bg-charcoal-800 text-white p-2 rounded"
      >
        <option value="">None</option>
        {workshops.data.results
          .sort((a, b) => ArrayUtils.sortAlphanumeric(a.name, b.name))
          .map(workshop => {
            return (
              <option key={workshop.id} value={workshop.id}>
                {workshop.name}
              </option>
            );
          })}
      </select>
    </>
  );
}
