import { min } from '@segment/snippet';
import { Oasis } from '../../oasis';

export const Segment = {
  identify(id: string, labels: Record<string, unknown>) {
    if (Oasis.Env.store.isDevMode) {
      Oasis.Logger.info({ id, labels, msg: '[Segment.identify]' });
    }

    if (Oasis.Session.store.status === 'AUTHENTICATED') {
      labels = {
        id: Oasis.Session.store.user.analyticsId,
        oxygen_id: Oasis.Session.store.user.id,
        email: Oasis.Session.store.user.email,
        first_name: Oasis.Session.store.user.firstName,
        last_name: Oasis.Session.store.user.lastName,
        country_code: Oasis.Session.store.user.country,
        license_type: Oasis.Session.store.license.type,
        wxr_release_channel: Oasis.Env.store.releaseChannel,
        wxr_context: Oasis.Env.store.context,
        wxr_git_sha: Oasis.Env.store.gitSha,
        wxr_is_dev_mode: Oasis.Env.store.isDevMode,
        ...labels,
      };
    }

    try {
      window.analytics?.identify(id, labels);
    } catch (e) {
      Oasis.Logger.error({ e, msg: '[Segment.identify]' });
    }
  },

  track(label: string, attrs = {}) {
    if (Oasis.Env.store.isDevMode) {
      Oasis.Logger.info({ label, attrs, msg: '[Segment.track]' });
    }

    try {
      window.analytics?.track(label, {
        ...attrs,
        env: { context: Oasis.Env.store.context },
      });
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[Segment.track]' });
    }
  },

  page(name: string) {
    if (Oasis.Env.store.isDevMode) {
      Oasis.Logger.info({ name, msg: '[Segment.page]' });
    }

    try {
      window.analytics?.page(name);
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[Segment.page]' });
    }
  },

  loadAnalytics(apiKey: string) {
    const el = document.getElementById('segment-snippet');

    if (!el) {
      Oasis.Logger.error('Failed to inject Segment snippet.');
      return;
    }

    el.innerHTML = min({
      apiKey,
      host: 'cdn.segment.com',
      page: false,
    });
  },
};
