export function createDedupedFn<T extends (...args: any) => Promise<any>>(fn: T) {
  let inFlight: Promise<ReturnType<T>> | null = null;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    if (!inFlight) {
      inFlight = fn(...args).finally(() => {
        inFlight = null;
      });
    }

    return inFlight;
  };
}
