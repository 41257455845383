import { Oasis } from '@oasis/sdk';
import { useMemo } from 'react';
import { Queries } from '~/shared/hooks/queries';

const PermissionLevels = {
  VIEW: 0,
  EDIT: 1,
  MANAGE: 2,
};

export function useWorkshopPermissions(workshopId: string) {
  const $session = Oasis.Session.useStore();
  const users = Queries.Workshops.useListWorkshopMembers(workshopId);

  const currentUser = useMemo(() => {
    if (!users.data) return null;

    const admin = users.data.results.admins.find(u => u.autodeskId === $session.user?.id);
    if (admin) return admin;

    const member = users.data.results.members.find(u => u.autodeskId === $session.user?.id);
    if (member) return member;
  }, [$session.user?.id, users.data]);

  const currentUserPermission = currentUser?.permission || 'VIEW';
  const currentLevel = PermissionLevels[currentUserPermission];

  return {
    isLoading: users.isLoading,
    currentUserPermission,
    canView: currentLevel >= PermissionLevels.VIEW,
    canEdit: currentLevel >= PermissionLevels.EDIT,
    canManage: currentLevel >= PermissionLevels.MANAGE,
  };
}
