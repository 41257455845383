import { z } from 'zod';
import { CommonSchemas } from '../../lib/common.schemas';

export const SessionSchemas = {
  me: z.object({
    id: z.string(),
    countryCode: z.string().refine(value => value || 'Unknown'),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    images: z.record(z.string(), z.string()),
    profile: z
      .object({
        id: z.string(),
        settings: CommonSchemas.userProfileSettings,
        favoriteProjectIds: z.array(z.string()).optional(),
      })
      .nullable(),
    license: z
      .object({
        valid: z.boolean(),
        isTrial: z.boolean(),
        expireAt: z.number().optional(),
      })
      .optional(),
    analyticsId: z.string(),
  }),

  getPairingCode: z.object({
    code: z.string(),
  }),
};
