import { debounce } from 'lodash';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

export const ui = proxy({
  collapsedNav: window.innerWidth < 1024,
});

export const useUI = () => useProxy(ui);

const resizeHandler = debounce(() => {
  ui.collapsedNav = window.innerWidth < 1024;
}, 16);

window.addEventListener('resize', resizeHandler);
