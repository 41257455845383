import { ArrowUpIcon, ChevronDownIcon } from '@adsk/alloy-react-icon';
import { RadioButton, RadioGroup } from '@adsk/alloy-react-radio-button';
import { Oasis, OasisSchemas, type ToolNames } from '@oasis/sdk';
import clsx from 'clsx';
import { formatDistance } from 'date-fns';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useClickAway } from 'react-use';
import { Checkbox } from '~/shared/components/forms/checkbox';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useIsSuperUser } from '~/shared/hooks/use-super-user';
import { ActiveWorkshopSpoofer } from './active-workshop-spoofer';

const persistedBottom = localStorage.getItem('dev-panel-is-bottom');

type Pages = 'settings' | 'mqtt-outgoing' | 'mqtt-incoming';

const TOOL_NAMES = ['none', 'measurement', 'create-issue', 'properties', 'annotation'] satisfies ToolNames[];

export default function DevPanel() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const isSuperUser = useIsSuperUser();
  Oasis.Debug.useStore();

  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isBottom, setIsBottom] = useState(persistedBottom === 'true' || false);
  const [showEnv, setShowEnv] = useState(false);
  const [showFlags, setShowFlags] = useState(false);
  const [page, setPage] = useState<Pages>('settings');
  const [isCameraSync] = useFeatureFlags(['241015-7221-unreal-lmv-cameras-sync']);

  useClickAway(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  if (!isSuperUser) {
    return null;
  }

  return createPortal(
    <>
      <div
        ref={ref}
        className={clsx(
          'fixed z-50 w-full max-w-2xl left-1/2 -translate-x-1/2 transition-transform !pointer-events-auto',
          isBottom ? 'bottom-0' : 'top-0',
          !isOpen && isBottom && 'translate-y-full',
          !isOpen && !isBottom && '-translate-y-full'
        )}
      >
        <div
          className={clsx(
            'bg-charcoal-900 text-white max-h-[75vh] overflow-auto',
            isBottom ? 'rounded-t-lg' : 'rounded-b-lg'
          )}
        >
          <nav className="flex border-b-1 border-charcoal-800 mb-3">
            <button
              className={clsx('p-3', page === 'settings' && 'bg-charcoal-800')}
              onClick={() => setPage('settings')}
            >
              Settings
            </button>
            <button
              className={clsx('p-3', page === 'mqtt-outgoing' && 'bg-charcoal-800')}
              onClick={() => setPage('mqtt-outgoing')}
            >
              MQTT (Outgoing)
            </button>
            <button
              className={clsx('p-3', page === 'mqtt-incoming' && 'bg-charcoal-800')}
              onClick={() => setPage('mqtt-incoming')}
            >
              MQTT (Incoming)
            </button>

            <button
              className={clsx('p-3 ml-auto text-body-sm', page === 'mqtt-outgoing' && 'bg-charcoal-800')}
              onClick={() => Oasis.Session.logout()}
            >
              Logout
            </button>
          </nav>

          <div className="px-6 py-3">
            {page === 'settings' && (
              <div>
                <div className="flex flex-start gap-4">
                  <button
                    className="text-label-sm border border-charcoal-500 text-white p-1 rounded"
                    onClick={() => Oasis.Env.setDevMode(false)}
                  >
                    Turn off dev mode
                  </button>
                  <button
                    className="text-label-sm border border-charcoal-500 text-white p-1 rounded"
                    onClick={async () => {
                      await Oasis.Session.deleteAllForgeXrSessions();
                      window.location.reload();
                    }}
                  >
                    Kill all forgexr sessions
                  </button>
                  <button
                    className="text-label-sm border border-charcoal-500 text-white p-1 rounded"
                    onClick={() => {
                      Oasis.MessagesProvider.publish({
                        topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/vr`,
                        payload: [
                          {
                            type: 'PushFileToStage',
                            args: {
                              id: 'urn:adsk.wipprod:fs.file:vf.sdr5GytcUcy2rYUQZ9MY-g?version=1',
                              urn: 'urn:adsk.wipprod:fs.file:vf.sdr5GytcUcy2rYUQZ9MY-g?version=1',
                              viewId: '6d8854d3-8ec0-9ca1-1750-a30d0d3e9684',
                              projectId: 'b.09d0b13c-4be2-4617-a035-46d6efb8a01b',
                              platform: 'acc',
                              workshopId: 'cnj1bk98k4qlri3gn24g&tab=details',
                              workshopName: '3d',
                            },
                          },
                        ],
                        opts: {
                          retained: true,
                          expirationSeconds: 15,
                        },
                      });
                    }}
                  >
                    Share 3d view
                  </button>
                </div>

                <div className="grid grid-cols-2 gap-2 mt-5">
                  <label className="flex items-center hover:underline cursor-pointer" htmlFor="dev-panel-bottom">
                    <Checkbox
                      id="dev-panel-bottom"
                      checked={isBottom}
                      onChange={state => {
                        if (typeof state === 'boolean') {
                          localStorage.setItem('dev-panel-is-bottom', String(state));
                          setIsBottom(state);
                        }
                      }}
                    />
                    <span className="ml-2">Bottom dev panel</span>
                  </label>

                  <label className="flex items-center hover:underline cursor-pointer" htmlFor="dev-panel-use-voiceover">
                    <Checkbox
                      id="dev-panel-use-voiceover"
                      defaultChecked={!!Oasis.Storage.get('usePairingVoiceover')}
                      onChange={state => {
                        if (state) {
                          Oasis.Storage.set('usePairingVoiceover', 'true');
                        } else {
                          Oasis.Storage.remove('usePairingVoiceover');
                        }
                      }}
                    />
                    <span className="ml-2">Use pairing voiceover</span>
                  </label>

                  <label className="flex items-center hover:underline cursor-pointer" htmlFor="dev-panel-env">
                    <Checkbox
                      id="dev-panel-env"
                      checked={showEnv}
                      onChange={state => {
                        if (typeof state === 'boolean') {
                          setShowEnv(state);
                        }
                      }}
                    />
                    <span className="ml-2">Show env</span>
                  </label>

                  <label className="flex items-center hover:underline cursor-pointer" htmlFor="dev-panel-flags">
                    <Checkbox
                      id="dev-panel-flags"
                      checked={showFlags}
                      onChange={state => {
                        if (typeof state === 'boolean') {
                          setShowFlags(state);
                        }
                      }}
                    />
                    <span className="ml-2">Show feature flags</span>
                  </label>
                </div>

                {showEnv && (
                  <>
                    <div className="border-b border-charcoal-800 w-full my-4" />
                    <pre className="pr-5 overflow-auto">{JSON.stringify({ ...Oasis.Env.store }, null, 2)}</pre>
                  </>
                )}

                {showFlags && (
                  <>
                    <div className="border-b border-charcoal-800 w-full my-4" />
                    <pre className="pr-5 overflow-auto">
                      {Object.entries(Oasis.FeatureFlags.store.flags).map(([key, value]) => (
                        <div key={key} className={clsx('flex items-center', value ? 'text-green-100' : 'text-red-100')}>
                          <span
                            className={clsx('w-3 h-3 mr-3 rounded-full', value ? 'bg-green-300' : 'bg-red-300')}
                          ></span>
                          <span className="w-2/3">{key}</span>
                          <span className="w-1/3">{JSON.stringify(value)}</span>
                        </div>
                      ))}
                    </pre>

                    <p className="my-2">
                      <a
                        href="https://app.launchdarkly.com/projects/forgexr_oasis/flags"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                      >
                        View all flags on Launch Darkly
                      </a>
                    </p>
                  </>
                )}

                <div className="border-b border-charcoal-800 w-full my-4" />

                <div>
                  <p className="text-label-sm font-bold mb-2">Release channel:</p>
                  <RadioGroup
                    value={$env.releaseChannel}
                    className="!grid grid-cols-4 bg-transparent text-white"
                    onChange={value => {
                      const channel = OasisSchemas.EnvReleaseChannel.parse(value);
                      Oasis.Env.setReleaseChannel(channel);
                    }}
                  >
                    {['develop', 'alpha', 'beta', 'prod'].map(channel => (
                      <label
                        key={channel}
                        className="flex items-center hover:underline cursor-pointer"
                        htmlFor={channel}
                      >
                        <RadioButton id={channel} value={channel} className="text-white mr-2" />
                        {channel}
                      </label>
                    ))}
                  </RadioGroup>
                </div>

                <div className="mt-6">
                  <p className="text-label-sm font-bold mb-2">Context:</p>
                  <RadioGroup
                    value={$env.context}
                    className="!grid grid-cols-4 bg-transparent text-white"
                    onChange={value => {
                      const context = OasisSchemas.EnvContext.parse(value);
                      Oasis.Env.setDebugContext(context);
                    }}
                  >
                    {['web', 'desktop', 'vr-workshop', 'vr-homespace'].map(context => (
                      <label
                        key={context}
                        className="flex items-center hover:underline cursor-pointer"
                        htmlFor={context}
                      >
                        <RadioButton id={context} value={context} className="text-white mr-2" />
                        {context}
                      </label>
                    ))}
                  </RadioGroup>
                </div>

                <div className="mt-6">
                  <p className="text-label-sm font-bold mb-2">VR target device:</p>
                  <RadioGroup
                    value={$env.vrTargetDevice}
                    className="!grid grid-cols-4 bg-transparent text-white"
                    onChange={value => {
                      const vrTargetDevice = OasisSchemas.Device.parse(value);
                      Oasis.Env.setVrTargetDevice(vrTargetDevice);
                    }}
                  >
                    {['vr', 'desktop'].map(device => {
                      const id = `vr-target-${device}`;

                      return (
                        <label key={device} className="flex items-center hover:underline cursor-pointer" htmlFor={id}>
                          <RadioButton id={id} value={device} className="text-white mr-2" />
                          {device}
                        </label>
                      );
                    })}
                  </RadioGroup>
                </div>

                {$session.user?.id && (
                  <>
                    <div className="mt-6">
                      <p className="text-label-sm font-bold mb-2">License:</p>

                      <RadioGroup
                        value={$session.license.type}
                        className="!grid grid-cols-4 bg-transparent text-white"
                        onChange={type => {
                          switch (type) {
                            case 'PAID':
                              return Oasis.Session.setLicense({ type, trialEligible: false });
                            case 'TRIAL':
                              return Oasis.Session.setLicense({
                                type: 'TRIAL',
                                trialEligible: false,
                                daysRemaining: 30,
                              });
                            case 'NO_ENTITLEMENT':
                            case 'FREE_VIEWER':
                              return Oasis.Session.setLicense({ type, trialEligible: true });
                          }
                        }}
                      >
                        {['No entitlement', 'Trial', 'Free viewer', 'Paid'].map(licenseType => (
                          <label
                            key={licenseType}
                            className="flex items-center hover:underline cursor-pointer"
                            htmlFor={licenseType}
                          >
                            <RadioButton
                              id={licenseType}
                              value={licenseType.toUpperCase().replace(' ', '_')}
                              className="text-white mr-2"
                            />
                            {licenseType}
                          </label>
                        ))}
                      </RadioGroup>

                      <label
                        className="flex items-center hover:underline cursor-pointer mt-4"
                        htmlFor="dev-panel-trial-eligible"
                      >
                        <Checkbox
                          id="dev-panel-trial-eligible"
                          checked={'trialEligible' in $session.license && $session.license.trialEligible}
                          onChange={state => {
                            Oasis.Session.setLicense({ ...$session.license, trialEligible: !!state });
                          }}
                        />
                        <span className="ml-2">Trial eligible</span>
                      </label>

                      {$session.license.type === 'TRIAL' && (
                        <>
                          <label className="mr-2">Days Remaining</label>
                          <input
                            type="number"
                            className="border border-charcoal-100 mt-10 bg-transparent rounded-sm p-2 w-10 text-center tabular-nums"
                            value={$session.license.daysRemaining}
                            onChange={e => {
                              Oasis.Session.setLicense({
                                type: 'TRIAL',
                                trialEligible: $session.license.trialEligible,
                                daysRemaining: e.target.valueAsNumber > 1 ? e.target.valueAsNumber : 1,
                              });
                            }}
                          />
                        </>
                      )}
                    </div>

                    <div className="mt-6">
                      <p className="text-label-sm font-bold mb-2">Missing prerequisites:</p>

                      <div className="grid grid-cols-3 gap-2 mt-5">
                        <label
                          className="flex items-center hover:underline cursor-pointer"
                          htmlFor="dev-panel-missing-hub"
                        >
                          <Checkbox
                            id="dev-panel-missing-hub"
                            checked={$session.missingPrerequisites.includes('hub')}
                            onChange={state => {
                              if (state) {
                                Oasis.Session.addMissingPrerequisite('hub');
                              } else {
                                Oasis.Session.removeMissingPrerequisite('hub');
                              }
                            }}
                          />
                          <span className="ml-2">Hub</span>
                        </label>
                        <label
                          className="flex items-center hover:underline cursor-pointer"
                          htmlFor="dev-panel-missing-project"
                        >
                          <Checkbox
                            id="dev-panel-missing-project"
                            checked={$session.missingPrerequisites.includes('project')}
                            onChange={state => {
                              if (state) {
                                Oasis.Session.addMissingPrerequisite('project');
                              } else {
                                Oasis.Session.removeMissingPrerequisite('project');
                              }
                            }}
                          />
                          <span className="ml-2">Project</span>
                        </label>
                        <label
                          className="flex items-center hover:underline cursor-pointer"
                          htmlFor="dev-panel-missing-profile"
                        >
                          <Checkbox
                            id="dev-panel-missing-profile"
                            checked={$session.missingPrerequisites.includes('profile')}
                            onChange={state => {
                              if (state) {
                                Oasis.Session.addMissingPrerequisite('profile');
                              } else {
                                Oasis.Session.removeMissingPrerequisite('profile');
                              }
                            }}
                          />
                          <span className="ml-2">Profile/Agreement</span>
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-6">
                  <p className="text-label-sm font-bold mb-2">Active VR Tool:</p>

                  <RadioGroup
                    value={$session.activeVrTool}
                    className="!grid grid-cols-3 gap-2 bg-transparent text-white"
                    onChange={tool => {
                      Oasis.Session.setActiveVrTool(tool as ToolNames);
                    }}
                  >
                    {TOOL_NAMES.map(tool => (
                      <label key={tool} className="flex items-center hover:underline cursor-pointer" htmlFor={tool}>
                        <RadioButton id={tool} value={tool} className="text-white mr-2 capitalize" />
                        {tool}
                      </label>
                    ))}
                  </RadioGroup>
                </div>
                {isCameraSync && (
                  <div className="mt-6">
                    <p className="text-label-sm font-bold mb-2">Camera Sync Controls:</p>
                    <RadioGroup
                      value={Oasis.Debug.store.lmvCameraSync ? 'start' : 'stop'}
                      className="!grid grid-cols-2 bg-transparent text-white"
                      onChange={value => {
                        Oasis.Debug.store.lmvCameraSync = value === 'start';
                      }}
                    >
                      <label className="flex items-center hover:underline cursor-pointer" htmlFor="camera-sync-start">
                        <RadioButton id="camera-sync-start" value="start" className="text-white mr-2" />
                        Start
                      </label>
                      <label className="flex items-center hover:underline cursor-pointer" htmlFor="camera-sync-stop">
                        <RadioButton id="camera-sync-stop" value="stop" className="text-white mr-2" />
                        Stop
                      </label>
                    </RadioGroup>
                  </div>
                )}
                <ActiveWorkshopSpoofer />
                <div className="border-b border-charcoal-800 w-full my-4" />

                <div className="flex items-center text-body-sm font-mono">
                  <p className=" ml-auto">Commit: {$env.gitSha}</p>
                </div>
              </div>
            )}

            {page === 'mqtt-outgoing' && <MqttLog variant="outgoing" />}
            {page === 'mqtt-incoming' && <MqttLog variant="incoming" />}
          </div>
        </div>

        <button
          className={clsx(
            'absolute left-1/2 -translate-x-1/2 px-10 text-body-sm uppercase z-50 group',
            isBottom ? 'bottom-full pt-5' : 'top-full pb-5'
          )}
          onTouchStart={() => setIsOpen(!isOpen)}
          onMouseDown={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center pointer-events-none px-2 py-1 bg-yellorange-100 text-yellorange-700 group-hover:bg-yellorange-300">
            Dev
            <ChevronDownIcon
              size={12}
              className={clsx(
                'ml-2 transition-transform',
                isOpen && !isBottom && 'rotate-180',
                !isOpen && isBottom && 'rotate-180'
              )}
            />
          </div>
        </button>
      </div>
      <div className="fixed bottom-2 right-2">
        <ArrowUpIcon className="w-5 h-5 text-green-700 opacity-0" />
      </div>
    </>,
    document.querySelector('body')!
  );
}

function MqttLog(props: { variant: 'outgoing' | 'incoming' }) {
  const $mqtt = Oasis.Mqtt.useStore();
  const log = props.variant === 'outgoing' ? $mqtt.outgoing : $mqtt.incoming;

  if (log.length === 0) {
    return <p className="p-10 text-center">No MQTT messages have been sent.</p>;
  }

  return (
    <ul>
      {log.map((item, index) => (
        <li key={index} className="text-body-sm border-b-1 border-charcoal-800 py-6 last:border-transparent space-y-2">
          <div className="flex items-center w-full">
            <p className="font-bold">{item.topic}</p>
            <p className="ml-auto text-charcoal-400">Sent {formatDistance(item.timestamp, new Date())} ago</p>
          </div>
          <pre>{JSON.stringify(item.payload, null, 2)}</pre>
        </li>
      ))}
    </ul>
  );
}
