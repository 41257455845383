import { ComponentProps } from 'react';

export function ShareToWorkshopIcon(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M34.036 27.692c0 .458-.193 1.06-.86 1.788-.678.74-1.753 1.5-3.233 2.183-2.956 1.364-7.177 2.26-11.943 2.26s-8.987-.896-11.943-2.26c-1.48-.683-2.555-1.444-3.232-2.183-.669-.729-.861-1.33-.861-1.788 0-.458.192-1.059.86-1.788.545-.595 1.348-1.203 2.412-1.774v-2.296C2 23.337 0 25.407 0 27.692 0 32.281 8.059 36 18 36s18-3.72 18-8.308c0-2.285-2-4.355-5.236-5.858v2.296c1.064.571 1.867 1.18 2.412 1.774.667.73.86 1.33.86 1.788Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.284 11.032a9.63 9.63 0 0 0-2.03.049V5.9L18 2.397 13.745 6.26v3.503a1.083 1.083 0 0 1-.29.73.969.969 0 0 1-.691.308H8.509v14.83h6.994c.5.777 1.11 1.477 1.808 2.076H7.527a.969.969 0 0 1-.69-.308 1.083 1.083 0 0 1-.292-.73V9.763c.014-.266.123-.516.306-.699a.954.954 0 0 1 .676-.284h4.255V5.79a1.12 1.12 0 0 1 .09-.434c.06-.136.144-.257.25-.356L17.36.264A.952.952 0 0 1 18.013 0c.242 0 .475.094.655.264l5.236 4.348c.119.096.215.22.28.362.066.141.1.297.1.455v5.603ZM14.02 19.857a9.646 9.646 0 0 0 .052 1.824v.945h-2.618v-2.77h2.566Zm-2.566-2.977v-2.77h2.618v2.77h-2.618Zm5.236-5.719V8.392h2.618v2.77h-2.618Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M31.219 20.5a7.718 7.718 0 1 1-7.719-7.719 7.727 7.727 0 0 1 7.719 7.719Zm-3.76-.792h-3.167v-3.166a.791.791 0 1 0-1.584 0v3.166h-3.166a.791.791 0 1 0 0 1.584h3.166v3.166a.791.791 0 1 0 1.584 0v-3.166h3.166a.791.791 0 1 0 0-1.584Z"
      />
    </svg>
  );
}
