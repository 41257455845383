import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ModalParams {
  onboarding: void;
  'pair-headset': { code: string };
  'create-workshop': void;
  'open-workshop-headset': { workshopId: string; workshopName?: string };
  'open-workshop-desktop': { workshopId: string; workshopName?: string };
  'clear-annotations': { workshopId: string };
  'shared-to-workshop': void;
  'add-user-to-workshop': void;
}

type ModalName = keyof ModalParams;
type MaybeParams<T extends ModalName, P = ModalParams[T]> = P extends object ? [params: P] : [params?: void];

export function useModal<T extends ModalName>(name: T) {
  const [searchParams] = useSearchParams();
  const openModal = useOpenModal();
  const navigateToModal = useNavigateToModal();
  const close = useCloseModal();

  const open = useCallback(
    (...params: MaybeParams<T>) => openModal(name, ...params), //
    [name, openModal]
  );

  const navigateTo = useCallback(
    (pathname: string, ...params: MaybeParams<T>) => navigateToModal(pathname, name, ...params), //
    [name, navigateToModal]
  );

  const params = useMemo(() => {
    const params: Record<string, string> = {};

    for (const [key, value] of searchParams) {
      if (key !== 'dialog') {
        params[key] = value;
      }
    }

    return params as ModalParams[T];
  }, [searchParams]);

  return {
    isOpen: searchParams.get('dialog') === name,
    open,
    close,
    params,
    navigateTo,
  };
}

export function useOpenModal() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback(
    <T extends ModalName>(name: T, ...params: MaybeParams<T>) => {
      searchParams.set('dialog', name);
      const paramsObj = params[0];

      if (paramsObj) {
        for (const key of Object.keys(paramsObj)) {
          const value = paramsObj[key as keyof typeof paramsObj];
          if (value) searchParams.set(key, `${value}`);
        }
      }

      navigate({ search: searchParams.toString() });
    },
    [searchParams, navigate]
  );
}

export function useNavigateToModal() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback(
    <T extends ModalName>(pathname: string, name: T, ...params: MaybeParams<T>) => {
      searchParams.set('dialog', name);
      const paramsObj = params[0];

      if (paramsObj) {
        for (const key of Object.keys(paramsObj)) {
          const value = paramsObj[key as keyof typeof paramsObj];
          if (value) searchParams.set(key, `${value}`);
        }
      }

      navigate({ pathname, search: searchParams.toString() });
    },
    [searchParams, navigate]
  );
}

export function useCloseModal() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback(
    (deleteAdditionalParams?: string[]) => {
      searchParams.delete('dialog');

      if (deleteAdditionalParams) {
        for (const param of deleteAdditionalParams) {
          searchParams.delete(param);
        }
      }

      navigate({ search: searchParams.toString() });
    },
    [searchParams, navigate]
  );
}
