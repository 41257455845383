import { Oasis } from '../../oasis';

export const Dcv = {
  emitInputEvent(type: 'input' | 'textarea', focus: 'focus' | 'blur') {
    Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}`,
      payload: [{ type: 'TextBoxFocus', args: { type, focus } }],
    });
  },

  emitClickEvent(value: string) {
    Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}`,
      payload: [{ type: 'ClickedOnFile', args: { urn: value } }],
    });
  },
};
