export function WorkshopsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
      <path
        fill="currentColor"
        d="M18.982 18.972H5.018a.646.646 0 0 1-.46-.206.722.722 0 0 1-.194-.487V7.01a.71.71 0 0 1 .203-.466.636.636 0 0 1 .451-.19h2.836V4.36c.001-.1.022-.198.061-.288a.71.71 0 0 1 .166-.238L11.572.676A.635.635 0 0 1 12.01.5c.16 0 .316.063.436.176l3.49 2.899a.677.677 0 0 1 .188.24.716.716 0 0 1 .066.304v6.102h2.793c.173 0 .34.073.463.203.122.13.191.306.191.49v7.384a.72.72 0 0 1-.2.475.645.645 0 0 1-.454.199Zm-13.31-1.385h12.655v-6h-2.836a.645.645 0 0 1-.46-.205.722.722 0 0 1-.195-.487V4.433L12 2.098 9.164 4.673V7.01a.722.722 0 0 1-.195.486.646.646 0 0 1-.46.206H5.673v9.886Zm10.691-3.85h-1.745v1.847h1.745v-1.846Zm-6.981 0H7.636v1.847h1.746v-1.846Zm0-3.83H7.636v1.846h1.746V9.907Zm3.49 3.83h-1.745v1.847h1.746v-1.846Zm0-3.83h-1.745v1.846h1.746V9.907Zm0-3.812h-1.745V7.94h1.746V6.095Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.69 18.961c0 .306-.128.707-.573 1.193-.451.492-1.168.999-2.155 1.455-1.97.91-4.785 1.506-7.962 1.506-3.177 0-5.991-.597-7.962-1.506-.987-.456-1.704-.963-2.155-1.456-.445-.485-.574-.886-.574-1.192 0-.305.129-.706.574-1.192.363-.396.898-.802 1.608-1.182v-1.53C1.333 16.056 0 17.436 0 18.96c0 3.06 5.373 5.539 12 5.539s12-2.48 12-5.539c0-1.523-1.333-2.903-3.49-3.905v1.53c.709.381 1.244.787 1.607 1.183.445.486.574.887.574 1.192Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
