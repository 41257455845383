import { Oasis } from '@oasis/sdk';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { landingUrl } from '~/shared/utils/const.asset-urls';
import { ProfileForm } from './components/profile-form';

interface Props {
  isTrial?: boolean;
}

export default function AgreementPage(props: Props) {
  const $session = Oasis.Session.useStore();
  const branding = useTemporaryBranding();

  return (
    <main
      className="min-w-screen min-h-screen flex bg-cover overflow-auto py-10"
      style={{ backgroundImage: `url(${landingUrl})` }}
    >
      <section className="w-full max-w-md bg-white shadow-high rounded-md p-10 m-auto">
        <header className="text-center space-y-4 mb-6">
          <h1 className="text-heading-2">Hi, {$session.user?.firstName}</h1>
          <p className="text-left">
            Welcome to Autodesk Workshop XR! Get started by updating your profile information and reviewing the
            disclosure, which contains important information about our services.
          </p>
        </header>

        <ProfileForm isTrial={props.isTrial} />

        <div className="flex justify-center mt-6">
          <img src={branding.logoUrl} alt={branding.productName + ' logo'} className="h-[2.125rem]" />
        </div>
      </section>
    </main>
  );
}
