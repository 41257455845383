import * as AlertDialog from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { iconXUrl } from '~/shared/utils/const.asset-urls';
import { Button } from './button';

interface ConfirmationStore {
  isOpen: boolean;
  isLoading: boolean;
  isNonDestructive: boolean;
  title: string;
  description: string;
  confirmLabel: string;
  confirm(): Promise<void>;
  cancel(): Promise<void>;
}

const store = proxy<ConfirmationStore>({
  isOpen: false,
  isLoading: false,
  isNonDestructive: false,
  title: '',
  description: '',
  confirmLabel: 'Submit',
  async confirm() {},
  async cancel() {},
});

export function Confirmation() {
  const $store = useProxy(store);

  async function handleConfirm() {
    $store.isLoading = true;
    await $store.confirm();
    $store.isLoading = false;
    Confirmation.hide();
  }

  async function handleCancel() {
    try {
      await $store.cancel();
    } finally {
      Confirmation.hide();
    }
  }

  return (
    <AlertDialog.Root
      open={$store.isOpen}
      onOpenChange={isOpen => {
        $store.isOpen = isOpen;
      }}
    >
      <section
        className={clsx(
          'fixed z-30 inset-0 bg-dark-blue-900.45 flex items-center justify-center transition-opacity duration-300',
          !$store.isOpen && 'hidden opacity-0'
        )}
      >
        <AlertDialog.Content
          className={clsx(
            'overflow-auto w-full px-6 py-4 max-w-md max-h-[96vh] bg-white shadow-high rounded-md transition-all ease-in-out duration-300',
            !$store.isOpen && '-translate-y-5 opacity-0'
          )}
        >
          <header className="sticky top-0 left-0 bg-white flex justify-between items-center w-full">
            <AlertDialog.Title className="text-heading-2">{$store.title}</AlertDialog.Title>

            <div className="flex items-center ml-auto -mr-3">
              <AlertDialog.Cancel className="flex w-10 h-10 justify-center items-center opacity-50 hover:opacity-100">
                <img src={iconXUrl} className="block" alt="Close icon" />
              </AlertDialog.Cancel>
            </div>
          </header>

          <div className="my-6">
            <AlertDialog.Description>{$store.description}</AlertDialog.Description>
          </div>

          <footer className="flex justify-end space-x-2">
            <AlertDialog.Cancel asChild>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <Button
              variant={$store.isNonDestructive ? 'primary' : 'primary-destructive'}
              onClick={handleConfirm}
              isLoading={$store.isLoading}
            >
              {$store.confirmLabel}
            </Button>
          </footer>
        </AlertDialog.Content>
      </section>
    </AlertDialog.Root>
  );
}

Confirmation.show = (params: {
  title: string;
  description: string;
  confirmLabel?: string;
  isNonDestructive?: boolean;
  confirm?: () => void | Promise<void>;
}): Promise<boolean> => {
  return new Promise(resolve => {
    store.title = params.title;
    store.description = params.description;
    store.confirmLabel = params.confirmLabel ?? 'Submit';
    store.isNonDestructive = params.isNonDestructive ?? false;
    store.isOpen = true;
    store.confirm = async () => {
      await params.confirm?.();
      return resolve(true);
    };
    store.cancel = async () => {
      return resolve(false);
    };
  });
};

Confirmation.hide = () => {
  store.isOpen = false;
  store.isLoading = false;
  store.isNonDestructive = false;
  store.title = '';
  store.description = '';
  store.confirmLabel = 'Submit';
  store.confirm = async () => {};
  store.cancel = async () => {};
};
