import { QueryClient, onlineManager } from '@tanstack/react-query';

/**
 * There were some issues in VR where our manual online/offline checks and state
 * weren't in sync with the default listener. This is a workaround to ensure that
 * the online state is controlled by our changes to `Env.store.online`
 */
onlineManager.setEventListener(() => {
  return () => {};
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10_000,
    },
    mutations: {
      retry: false,
    },
  },
});
