import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useEffect, useRef, useState, type ComponentProps, type ReactNode } from 'react';

interface Props extends ComponentProps<'img'> {
  urn?: string;
  guid?: string;
  fallback?: ReactNode;
  lazyload?: boolean;
}

export function DerivativeThumbnail({ urn, guid, fallback, lazyload, ...props }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [src, setSrc] = useState('');
  const [forceFallback, setForceFallback] = useState(false);

  useEffect(() => {
    let controller: AbortController | undefined;
    let derivativeUrn = urn;

    if (derivativeUrn?.includes(':')) {
      const chunks = derivativeUrn.split(':') ?? [];
      derivativeUrn = chunks[chunks.length - 1]?.split('/')[0];
    }

    if (ref.current && derivativeUrn) {
      async function fetchThumbnail() {
        controller = new AbortController();

        const res = await Oasis.Files.getDerivativeThumbnail(
          { urn: derivativeUrn!, guid },
          { signal: controller.signal }
        );

        if (res.ok) {
          setSrc(URL.createObjectURL(res.value));
        }
      }

      if (!lazyload) {
        fetchThumbnail();
        return () => controller?.abort('<DerivativeThumbnail /> unmounted');
      }

      function callback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        for (const entry of entries) {
          if (entry.isIntersecting && derivativeUrn) {
            fetchThumbnail();
            observer.disconnect();
          }
        }
      }

      const observer = new IntersectionObserver(callback);
      observer.observe(ref.current);

      return () => {
        controller?.abort('<DerivativeThumbnail /> unmounted');
        observer.disconnect();
      };
    }
  }, [urn, guid, lazyload]);

  return (
    <div ref={ref} className="flex items-center justify-center">
      {src && !forceFallback ? (
        <img {...props} src={src} className={clsx(src && 'bg-white')} onError={() => setForceFallback(true)} />
      ) : (
        fallback
      )}
    </div>
  );
}
