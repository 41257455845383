import { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

export function AccDocumentIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M14.94 1.89H7.05A3.76 3.76 0 0 0 3.3 5.63v12.74a3.76 3.76 0 0 0 3.75 3.75H17a3.76 3.76 0 0 0 3.75-3.75V7.64l-5.81-5.75Zm4.26 16.48a2.25 2.25 0 0 1-2.2 2.25H7.05a2.25 2.25 0 0 1-2.25-2.25V5.63a2.25 2.25 0 0 1 2.25-2.25h6.89v5.26h5.26v9.73Z"
      />
      <path
        fill="currentColor"
        d="m7.054 17.364 6.123-3.545h3.177a.198.198 0 0 1 .13.051.171.171 0 0 1 .055.123.14.14 0 0 1-.02.084.157.157 0 0 1-.067.06l-3.007 1.678c-.196.11-.264.328-.264.491l-.003 1.058H17v-6.145a.204.204 0 0 0-.019-.086.22.22 0 0 0-.053-.072.237.237 0 0 0-.172-.06h-3.647L7 14.528v2.835h.054Z"
      />
    </svg>
  );
}
