import Button from '@adsk/alloy-react-button/es/Button';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConditionalWrapper } from '~/shared/components/base/conditional-wrapper';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { useUI } from '~/shared/hooks/use-ui-store';
import { ActiveWorkshopBanner } from './active-workshop-banner';
import { HeadsetsMenu } from './headsets-menu';
import { InfoMenu } from './info-menu';
import { ProjectPicker } from './project-picker';
import { TrialBanner } from './trial-banner';
import { UpdatesMenu } from './updates-menu';
import { UserProfileMenu } from './user-profile-menu';

export const Header = memo(function _Header() {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const $ui = useUI();
  const navigate = useNavigate();

  const smallLogo = $env.isVr || $ui.collapsedNav;
  const branding = useTemporaryBranding();

  if ($session.status === 'UNAUTHENTICATED') {
    return (
      <header className="h-12 px-2 flex items-center justify-between">
        <img
          src={smallLogo ? branding.productIconUrl : branding.logomarkUrl}
          alt={branding.productName + ' logo'}
          className="block h-[2.125rem]"
        />

        <Button
          as="a"
          href="/login"
          size="small"
          onClick={e => {
            e.preventDefault();
            navigate('/login', { replace: true });
          }}
        >
          Login
        </Button>
      </header>
    );
  }

  return (
    <header>
      <ActiveWorkshopBanner />

      <div className="relative z-30 flex items-center h-12 border-b border-charcoal-100 px-2">
        <ConditionalWrapper
          condition={!$env.isVr}
          wrapper={children => (
            <Link to="/" onClick={e => $env.isVr && e.preventDefault()}>
              {children}
            </Link>
          )}
        >
          <img
            src={smallLogo ? branding.productIconUrl : branding.logomarkUrl}
            alt={branding.productName + ' logo'}
            className="block h-[2.125rem]"
          />
        </ConditionalWrapper>

        <ProjectPicker />

        <div className="flex items-center ml-auto">
          {!($env.releaseChannel === 'beta') && <TrialBanner />}

          {!$env.isVr && (
            <div className="flex items-center">
              <InfoMenu />
              <HeadsetsMenu />
            </div>
          )}

          {$env.isDesktop && <UpdatesMenu />}
          <div className={clsx('mx-5', !$env.isVr && 'w-[1px] h-8 bg-charcoal-200')} />
          <UserProfileMenu />
        </div>
      </div>
    </header>
  );
});
