import { CheckmarkCircleFilledIcon, MoreVerticalIcon, XIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { Oasis } from '@oasis/sdk';
import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { DerivativeThumbnail } from '~/shared/components/base/derivative-thumbnail';
import { ShareToWorkshopButton } from '~/shared/components/base/share-to-workshop-button';
import { LargeDocumentIcon } from '~/shared/components/icons/large-document-icon';
import { ShareToWorkshopIcon } from '~/shared/components/icons/share-to-workshop-icon';
import { Queries } from '~/shared/hooks/queries';
import { FileDetailsContextMenu } from './file-details-context-menu';

interface Props {
  projectId: string;
  fileUrn: string;
}

export function FileDetailsPanel(props: Props) {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = { projectId: props.projectId, documentId: props.fileUrn };
  const file = Queries.Files.useFindDocumentById(queryParams);
  const views = Queries.Files.useList3dViews(queryParams);
  const activeModel = Queries.Users.useGetActiveModel(props.projectId);

  function closePanel() {
    searchParams.delete('fileDetailsUrn');
    setSearchParams(searchParams);
  }

  const isLoading = file.isFetching || views.isFetching;
  const userIsViewOnly = $session.activeWorkshop?.permission === 'VIEW';

  return (
    <section className="flex flex-col h-full">
      <header className="flex items-center p-4 border-b border-charcoal-100">
        <h2 className="text-heading-2 mr-20 flex-1 overflow-hidden">{file.data?.filename}</h2>

        <div className="ml-auto space-x-3 pr-2">
          <button
            className="ml-auto flex items-center justify-center appearance-none opacity-50 hover:opacity-100"
            onClick={closePanel}
          >
            <XIcon />
          </button>
        </div>
      </header>

      {isLoading && (
        <div className="flex-1 w-full h-full flex flex-col items-center justify-center">
          <ProgressRing size="medium" />
        </div>
      )}

      {!isLoading && file.data && views.data && (
        <div className="flex-1 overflow-auto p-4">
          <h2 className="text-label-sm mb-2">3D Views</h2>
          {views.data && (
            <ul className="border-t border-l border-r border-charcoal-100">
              {views.data
                .sort(view => (activeModel.data?.activeViewGuid === view.guid ? -1 : 1))
                .map((view, index) => {
                  const isViewShared = activeModel.data?.activeViewGuid === view.guid;

                  // Using `index` as key is not ideal, but the ShareToWorkshopButton's Tooltip would get
                  // stuck in a weird persistent state after reordering the list with `view.guid` as key.
                  return (
                    <li key={index} className="flex items-center gap-4 border-b border-charcoal-100 p-2">
                      <div className="bg-charcoal-50 w-[5.625rem] h-[3.75rem] overflow-hidden flex">
                        <DerivativeThumbnail
                          urn={view.urn}
                          guid={view.guid}
                          alt={`${view.name} thumbnail`}
                          className="w-[5.625rem] h-[3.75rem] object-contain"
                          fallback={<LargeDocumentIcon className="m-auto w-14" />}
                        />
                      </div>

                      <div className="flex-1 flex items-center gap-4 overflow-hidden">
                        <p className="flex-1 truncate">{view.name}</p>

                        <div className="flex items-center">
                          <ShareToWorkshopButton
                            tooltipPlacement="left"
                            fileType={file.data.fileType}
                            fileVersionUrn={file.data.latestVersionId}
                            viewGuid={view.guid}
                            isFileShared={isViewShared}
                            isFileProcessing={file.data.isProcessing}
                            isShortHand
                            is3dView
                          >
                            <button
                              className={clsx(
                                'group inline-flex items-center justify-center h-8 w-8 cursor-pointer text-charcoal-700',
                                'disabled:cursor-default',
                                !userIsViewOnly && 'hover:text-blue-500 focus-visible:bg-blue-500'
                              )}
                            >
                              {isViewShared ? (
                                <CheckmarkCircleFilledIcon className="text-green-500" size={20} />
                              ) : (
                                <ShareToWorkshopIcon className="w-6 h-6 group-disabled:opacity-40" />
                              )}
                            </button>
                          </ShareToWorkshopButton>

                          {isViewShared && $env.releaseChannel !== 'prod' && (
                            <Popover.Root>
                              <Popover.Trigger
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                className={clsx(
                                  'inline-flex items-center justify-center h-8 w-8 cursor-pointer',
                                  'group-hover:opacity-100 hover:text-blue-500',
                                  'disabled:hover:text-charcoal-700 disabled:group-hover:opacity-60'
                                )}
                              >
                                <MoreVerticalIcon />
                              </Popover.Trigger>
                              <Popover.Portal>
                                <Popover.Content
                                  hideWhenDetached
                                  side="bottom"
                                  align="end"
                                  sideOffset={-8}
                                  alignOffset={8}
                                  className="w-full z-20"
                                >
                                  <FileDetailsContextMenu />
                                </Popover.Content>
                              </Popover.Portal>
                            </Popover.Root>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      )}
    </section>
  );
}
