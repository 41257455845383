import { Oasis } from '../../oasis';

export const Pendo = {
  blocked: false,

  init(apiKey: string) {
    return new Promise<unknown>(resolve => {
      const el = document.createElement('script');

      el.async = true;
      el.src = `https://content.p.workshop.autodesk.com/agent/static/${apiKey}/pendo.js`;
      el.onerror = resolve;
      el.onload = () => {
        pendo.initialize();
        resolve(true);
      };

      document.body.appendChild(el);
    });
  },

  identify(params: pendo.Identity) {
    if (Oasis.Env.store.isDevMode) {
      Oasis.Logger.info({ params, msg: '[Pendo.identify]' });
    }

    if (Oasis.Session.store.status === 'AUTHENTICATED') {
      params.visitor = {
        id: Oasis.Session.store.user.analyticsId,
        oxygen_id: Oasis.Session.store.user.id,
        email: Oasis.Session.store.user.email,
        first_name: Oasis.Session.store.user.firstName,
        last_name: Oasis.Session.store.user.lastName,
        country_code: Oasis.Session.store.user.country,
        license_type: Oasis.Session.store.license.type,
        wxr_license_type: Oasis.Session.store.license.type,
        wxr_release_channel: Oasis.Env.store.releaseChannel,
        wxr_context: Oasis.Env.store.context,
        wxr_git_sha: Oasis.Env.store.gitSha,
        wxr_is_dev_mode: Oasis.Env.store.isDevMode,
        ...params.visitor,
      };
    }

    try {
      pendo?.identify(params);
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[Pendo.identify]' });
    }
  },

  track(label: string, attrs: pendo.Metadata, omitEnvContext = false) {
    if (Oasis.Env.store.isDevMode) {
      Oasis.Logger.info({ label, attrs, msg: '[Pendo.track]' });
    }

    if (!omitEnvContext) {
      attrs = {
        ...attrs,
        context: Oasis.Env.store.context,
      };
    }

    try {
      pendo?.track(label, attrs);
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[Pendo.track]' });
    }
  },

  flush() {
    return pendo?.flushNow?.();
  },
};
