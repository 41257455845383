import { format, formatISO } from 'date-fns';

export const DateUtils = {
  getFriendlyFormat(date: Date | string, opts?: { includeTime?: boolean }) {
    if (typeof date === 'string') {
      date = date.length ? new Date(date) : new Date();
    }

    let template = 'MMM d, yyyy';

    if (opts?.includeTime) {
      template += ' h:mmaaa';
    }

    try {
      return format(date, template);
    } catch {
      return '';
    }
  },

  getDateAsValue(value: Date | string) {
    if (typeof value === 'string') {
      value = new Date(value);
    }

    return formatISO(value, { representation: 'date' });
  },

  getDaysBetweenDates(endDate: Date, startDate: Date) {
    const dateDifferenceInMs = endDate.valueOf() - startDate.valueOf();
    return dateDifferenceInMs / (1000 * 60 * 60 * 24);
  },
};
