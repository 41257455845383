import clsx from 'clsx';
import { useMemo } from 'react';
import { Combobox } from '~/shared/components/forms/combobox';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { usePermittedAssignees } from '../../../hooks/use-permitted-assignees';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  value?: string | null;
  onChange(value: { type: 'user' | 'company' | 'role' | null; id: string }): void;
  required?: boolean;
}

export function CreateIssueAssignedToField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const combobox = Combobox.useComboboxProps();

  const projectUsersQuery = Queries.Projects.useListProjectUsers({ projectId });
  const projectRolesQuery = Queries.Projects.useListRoles({ projectId });
  const projectCompaniesQuery = Queries.Projects.useListCompanies({ projectId });
  const userPermissions = Queries.Issues.useGetUserPermissions({ projectId, platform });
  const permittedActions = userPermissions.data?.issues.new.permittedActions || [];
  const canAssignAll = permittedActions && permittedActions.includes('assign_all');
  const canAssignSameCompany = permittedActions && permittedActions.includes('assign_same_company');

  const displayValue = useMemo(() => {
    const user = projectUsersQuery.data?.results.find(user => user.autodeskId === props.value);
    if (user) {
      let value = user.name ?? 'Project User';
      if (user.companyName) value += ` (${user.companyName})`;
      return value;
    }

    const role = projectRolesQuery.data?.results.find(role => role.memberGroupId === props.value);
    if (role) return role.name;

    const company = projectCompaniesQuery.data?.results.find(company => company.memberGroupId === props.value);
    if (company) return company.name;

    return '';
  }, [projectUsersQuery.data, projectRolesQuery.data, projectCompaniesQuery.data, props.value]);

  const sections = usePermittedAssignees(projectId, canAssignSameCompany);

  function handleClick(type: 'user' | 'company' | 'role' | null, id: string) {
    if (props.value === id) {
      props.onChange({ type: null, id: '' });
    } else {
      props.onChange({ type, id });
    }
  }

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        Assigned To
      </Label>

      <Combobox
        {...combobox}
        name="assigned-to"
        value={props.value ?? ''}
        displayValue={displayValue}
        placeholder="Select a member, role or company"
        disabled={!canAssignAll && !canAssignSameCompany}
      >
        <Combobox.TriggerInput />
        <Combobox.Content>
          {sections.map(
            section =>
              section.items.length !== 0 && (
                <Combobox.Section key={section.title} title={section.title}>
                  {section.title === 'Member' &&
                    section.items.map(item => {
                      if (!item.autodeskId || combobox.filterItem(`${item.name} ${item.companyName}`)) {
                        return null;
                      }

                      return (
                        <Combobox.Item
                          key={item.id}
                          value={item.autodeskId}
                          onClick={() => {
                            if (item.autodeskId) {
                              handleClick('user', item.autodeskId);
                            }
                          }}
                          disabled={!item.autodeskId}
                        >
                          <div>
                            <p className={clsx(!item.name && 'italic')}>{item.name || 'Project User'}</p>
                            {item.companyName && <p className="text-charcoal-500">{item.companyName}</p>}
                          </div>
                        </Combobox.Item>
                      );
                    })}

                  {section.title === 'Role' &&
                    section.items.map(item => {
                      if (combobox.filterItem(item.name)) {
                        return null;
                      }

                      return (
                        <Combobox.Item key={item.id} value={item.id} onClick={() => handleClick('role', item.id)}>
                          <p>{item.name}</p>
                        </Combobox.Item>
                      );
                    })}

                  {section.title === 'Company' &&
                    section.items.map(item => {
                      if (combobox.filterItem(item.name)) {
                        return null;
                      }

                      return (
                        <Combobox.Item key={item.id} value={item.id} onClick={() => handleClick('company', item.id)}>
                          <p>{item.name}</p>
                        </Combobox.Item>
                      );
                    })}
                </Combobox.Section>
              )
          )}
        </Combobox.Content>
      </Combobox>
    </div>
  );
}
