import Button from '@adsk/alloy-react-button';
import Checkbox from '@adsk/alloy-react-checkbox';
import { Oasis } from '@oasis/sdk';
import { useState, type FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Mutations } from '~/shared/hooks/mutations';
import { useNavigateToModal } from '~/shared/hooks/use-modal';

export function ConsentForm() {
  const $session = Oasis.Session.useStore();
  const navigate = useNavigate();
  const navigateToModal = useNavigateToModal();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const updateProfile = Mutations.Auth.useUpdateUserProfile();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (!acceptedTerms) {
      return NotificationManager.push({
        status: 'error',
        content: 'You must agree to the disclosure notice.',
      });
    }

    updateProfile.mutate(
      {
        displayName: `${$session.user?.firstName} ${$session.user?.lastName}`,
      },
      {
        async onSuccess() {
          const user = await Oasis.Session.init({ connect: true });

          if (!user.ok) {
            NotificationManager.push({
              status: 'error',
              content: 'Something went wrong verifying your license. Please try again.',
            });

            navigate('/login');
            return;
          }

          if (user.value.license?.valid) {
            navigateToModal('/projects', 'onboarding');
            return;
          }

          Oasis.Session.setLicense({ type: 'FREE_VIEWER', trialEligible: false });
          navigateToModal('/projects', 'onboarding');
        },
      }
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex items-center">
        <Checkbox
          disabled={updateProfile.isPending}
          checked={acceptedTerms}
          onChange={checked => {
            if (typeof checked === 'boolean') {
              setAcceptedTerms(checked);
            }
          }}
        />
        <p className="ml-4">
          I acknowledge the{' '}
          <a href="/about#headset-data-collection" target="_blank" className="text-blue-700 hover:underline">
            Headset Data Collection Notice&nbsp;Disclosure
          </a>
          .
          <span className="text-red-500" aria-label="required">
            *
          </span>
        </p>
      </div>

      <p className="mt-10 border-t border-charcoal-100 flex justify-between gap-5 py-4 px-6 -mx-6">
        <Button
          type="submit"
          disabled={!acceptedTerms}
          loading={updateProfile.isPending}
          variant="primary"
          className="ml-auto"
        >
          Finish
        </Button>
      </p>
    </form>
  );
}
