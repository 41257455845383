import type { Function } from 'ts-toolbelt';

export type Result<Data, Error = undefined> = { ok: true; value: Data } | { ok: false; error: Error };

export function Ok<Data>(data: Data): Result<Data, never> {
  return { ok: true, value: data };
}

export function Err<Error extends { code: string; title?: string; description?: string }>(
  error: Function.Narrow<Error>
): Result<never, Function.Narrow<Error>> {
  return { ok: false, error };
}
