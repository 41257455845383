import { CheckmarkIcon, PencilIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Dropdown } from '~/shared/components/forms/dropdown';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { getStatusColorClasses, getStatusLabel } from '~/shared/utils/const.issues';
import { useUpdateIssueContext } from '../context';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  status: string;
  onSubmit(value: string): void;
}

const BIM_360_STATUSES = [
  'draft',
  'open',
  'work_completed',
  'ready_to_inspect',
  'in_dispute',
  'not_approved',
  'closed',
  'void',
];

export function UpdateIssueStatusField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const { permittedAttributes, permittedStatuses } = useUpdateIssueContext();

  const label = getStatusLabel(props.status);
  const colorClasses = getStatusColorClasses(props.status);
  const canEdit = permittedAttributes.includes('status');

  const userPermissions = Queries.Issues.useGetUserPermissions({ projectId, platform });

  const statusValues = useMemo(() => {
    if (permittedStatuses.length) {
      return permittedStatuses;
    }

    if (platform === 'acc' && userPermissions.data?.issues.new.permittedStatuses) {
      return userPermissions.data.issues.new.permittedStatuses || ['draft', 'open'];
    }

    if (props.status === 'draft') {
      return ['draft', 'open'];
    }

    return BIM_360_STATUSES;
  }, [props.status, userPermissions.data, platform, permittedStatuses]);

  return (
    <div>
      <Label className="mb-2">Status</Label>

      <InlineEditable canEdit={canEdit}>
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <Dropdown open>
              <Dropdown.Trigger className="flex items-center p-2 border border-blue-500 shadow-halo-sm shadow-blue-100 rounded">
                <div className={clsx('w-1 h-4 mr-2 rounded-full', colorClasses)} />
                {label}
                <PencilIcon className="ml-3" size={16} />
              </Dropdown.Trigger>

              <Dropdown.Content onClick={e => e.stopPropagation()}>
                <Dropdown.RadioGroup
                  onValueChange={nextValue => {
                    // setValue(nextValue as keyof typeof STATUS_COLOR_CLASSES);
                    props.onSubmit(nextValue);
                    setIsEditing(false);
                  }}
                >
                  {statusValues.map(status => {
                    const isActive = status === props.status;

                    return (
                      <Dropdown.RadioItem
                        key={status}
                        value={status}
                        className={clsx('flex items-center', isActive && 'bg-blue-50')}
                        disabled={status === 'draft'}
                      >
                        <div className={clsx('w-1 h-4 mr-2 rounded-full', getStatusColorClasses(status))} />
                        <div>
                          <p>{getStatusLabel(status)}</p>
                          {status === 'work_completed' && (
                            <p className="text-body-sm text-charcoal-500">Assign for internal inspection</p>
                          )}
                          {status === 'ready_to_inspect' && (
                            <p className="text-body-sm text-charcoal-500">Assign for external review</p>
                          )}
                        </div>

                        <div className="w-20 ml-auto">
                          {isActive && <CheckmarkIcon className="ml-auto text-blue-600" />}
                        </div>
                      </Dropdown.RadioItem>
                    );
                  })}
                </Dropdown.RadioGroup>
              </Dropdown.Content>
            </Dropdown>
          ) : (
            <div className="flex items-center">
              <div className={clsx('w-1 h-4 mr-2 rounded-full', colorClasses)} />
              {label}
            </div>
          );
        }}
      </InlineEditable>
    </div>
  );
}
