export const TYPES = {
  /**********************Old Types*********************/
  MEASUREMENT_1_0_0: 'autodesk.workshopxr:measurement-1.0.0',

  /**********************New Types*********************/
  CONTROLS: 'viewer:controls-1.0.0',
  TOOLS: 'viewer:tool-1.0.0',
  VECTOR4: 'viewer:vector4-1.0.0',
  VISIBILITY_MANAGER: 'viewer:visibilityManager-1.0.0',
  MEASUREMENT: 'autodesk.workshopxr:measurement-1.0.1',
  POSITION: 'autodesk.workshopxr:position-1.0.0',
  ANNOTATION: 'autodesk.workshopxr:annotation-1.0.0',
  SESSION: 'autodesk.workshopxr:session-1.0.0',
};

export const PROPERTY_NAMES = {
  CONTROLS: 'controls',
  VISIBILITY_MANAGER: 'visibilityManager',
  HIDDEN_NODES: 'hiddenNodes',
  OASIS_HIDDEN_NODES: 'oasisHiddenNodes',
  ISOLATED_NODES: 'isolatedNodes',
  EXPLODE: 'explode',
  CUTPLANES: 'cutplanes',
  TOOLS: 'tools',
  MODEL_URN: 'modelUrn',
};
