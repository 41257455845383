import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  CloudUpArrowIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashCanIcon,
} from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { ShareToWorkshopIcon } from '../../icons/share-to-workshop-icon';

const ICONS = {
  plus: PlusIcon,
  upload: CloudUpArrowIcon,
  search: MagnifyingGlassIcon,
  delete: TrashCanIcon,
  chevronLeft: ChevronLeftIcon,
  'share-to-workshop': ShareToWorkshopIcon,
  arrowLeft: ArrowLeftIcon,
};

export function ButtonIcon({ icon, position }: { icon: keyof typeof ICONS; position: 'left' | 'right' }) {
  const Icon = icon ? ICONS[icon] : null;

  return !Icon ? null : <Icon className={clsx('w-6 h-6', position === 'left' ? '-ml-2 mr-2' : '-mr-2 ml-2')} />;
}
