import Checkbox from '@adsk/alloy-react-checkbox';
import { ChevronDownIcon } from '@adsk/alloy-react-icon';
import { WorkshopPermissions } from '@oasis/sdk';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { PermissionsIndicator } from '../base/permissions-indicator';
import { TextInput } from './text-input';

interface Props {
  value?: WorkshopPermissions | null;
  onChange: (permission: WorkshopPermissions) => void;
}

const PermissionsArr = ['VIEW', 'EDIT', 'MANAGE'] as const;

const PermissionsMap = {
  VIEW: {
    display: 'View',
    description: 'View models and workshop content.',
    level: 1,
  },
  EDIT: {
    display: 'Edit',
    description: 'Share and update models, guide meetings.',
    level: 2,
  },
  MANAGE: {
    display: 'Manage',
    description: 'Full administrative control.',
    level: 3,
  },
} as const;

export function PermissionsSelect({ value, onChange }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useClickAway(containerRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div ref={containerRef} className="relative">
      <div className="relative">
        <TextInput
          readOnly
          className="appearance-none w-full"
          value={value ? PermissionsMap[value].display : ''}
          onFocus={() => setIsOpen(true)}
        />
        <button
          className="absolute flex items-center justify-center top-0 right-0 bottom-0 touch-none pr-2 opacity-50"
          onClick={() => setIsOpen(!isOpen)}
        >
          <ChevronDownIcon className={clsx('transition-transform', isOpen && 'rotate-180')} />
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-10 pt-1 top-full left-[1px] right-[1px] flex">
          <ul
            role="listbox"
            aria-multiselectable={true}
            aria-label="Options"
            className="w-full bg-white border border-charcoal-200 shadow-high"
          >
            {PermissionsArr.map(permission => {
              const isSelected = value === permission;
              const item = PermissionsMap[permission];

              return (
                <li
                  tabIndex={0}
                  key={permission}
                  role="option"
                  aria-selected={isSelected}
                  className="w-full flex items-center p-3 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50"
                  onClick={() => {
                    onChange(permission as WorkshopPermissions);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex items-center w-full">
                    <Checkbox checked={isSelected} className="mr-2" />

                    <div className="ml-2">
                      <p className="text-label-md">{item.display}</p>
                      <p className="text-label-sm">{item.description}</p>
                    </div>

                    <div className="ml-auto mr-3">
                      <PermissionsIndicator level={item.level} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
