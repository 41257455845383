import { LinkButton } from '@adsk/alloy-react-button';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function OauthRedirectPage() {
  const $env = Oasis.Env.useStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // When someone is going to login clear out any remneants of a previous session.
  useEffect(() => {
    Oasis.Storage.reset();
    Oasis.TokenManager.destroyTokens();
  }, []);

  useEffect(() => {
    async function onMount() {
      const start = await Oasis.Bridge?.startOauthServer();

      const unlisten = await Oasis.Bridge?.listenForOauthRedirect(tokens => {
        if (tokens) {
          navigate(`/oauth/login?access_token=${tokens.accessToken}&refresh_token=${tokens.refreshToken}`);
        }
      });

      await Oasis.Session.loginWithRedirect({
        destination: searchParams.get('trial') ? 'trial' : 'login',
        redirectUri: start && start.ok ? `http://127.0.0.1:${start.value.port}` : undefined,
      });

      return () => unlisten?.();
    }

    onMount();
  }, [navigate, searchParams]);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <ProgressRing size="large" />

        {$env.isDesktop ? (
          <p className="text-body-lg mt-5">Login with your browser...</p>
        ) : (
          <p className="text-body-lg mt-5">Redirecting...</p>
        )}

        <p className="text-body-sm mt-3">
          <LinkButton onClick={() => navigate('/login', { replace: true })} className="text-blue-500 font-medium">
            Back
          </LinkButton>
        </p>
      </div>
    </div>
  );
}
