import type { ConnectionState, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng/esm';
import { proxy, useSnapshot } from 'valtio';
import { Err, Ok } from '../../lib/result';
import { Oasis } from '../../oasis';
import { Agora } from '../../providers/agora/agora.provider';

interface Store {
  active: boolean;
  status: ConnectionState;
  mic: IMicrophoneAudioTrack | undefined;
  workshop: { id: string; name: string } | undefined;
}

export const Voice = {
  store: proxy<Store>({
    active: false,
    mic: undefined,
    status: 'DISCONNECTED',
    workshop: undefined,
  }),

  useStore: () => useSnapshot(Voice.store),

  init() {
    Agora.init({
      onStatusChange: status => {
        Voice.store.status = status;
      },
    });
  },

  async connect(params: { workshopId: string }) {
    const result = await Oasis.Workshops.findWorkshopById(params.workshopId);

    if (!result.ok) {
      Voice.store.active = false;
      return result;
    }

    if (!result.value.settings?.voice?.channelToken) {
      Voice.store.active = false;
      Oasis.Logger.error('[Voice.connect] Voice channel token is missing');
      return Err({ code: 'INVALID_CHANNEL_TOKEN' });
    }

    if (!Oasis.Session.store.user) {
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Agora.connect({
      channel: result.value.id,
      channelToken: result.value.settings.voice.channelToken,
      uid: Oasis.Session.store.user.id,
    });

    Voice.store.active = true;
    Voice.store.workshop = {
      id: result.value.id,
      name: result.value.name,
    };

    const micTrack = await Agora.createMicrophoneTrack();
    await Agora.client.publish(micTrack);

    return Ok({ micTrack });
  },

  async disconnect() {
    await Agora.disconnect();
    Voice.store.mic?.close();
    Voice.store.mic = undefined;
    Voice.store.active = false;
  },
};
