import clsx from 'clsx';
import { ComponentProps, useState } from 'react';

interface Props extends ComponentProps<'div'> {
  contentType: 'folders' | 'items';
  fileType?: string | undefined;
}

export function FileTypeIcon({ contentType, fileType, ...props }: Props) {
  const [hasErrored, setHasErrored] = useState(false);

  let fileName = contentType === 'folders' ? 'folder' : 'file';
  if (fileType) fileName = fileType;

  return (
    <div {...props} className={clsx('flex items-center w-6', props.className)}>
      <img
        src={`/file-type-icons/${fileName}.svg`}
        alt={`${fileName} icon`}
        onError={e => {
          if (!hasErrored) {
            setHasErrored(true);
            e.currentTarget.src = '/file-type-icons/file.svg';
          }
        }}
        className="w-full"
      />
    </div>
  );
}
