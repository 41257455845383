import Button from '@adsk/alloy-react-button';
import { EnvelopeIcon } from '@adsk/alloy-react-icon';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { MARKETING_URLS } from '~/shared/utils/const.marketing';

export function TrialBanner() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const branding = useTemporaryBranding();
  const fileMatch = useMatch('/projects/:projectId/files/:fileId');

  const [showModal, setShowModal] = useState(false);

  const message = useMemo(() => {
    if ($session.license.type === 'FREE_VIEWER') {
      return $env.isVr
        ? 'You are on a free license - upgrade to unlock all features.'
        : 'Your access is limited by a free license. Upgrade to unlock all features.';
    }

    if ($session.license.type === 'TRIAL' && $session.license.daysRemaining < 21) {
      const timing = `${$session.license.daysRemaining} ${$session.license.daysRemaining === 1 ? 'day' : 'days'}`;

      return (
        <>
          {timing} remaining on your <span className="hidden 2xl:inline">{branding.productName}</span> trial
        </>
      );
    }

    return (
      <>
        Welcome to your <span className="hidden 2xl:inline">{branding.productName}</span> 30 day trial
      </>
    );
  }, [$session.license, $env.isVr, branding.productName]);

  if ($session.license.type === 'PAID') {
    return null;
  }

  return (
    <aside className="flex items-center mr-2">
      {(!$env.isVr ? !fileMatch : true) && (
        <p
          className={clsx(
            'text-body-md invisible xl:visible',
            ($session.license.type === 'FREE_VIEWER' ||
              ($session.license.type === 'TRIAL' && $session.license.daysRemaining < 10)) &&
              'text-red-500'
          )}
        >
          {message}
        </p>
      )}

      {!$env.isVr && (
        <Button variant="primary" size="small" className="ml-4 whitespace-nowrap" onClick={() => setShowModal(true)}>
          Trial support
        </Button>
      )}

      {/* @ts-ignore */}
      <Modal open={showModal} className="!h-auto">
        <Modal.Header>
          <h2>Trial support</h2>
          <Modal.Close showEscapeLabel={false} onClick={() => setShowModal(false)} />
        </Modal.Header>
        <Modal.Body className="!pt-6 !pb-10">
          {$session.license.type === 'TRIAL' && (
            <>
              {$session.license.daysRemaining ? (
                <p className="text-body-md font-bold">
                  {$session.license.daysRemaining} {$session.license.daysRemaining === 1 ? 'day' : 'days'} remaining on
                  your {branding.productName} trial.
                </p>
              ) : (
                <p className="text-body-md">Your trial has expired.</p>
              )}
              <br />
              <p className="text-body-md">
                Review projects with your collaborators in Extended Reality (XR).
                <br /> Learn more about getting started{' '}
                <a href={MARKETING_URLS.TRIAL_RESOURCES} target="_blank" className="text-blue-500" rel="noreferrer">
                  here
                </a>
                .
              </p>
              <br />
              <p className="text-body-md">
                After your trial ends, you will have restricted feature access. <br />
                Upgrade to a full license to take advantage of all available features.
              </p>
            </>
          )}

          {$session.license.type === 'FREE_VIEWER' && (
            <>
              <p className="text-body-md font-bold">Your access is limited by a free license.</p>
              <br />
              <p className="text-body-md">
                You can continue to collaborate in {branding.productName} <br />
                with a free license, which has restricted feature access.
              </p>
              <br />
              <p className="text-body-md">
                Upgrade your license to take full advantage of <br />
                {branding.productName}&apos;s feature set.
              </p>
            </>
          )}

          <p className="mt-8 text-body-md font-bold">
            {$session.license.type === 'TRIAL' ? 'Ready to upgrade?' : 'Ready to purchase?'}
          </p>

          <p className="mt-2">
            <Button variant="primary" as="a" href={MARKETING_URLS.CONTACT_US} target="_blank" rel="noreferrer">
              Contact our sales team
            </Button>
          </p>

          <p className="mt-8">Or, contact your ACC account administrator</p>

          <p className="mt-2">
            <a
              href="mailto:?subject=My%20Autodesk%20Workshop%20XR%20subscription%20expired&body=Please%20assign%20me%20a%20subscription%20so%20I%20can%20continue%20using%20Autodesk%20Workshop%20XR."
              className="inline-flex items-center text-blue-700 hover:text-blue-500"
            >
              <EnvelopeIcon className="mr-2" />
              Request a license
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </aside>
  );
}
