import { Oasis } from './oasis';
import { ApsHttp } from './providers/http/aps-http.provider';
import { OasisHttpProvider } from './providers/http/oasis-http.provider';
import type { ReleaseChannel } from './services/releases/releases.types';
import type { EnvContext } from './types';

export * from './lib/result';
export * from './oasis';
export * from './services/feature-flags/feature-flags.types';
export * from './services/files/files.types';
export * from './services/issues/issues.schemas';
export { IssuesSchemas } from './services/issues/issues.schemas';
export * from './services/issues/issues.types';
export * from './services/releases/releases.schemas';
export * from './services/releases/releases.types';
export * from './services/session/session.types';
export { WorkshopsSchemas } from './services/workshops/workshops.schemas';
export * from './types';

/**
 * @name initOasisApp
 * Kick the tires and initialize all the modules with the provided configuration.
 */
export async function initOasisApp(params: {
  context: EnvContext;
  lmvEnv: string;
  fluidEnv: string;
  acmNamespace: string;
  apiUrl: string;
  mqttUrl: string;
  releaseChannel?: ReleaseChannel;
  segmentWriteKey?: string;
  sentryDsn?: string;
  gitSha: string;
}) {
  if (!params.releaseChannel) {
    params.releaseChannel = 'develop';
  }

  Oasis.Logger.init({
    releaseChannel: params.releaseChannel,
    sentryDsn: params.sentryDsn,
  });

  Oasis.Env.init(params);

  if (params.segmentWriteKey) {
    Oasis.Segment.loadAnalytics(params.segmentWriteKey);
  }

  await Oasis.Pendo.init('4df1398c-2a22-48e9-6ba7-068152b64546');
  await Oasis.FeatureFlags.init(params.releaseChannel);

  OasisHttpProvider.init({
    baseUrl: params.apiUrl,
    releaseChannel: params.releaseChannel,
  });

  ApsHttp.init({
    releaseChannel: params.releaseChannel,
  });

  // if (params.context === 'desktop') {
  //   Oasis.Bridge = await import('./services/bridge/bridge.service').then(m => m.Bridge);
  // }
}

/**
 * Bind the `adsk` object to the window for easy access in the browser console.
 */
(window as any).adsk = {
  toggleEnv() {
    console.warn('Deprecated: Use `adsk.toggleDevMode()` instead');
    Oasis.Env.setDevMode(!Oasis.Env.store.isDevMode);
  },

  toggleDevMode() {
    Oasis.Env.setDevMode(!Oasis.Env.store.isDevMode);
  },

  useFluid(flag = false) {
    Oasis.Env.setUseFluid(flag);
    (window as any).DEFAULT_DOCUMENT_ID = '1691134b-6617-466d-8b48-504d57d95693';
    (window as any).DEFAULT_WORKSHOP_ID = 'test1';
  },
};
