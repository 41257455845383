export type Platforms = 'acc' | 'bim360';

export const ProjectUtils = {
  formatId(id: string, opts?: { prefix: boolean }) {
    if (opts?.prefix) {
      return id.startsWith('b.') ? id : `b.${id}`;
    }

    return id.replace('b.', '');
  },

  // formatId(id: string, type: ProjectTypes = 'acc') {
  //   const hasPrefix = id.startsWith('b.');
  //   if (type === 'acc' && hasPrefix) return id.replace('b.', '');
  //   if (type === 'bim360' && !hasPrefix) return `b.${id}`;
  //   return id;
  // },
};
