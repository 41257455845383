import { DataBinding } from '@fluid-experimental/property-binder';
import { PROPERTY_NAMES } from './Constants';

// @TODO convert it to TS, for some reason its recompiled to a version that causes runtime issues.
export class VisibilityManagerBinding extends DataBinding {
  onHiddenNodesChanged(key, context) {
    const { onHiddenNodesChanged } = this.getUserData();
    this.onCommonChange(key, context, onHiddenNodesChanged);
  }

  onIsolatedNodesChanged(key, context) {
    const userData = this.getUserData();

    if (userData?.onIsolatedNodesChanged) {
      this.onCommonChange(key, context, userData.onIsolatedNodesChanged);
    }
  }

  onCommonChange(key, context, callback) {
    this.getDataBinder().requestChangesetPostProcessing(() => {
      const tree = this.getDataBinder().getPropertyTree();
      // Make sure the callback is called, change notifications are triggered at the end only.
      tree.pushNotificationDelayScope();
      callback(Number.parseFloat(key), context.value, context.opType);
      tree.popNotificationDelayScope();
    });
  }

  onNodesChanged(key, context) {
    const params = {
      id: context.getRelativeTokenizedPath()[1],
      opType: context.getOperationType(),
    };
    switch (context.getRelativeTokenizedPath()[0]) {
      case PROPERTY_NAMES.HIDDEN_NODES:
        params.value = context.getProperty() ? context.getProperty().get(params.id) : true;
        this.onHiddenNodesChanged(key, params);
        break;
      case PROPERTY_NAMES.ISOLATED_NODES:
        if (
          params.opType === 'insert' &&
          context.getProperty().resolvePath(`../${PROPERTY_NAMES.HIDDEN_NODES}`).getAsArray().length !== 0
        ) {
          // if there are hidden nodes, this means we should ignore isolation.
          // Isolate should clear hiddenNodes array, if it has still elements, this means isolation is cancelled.
          return;
        }
        params.value = context.getProperty() ? context.getProperty().get(params.id) : false;
        this.onIsolatedNodesChanged(key, params);
        break;
      default:
        break;
    }
  }

  static initialize() {
    this.registerOnPath(
      'hiddenNodes',
      ['collectionModify', 'collectionInsert', 'collectionRemove'],
      this.prototype.onNodesChanged
    );

    this.registerOnPath(
      'isolatedNodes',
      ['collectionModify', 'collectionInsert', 'collectionRemove'],
      this.prototype.onNodesChanged
    );
  }
}

VisibilityManagerBinding.initialize();
