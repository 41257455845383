import { Platforms } from '@oasis/utils';
import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import { FolderTreeMode, type FolderTreeSelectionItem } from './types';

export interface Folder {
  id: string;
  name: string;
  hasChildren: boolean;
  path: string;
  permissionActions: string[];
}

export interface FolderTreeContextState {
  projectId: string;
  platform: Platforms;
  mode: FolderTreeMode;
  activeFolderUrn: string;
  shareableOnly: boolean;
  setActiveFolder: (urn: string) => void;
  selection: FolderTreeSelectionItem[];
  onFolderSelect?: (folder: Folder) => void;
  onFileSelect?: (content: FolderTreeSelectionItem) => void;
  disableContextMenu?: boolean;
  disableFolderSearchParam?: boolean;
  disableFolderPersistance?: boolean;
}

export const FolderTreeContext = createContext<FolderTreeContextState>({
  projectId: '',
  platform: 'acc',
  mode: 'FOLDERS_ONLY',
  activeFolderUrn: '',
  setActiveFolder: noop,
  selection: [],
  onFolderSelect: noop,
  onFileSelect: noop,
  shareableOnly: false,
  disableContextMenu: false,
  disableFolderSearchParam: false,
  disableFolderPersistance: false,
});

export function useFolderTreeContext() {
  return useContext(FolderTreeContext);
}
