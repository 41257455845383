const LocalStorageKeys = {
  currentUserId: 'oasis.current-user-id',
  forgeXrToken: 'oasis.session-token', // `forgeXrToken` from our own API
  forgeXrRefreshToken: 'oasis.session-refresh-token', // `forgeXrRefreshToken` from our own API
  accessToken: 'oasis.access', // `oxygenToken` from our own API, or `accessToken` from Forge API
  refreshToken: 'oasis.refresh',
  sessionId: 'oasis.session-id',
  isDevMode: 'oasis.is-dev-mode',
  context: 'oasis.context',
  vrTargetDevice: 'oasis.vr-target',
  deeplinkUri: 'oasis.deeplink-uri',
  postAuthRedirect: 'oasis.oauth-previous-path',
  useFluid: 'oasis.use-fluid',
  tauriAppVersion: 'oasis.tauri-app-version',
  currentProjectId: 'oasis.current-project-id',
  filePanelSize: 'oasis.file-panel-size',
  usePairingVoiceover: 'oasis.use-pairing-voiceover',
  hasVrDevice: 'oasis.has-vr-device',
  gsh: 'Y2hpY2Fnby1iZWFycy0yMDI0LTIwMjUtc3VwZXJib3dsLWNoYW1wcw==', // trial started at.. trying to at least obfuscate this a little..
};

const SessionStorageKeys = {
  activeFolderUrn: 'oasis-temp.active-folder-urn',
};

export const Storage = {
  get(key: keyof typeof LocalStorageKeys): string | null {
    return localStorage.getItem(LocalStorageKeys[key]);
  },
  set(key: keyof typeof LocalStorageKeys, value: string | number | boolean) {
    return localStorage.setItem(LocalStorageKeys[key], String(value));
  },
  remove(key: keyof typeof LocalStorageKeys) {
    return localStorage.removeItem(LocalStorageKeys[key]);
  },
  getTemporary(key: keyof typeof SessionStorageKeys): string | null {
    return sessionStorage.getItem(SessionStorageKeys[key]);
  },
  setTemporary(key: keyof typeof SessionStorageKeys, value: string | number | boolean) {
    return sessionStorage.setItem(SessionStorageKeys[key], String(value));
  },
  removeTemporary(key: keyof typeof SessionStorageKeys) {
    return sessionStorage.removeItem(SessionStorageKeys[key]);
  },
  reset() {
    localStorage.clear();
    sessionStorage.clear();
  },
};
