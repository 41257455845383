import Avatar from '@adsk/alloy-react-avatar';
import { CheckmarkIcon, XIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dropdown } from '~/shared/components/forms/dropdown';
import { Label } from '~/shared/components/forms/label';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';

interface Props {
  projectId: string;
  memberId: string;
}

enum XRAccessLevel {
  XRAdmin = 'XR Admin',
  XRMember = 'XR Member',
}

export const MemberDetailsPanel = memo(function _MemberDetailsPanel(props: Props) {
  const { memberId, projectId } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const adminUsers = Queries.Projects.useListAdminUsers({ projectId });
  const members = Queries.Projects.useListProjectUsers({ projectId });

  const grantAdminAccess = Mutations.Projects.useGrantAdminAccess();
  const revokeAdminAccess = Mutations.Projects.useRevokeAdminAccess();

  const member = useMemo(() => {
    if (members.data?.results) {
      const member = members.data.results.find(member => member.id === memberId);

      if (member) {
        return {
          ...member,
          xrAccessLevel:
            adminUsers.data && adminUsers.data.results?.some(admin => admin.id === member.id)
              ? XRAccessLevel.XRAdmin
              : XRAccessLevel.XRMember,
        };
      }
    }

    return null;
  }, [memberId, members.data?.results, adminUsers.data]);

  const currentAccessLevel = member?.xrAccessLevel ?? XRAccessLevel.XRMember;

  function closePanel() {
    searchParams.delete('memberId');
    setSearchParams(searchParams);
  }

  function changeAccessLevel(value: string) {
    if (!member?.autodeskId) return;

    if (value === XRAccessLevel.XRAdmin) {
      grantAdminAccess.mutate({ projectId, adminId: member.autodeskId });
    }

    if (value === XRAccessLevel.XRMember) {
      revokeAdminAccess.mutate({ projectId, adminId: member.autodeskId });
    }
  }

  return (
    <div className="min-h-full flex flex-col">
      <div className="flex flex-row p-2">
        <div className="flex items-center	flex-row p-2">
          <div className="flex items-center ml-auto space-x-1 pr-2">
            <p className="text-label-md truncate">
              <Avatar size="extraLarge" name={member?.name ?? ''} imageUrl={member?.imageUrl} className="mr-3" />
            </p>
          </div>
          <div className="flex flex-1 flex-col pr-2">
            <div className="flex pr-2 pb-1">
              <p className="text-label-md truncate">{member?.name}</p>
            </div>
            <div className="flex pr-2 pb-1">
              <p className="text-label-sm truncate">{member?.email}</p>
            </div>
          </div>
        </div>
        <div className="flex ml-auto  items-start">
          <button className="p-2 appearance-none opacity-50 hover:opacity-100" onClick={closePanel}>
            <XIcon />
          </button>
        </div>
      </div>

      <div className="border-t border-charcoal-200 mb-3" />

      <div className="flex-1 overflow-auto px-3">
        <div className="mb-2">
          <Label className="mb-2">XR Access Level</Label>
          <Dropdown>
            <Dropdown.Trigger className="flex items-center">
              <div className="w-1 h-4 mr-2 rounded-full" />
              {currentAccessLevel}
            </Dropdown.Trigger>

            <Dropdown.Content>
              <Dropdown.RadioGroup onValueChange={changeAccessLevel}>
                {[
                  {
                    value: XRAccessLevel.XRMember,
                    description: 'Access granted per individual workshop, via workshop permissions.',
                  },
                  {
                    value: XRAccessLevel.XRAdmin,
                    description: 'Manage access automatically granted to all workshops in current project.',
                  },
                ].map(item => {
                  const isActive = currentAccessLevel === item.value;

                  return (
                    <Dropdown.RadioItem
                      key={item.value}
                      value={item.value}
                      className={clsx('flex items-center', isActive && 'bg-blue-50')}
                      disabled={isActive}
                    >
                      <div className="flex-1 overflow-hidden">
                        <p className="max-w-full truncate text-charcoal-800">{item.value}</p>

                        <p className="max-w-full">
                          <span className="text-charcoal-500">{item.description}</span>
                        </p>
                      </div>
                      <div className="w-10">{isActive && <CheckmarkIcon className="ml-auto text-blue-600" />}</div>
                    </Dropdown.RadioItem>
                  );
                })}
              </Dropdown.RadioGroup>
            </Dropdown.Content>
          </Dropdown>
        </div>

        <div className="mt-4 mb-2">
          <Label className="mb-2">Company</Label>
          <p className="pl-3">{member?.companyName}</p>
        </div>
      </div>
    </div>
  );
});
