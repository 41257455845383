/**
 * This component got fairly hacky in order to cleanup some render perf.
 * We're using a valtio proxy to pass in some
 */

import Avatar from '@adsk/alloy-react-avatar/es/Avatar';
import Checkbox from '@adsk/alloy-react-checkbox/es/Checkbox';
import { CheckmarkCircleFilledIcon, MoreVerticalIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import {
  TableCell,
  TableHeader,
  TableHeaderGroup,
  TableRow,
  TableSkeletonLoadingRow,
  useSkeletonLoadingRows,
  useTable,
} from '@adsk/alloy-react-table';
import { FileSearchFilters, FolderContent, Oasis } from '@oasis/sdk';
import { ArrayUtils, DateUtils, FileUtils } from '@oasis/utils';
import * as ContextMenu from '@radix-ui/react-context-menu';
import * as Popover from '@radix-ui/react-popover';
import {
  OnChangeFn,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';
import { MoveToFolderModal } from '~/features/files/components/move-to-folder-modal';
import { DerivativeThumbnail } from '~/shared/components/base/derivative-thumbnail';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { ShareToWorkshopButton } from '~/shared/components/base/share-to-workshop-button';
import { Tooltip } from '~/shared/components/base/tooltip';
import { InlineEditFile } from '~/shared/components/forms/inline-edit-file';
import { LargeDocumentIcon } from '~/shared/components/icons/large-document-icon';
import { LargeFolderIcon } from '~/shared/components/icons/large-folder-icon';
import { ShareToWorkshopIcon } from '~/shared/components/icons/share-to-workshop-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { FilesContextMenu } from './files-context-menu';

interface Store {
  isEditing: boolean;
  contextItem: FolderContent | null;
}

const store = proxy<Store>({
  isEditing: false,
  contextItem: null,
});

function setIsEditing(value: boolean) {
  store.isEditing = value;
}

function setContextItem(value: FolderContent | null) {
  store.contextItem = value;
}

function resetStore() {
  store.isEditing = false;
  store.contextItem = null;
}

const columnHelper = createColumnHelper<FolderContent>();

interface Props {
  folderId: string;
  children?: ReactNode;
  rowSelection: Record<string, boolean>;
  setRowSelection: OnChangeFn<RowSelectionState>;
  filterSupported: boolean;
  mode: string; // list | grid
  query: string;
  filters: FileSearchFilters;
}

export function FilesTable({ folderId, rowSelection, setRowSelection, filterSupported, mode, query, filters }: Props) {
  const { projectId, platform } = useProjectContext();
  const $env = Oasis.Env.useStore();
  const $store = useSnapshot(store);
  const $session = Oasis.Session.useStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const containerRef = useRef<HTMLDivElement>(null);
  const centerTableRef = useRef<HTMLTableElement>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [moveToFolder, setMoveToFolder] = useState(false);

  const activeModelRes = Queries.Users.useGetActiveModel(projectId);
  const searchResults = Queries.Files.useListFilesFoldersUsingSearch({
    projectId,
    platform,
    folderId,
    sort: [{ field: 'name', order: 'asc' }],
    query,
    filters,
  });

  const moveFolder = Mutations.Files.useMoveFolder();
  const moveDocuments = Mutations.Files.useMoveDocuments();

  useEffect(() => resetStore, []);

  const data = useMemo(() => {
    if (searchResults.data) {
      return filterSupported ? searchResults.data.filter(file => file.supportedInVR) : searchResults.data;
    }

    return [];
  }, [searchResults.data, filterSupported]);

  const supportedCount = useMemo(() => {
    return data.filter(row => ($env.isVr ? row.supportedInVR : row.supportedInLMV)).length;
  }, [$env.isVr, data]);

  const isGrid = mode === 'grid';

  const columns = useMemo(
    () =>
      [
        columnHelper.display({
          id: 'selection',
          size: 48,
          maxSize: 48,
          enableResizing: false,
          meta: { disableRowClick: true },
          header: ({ table }) => {
            let checked = table.getIsAllRowsSelected()
              ? true
              : table.getIsSomeRowsSelected()
                ? Checkbox.INDETERMINATE
                : false;

            if (table.getSelectedRowModel().flatRows.length === supportedCount) {
              checked = true;
            }

            return (
              <Checkbox
                checked={checked}
                onChange={(_state, event) => {
                  table.getToggleAllRowsSelectedHandler()(event);
                }}
                inputProps={{ className: 'hit-slop' }}
              />
            );
          },
          cell: ({ row }) => (
            <Checkbox
              disabled={$env.isVr ? !row.original.supportedInVR : !row.original.supportedInLMV}
              checked={row.getIsSelected()}
              onClick={e => e.stopPropagation()}
              onChange={(_state, event) => {
                row.getToggleSelectedHandler()(event);
              }}
              inputProps={{ className: 'hit-slop' }}
            />
          ),
        }),

        columnHelper.accessor('name', {
          size: 360,
          minSize: 200,
          header: 'Name',
          cell: ({ row }) => {
            const isModelActive = activeModelRes.data?.model?.data.id === row.original.latestVersionId;
            const isViewActive = isModelActive && !!activeModelRes.data?.activeViewGuid;

            return (
              <_NameCell
                row={row.original}
                projectId={projectId}
                disabled={$env.isVr ? !row.original.supportedInVR : !row.original.supportedInLMV}
                isGrid={isGrid}
                isViewActive={isViewActive}
              />
            );
          },
        }),

        columnHelper.accessor('storageSize', {
          size: 120,
          minSize: 80,
          header: 'Size',
          cell: info => {
            const storageSize = info.getValue();
            const fileSize = storageSize ? FileUtils.toHumanFileSize(storageSize) : '--';
            return <p className="text-label-md truncate">{fileSize}</p>;
          },
        }),

        columnHelper.accessor('updatedAt', {
          size: 200,
          minSize: 140,
          header: 'Last updated',
          cell: info => DateUtils.getFriendlyFormat(info.getValue(), { includeTime: true }),
        }),

        columnHelper.accessor('updatedBy', {
          size: 240,
          minSize: 200,
          header: 'Updated by',
          cell: info => {
            const name = info.getValue();

            return (
              <div className="flex items-center max-w-full">
                <Avatar
                  size="small"
                  name={name}
                  imageUrl={`https://images.profile.autodesk.com/${info.row.original.updatedById}/profilepictures/x50.jpg`}
                />
                <span className="ml-2 truncate">{name}</span>
              </div>
            );
          },
        }),

        columnHelper.display({
          id: 'actions',
          size: $session.activeWorkshop ? 88 : 52,
          maxSize: $session.activeWorkshop ? 88 : 52,
          enableResizing: false,
          enableSorting: false,
          cell: info => {
            const userIsViewOnly = $session.activeWorkshop?.permission === 'VIEW';
            const isModelActive = activeModelRes.data?.model?.data.id === info.row.original.latestVersionId;
            const isViewActive = isModelActive && !!activeModelRes.data?.activeViewGuid;

            return (
              <div className="flex items-center justify-end w-full h-full gap-3">
                {info.row.original.type !== 'folders' && (
                  <ShareToWorkshopButton
                    tooltipPlacement="left"
                    fileType={info.row.original.fileType}
                    fileVersionUrn={info.row.original.latestVersionId}
                    isFileShared={isModelActive && !isViewActive}
                    isFileProcessing={info.row.original.isProcessing}
                    isShortHand
                  >
                    <button
                      className={clsx(
                        'group inline-flex items-center justify-center h-8 w-8 cursor-pointer text-charcoal-700',
                        'disabled:cursor-default',
                        !userIsViewOnly &&
                          !info.row.original.isProcessing &&
                          'hover:text-blue-500 focus-visible:bg-blue-500'
                      )}
                    >
                      {isModelActive && !isViewActive ? (
                        <CheckmarkCircleFilledIcon className="text-green-500" size={20} />
                      ) : (
                        <ShareToWorkshopIcon className="w-6 h-6 group-disabled:opacity-40" />
                      )}
                    </button>
                  </ShareToWorkshopButton>
                )}

                {($env.isVr ? info.row.original.supportedInVR : info.row.original.supportedInLMV) && (
                  <Popover.Root>
                    <Popover.Trigger
                      onClick={e => {
                        e.stopPropagation();
                        setContextItem(info.row.original);
                      }}
                      className={clsx(
                        'inline-flex items-center justify-center h-8 w-8 cursor-pointer -ml-2',
                        'group-hover:opacity-100 hover:text-blue-500',
                        'disabled:hover:text-charcoal-700 disabled:group-hover:opacity-60'
                      )}
                    >
                      <MoreVerticalIcon />
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        hideWhenDetached
                        side="bottom"
                        align="end"
                        sideOffset={-8}
                        alignOffset={8}
                        className="w-full z-20"
                      >
                        <FilesContextMenu
                          projectId={projectId}
                          target={info.row.original}
                          setIsEditing={setIsEditing}
                          setMoveToFolder={setMoveToFolder}
                          setContextItem={setContextItem}
                          isFileShared={isModelActive && !isViewActive}
                          isFileProcessing={info.row.original.isProcessing}
                        />
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>
                )}
              </div>
            );
          },
        }),
      ].filter(ArrayUtils.truthy),
    [$session.activeWorkshop, $env.isVr, projectId, activeModelRes.data, supportedCount, isGrid]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      sorting,
      columnPinning: {
        left: ['selection', 'name'],
        right: ['actions'],
      },
    },
    onRowSelectionChange: selection => {
      if (typeof selection === 'function') {
        selection = selection(rowSelection);
      }

      const keys = Object.keys(selection);

      for (const key of keys) {
        const row = data?.[Number(key)];

        if (row) {
          selection[key] = $env.isVr ? row.supportedInVR : row.supportedInLMV;
        }
      }

      setRowSelection(selection);
    },
    onSortingChange: setSorting,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const moveFolderModal = moveToFolder && (
    <MoveToFolderModal
      projectId={projectId}
      close={() => setMoveToFolder(false)}
      onSubmit={async folder => {
        if (!folder?.name) {
          return NotificationManager.push({
            status: 'error',
            content: 'Attempted file selection failed. Try again.',
          });
        }

        if ($store.contextItem?.type === 'folders') {
          moveFolder.mutate({
            projectId: projectId,
            attrs: {
              folderUrn: $store.contextItem.id,
              targetFolderUrn: folder.id,
            },
          });
        }

        if ($store.contextItem?.type === 'items') {
          moveDocuments.mutate({
            projectId: projectId,
            targetFolderUrn: folder.id,
            documentUrns: [$store.contextItem.id],
          });
        }

        setMoveToFolder(false);
      }}
    />
  );

  const { getTableRowProps, getTableCellProps, getTableHeaderProps } = useTable({ table });
  const { getSkeletonLoadingRows, getSkeletonLoadingRowProps } = useSkeletonLoadingRows({
    // @ts-expect-error
    table,
    numberOfRows: 8,
  });

  // If we already have the data the default folder will get added to the search params almost
  // instantly so no need to show the full loading skeleton during that flash of time.
  const isLoading = (!searchResults.data && !searchParams.get('folder')) || searchResults.isLoading;

  if (!isLoading && !data) {
    return (
      <>
        <OasisErrorState illustration="folderPdfClosedFistGrey" title="No folder contents" />
        {moveFolderModal}
      </>
    );
  }

  function handleRowClick(params: { id: string; supported?: boolean; type: string; toggleSelected: () => void }) {
    if (!params.supported) {
      return;
    }

    if (params.type === 'items' && $env.isVr) {
      params.toggleSelected();
    } else if (params.type === 'items') {
      Oasis.Dcv.emitClickEvent(params.id);

      navigate(
        {
          pathname: `/projects/${projectId}/files/${params.id}`,
          search: searchParams.toString(),
        },
        {
          state: { from: 'Files', backUrl: window.location.href },
        }
      );
    }

    if (params.type === 'folders') {
      setRowSelection({});
      searchParams.set('folder', params.id);
      setSearchParams(searchParams);
    }
  }

  if (isGrid) {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <ProgressRing size="medium" />
        </div>
      );
    }

    const headers = table.getHeaderGroups().flatMap(headerGroup => headerGroup.headers);
    const selectionHeader = headers.find(header => header.id === 'selection');

    return (
      <div className="flex flex-col items-start w-full h-full overflow-auto mr-5 focus:outline-none">
        <div className="p-4 border-b border-charcoal-200 w-full">
          {selectionHeader && flexRender(selectionHeader.column.columnDef.header, selectionHeader.getContext())}
        </div>

        <ul
          className={clsx(
            'grid gap-4 pr-5 pb-5 overflow-auto p-4',
            'sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4',
            'vr:grid-cols-3'
          )}
        >
          {table.getRowModel().rows.map(row => {
            const cells = row.getVisibleCells();
            const name = cells.find(cell => cell.column.id === 'name');
            const actions = cells.find(cell => cell.column.id === 'actions');
            const selection = cells.find(cell => cell.column.id === 'selection');

            const supported = $env.isVr ? row.original.supportedInVR : row.original.supportedInLMV;
            const isModelActive = activeModelRes.data?.model?.data.id === row.original.latestVersionId;
            const isViewActive = isModelActive && !!activeModelRes.data?.activeViewGuid;
            const isSelected = row.getIsSelected() || searchParams.get('fileDetailsUrn') === row.original.id;

            return (
              <li
                key={row.id}
                className={clsx(
                  'flex flex-col rounded-md bg-white border transition-all duration-100 cursor-pointer',
                  isSelected
                    ? 'shadow-halo-lg shadow-blue-500.35 border-blue-500'
                    : 'border-charcoal-200 hover:shadow-low'
                )}
                onClick={() =>
                  handleRowClick({
                    id: row.original.id,
                    supported,
                    type: row.original.type,
                    toggleSelected: () => row.toggleSelected(!row.getIsSelected()),
                  })
                }
              >
                <div
                  className="group relative aspect-[210/134] block p-0 m-0 bg-charcoal-50 flex items-center justify-center border-b border-charcoal-200 rounded-t-[0.1875rem] overflow-hidden"
                  aria-label={`${row.original.name} icon/thumbnail`}
                >
                  <div
                    className={clsx(
                      'absolute top-2 left-2 bg-white rounded-sm',
                      row.getIsSelected() ? 'opacity-100' : 'opacity-0 focus-within:opacity-100 group-hover:opacity-100'
                    )}
                  >
                    {selection && flexRender(selection.column.columnDef.cell, selection.getContext())}
                  </div>

                  {isViewActive && (
                    <p className="inline-flex items-center h-5 px-2 absolute top-2 right-2 bg-green-500 text-white rounded-3xl text-label-sm whitespace-nowrap">
                      3D View shared
                    </p>
                  )}

                  {row.original.type === 'folders' ? (
                    <LargeFolderIcon className="w-28" />
                  ) : (
                    <DerivativeThumbnail
                      urn={row.original.thumbnailId}
                      alt={`${row.original.name} thumbnail`}
                      fallback={<LargeDocumentIcon className="w-28" />}
                      lazyload
                    />
                  )}
                </div>
                <div className="flex flex-col p-2">
                  <div className="flex gap-3 items-center min-h-[2.375rem]">
                    <div className="flex-1 overflow-hidden pr-1">
                      <Tooltip content={row.original.name} className="max-w-48 break-all" placement="bottom">
                        {name && flexRender(name.column.columnDef.cell, name.getContext())}
                      </Tooltip>
                    </div>

                    {actions && (
                      <Popover.Root>
                        <div>{flexRender(actions.column.columnDef.cell, actions.getContext())}</div>
                        <Popover.Portal>
                          <Popover.Content
                            hideWhenDetached
                            side="bottom"
                            align="end"
                            sideOffset={-8}
                            alignOffset={8}
                            className="w-full z-20"
                          >
                            {$store.contextItem && (
                              <FilesContextMenu
                                projectId={projectId}
                                target={$store.contextItem}
                                setIsEditing={setIsEditing}
                                setMoveToFolder={setMoveToFolder}
                                setContextItem={setContextItem}
                                isFileShared={isModelActive && !isViewActive}
                              />
                            )}
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  const activeFileDetailsUrn = searchParams.get('fileDetailsUrn');

  return (
    <>
      <div ref={containerRef} className="flex items-start w-full h-full overflow-auto mr-5 focus:outline-none">
        <ContextMenu.Root
          onOpenChange={isOpen => {
            if (!isOpen) store.contextItem = null;
          }}
        >
          <table ref={centerTableRef} className="flex-1">
            <thead className="sticky top-0 z-20">
              <TableHeaderGroup>
                {table.getLeftHeaderGroups().map(headerGroup => {
                  let totalPinnedWidth = 0;

                  return headerGroup.headers.map(header => {
                    const left = totalPinnedWidth;
                    totalPinnedWidth += header.column.getSize();

                    return (
                      <TableHeader
                        key={header.id}
                        {...getTableHeaderProps(header)}
                        className="!sticky z-20 scroll-shadow scroll-shadow--right"
                        style={{ left }}
                      />
                    );
                  });
                })}

                {table
                  .getCenterHeaderGroups()
                  .map(headerGroup =>
                    headerGroup.headers.map(header => <TableHeader key={header.id} {...getTableHeaderProps(header)} />)
                  )}

                {table.getRightHeaderGroups().map(headerGroup => {
                  let totalPinnedWidth = 0;

                  return headerGroup.headers.map(header => {
                    const right = totalPinnedWidth;
                    totalPinnedWidth += header.column.getSize();

                    return (
                      <TableHeader
                        key={header.id}
                        {...getTableHeaderProps(header)}
                        className="!sticky z-20 scroll-shadow scroll-shadow--left"
                        style={{ right }}
                      />
                    );
                  });
                })}
              </TableHeaderGroup>
            </thead>
            <ContextMenu.Trigger asChild>
              <tbody>
                {isLoading &&
                  getSkeletonLoadingRows().map(skeletonRow => (
                    <TableSkeletonLoadingRow key={skeletonRow.id} {...getSkeletonLoadingRowProps(skeletonRow)} />
                  ))}

                {!!(
                  !isLoading &&
                  (filters.fileType.length || filters.sourceIdType.length || query.length) &&
                  !table.getRowModel().rows.length
                ) && (
                  <div className="items-center justify-center pt-40 pb-6">
                    <OasisErrorState
                      illustration="folderPhotoGrey"
                      title="No results found"
                      description="Adjust your search criteria and try again"
                    />
                  </div>
                )}

                {table.getRowModel().rows.map(row => {
                  const supported = $env.isVr ? row.original.supportedInVR : row.original.supportedInLMV;

                  let totalLeftPinnedWidth = 0;
                  let totalRightPinnedWidth = 0;

                  const { isSelected, ...rowProps } = getTableRowProps(row);

                  return (
                    <TableRow
                      key={row.id}
                      {...rowProps}
                      onContextMenu={() => setContextItem(row.original)}
                      isSelected={isSelected || activeFileDetailsUrn === row.original.id}
                      onClick={() => {
                        handleRowClick({
                          id: row.original.id,
                          supported,
                          type: row.original.type,
                          toggleSelected: () => row.toggleSelected(!row.getIsSelected()),
                        });
                      }}
                      className={clsx('group', !supported && 'opacity-50 pointer-default pointer-events-none')}
                    >
                      {row.getLeftVisibleCells().map(cell => {
                        const left = totalLeftPinnedWidth;
                        totalLeftPinnedWidth = totalLeftPinnedWidth + cell.column.getSize();

                        return (
                          <TableCell
                            key={cell.id}
                            {...getTableCellProps(cell)}
                            className="!sticky z-10 scroll-shadow scroll-shadow--right"
                            style={{ left }}
                          />
                        );
                      })}

                      {row.getCenterVisibleCells().map(cell => (
                        <TableCell key={cell.id} {...getTableCellProps(cell)} />
                      ))}

                      {row.getRightVisibleCells().map(cell => {
                        const right = totalRightPinnedWidth;
                        totalRightPinnedWidth = totalRightPinnedWidth + cell.column.getSize();

                        return (
                          <TableCell
                            key={cell.id}
                            {...getTableCellProps(cell)}
                            className="!sticky z-10 scroll-shadow scroll-shadow--left !px-2"
                            style={{ right }}
                          />
                        );
                      })}
                    </TableRow>
                  );
                })}
              </tbody>
            </ContextMenu.Trigger>
          </table>

          <ContextMenu.Portal>
            <ContextMenu.Content className="z-20">
              {$store.contextItem && (
                <FilesContextMenu
                  projectId={projectId}
                  target={$store.contextItem}
                  setIsEditing={setIsEditing}
                  setMoveToFolder={setMoveToFolder}
                  setContextItem={setContextItem}
                />
              )}
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>
      </div>

      {moveFolderModal}
    </>
  );
}

function _NameCell(props: {
  row: FolderContent;
  projectId: string;
  disabled?: boolean;
  isGrid?: boolean;
  isViewActive?: boolean;
}) {
  const $store = useSnapshot(store);

  return (
    <InlineEditFile
      item={props.row}
      disabled={props.disabled}
      isEditing={props.row.id === $store.contextItem?.id && $store.isEditing}
      setIsEditing={setIsEditing}
      setContextItem={setContextItem}
      projectId={props.projectId}
      urn={$store.contextItem?.id || ''}
      hideIcon={props.isGrid}
      className={props.isGrid ? 'font-bold' : undefined}
      isViewActive={!props.isGrid && props.isViewActive}
    />
  );
}
