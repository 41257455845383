import AlloyCheckbox from '@adsk/alloy-react-checkbox';
import clsx from 'clsx';
import { ComponentProps } from 'react';

interface Props extends ComponentProps<typeof AlloyCheckbox> {
  id?: string;
  label?: string;
  containerClass?: string;
  checked?: boolean;
  readOnly?: boolean;
}

export function Checkbox({ id, label, containerClass, readOnly, ...props }: Props) {
  return (
    <div className={clsx('flex items-center', containerClass)}>
      <AlloyCheckbox id={id} {...props} readOnly={readOnly} />

      {label && (
        <label htmlFor={id} className="text-label-sm ml-2">
          {label}
        </label>
      )}
    </div>
  );
}
