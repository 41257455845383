import { authMutations } from './auth.mutations';
import { filesMutations } from './files.mutations';
import { issuesMutations } from './issues.mutations';
import { projectsMutations } from './projects.mutations';
import { workshopsMutations } from './workshops.mutations';

export const Mutations = {
  Auth: authMutations,
  Files: filesMutations,
  Issues: issuesMutations,
  Projects: projectsMutations,
  Workshops: workshopsMutations,
};
