import { useSearchParams } from 'react-router-dom';

export type WorkshopDetailsTabs = 'details' | 'permissions' | 'settings';

export function useWorkshopDetails() {
  const [searchParams, setSearchParams] = useSearchParams();

  const workshopId = searchParams.get('workshopId') ?? '';
  const activeTab = searchParams.get('tab') ?? (undefined as WorkshopDetailsTabs | undefined);

  return {
    isOpen: searchParams.has('workshopId'),
    workshopId,
    activeTab,
    showPanel(id: string, tab: WorkshopDetailsTabs, hideModal = true) {
      searchParams.set('workshopId', id);
      searchParams.set('tab', tab);

      if (hideModal) {
        searchParams.delete('dialog');
      }

      setSearchParams(searchParams);
    },
    hidePanel() {
      searchParams.delete('workshopId');
      searchParams.delete('tab');
      setSearchParams(searchParams);
    },
  };
}
