import { ErrorBoundary } from '@sentry/react';
import { ComponentType, ReactNode } from 'react';
import { OasisErrorState } from './oasis-error-state';

interface Props {
  children: ReactNode;
  additionalData?: Record<string, unknown>;
}

export function OasisErrorBoundary({ children, additionalData }: Props) {
  return (
    <ErrorBoundary
      key={window.location.pathname}
      fallback={({ error, eventId, resetError }) => (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <OasisErrorState oasisError={error} resetErrorBoundary={resetError} additionalData={additionalData} />
          <p className="font-mono text-body-sm p-10 text-red-500 opacity-0 hover:opacity-100">[{eventId}]</p>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

export function withOasisErrorBoundary<T extends object>(Component: ComponentType<T>) {
  return function WrappedComponent(props: T) {
    return (
      <OasisErrorBoundary>
        <Component {...props} />
      </OasisErrorBoundary>
    );
  };
}
