import customProtocolCheck from 'custom-protocol-check';
import { MouseEvent } from 'react';

export function tryOpenInNewTab(e: MouseEvent<HTMLElement, Event>, url: string) {
  if (e.ctrlKey || e.metaKey) {
    window.open(url, '_blank');
    return true;
  }

  return false;
}

export async function tryDeeplink(params: {
  protocol: string;
  uri: `goto/${string}`;
  onError?: () => void;
  onSuccess?: () => void;
  onUnsupported?: () => void;
}) {
  return new Promise(resolve => {
    customProtocolCheck(
      `${params.protocol}://${params.uri}`,
      () => {
        params.onError?.();
        resolve(false);
      },
      () => {
        params.onSuccess?.();
        resolve(true);
      },
      3000, // timeout before error is shown
      () => {
        params.onUnsupported?.();
        resolve(false);
      }
    );
  });
}
