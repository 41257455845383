import { ConnectionManager } from '@oasis/fluid-interop';
import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { Queries } from './queries';
import { proxy } from 'valtio';
import { deepClone, useProxy } from 'valtio/utils';
import _ from 'lodash';

export type ConnectionStatus = 'IDLE' | 'ERROR' | 'CONNECTING' | 'CONNECTED';

const initialValues = {
  status: 'IDLE' as ConnectionStatus,
  workshopId: undefined as string | undefined,
  workshopDocumentId: undefined as string | undefined,
  connectionErrors: [] as string[],
};

export const WorkshopConnection = {
  store: proxy<{
    status: ConnectionStatus;
    workshopId: string | undefined;
    workshopDocumentId: string | undefined;
    connectionErrors: string[];
  }>(deepClone(initialValues)),

  useStore: () => useProxy(WorkshopConnection.store),

  currentConnection: undefined as ConnectionManager | undefined,

  resetStore() {
    const resetObj = deepClone(initialValues);
    Object.entries(resetObj).forEach(([key, value]) => {
      WorkshopConnection.store[key as keyof typeof initialValues] = value as any;
    });
    this.currentConnection?.disconnect();
    this.currentConnection = undefined;
  },

  /**
   * Connects to a workshop using the provided workshop ID and workshop document ID.
   *
   * This method updates the connection status and manages the connection lifecycle.
   * It sets the status to 'CONNECTING' initially, and then to 'CONNECTED' once the connection is established.
   * If the connection is lost, it automatically attempts to reconnect and updates the status accordingly.
   *
   * @param workshopId - The unique identifier of the workshop to connect to.
   * @param workshopDocumentId - The unique identifier of the workshop document to connect to. If not provided,
   * it will create new one and update the workshop data model with the new document ID.
   * @throws Will throw an error if the connection attempt fails, and updates the store with the error message.
   */
  async connect(workshopId: string, workshopDocumentId?: string) {
    if (this.store.status === 'CONNECTING') {
      return;
    }

    this.store.status = 'CONNECTING';
    this.store.workshopId = workshopId;
    this.store.workshopDocumentId = workshopDocumentId;
    let connectionManager = this.currentConnection;
    if (!connectionManager) {
      try {
        connectionManager = await Oasis.Fluid.connectToWorkshop(workshopId, workshopDocumentId);
        this.currentConnection = connectionManager;

        connectionManager.on('connected', () => {
          this.store.status = 'CONNECTED';
        });

        // The Fluid client handles reconnection logic automatically.
        // When the connection is lost, update the status to 'CONNECTING'.
        // The client will attempt to reconnect automatically.
        connectionManager.on('disconnected', () => {
          this.store.status = 'CONNECTING';
        });
      } catch (error: any) {
        this.store.status = 'ERROR';
        this.store.connectionErrors = [error.message];
        throw error;
      }
    }

    this.store.status = 'CONNECTED';
  },
};

export function useWorkshopState({
  connectFluidBasedOnContext,
  workshopId,
}: { connectFluidBasedOnContext?: boolean; workshopId?: string } = {}) {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const $workshopConnectionState = WorkshopConnection.useStore();

  const isInWorkshop = Boolean(workshopId || $session.activeWorkshop);
  const activeWorkshopId = workshopId || $session.activeWorkshop?.id;

  const workshop = Queries.Workshops.useFindWorkshopById({ workshopId: activeWorkshopId });

  useEffect(() => {
    if ($workshopConnectionState.workshopId && activeWorkshopId !== $workshopConnectionState.workshopId) {
      WorkshopConnection.resetStore();
    }
  }, [activeWorkshopId, $workshopConnectionState.workshopId]);

  useEffect(() => {
    if (connectFluidBasedOnContext && !$env.isVr) {
      return;
    }

    if (activeWorkshopId && workshop.status === 'success') {
      WorkshopConnection.connect(activeWorkshopId, workshop.data.fluidState?.id);
    }
  }, [workshop.data, activeWorkshopId, isInWorkshop, workshop.status, connectFluidBasedOnContext, $env.isVr]);

  return {
    workshopConnection: WorkshopConnection.currentConnection,
    activeWorkshopId: activeWorkshopId,
    connectionErrors: $workshopConnectionState.connectionErrors,
    status: $workshopConnectionState.status,
  };
}
