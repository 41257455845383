import Modal from '@adsk/alloy-react-modal/es/Modal';
import { useState } from 'react';
import { Button } from '~/shared/components/base/button';
import type { FolderTreeSelectionItem } from '~/shared/components/base/folder-tree/types';
import { ModelSelectionTable } from '~/shared/components/base/model-selection-table';
import { Queries } from '~/shared/hooks/queries';

interface Props {
  projectId: string;
  onSubmit(selection: FolderTreeSelectionItem | undefined): void;
  close(): void;
}

export function AddFilesModal(props: Props) {
  const [selection, setSelection] = useState<FolderTreeSelectionItem | undefined>();

  const topFolders = Queries.Projects.useListTopFolders({ projectId: props.projectId });
  const rootFolderUrn = topFolders.data?.rootFolderUrn;

  if (!rootFolderUrn) {
    return null;
  }

  return (
    // @ts-expect-error
    <Modal open className="!h-auto" width={690}>
      <Modal.Header>
        <div>
          <h2>Add files</h2>
          <p className="text-heading-3 text-charcoal-500 mt-2">RVT or NWD</p>
        </div>
        <Modal.Close showEscapeLabel={false} onClick={props.close} />
      </Modal.Header>
      <Modal.Body className="!pt-4 !pb-6">
        <ModelSelectionTable projectId={props.projectId} selectedItem={selection} onChange={setSelection} />
      </Modal.Body>
      <Modal.Footer className="flex items-center">
        <p className="flex items-center mr-auto">
          {selection && (
            <>
              {selection.type === 'model' && <p>Model selected</p>}
              {selection.type === 'view' && <p>3D view selected</p>}
              <button className="ml-2 text-blue-700 hover:underline" onClick={() => setSelection(undefined)}>
                Clear
              </button>
            </>
          )}
        </p>
        <div className="space-x-2">
          <Button variant="tertiary" onClick={props.close}>
            Cancel
          </Button>
          <Button onClick={() => props.onSubmit(selection)} disabled={!selection}>
            Add
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
