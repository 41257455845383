import { useMemo } from 'react';

interface Props {
  value: string[];
  onChange?(value: string[]): void;
  readOnly?: boolean;
}

export function CodeInput({ value, readOnly }: Props) {
  // const [currentIndex, setCurrentIndex] = useState(0);

  const inputs = useMemo(() => {
    return new Array(6).fill('').map((_, index) => index);
  }, []);

  // function handleAutofill(e: ChangeEvent<HTMLInputElement>) {}

  // function handleInput(e: ChangeEvent<HTMLInputElement>) {
  //   if (!onChange) {
  //     return;
  //   }

  //   const prev = document.getElementById(
  //     `code-input-${currentIndex - 1}`
  //   ) as HTMLInputElement | null;
  //   const next = document.getElementById(
  //     `code-input-${currentIndex + 1}`
  //   ) as HTMLInputElement | null;

  //   if (prev && !prev.value) {
  //     prev.focus();
  //     setCurrentIndex(currentIndex - 1);
  //   }

  //   const nextValue = inputs.map(input => {
  //     if (input !== currentIndex) {
  //       return value[input];
  //     }

  //     return e.currentTarget.value.length > 1
  //       ? e.currentTarget.value.split('')[e.currentTarget.value.length - 1]
  //       : e.currentTarget.value;
  //   });

  //   onChange(nextValue);

  //   if (!e.currentTarget.value) {
  //     return;
  //   }

  //   if ((!prev || prev?.value) && next) {
  //     next.focus();
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // }

  return (
    <div>
      {/* <input
        value={Number(value.join(''))}
        autoComplete="one-time-code"
        required
        aria-hidden="true"
        className="hidden"
        onInput={handleAutofill}
        readOnly
      /> */}

      <div className="flex justify-between space-x-3">
        {inputs.map(input => {
          return (
            <input
              id={`code-input-${input}`}
              key={input}
              maxLength={1}
              value={value[input] || ''}
              required
              autoFocus={input === 0}
              placeholder="X"
              className="font-mono text-center py-4 text-title placeholder:text-charcoal-500 w-full rounded-sm border border-charcoal-200 focus:border-charcoal-400 disabled:bg-white"
              style={{ marginRight: input === 2 ? 20 : 0 }}
              // onInput={handleInput}
              // onFocus={() => setCurrentIndex(input)}
              disabled={readOnly}
              readOnly
            />
          );
        })}
      </div>
    </div>
  );
}
