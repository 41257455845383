import { CheckmarkIcon, XIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { KeyboardEvent, useState } from 'react';
import { twvg } from 'twvg';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { TextareaInput } from '~/shared/components/forms/text-area-input';
import { TextInput } from '~/shared/components/forms/text-input';
import { useUpdateIssueContext } from '../context';

interface Props {
  type?: 'text' | 'numeric';
  name: string;
  label: string;
  defaultValue?: string | null;
  onSubmit(value: string): void;
  textarea?: boolean;
  customAttribute?: boolean;
  required?: boolean;
}

export function UpdateIssueTextField(props: Props) {
  const { permittedAttributes } = useUpdateIssueContext();
  const [value, setValue] = useState(props.defaultValue || '');
  const [error, setError] = useState('');

  const canEdit = props.customAttribute || permittedAttributes.includes(props.name);

  function validate() {
    if (props.required && !value) {
      setError('Required.');
      return false;
    }

    setError('');
    return true;
  }

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        {props.label}
      </Label>

      <InlineEditable
        canEdit={canEdit}
        onClickAway={() => {
          if (validate() && canEdit && value !== props.defaultValue) {
            props.onSubmit(value);
          }
        }}
        topEditButton={props.textarea}
      >
        {({ isEditing, setIsEditing }) => {
          function handleKeyDown(e: KeyboardEvent) {
            if (e.key === 'Escape') {
              e.preventDefault();
              setValue(props.defaultValue || '');
              setIsEditing(false);
            }
            if (e.key === 'Enter' && !props.textarea) {
              e.preventDefault();

              if (validate()) {
                props.onSubmit(value);
                setIsEditing(false);
              }
            }
          }

          return isEditing ? (
            <div className="relative">
              {props.textarea ? (
                <TextareaInput
                  value={value}
                  placeholder="Unspecified"
                  onTextChange={setValue}
                  onKeyDown={handleKeyDown}
                  autoFocus
                  className="pr-16 text-left min-h-[6rem]"
                />
              ) : (
                <TextInput
                  type={props.type}
                  value={value}
                  placeholder="Unspecified"
                  onTextChange={setValue}
                  onKeyDown={handleKeyDown}
                  autoFocus
                  className="pr-16"
                />
              )}

              <div
                className={clsx(
                  'flex absolute top-0 right-0 bottom-0 mr-2 space-x-1',
                  props.textarea ? 'items-start pt-2' : 'items-center'
                )}
              >
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="flex items-center justify-center w-6 h-6 text-blue-500 border border-charcoal-300 hover:border-charcoal-400 hover:shadow-halo-sm"
                  title="Cancel"
                >
                  <XIcon size={16} />
                </button>
                <button
                  type="button"
                  disabled={props.defaultValue === value}
                  onClick={() => {
                    if (validate()) {
                      props.onSubmit(value || '');
                      setIsEditing(false);
                    }
                  }}
                  className={clsx(
                    'flex items-center justify-center w-6 h-6 border border-blue-500 bg-blue-500 text-white hover:shadow-halo-sm',
                    twvg('disabled', 'opacity-25 border-charcoal-300 bg-white text-charcoal-800')
                  )}
                  title="Submit"
                >
                  <CheckmarkIcon size={16} />
                </button>
              </div>
            </div>
          ) : (
            <p className={clsx('text-left', !value && 'text-charcoal-500')}>{value || 'Unspecified'}</p>
          );
        }}
      </InlineEditable>

      {error && <p className="text-label-sm text-red-500 mt-1">{error}</p>}
    </div>
  );
}
