import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
} from '@adsk/alloy-react-icon';
import { Table } from '@tanstack/react-table';
import clsx from 'clsx';
import { RefObject, useEffect, useState } from 'react';

interface Props {
  table: Table<any>;
  totalRows: number | undefined;
  isLoading: boolean;
  tableBodyRef?: RefObject<HTMLDivElement>;
}

interface PaginationMeta {
  totalRows: number;
  pageSize: number;
  currentPage: number;
  visibleRows: number;
  pageCount: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  setPageIndex: (page: number) => void;
  minRange: number;
  maxRange: number;
}

export function TablePagination(props: Props) {
  const [meta, setMeta] = useState<PaginationMeta | null>(null);

  useEffect(() => {
    if (!props.isLoading && props.totalRows) {
      const pageSize = props.table.getState().pagination.pageSize;
      const currentPage = props.table.getState().pagination.pageIndex + 1;
      const visibleRows = props.table.getRowModel().rows.length;

      setMeta({
        totalRows: props.totalRows,
        pageSize,
        currentPage,
        visibleRows,
        pageCount: props.table.getPageCount(),
        hasPrevPage: props.table.getCanPreviousPage(),
        hasNextPage: props.table.getCanNextPage(),
        goToPrevPage: props.table.previousPage,
        goToNextPage: props.table.nextPage,
        setPageIndex: props.table.setPageIndex,
        minRange: pageSize * (currentPage - 1),
        maxRange: pageSize * (currentPage - 1) + visibleRows,
      });
    }
  }, [props]);

  if (!meta) {
    return null;
  }

  return (
    <div
      className={clsx([
        'sticky bottom-0 left-0 px-3 py-5 w-full flex items-center',
        'bg-white border-t border-charcoal-100 text-charcoal-700',
      ])}
    >
      <div>
        <p>
          Showing {meta.minRange || 1}&ndash;{meta.maxRange} of {meta.totalRows}
        </p>
      </div>
      <div className="ml-auto flex items-center">
        <button
          onClick={() => meta.setPageIndex(0)}
          disabled={!meta.hasPrevPage || props.isLoading}
          className={clsx(!meta.hasPrevPage && 'opacity-20')}
        >
          <DoubleChevronLeftIcon />
        </button>
        <button
          onClick={meta.goToPrevPage}
          disabled={!meta.hasPrevPage || props.isLoading}
          className={clsx('ml-1', !meta.hasPrevPage && 'opacity-20')}
        >
          <ChevronLeftIcon className="ml-1" />
        </button>

        <p className="mx-4">
          {meta.currentPage} of {meta.pageCount}
        </p>

        <button
          onClick={() => {
            meta.goToNextPage();
            props.tableBodyRef?.current?.scrollTo(0, 0);
          }}
          disabled={!meta.hasNextPage || props.isLoading}
          className={clsx('mr-1', !meta.hasNextPage && 'opacity-20')}
        >
          <ChevronRightIcon />
        </button>
        <button
          onClick={() => {
            meta.setPageIndex(meta.pageCount - 1);
            props.tableBodyRef?.current?.scrollTo(0, 0);
          }}
          disabled={!meta.hasNextPage || props.isLoading}
          className={clsx(!meta.hasNextPage && 'opacity-20')}
        >
          <DoubleChevronRightIcon />
        </button>
      </div>
    </div>
  );
}
