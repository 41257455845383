import { createContext, useContext } from 'react';

export interface UpdateIssueContextValue {
  permittedActions: string[];
  permittedAttributes: string[];
  permittedStatuses: string[];
}

export const UpdateIssueContext = createContext<UpdateIssueContextValue>({
  permittedActions: [],
  permittedAttributes: [],
  permittedStatuses: [],
});

export function useUpdateIssueContext() {
  return useContext(UpdateIssueContext);
}
