import { CheckmarkIcon, PencilIcon, XIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import Tooltip, { OverflowTooltip } from '@adsk/alloy-react-tooltip';
import { FolderContent } from '@oasis/sdk';
import clsx from 'clsx';
import { memo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { FileTypeIcon } from '~/shared/components/base/file-type-icon';
import { Mutations } from '~/shared/hooks/mutations';
import { useMonitorDocProcessingState } from '~/shared/hooks/use-monitor-doc-processing-state';

interface Props {
  projectId: string;
  item: FolderContent;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  setContextItem: (contextItem: FolderContent | null) => void;
  urn: string;
  isViewActive?: boolean;
  disabled?: boolean;
  hideIcon?: boolean;
  className?: string;
}

export const InlineEditFile = memo(function _InlineEditFile({
  item,
  isEditing,
  setIsEditing,
  setContextItem,
  projectId,
  urn,
  disabled,
  hideIcon,
  className,
  isViewActive,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(item.name);
  const renameFolders = Mutations.Files.useRenameFolder();
  const renameDocuments = Mutations.Files.useRenameDocuments();
  const monitoredProccessState = useMonitorDocProcessingState({
    documentVersionId: item.latestVersionId,
    isProcessingDefault: item.isProcessing,
  });

  useClickAway(containerRef, () => {
    if (isEditing) setIsEditing(false);
  });

  if (isEditing) {
    return (
      <div
        ref={containerRef}
        className="relative flex-1 inline-flex items-center max-w-full"
        onClick={e => e.stopPropagation()}
      >
        <input
          value={value}
          onInput={e => setValue(e.currentTarget.value)}
          className={clsx(
            'flex-1 w-full pr-20 border border-charcoal-300 focus:border-blue-500 rounded-0 p-2 focus:outline-none',
            className
          )}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (item.type === 'folders') {
                renameFolders.mutate({ projectId: projectId, attrs: { name: value, urn: urn } });
              } else if (item.type === 'items') {
                renameDocuments.mutate({
                  projectId: projectId,
                  attrs: { name: value, versionedUrn: item.latestVersionId },
                });
              }
              setIsEditing(false);
            }
          }}
          autoFocus
        />
        <div className="flex items-center absolute top-0 right-0 bottom-0 mr-2 space-x-1">
          {[
            { name: 'Cancel', Icon: XIcon, onClick: () => setIsEditing(false) },
            {
              name: 'Submit',
              Icon: CheckmarkIcon,
              onClick: async () => {
                if (item.type === 'folders') {
                  renameFolders.mutate({ projectId: projectId, attrs: { name: value, urn: urn } });
                } else if (item.type === 'items') {
                  renameDocuments.mutate({
                    projectId: projectId,
                    attrs: { name: value, versionedUrn: item.latestVersionId },
                  });
                }
                setIsEditing(false);
              },
            },
          ].map(({ name, Icon, onClick }) => (
            <button
              key={name}
              onClick={onClick}
              className="flex items-center justify-center w-6 h-6 text-blue-500 border border-charcoal-300 hover:shadow-halo-md"
              title={name}
            >
              <Icon size={16} />
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-full inline-flex items-center group/inline-edit">
      {!hideIcon && (
        <div className="mr-2 flex items-center justify-center w-6">
          {monitoredProccessState.isProcessing || renameFolders.isPending || renameDocuments.isPending ? (
            <Tooltip content={`File processing: ${monitoredProccessState.progress}`} placement="top">
              <ProgressRing size="xsmall" />
            </Tooltip>
          ) : (
            <FileTypeIcon contentType={item.type} fileType={item.fileType} />
          )}
        </div>
      )}

      <div className={clsx('relative inline-flex items-center flex-1 max-w-full overflow-hidden', className)}>
        <OverflowTooltip content={item.name} placement="top">
          {item.name}
        </OverflowTooltip>
        {isViewActive && (
          <p className="inline-flex items-center h-5 px-2 ml-2 bg-green-500 text-white rounded-3xl text-label-sm whitespace-nowrap">
            3D View shared
          </p>
        )}
      </div>

      {!disabled && (
        <button
          onClick={e => {
            e.stopPropagation();
            setContextItem(item);
            setIsEditing(true);
          }}
          className="ml-2 my-1 opacity-75 hover:opacity-100"
        >
          <PencilIcon className="text-blue-500 opacity-0 group-hover/inline-edit:opacity-100" />
        </button>
      )}
    </div>
  );
});
