import Notification from '@adsk/alloy-react-notification';
import cloneDeep from 'lodash/cloneDeep';
import { ComponentProps, ReactNode } from 'react';
import { proxy, useSnapshot } from 'valtio';

interface ContentErrorMap<T extends string> {
  errorCode: T;
  errorMessages: Partial<Record<T, ReactNode>>;
  defaultMessage?: ReactNode;
}

interface Notification<T extends string> {
  id: number;
  type?: 'toast' | 'banner';
  status: 'info' | 'success' | 'warning' | 'error' | 'none';
  indefinite?: boolean;
  timeout: NodeJS.Timeout | undefined;
  content: ReactNode | ((remove: () => void) => ReactNode) | ContentErrorMap<T>;
}

interface NotificationStore {
  items: Notification<any>[];
}

const store = proxy<NotificationStore>({
  items: [],
});

function pushItem<T extends string>(item: Omit<Notification<T>, 'id' | 'timeout'>) {
  const id = Date.now() * Math.random();
  let timeout;

  if (!item.indefinite) {
    timeout = setTimeout(() => removeItem(id), 4000);
  }

  const next = [...store.items, { id, timeout, ...item }];

  store.items = next.length > 3 ? next.splice(1) : next;
}

function removeItem(id: number) {
  store.items = store.items.filter(item => {
    if (item.id === id) {
      clearTimeout(item.timeout);
      return false;
    }

    return true;
  });
}

export function NotificationManager(props: ComponentProps<'div'>) {
  const $store = useSnapshot(store);

  if ($store.items.length === 0) {
    return null;
  }

  return (
    <div {...props} className="fixed top-16 right-4 z-50 pointer-events-auto">
      {$store.items.map(item => {
        const { id, status, content } = cloneDeep(item); // something odd with the proy happening without clone

        if (!content) {
          throw new Error('Notifications must render some content.');
        }

        function remove() {
          removeItem(id);
        }

        return (
          <Notification key={id} status={status} onDismiss={remove}>
            <>
              {typeof content === 'function'
                ? content(remove)
                : typeof content === 'object' && 'errorCode' in content
                  ? mapErrorMessages(content as ContentErrorMap<any>)
                  : content}
            </>
          </Notification>
        );
      })}
    </div>
  );
}

NotificationManager.push = pushItem;
NotificationManager.pop = removeItem;

function mapErrorMessages<T extends string>(params: ContentErrorMap<T>) {
  const { errorCode: code, errorMessages: messages } = params;

  if (typeof code === 'string' && code in messages) {
    return messages[code];
  }

  return params.defaultMessage || 'Something went wrong. Please try again.';
}
