import { ArrayUtils } from '@oasis/utils';
import { useMemo } from 'react';
import { IssueSubtypeCircle } from '~/features/issues/components/issue-subtype-circle';
import { Combobox } from '~/shared/components/forms/combobox';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useUpdateIssueContext } from '../context';

interface Props {
  issueTypeId: string;
  issueSubtypeId: string;
  status: string;
  onSubmit(ids: { issueTypeId: string; issueSubtypeId: string }): void;
}

export function UpdateIssueTypeField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const { permittedAttributes } = useUpdateIssueContext();
  const combobox = Combobox.useComboboxProps();

  const issueTypes = Queries.Issues.useListIssueTypes({ projectId, platform });

  const canEdit = permittedAttributes.includes('issueSubtypeId');

  const label = useMemo(() => {
    if (issueTypes.data) {
      const issueType = issueTypes.data.results.find(
        issueType => issueType.isActive && issueType.id === props.issueTypeId
      );
      const subtype =
        issueType && issueType.subtypes.find(subtype => subtype.isActive && subtype.id === props.issueSubtypeId);

      return (
        <div className="flex items-center">
          {subtype && <IssueSubtypeCircle status={props.status} code={subtype.code} />}
          {issueType?.title} <span className="mx-1">&gt;</span> {subtype?.title}
        </div>
      );
    }
  }, [issueTypes.data, props]);

  const sortedIssueTypes = useMemo(() => {
    return !issueTypes.data
      ? []
      : issueTypes.data.results
          .filter(item => item.isActive)
          .sort((a, b) => ArrayUtils.sortAlphanumeric(a.title, b.title))
          .map(type => {
            type.subtypes = type.subtypes
              .filter(item => item.isActive)
              .sort((a, b) => ArrayUtils.sortAlphanumeric(a.title, b.title));
            return type;
          });
  }, [issueTypes.data]);

  return (
    <div>
      <Label className="mb-2">Type</Label>

      <InlineEditable canEdit={canEdit}>
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <Combobox
              {...combobox}
              name="issue-type"
              open={isEditing}
              setOpen={open => {
                combobox.setOpen(open);
                setIsEditing(open);
              }}
              value={props.issueSubtypeId ?? ''}
            >
              <Combobox.TriggerInput />
              <Combobox.Content>
                {sortedIssueTypes.map(issueType => {
                  return (
                    <Combobox.Section key={issueType.id} title={issueType.title}>
                      {issueType.subtypes.map(subtype => {
                        if (combobox.filterItem(`${issueType.title} ${subtype.title}`)) {
                          return null;
                        }

                        return (
                          <Combobox.Item
                            key={subtype.id}
                            value={subtype.id}
                            onClick={() => {
                              props.onSubmit({
                                issueTypeId: issueType.id,
                                issueSubtypeId: subtype.id,
                              });
                            }}
                          >
                            <div className="flex items-center">
                              <IssueSubtypeCircle status={props.status} code={subtype.code} />
                              <span className="ml-2">{subtype.title}</span>
                            </div>
                          </Combobox.Item>
                        );
                      })}
                    </Combobox.Section>
                  );
                })}
              </Combobox.Content>
            </Combobox>
          ) : (
            <div className="flex items-center">{label}</div>
          );
        }}
      </InlineEditable>
    </div>
  );
}
