import { useState } from 'react';
import { SearchInput } from '~/shared/components/forms/search-input';
import { AddUserToWorkshopModal } from '../add-user-to-workshop-modal';
import { MemberPermissionsTable } from '../member-permissions-table';

interface Props {
  projectId: string;
  workshopId: string;
}

export function WorkshopPermissionsTab(props: Props) {
  const [query, setQuery] = useState('');

  return (
    <>
      <div className="flex items-center px-4 mb-10 mt-6">
        <AddUserToWorkshopModal projectId={props.projectId} workshopId={props.workshopId} />

        <div className="ml-auto w-full max-w-xs">
          <SearchInput value={query} onTextChange={setQuery} placeholder="Search for name or email" />
        </div>
      </div>

      <div className="pl-4">
        <MemberPermissionsTable workshopId={props.workshopId} query={query} projectId={props.projectId} />
      </div>
    </>
  );
}
