import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';

export default function OauthLoginHandlerPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function checkAuth() {
      const user = await Oasis.Session.handleOauthSearchParams();

      // If we stored a project path to redirect to after auth, use that.
      let postAuthRedirect = Oasis.Storage.get('postAuthRedirect');
      if (!postAuthRedirect?.includes('/projects')) postAuthRedirect = '/projects';
      Oasis.Storage.remove('postAuthRedirect');

      if (!user.ok) {
        NotificationManager.push({
          status: 'error',
          content: 'Unable to authenticate user. Please try logging in again.',
        });

        return navigate('/login', { replace: true });
      }

      if (
        !user.value.profile &&
        (user.value.license?.isTrial ||
          user.value.license?.valid ||
          Oasis.Env.store.releaseChannel === 'beta' ||
          Oasis.Env.store.releaseChannel === 'develop')
      ) {
        Oasis.Session.addMissingPrerequisite('profile');
      }

      navigate(postAuthRedirect, { replace: true });

      Oasis.Bridge?.checkPersistedUri();
    }

    checkAuth();
  }, [navigate, searchParams]);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div>
        <ProgressRing size="large" />
        <p className="text-body-lg mt-5">{searchParams.get('preauthorized') ? 'Verifying...' : 'Signing you in...'}</p>
      </div>
    </div>
  );
}
