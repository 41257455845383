import Button from '@adsk/alloy-react-button/es/Button';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { useState } from 'react';
import { FolderTree } from '~/shared/components/base/folder-tree';
import { Queries } from '~/shared/hooks/queries';
import { Folder } from '../../../shared/components/base/folder-tree/context';

interface Props {
  projectId: string;
  onSubmit(folder: Folder | undefined): void;
  close(): void;
}

export function MoveToFolderModal(props: Props) {
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const topFolders = Queries.Projects.useListTopFolders({ projectId: props.projectId });

  if (!topFolders.data?.rootFolderUrn) {
    return null;
  }

  return (
    // @ts-expect-error
    <Modal open className="!h-auto" width={672}>
      <Modal.Header>
        <h2>Select target folder</h2>
        <Modal.Close showEscapeLabel={false} onClick={props.close} />
      </Modal.Header>

      <Modal.Body className="!py-6">
        <div className="border border-charcoal-400 rounded h-96 max-h-full overflow-auto">
          <FolderTree
            mode="FOLDERS_ONLY"
            onFolderSelect={setSelectedFolder}
            shareableOnly
            disableContextMenu
            disableFolderPersistance
            disableFolderSearchParam
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="flex items-center gap-2">
        <Button variant="tertiary" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.onSubmit(selectedFolder)}>
          Move
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
