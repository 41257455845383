type Job = () => Promise<any>;
type ResolveFn = () => void;

export class AsyncQueue {
  private readonly queue: Job[] = [];
  private active = 0;
  private resolveComplete: ResolveFn | undefined;
  private observing: boolean = false;

  constructor(
    private concurrency: number,
    private throttle = 0
  ) {}

  push(job: Job) {
    this.queue.push(job);
    this.process();
  }

  observe(): Promise<void> | void {
    if (!this.observing) {
      this.observing = true;

      return new Promise(resolve => {
        if (this.queue.length === 0 && this.active === 0) {
          resolve();
        } else {
          this.resolveComplete = resolve;
        }
      });
    }
  }

  dump() {
    this.queue.length = 0;
  }

  private async process() {
    while (this.active < this.concurrency && this.queue.length > 0) {
      const job = this.queue.shift();

      if (job) {
        this.active++;

        try {
          await job();

          if (this.throttle) {
            await new Promise(resolve => setTimeout(resolve, this.throttle));
          }
        } finally {
          this.active--;
          this.process();
        }
      }
    }
    if (this.queue.length === 0 && this.active === 0 && this.resolveComplete) {
      this.resolveComplete();
      this.resolveComplete = undefined;
    }
  }
}
