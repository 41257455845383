import { CheckmarkIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { STATUS_COLOR_CLASSES } from '~/shared/utils/const.issues';

interface Props {
  status: string;
  code?: string;
}

export function IssueSubtypeCircle({ code, status }: Props) {
  const colorClasses = STATUS_COLOR_CLASSES[status] || STATUS_COLOR_CLASSES.default;

  if (!code) {
    return null;
  }

  return (
    <span
      className={clsx(
        'inline-flex items-center justify-center w-5 h-5 rounded-full mr-2 font-bold',
        code.length < 3 ? 'text-[0.6875rem]' : 'text-[0.5625rem]',
        colorClasses
      )}
    >
      {code === '✓' ? <CheckmarkIcon size={16} /> : code}
    </span>
  );
}
