export const JOB_TITLES = _mapToDropdownItems([
  '3D generalist',
  '3D modeler',
  '3D visualization artist',
  'Administrative professional',
  'Animator',
  'Architectural detailer',
  'Architectural technician',
  'Architecture energy analyst',
  'Art director',
  'BIM manager',
  'Building architect',
  'Building inspector',
  'Building owner',
  'Business owner/Entrepreneur',
  'C-Level, not mentioned elsewhere',
  'CAD manager',
  'CAM engineer',
  'Cartographer / Photogrammetrist',
  'Chief creative officer',
  'Chief financial officer',
  'Chief information officer',
  'Chief marketing officer',
  'Chief operations officer',
  'Chief technical officer',
  'Civil engineer (Geotechnical)',
  'Civil engineer (Hydrology / hydraulics)',
  'Civil engineer (Land and site design)',
  'Civil engineer (Rail)',
  'Civil engineer (Roads and highways)',
  'Civil engineer (Tunnels and bridges)',
  'Colorist',
  'Competition mentor',
  'Compositor',
  'Computational designer',
  'Concept artist',
  'Construction Manager',
  'Control systems engineer/technician',
  'Design manager/director',
  'Designer, not mentioned elsewhere',
  'Digital producer',
  'Director, not mentioned elsewhere',
  'Document controller',
  'Editor',
  'Education administrator',
  'Electrical detailer',
  'Electrical engineer (MEP)',
  'Electrical engineer (Product and manufacturing)',
  'Electrical estimator',
  'Electrical technician/designer (MEP)',
  'Electrical technician/designer (Product and manufacturing)',
  'Electronics designer',
  'Electronics engineer',
  'Energy analyst (MEP)',
  'Engineer (Other specialities)',
  'Environment Artist',
  'Environmental Engineer',
  'Estimator/quantity surveyor',
  'Facility manager',
  'Field/site personnel',
  'Finance professional',
  'Fire systems engineer/technician',
  'Game designer/developer',
  'Geographic information specialist',
  'Geotechnical Engineer',
  'Graphic designer',
  'Head of CG',
  'Health and safety professional',
  'HR professional',
  'Industrial designer',
  'Industrial process engineer',
  'Industrial process technician/designer',
  'Installation/maintenance/repair professional',
  'Interior designer',
  'IT/comms engineer/technician',
  'IT/software administrator',
  'IT/software manager',
  'Landscape architect',
  'Lighting artist',
  'Lighting designer',
  'Look-dev artist',
  'Machinist',
  'Manager, not mentioned elsewhere',
  'Managing director/director of operations',
  'Manufacturing/production engineer',
  'Marketing and communication professional',
  'Mechanical detailer (MEP - other specialties)',
  'Mechanical detailer (MEP - piping)',
  'Mechanical detailer (MEP - sheet metal)',
  'Mechanical detailer (Other specialities)',
  'Mechanical engineer (MEP)',
  'Mechanical engineer (Product and manufacturing)',
  'Mechanical estimator (MEP - ductwork)',
  'Mechanical estimator (MEP - piping)',
  'Mechanical estimator&nbsp;(MEP - other specialties)',
  'Mechanical technician/designer (MEP)',
  'Mechanical technician/designer (Product and manufacturing)',
  'Mocap artist',
  'Molding specialist',
  'Motion graphics artist',
  'Non-manager, not mentioned elsewhere',
  'Pipeline engineer',
  'Plant design engineer',
  'Pre-construction manager',
  'President/CEO',
  'Previz artist',
  'Producer',
  'Product designer',
  'Product engineer',
  'Product manager',
  'Production coordinator/data wrangler',
  'Production operations manager',
  'Project engineer',
  'Project manager',
  'Prototype specialist',
  'Quality manager',
  'R&amp;D designer',
  'Real estate professional',
  'Rigger',
  'Sales professional',
  'Software engineer/developer',
  'Stereographer',
  'Structural detailer (other specialties)',
  'Structural engineer',
  'Structural estimator',
  'Structural fabricator',
  'Structural precast detailer',
  'Structural rebar detailer',
  'Structural steel detailer',
  'Structural technician/designer',
  'Student (17 and younger)',
  'Student (18 plus)',
  'Studio owner',
  'Subcontractor',
  'Superintendent/foreman',
  'Supply chain/purchasing/procurement professional',
  'Support Specialist',
  'Surveyor',
  'Sustainability consultant (Architecture)',
  'Sustainability consultant (MEP)',
  'Teacher/faculty',
  'Technical and engineering manager',
  'Technical artist',
  'Technical director',
  'Technician/drafter, not mentioned elsewhere',
  'Texture artist',
  'Urban planner',
  'VFX artist',
  'Vice president',
  'Video Editor',
  'Water/sanitary/gas engineer/technician',
  'Web designer/UX designer',
]);

export const INDUSTRIES = _mapToDropdownItems([
  'Architecture, Engineering, Construction (AEC)',
  'Design & Manufacturing (D&M)',
  'Media & Entertainment (M&E)',
  'Other (Please specify)',
]);

export const SEGMENTS: Record<string, Record<string, unknown>[]> = {
  'Architecture, Engineering, Construction (AEC)': _mapToDropdownItems([
    'Architectural Services',
    'Buildings',
    'Civil Infrastructure',
    'Engineering Service Providers',
    'Mining',
    'Oil & Gas',
    'Utilities & Telecom',
    'Other (Please specify)',
  ]),
  'Design & Manufacturing (D&M)': _mapToDropdownItems([
    'Aerospace & Defense Equipment',
    'Automotive & Other Transportation',
    'Building Product & Fabrication',
    'Consumer Products',
    'Industrial Machinery',
    'Life Sciences Manufacturing',
    'Process Manufacturing',
    'Manufacturing ',
    'Other (Please specify)',
  ]),
  'Media & Entertainment (M&E)': _mapToDropdownItems([
    'Advertising',
    'Publishing & Graphic Design',
    'Film and TV',
    'Games',
    'Other (Please specify)',
  ]),
  'Other (Please specify)': [],
};

function _mapToDropdownItems(items: string[]) {
  return items.map(title => ({ value: title, label: title }));
}
