import { DataBinder, type DataBinderHandle } from '@fluid-experimental/property-binder';
import { MapProperty } from '@fluid-experimental/property-properties';
import { useCallback, useEffect } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { useWorkshopState } from '~/shared/hooks/use-workshop-state';

export type WorkshopFluidDataType = 'measurements' | 'annotations';

const store = proxy({
  isRegistering: true,
  hasMeasurements: false,
  hasAnnotations: false,
});

export function useWorkshopFluidControls(workshopId: string) {
  const { workshopConnection, status } = useWorkshopState({ connectFluidBasedOnContext: false, workshopId });
  const $store = useProxy(store);
  const isConnecting = status === 'CONNECTING';
  const getSessionsProperty = useCallback(() => {
    return workshopConnection?.propertyTree.root.get<MapProperty>('sessions');
  }, [workshopConnection]);

  useEffect(() => {
    const dataBinder = new DataBinder();
    let handle: DataBinderHandle | undefined;

    if (workshopConnection) {
      dataBinder.attachTo(workshopConnection.propertyTree);
      handle = dataBinder.registerOnPath('sessions', ['insert', 'modify', 'remove'], () => {
        const sessionsProperty = getSessionsProperty();
        const sessions = sessionsProperty?.getIds() ?? [];
        let nextHasMeasurements = false;
        let nextHasAnnotations = false;

        if (sessions.length) {
          for (const session of sessions) {
            const modelData = sessionsProperty?.get<MapProperty>(session);

            if (!modelData) {
              continue;
            }

            if (!nextHasMeasurements) {
              const measurementData = modelData.get<MapProperty>('measurements')?.getAsArray() || [];
              nextHasMeasurements = measurementData.length > 0;
            }

            if (!nextHasAnnotations) {
              const annotationData = modelData.get<MapProperty>('annotations')?.getAsArray() || [];
              nextHasAnnotations = annotationData.length > 0;
            }
          }
        }

        store.hasMeasurements = nextHasMeasurements;
        store.hasAnnotations = nextHasAnnotations;
        store.isRegistering = false;
      });
    }

    return () => {
      if (handle && workshopConnection) {
        dataBinder.detach();
        handle.destroy();
      }

      store.isRegistering = true;
      store.hasMeasurements = false;
      store.hasAnnotations = false;
    };
  }, [workshopConnection, getSessionsProperty, workshopId]);

  function resetFluidData(dataType: WorkshopFluidDataType) {
    const sessionsProperty = getSessionsProperty();
    const sessions: MapProperty[] = sessionsProperty?.getAsArray() ?? [];

    for (const modelData of sessions) {
      const stateData = modelData?.get<MapProperty>(dataType);

      if (!modelData || !stateData) {
        return false;
      }

      stateData.clear();
    }

    workshopConnection?.propertyTree?.commit();
  }

  return {
    isConnecting: isConnecting || $store.isRegistering,
    hasMeasurements: $store.hasMeasurements,
    hasAnnotations: $store.hasAnnotations,
    resetData(...dataTypes: WorkshopFluidDataType[]) {
      for (const dataType of dataTypes) {
        resetFluidData(dataType);
      }
    },
  };
}
