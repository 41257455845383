import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function IndexPage() {
  const $session = Oasis.Session.useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if ($session.status !== 'PENDING') {
      navigate($session.status === 'UNAUTHENTICATED' ? '/login' : '/projects');
    }
  }, [$session.status, navigate]);

  return null;
}
