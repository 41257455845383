import { CheckmarkCircleFilledIcon, XIcon } from '@adsk/alloy-react-icon';
import { Oasis, OasisResponses } from '@oasis/sdk';
import { ArrayUtils, DateUtils, FileUtils } from '@oasis/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { WorkshopActiveUsers } from '~/features/workshops/components/workshop-active-users';
import { WorkshopThumbnail } from '~/features/workshops/components/workshop-thumbnail';
import { useWorkshopPermissions } from '~/features/workshops/hooks/use-workshop-permissions';
import { Button } from '~/shared/components/base/button';
import { ConditionalWrapper } from '~/shared/components/base/conditional-wrapper';
import { Confirmation } from '~/shared/components/base/confirmation';
import { FileTypeIcon } from '~/shared/components/base/file-type-icon';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Skeleton } from '~/shared/components/base/skeleton';
import { Tooltip } from '~/shared/components/base/tooltip';
import { AccDocumentIcon } from '~/shared/components/icons/acc-documentation-icon';
import { Doctype3dViewIcon } from '~/shared/components/icons/doctype-3d-view-icon';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { emptyWorkshopTableIlloUrl } from '~/shared/utils/const.asset-urls';
import { AddFilesModal } from '../add-files-modal';

interface Props {
  projectId: string;
  workshop: OasisResponses['Workshops']['FindById'];
}

/**
 * Large chunks of functionality are commented out for uploads. We'll use it eventually,
 * but decisions on how we determine where these files are uploaded hasnt been made.
 */
export function WorkshopDetailsTab(props: Props) {
  const $session = Oasis.Session.useStore();
  const queryClient = useQueryClient();

  const [showFiles, setShowFiles] = useState(false);

  const settings = Queries.Workshops.useGetWorkshopSettings(props.workshop.id);
  const users = Queries.Workshops.useListWorkshopMembers(props.workshop.id);
  const activeUsers = Queries.Workshops.useListActiveUsers(props.workshop.id);
  const permissions = useWorkshopPermissions(props.workshop.id);

  // TODO: Cleanup legacy `murnxfrm` when VR merges distinct keys.
  const versionId =
    typeof settings.data?.murn?.value === 'string'
      ? settings.data?.murn.value
      : typeof settings.data?.murnxfrm?.value === 'string'
        ? settings.data.murnxfrm?.value.split(/_(.*?)(;|$)/)[1] || ''
        : '';

  const fileReference = Queries.Files.useFindDocumentVersionById({
    projectId: props.projectId,
    documentVersionId: versionId,
  });
  const views = Queries.Files.useList3dViews({
    projectId: props.projectId,
    documentId: fileReference.data?.data?.relationships?.item?.data?.id || '',
  });

  const updateSettingsModel = Mutations.Workshops.useUpdateWorkshopSettingsModel();
  const removeSettingsModel = Mutations.Workshops.useRemoveWorkshopSettingsModel();

  function removeTableContent() {
    Confirmation.show({
      title: 'Remove content?',
      description: "This action cannot be undone. Removing this content won't delete it from your Files.",
      confirmLabel: 'Remove',
      async confirm() {
        if (!settings.data || !settings.data.version?.value || !$session.user?.id) {
          return NotificationManager.push({
            status: 'error',
            content: 'Unable to update table content. Please refresh and try again.',
          });
        }

        removeSettingsModel.mutate({
          projectId: props.projectId,
          workshopId: props.workshop.id,
          currentSettingsVersion: typeof settings.data.version.value === 'number' ? settings.data.version.value : 0,
        });

        queryClient.removeQueries({
          queryKey: ['projects', props.projectId, 'versions', versionId],
        });
      },
    });
  }

  const fileReferenceMeta = useMemo(() => {
    if (!settings.data) {
      return null;
    }

    const user =
      settings.data.mupdatedby &&
      users.data?.results.members?.find(user => {
        return user.autodeskId === settings.data?.mupdatedby?.value;
      });
    const updatedAt =
      typeof settings.data.mupdatedat?.value === 'string'
        ? DateUtils.getFriendlyFormat(settings.data.mupdatedat.value)
        : null;

    if (!user || !updatedAt) {
      return null;
    }

    return {
      updatedBy: user?.name,
      updatedAt: updatedAt,
    };
  }, [settings.data, users.data]);

  const activeView = useMemo(() => {
    const viewGuid = settings.data?.mvid?.value;
    if (typeof viewGuid === 'string' && views.data) {
      return views.data.find(view => view.guid === viewGuid);
    }
  }, [settings.data, views.data]);

  const hasModelLoaded = versionId || typeof settings.data?.murn?.value === 'string';
  const hasViewLoaded = typeof settings.data?.mvid?.value === 'string';
  const hasTableContent = hasModelLoaded || hasViewLoaded;

  return (
    <section className="px-4 py-6">
      <div className="bg-charcoal-50 rounded-md shadow-low aspect-video">
        <WorkshopThumbnail workshopId={props.workshop.id} workshopName={props.workshop.name} />

        {activeUsers.data && activeUsers.data.results.length > 0 && (
          <div className="bg-white px-4 pt-2 pb-4">
            <p className="text-heading-4 mb-2">
              {activeUsers.data.results
                .map((user, index) => (index > 1 ? null : `${user.firstName} ${user.lastName}`))
                .filter(ArrayUtils.truthy)
                .join(', ')}

              {activeUsers.data.results.length > 2 && ` and ${activeUsers.data.results.length - 3} others are here...`}
            </p>
            <WorkshopActiveUsers workshopId={props.workshop.id} />
          </div>
        )}
      </div>

      <div className="mt-8">
        <h3 className="text-label-sm text-charcoal-700 mb-4">
          Table content <span className="text-charcoal-400">(RVT or NWD)</span>
        </h3>

        <div className="flex items-center w-full space-x-3 mb-4">
          <Tooltip
            placement="bottom"
            content={!permissions.canEdit ? 'You do not have permission to change models.' : undefined}
            containerClass="w-full"
            className="mt-2"
          >
            <Button
              variant="secondary"
              className="w-full"
              onClick={() => setShowFiles(true)}
              disabled={!permissions.canEdit}
            >
              <AccDocumentIcon className="w-5 mr-2" /> From Files
            </Button>
          </Tooltip>
        </div>

        {settings.data && hasTableContent && (
          <_ActiveFileReference
            type={hasViewLoaded ? 'view' : 'model'}
            projectId={props.projectId}
            documentUrn={fileReference.data?.data.relationships.item.data.id || ''}
            name={hasViewLoaded ? activeView?.name : fileReference.data?.data?.attributes.name}
            updatedBy={fileReferenceMeta?.updatedBy}
            updatedAt={fileReferenceMeta?.updatedAt}
            canEdit={permissions.canEdit}
            isLoading={fileReference.isLoading || users.isLoading || (hasViewLoaded && views.isLoading)}
            removeTableContent={removeTableContent}
          />
        )}

        {permissions.canEdit && !settings.isLoading && !hasTableContent && (
          <div className="flex flex-col items-center justify-center pb-8 border text-label-md text-charcoal-200 rounded-sm">
            <img src={emptyWorkshopTableIlloUrl} alt="Empty workshop table illustation" className="w-36" />
            <h3 className="text-charcoal-600">Table is empty. </h3>
          </div>
        )}
      </div>

      {showFiles && settings.data && $session.user?.id && (
        <AddFilesModal
          projectId={props.projectId}
          close={() => setShowFiles(false)}
          onSubmit={async selection => {
            if (!selection?.urn || !settings.data) {
              return NotificationManager.push({
                status: 'error',
                content: 'Choose a file and try again.',
              });
            }

            updateSettingsModel.mutate(
              {
                projectId: props.projectId,
                workshopId: props.workshop.id,
                documentUrn: selection.urn,
                viewGuid: selection.viewGuid,
                currentSettingsVersion:
                  typeof settings.data.version?.value === 'number' ? settings.data.version.value : 0,
              },
              {
                onSuccess() {
                  NotificationManager.push({
                    status: 'success',
                    content: 'Updated workshop model.',
                  });
                },
                onError() {
                  NotificationManager.push({
                    status: 'error',
                    content: 'Failed to update workshop settings.',
                  });
                },
              }
            );

            setShowFiles(false);
          }}
        />
      )}
    </section>
  );
}

function _ActiveFileReference(props: {
  projectId: string;
  documentUrn: string;
  type: 'model' | 'view';
  name?: string;
  updatedBy?: string;
  updatedAt?: string;
  canEdit: boolean;
  isLoading: boolean;
  removeTableContent(): void;
}) {
  const $env = Oasis.Env.useStore();

  return (
    <div className="flex items-center min-h-[4rem] pl-4 pr-3 py-3 border border-charcoal-200">
      <div className="flex items-center justify-center w-8 mr-3 mb-0.5">
        {props.isLoading && <div className="w-5 h-5 bg-charcoal-100 animate-pulse" />}

        {!props.isLoading && props.type === 'model' && (
          <FileTypeIcon
            contentType="items"
            fileType={FileUtils.getFileType({
              type: 'items',
              name: props.name ?? '',
            })}
          />
        )}

        {!props.isLoading && props.type === 'view' && <Doctype3dViewIcon className="w-6 text-[#5C7896]" />}
      </div>

      <div className="flex-1">
        {props.isLoading ? (
          <div className="mb-1">
            <Skeleton />
          </div>
        ) : (
          <>
            <ConditionalWrapper
              condition={!$env.isVrHomespace}
              wrapper={children => (
                <Link
                  to={`/projects/${props.projectId}/files/${props.documentUrn}`}
                  state={{ from: 'Workshops', path: window.location.pathname }}
                  className="hover:text-blue-500"
                >
                  {children}
                </Link>
              )}
            >
              <p className="mr-2 flex-1 w-full truncate align-middle text-left">{props.name}</p>
            </ConditionalWrapper>

            {props.updatedBy && props.updatedAt && (
              <p className="flex items-center min-h-[1.5rem] text-body-sm text-charcoal-500">
                {props.updatedBy}, {props.updatedAt}
              </p>
            )}
          </>
        )}
      </div>
      <div className="flex items-center ml-2 text-center">
        <div className="mr-2">
          <CheckmarkCircleFilledIcon className="text-green-500" size={20} />
        </div>

        {props.canEdit && (
          <button onClick={props.removeTableContent} className="p-2 text-charcoal-600 hover:text-blue-500">
            <XIcon />
          </button>
        )}
      </div>
    </div>
  );
}
