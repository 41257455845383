import { ArrowRotateIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { Popover } from '../../../../components/base/popover';
import { UnrealReleaseStatus } from './unreal-release-status';

export function UpdatesMenu() {
  const $env = Oasis.Env.useStore();
  const $bridge = Oasis.Bridge?.useStore();

  if (!$bridge || !$env.isDesktop || ($bridge && $bridge.unrealUpdateStatus === 'UPTODATE')) {
    return null;
  }

  return (
    // <Popover className="flex items-center relative ml-4">
    <Popover>
      <Popover.Trigger className="flex items-center justify-center w-8 h-12 text-charcoal-600 hover:text-charcoal-800">
        <div className="relative">
          <ArrowRotateIcon />
          <div
            className={clsx(
              'w-2 h-2 border-white absolute bottom-0 right-0 rounded-full',
              $bridge.unrealUpdateStatus === 'DONE' ? 'bg-green-500' : 'bg-yellorange-500'
            )}
          />
        </div>
      </Popover.Trigger>

      <Popover.Content className="HeaderRightNav__Content -right-5 w-[26rem] p-4 pb-6">
        <div className="HeaderRightNav__Arrow" />
        <p className="text-heading-4 mb-3">App Updates</p>
        <UnrealReleaseStatus />
      </Popover.Content>
    </Popover>
  );
}
