import { Oasis, OasisSchemas, SubscriptionHandler, SubscriptionPayloads } from '@oasis/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useCallback, useEffect } from 'react';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { AppHistory } from '~/shared/utils/app-history';
import { useFeatureFlags } from './use-feature-flags';

export const Subscriptions = {
  useCommands() {
    const $mqtt = Oasis.Mqtt.useStore();
    const $session = Oasis.Session.useStore();
    const $env = Oasis.Env.useStore();
    const isReceivingCommandsLocally = useFeatureFlags('040924-6783-send-console-commands-locally');

    const handler: SubscriptionHandler = useCallback(
      async (payload, topic) => {
        // If its a console only payload but not VR we can ignore it.
        // Likewise, if it's a payload without a command `type` ignore it.
        const nonApplicableVrPayload = $env.isVr && !topic.includes('console');
        const invalidPayload = !('type' in payload);

        if (nonApplicableVrPayload || invalidPayload) return;

        switch (payload.type) {
          case 'PairedDevice':
            NotificationManager.push({
              type: 'banner',
              status: 'success',
              content: (
                <p>
                  <span className="font-bold">Headset</span> successfully paired.
                </p>
              ),
            });

            AppHistory.searchParams.delete('dialog');
            AppHistory.setSearchParams(AppHistory.searchParams);
            break;

          case 'JoinedWorkshop':
            Oasis.Session.setActiveWorkshop(payload.args.id);
            break;

          case 'LeftWorkshop':
            if (Oasis.Session.store.activeWorkshop?.id === payload.args.id) {
              Oasis.Session.clearActiveWorkshop();
            }
            break;

          case 'Navigate':
            const [_, querystring] = payload.args.path.split('?');
            const navigateParams = new URLSearchParams(querystring);
            const newContext = OasisSchemas.EnvContext.safeParse(navigateParams.get('context'));
            const newVrTargetDevice = OasisSchemas.Device.safeParse(navigateParams.get('vr-target'));

            const url = new URL(window.location.href);
            const urlPath = url.pathname + url.search;

            if (isReceivingCommandsLocally) {
              if (urlPath.indexOf(payload.args.path) !== -1) {
                console.info('Ignoring navigate command as the path is already the same');
                break;
              }
            }
            if (newContext.success) {
              Oasis.Env.setContext(newContext.data);
            }

            if (newVrTargetDevice.success) {
              Oasis.Env.setVrTargetDevice(newVrTargetDevice.data);
            }

            AppHistory.navigate(payload.args.path, {
              state: {
                fromNavigateCommand: true,
                path: payload.args.path,
              },
            });
            break;

          case 'OpenIssue':
            AppHistory.navigate(`/projects/${payload.args.projectId}/issues?issueId=${payload.args.id}`);
            Oasis.NetworkCommands.emitNavigationProcessed({ path: `${payload.args.projectId}/${payload.args.id}` });
            break;

          case 'ForwardToPendo':
            try {
              JSON.parse(JSON.stringify(payload.args.data));
              Oasis.Pendo.track(payload.args.label, payload.args.data, true);
              Oasis.Pendo.flush();
            } catch (error) {
              Oasis.Logger.error({ msg: '[Subscriptions.useCommands] Attempted to forward invalid data to Pendo.' });
            }
            break;

          case 'ForwardToSegment':
            try {
              JSON.parse(JSON.stringify(payload.args.data));
              Oasis.Segment.track(payload.args.label, payload.args.data);
            } catch (error) {
              Oasis.Logger.error({ msg: '[Subscriptions.useCommands] Attempted to forward invalid data to Segment.' });
            }
            break;

          case 'SetActiveTool':
            Oasis.Session.setActiveVrTool(payload.args.name);
            break;
        }
      },
      [$env.isVr, isReceivingCommandsLocally]
    );

    useEffect(() => {
      if (isReceivingCommandsLocally) {
        const listener = (event: CustomEvent<SubscriptionPayloads>) => {
          const payload = event.detail;
          Oasis.Logger.info({ payload, msg: '[Subscriptions.useCommands] Received client command locally' });
          handler(payload, 'console');
        };

        window.addEventListener<any>('clientCommands', listener);

        return () => {
          window.removeEventListener<any>('clientCommands', listener);
        };
      }
    }, [handler, isReceivingCommandsLocally]);

    useEffect(() => {
      const userId = $session.user?.id || Oasis.Storage.get('currentUserId');

      if (!userId || $mqtt.status !== 'CONNECTED') {
        return;
      }

      const activeContexts = $env.isVr ? ['console'] : ['web'];
      let unsubFns: (() => void)[] = [];

      (async () => {
        unsubFns = await Promise.all(
          activeContexts.map(async context => {
            const topic = `wsq/v1/cmd/${userId}/${context}`;
            const { removeHandler } = await Oasis.Mqtt.subscribe(topic, handler);
            return removeHandler ?? noop;
          })
        );
      })();

      return () => {
        for (const unsub of unsubFns) {
          unsub();
        }
      };
    }, [$session.user?.id, $env.isVr, $mqtt.status, handler]);
  },

  useIssueEvents(projectId: string) {
    const $session = Oasis.Session.useStore();
    const queryClient = useQueryClient();

    useEffect(() => {
      const workshopId = $session.activeWorkshop?.id;

      if (!workshopId) {
        return;
      }

      function invalidate() {
        queryClient.invalidateQueries({
          queryKey: ['projects', projectId, 'issues'],
        });
      }

      const unsubFns: (() => void)[] = [];

      (async () => {
        for (const action of ['add', 'update', 'del']) {
          const subject = action + 'i';

          const { unsubscribe } = await Oasis.Mqtt.subscribe(
            `wsq/v1/ws1/${workshopId}/${subject}`,
            (payload: unknown) => {
              Oasis.Logger.info({ payload, msg: `[Subscriptions.useIssueEvents#${subject}]` });
              invalidate();
            }
          );

          if (unsubscribe) {
            unsubFns.push(unsubscribe);
          }
        }
      })();

      return () => {
        for (const unsub of unsubFns) {
          unsub();
        }
      };
    }, [$session.activeWorkshop?.id, projectId, queryClient]);
  },
};
