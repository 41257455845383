import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { useNavigateToModal } from '~/shared/hooks/use-modal';

export default function OauthPairingHandlerPage() {
  const navigate = useNavigate();
  const navigateToModal = useNavigateToModal();

  useEffect(() => {
    (async () => {
      const pairingCode = await Oasis.Session.getPairingCode();
      let postAuthRedirect = Oasis.Storage.get('postAuthRedirect');

      Oasis.Logger.debug({
        msg: 'Pairing code redirect',
        pairingCode,
        postAuthRedirect,
      });

      if (!postAuthRedirect?.includes('/projects')) {
        postAuthRedirect = '/projects';
      }

      Oasis.Storage.remove('postAuthRedirect');

      if (!pairingCode.ok) {
        NotificationManager.push({
          status: 'error',
          content: (
            <p>
              <span className="font-bold">Pairing code</span> failed to generate. Try again.
            </p>
          ),
        });

        if (pairingCode.error.code === 'MISSING_FORGE_XR_TOKEN') {
          navigate(postAuthRedirect, { replace: true });
        }

        return;
      }

      navigateToModal(postAuthRedirect, 'pair-headset', { code: pairingCode.value });
    })();
  }, [navigate, navigateToModal]);

  return null;
}
