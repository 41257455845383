import Avatar from '@adsk/alloy-react-avatar';
import { AlertInformationIcon, ChevronRightIcon, MoreVerticalIcon, PeopleIcon } from '@adsk/alloy-react-icon';
import {
  TableCell,
  TableHeader,
  TableHeaderGroup,
  TableRow,
  TableSkeletonLoadingRow,
  useSkeletonLoadingRows,
  useTable,
} from '@adsk/alloy-react-table';
import { Oasis, WorkshopPermissions } from '@oasis/sdk';
import * as ContextMenu from '@radix-ui/react-context-menu';
import * as Popover from '@radix-ui/react-popover';
import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { ConditionalWrapper } from '~/shared/components/base/conditional-wrapper';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { Tooltip } from '~/shared/components/base/tooltip';
import { InlinePermissionsSelect } from '~/shared/components/forms/inline-permissions-select';
import { Queries } from '~/shared/hooks/queries';
import { MemberPermissionsContextMenu } from './member-permissions-context-menu';

interface Row {
  admin: boolean;
  autodeskId: string;
  name: string;
  email?: string;
  permission: WorkshopPermissions;
  companyName: string | null;
  children: Row[];
}

interface Props {
  workshopId: string;
  query?: string;
  projectId: string;
}

const columnHelper = createColumnHelper<Row>();

export function MemberPermissionsTable(props: Props) {
  const $session = Oasis.Session.useStore();

  const [contextItem, setContextItem] = useState<Row | null>(null);
  const [expanded, setExpanded] = useState<ExpandedState>({ admins: true });
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const workshopMembers = Queries.Workshops.useListWorkshopMembers(props.workshopId);
  const projectAdmins = Queries.Projects.useListAdminUsers({ projectId: props.projectId });
  const projectMembers = Queries.Projects.useListProjectUsers({ projectId: props.projectId });

  const isAdminUser = useMemo(
    () => projectAdmins.data?.results?.some(user => user.autodeskId === $session.user?.id),
    [$session.user, projectAdmins.data]
  );
  const hasMultipleManagers = useMemo(() => {
    const count = workshopMembers.data?.results.members.filter(member => member.permission === 'MANAGE').length || 0;
    return count > 1;
  }, [workshopMembers.data?.results.members]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Name',
        size: 180,
        enableResizing: false,
        cell: info => {
          const currentMember = projectMembers.data?.results.find(
            member => member.autodeskId === info.row.original.autodeskId
          );

          if (!info.row.original.admin) {
            if (info.getValue() === 'XR Admins') {
              return (
                <>
                  <button
                    className="inline-flex items-center justify-center w-8 h-7 -ml-1"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      info.row.toggleExpanded();
                    }}
                  >
                    <ChevronRightIcon
                      className={clsx(
                        'transition-transform duration-100 transform-gpu',
                        info.row.getIsExpanded() && 'rotate-90'
                      )}
                    />
                  </button>
                  <p className="truncate pl-2">
                    <Avatar size="small" name={''} imageUrl={''} className="mr-3">
                      <PeopleIcon className="w-5 h-5" />
                    </Avatar>
                    {info.getValue()}
                  </p>
                </>
              );
            } else {
              return (
                <p className="truncate">
                  <Avatar size="small" name={info.getValue()} imageUrl={currentMember?.imageUrl} className="mr-3" />
                  {info.getValue()}
                </p>
              );
            }
          }

          return (
            <p className="truncate pl-9">
              <Avatar size="small" name={info.getValue()} imageUrl={currentMember?.imageUrl} className="mr-3" />
              {info.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor('permission', {
        header: 'Permissions',
        minSize: 180,
        size: 180,
        enableResizing: false,
        cell: info => {
          if (!info.row.original.admin) {
            if (info.row.original.name === 'XR Admins') {
              return (
                <>
                  <InlinePermissionsSelect
                    workshopId={props.workshopId}
                    userId={info.row.original.autodeskId}
                    value={info.getValue()}
                    disabled={true}
                  />
                  {isAdminUser && (
                    <Tooltip
                      placement="right"
                      content="XR Access level can be changed for individuals in the Members tab."
                      className="ml-2 max-w-56"
                    >
                      <AlertInformationIcon size={16} className="hover:text-blue-600" />
                    </Tooltip>
                  )}
                </>
              );
            } else {
              const isOnlyManager = !hasMultipleManagers && info.getValue() === 'MANAGE';

              return (
                <ConditionalWrapper
                  condition={isOnlyManager}
                  wrapper={children => (
                    <Tooltip placement="left" content="One workshop member is required to have manage permissions.">
                      {children}
                    </Tooltip>
                  )}
                >
                  <InlinePermissionsSelect
                    disabled={isOnlyManager}
                    workshopId={props.workshopId}
                    userId={info.row.original.autodeskId}
                    value={info.getValue()}
                  />
                </ConditionalWrapper>
              );
            }
          }
        },
      }),
      columnHelper.accessor('companyName', {
        header: 'Company',
        size: 180,
        enableResizing: false,
        cell: info => <p className="truncate">{info.getValue()}</p>,
      }),
      columnHelper.display({
        id: 'actions',
        size: 60,
        enableResizing: false,
        enableSorting: false,
        cell: info => {
          if (!info.row.original.admin && info.row.original.name !== 'XR Admins') {
            return (
              <Popover.Trigger
                onClick={e => {
                  e.stopPropagation();
                  setContextItem(info.row.original);
                }}
                className="w-14 h-full inline-flex items-center justify-center opacity-60 text-charcoal-700 group-hover:opacity-100 hover:text-blue-500"
              >
                <MoreVerticalIcon />
              </Popover.Trigger>
            );
          }
        },
      }),
    ];
  }, [isAdminUser, hasMultipleManagers, projectMembers.data?.results, props.workshopId]);

  const data: Row[] = useMemo(() => {
    if (!workshopMembers.data) {
      return [];
    }

    let projectMembers: Row[] = workshopMembers.data.results.members.map(item => ({
      ...item,
      autodeskId: item.autodeskId || item.email,
      admin: false,
      children: [],
    }));

    let adminUsers = workshopMembers.data.results.admins.map(item => ({
      ...item,
      autodeskId: item.autodeskId || item.email,
      admin: true,
      children: [],
    }));

    if (props.query) {
      const query = props.query.toLowerCase();

      projectMembers = projectMembers.filter(item => {
        const searchable = item.name.toLowerCase() + item.email?.toLowerCase();
        return searchable.includes(query);
      });

      adminUsers = adminUsers.filter(item => {
        const searchable = item.name.toLowerCase() + item.email?.toLowerCase();
        return searchable.includes(query);
      });
    }

    if (adminUsers.length !== 0) {
      projectMembers.push({
        autodeskId: 'admins',
        name: 'XR Admins',
        admin: false,
        companyName: null,
        permission: 'MANAGE',
        children: adminUsers,
      });
    }

    return projectMembers;
  }, [props.query, workshopMembers.data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      sorting,
      expanded,
      columnPinning: {
        right: ['actions'],
      },
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 9999,
      },
    },
    getRowId: (row, _index, parent) => (parent ? `${parent.id}-${row.autodeskId}` : row.autodeskId),
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.children,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { getTableRowProps, getTableCellProps, getTableHeaderGroupProps, getTableHeaderProps } = useTable({ table });
  const { getSkeletonLoadingRows, getSkeletonLoadingRowProps } = useSkeletonLoadingRows({
    // @ts-expect-error
    table,
    numberOfRows: 8,
  });

  if (workshopMembers.data) {
    return (
      <div className="flex items-start w-full h-full overflow-auto mr-5">
        <ContextMenu.Root
          onOpenChange={isOpen => {
            if (!isOpen) setContextItem(null);
          }}
        >
          <table className="flex-1">
            <thead className="sticky top-0 z-10">
              {table.getCenterHeaderGroups().map(headerGroup => (
                <TableHeaderGroup key={headerGroup.id} {...getTableHeaderGroupProps(headerGroup)}>
                  {headerGroup.headers.map(header => (
                    <TableHeader key={header.id} {...getTableHeaderProps(header)} />
                  ))}
                </TableHeaderGroup>
              ))}
            </thead>
            <ContextMenu.Trigger asChild>
              <tbody>
                {workshopMembers.isLoading &&
                  getSkeletonLoadingRows().map(skeletonRow => (
                    <TableSkeletonLoadingRow key={skeletonRow.id} {...getSkeletonLoadingRowProps(skeletonRow)} />
                  ))}

                {table.getRowModel().rows.map(row => {
                  return (
                    <TableRow
                      key={row.id}
                      {...getTableRowProps(row)}
                      isHighlightable={false}
                      onContextMenu={() => setContextItem(row.original)}
                      className="!cursor-default"
                    >
                      {row.getCenterVisibleCells().map(cell => (
                        <TableCell key={cell.id} {...getTableCellProps(cell)} className="!bg-white" />
                      ))}
                    </TableRow>
                  );
                })}
              </tbody>
            </ContextMenu.Trigger>
          </table>

          <ContextMenu.Portal>
            <ContextMenu.Content className="z-20">
              <MemberPermissionsContextMenu
                workshopId={props.workshopId}
                user={contextItem}
                onSuccess={() => setContextItem(null)}
              />
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>

        <table className="sticky right-0">
          <thead className="sticky top-0 z-10">
            {table.getRightHeaderGroups().map(headerGroup => (
              <TableHeaderGroup key={headerGroup.id} {...getTableHeaderGroupProps(headerGroup)}>
                {headerGroup.headers.map(header => (
                  <TableHeader key={header.id} {...getTableHeaderProps(header)} />
                ))}
              </TableHeaderGroup>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} {...getTableRowProps(row)}>
                {row.getRightVisibleCells().map(cell => {
                  const { children, ...rest } = getTableCellProps(cell);

                  return (
                    <TableCell key={cell.id} {...rest} className="!p-0 !bg-white">
                      <Popover.Root>
                        {children}
                        <Popover.Portal>
                          <Popover.Content
                            hideWhenDetached
                            side="bottom"
                            align="end"
                            sideOffset={-8}
                            alignOffset={8}
                            className="w-full z-20"
                          >
                            <MemberPermissionsContextMenu
                              workshopId={props.workshopId}
                              user={contextItem}
                              onSuccess={() => setContextItem(null)}
                            />
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <OasisErrorState oasisError={workshopMembers.error} />;
}
