import { uniqBy } from 'lodash';
import { Fragment, ReactNode, useMemo } from 'react';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { DEFAULT_FIELDS_MAPPING, IssueField } from '~/shared/utils/const.issues';

interface Props {
  issueSubtypeId: string;
  renderField(field: IssueField): ReactNode;
}

export function IssueFieldMapper(props: Props) {
  const { projectId, platform } = useProjectContext();
  const issueSubtype = Queries.Issues.useFindIssueSubtypeById({
    projectId,
    platform,
    id: props.issueSubtypeId,
  });

  const fields = useMemo(() => {
    return issueSubtype.data?.fieldsMetadata && issueSubtype.data.fieldsMetadata.length
      ? uniqBy(issueSubtype.data.fieldsMetadata, 'id')
      : DEFAULT_FIELDS_MAPPING;
  }, [issueSubtype.data]);

  return fields.map(field => (
    // React types currently don't declare `key` but it is supported.
    // @ts-ignore
    <Fragment key={field.id}>{field.visible && props.renderField(field)}</Fragment>
  ));
}
