import { Platforms } from '@oasis/utils';
import { createContext, useContext } from 'react';

interface ProjectContextValue {
  hubId: string;
  projectId: string;
  platform: Platforms;
}

export const ProjectContext = createContext<ProjectContextValue>({
  hubId: '',
  projectId: '',
  platform: 'acc',
});

export function useProjectContext() {
  const ctx = useContext(ProjectContext);

  if (!ctx.hubId) {
    throw new Error('`hubId` must be set in ProjectContext.');
  }

  if (!ctx.projectId) {
    throw new Error('`projectId` must be set in ProjectContext.');
  }

  return useContext(ProjectContext);
}
