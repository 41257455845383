import clsx from 'clsx';

interface Props<T> {
  value: string;
  options: { label: string; value: T }[];
  disabled?: boolean;
}

export function PillBox<T extends string>(props: Props<T>) {
  const activeOption = props.options.find(option => option.value === props.value);
  const activeIndex = props.options.findIndex(option => option.value === props.value);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex items-center space-x-1">
        {props.options.map((option, index) => (
          <div key={option.value}>
            <div
              className={clsx(
                'relative rounded-full h-2 w-10',
                props.disabled ? 'bg-blue-100' : activeIndex >= index ? 'bg-blue-500' : 'bg-charcoal-100'
              )}
            />
          </div>
        ))}
      </div>

      <p
        className={clsx(
          'relative flex items-center text-center text-label-sm mt-1',
          props.disabled && 'text-charcoal-500'
        )}
      >
        {activeOption?.label}
      </p>
    </div>
  );
}
