import { PersonMinusIcon } from '@adsk/alloy-react-icon';
import { WorkshopPermissions } from '@oasis/sdk';
import { Mutations } from '~/shared/hooks/mutations';

interface Props {
  workshopId: string;
  user: {
    autodeskId: string;
    name: string | null;
    permission: WorkshopPermissions;
  } | null;
  onSuccess?: () => void;
}

export function MemberPermissionsContextMenu(props: Props) {
  const updatePermissions = Mutations.Workshops.useUpdateWorkshopPermissions();

  if (!props.user) {
    return null;
  }

  return (
    <aside className="bg-white shadow-high min-w-[220px] rounded-md overflow-hidden">
      <button
        className="w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50 text-red-600"
        onClick={() => {
          if (!props.user) {
            return null;
          }

          updatePermissions.mutate({
            workshopId: props.workshopId,
            data: {
              userIds: [props.user.autodeskId],
              permission: props.user.permission,
              action: 'revoke',
            },
          });

          props.onSuccess?.();
        }}
      >
        <PersonMinusIcon className="mr-2" />
        Remove user
      </button>
    </aside>
  );
}
