import { IllustrationType } from '@adsk/alloy-react-illustration';
import { OasisErrorCodes } from '~/shared/components/base/oasis-error-state';

interface Opts {
  code: OasisErrorCodes;
  illustration?: IllustrationType;
  title?: string;
  description?: string;
}

export class OasisError extends Error {
  code: OasisErrorCodes;
  illustration?: IllustrationType;
  title?: string;
  description?: string;

  constructor(opts: Opts, message: string) {
    super();
    this.code = opts.code;
    this.illustration = opts.illustration;
    this.title = opts.title;
    this.description = opts.description;
    this.message = message;
  }
}
