import { useState } from 'react';
import { Label } from '~/shared/components/forms/label';
import { TextareaInput } from '~/shared/components/forms/text-area-input';
import { TextInput } from '~/shared/components/forms/text-input';

interface Props {
  type?: string;
  name: string;
  label: string;
  defaultValue?: string | null;
  onChange(value: string): void;
  textarea?: boolean;
}

export function CreateIssueTextField(props: Props) {
  const [value, setValue] = useState(props.defaultValue || '');

  return (
    <div>
      <Label className="mb-2">{props.label}</Label>

      <div className="relative">
        {props.textarea ? (
          <TextareaInput
            value={value}
            placeholder="Unspecified"
            onTextChange={setValue}
            className="pr-16 text-left min-h-[6rem]"
          />
        ) : (
          <TextInput
            type={props.type}
            value={value}
            placeholder="Unspecified"
            onTextChange={setValue}
            className="pr-16"
          />
        )}
      </div>
    </div>
  );
}
