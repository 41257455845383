import { FeatureFlagNames, Oasis } from '@oasis/sdk';
import { useEffect, useRef, useState } from 'react';

export function useFeatureFlags(nameValue: FeatureFlagNames): boolean;
export function useFeatureFlags(nameValue: FeatureFlagNames[]): boolean[];
export function useFeatureFlags(nameValue: FeatureFlagNames | FeatureFlagNames[]) {
  const $store = Oasis.FeatureFlags.useStore();
  const name = useRef(nameValue).current;
  const [value, setValue] = useState<boolean | boolean[]>(_getValue(name));

  const staticStore = JSON.stringify($store.flags);

  useEffect(() => {
    const value = _getValue(name);
    setValue(value);
  }, [name, staticStore]);

  return value;
}

function _getValue(flags: FeatureFlagNames | FeatureFlagNames[]) {
  if (typeof flags === 'string') {
    return Oasis.FeatureFlags.getFlag<boolean>(flags);
  } else {
    return flags.map(n => Oasis.FeatureFlags.getFlag<boolean>(n));
  }
}
