import { useMemo } from 'react';
import { Combobox } from '~/shared/components/forms/combobox';
import { Label } from '~/shared/components/forms/label';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  value: string | number | null;
  options: { id: string; value: string }[];
  name: string;
  label: string;
  onChange(value: string | number | null): void;
}

export function CreateIssueListField(props: Props) {
  const combobox = Combobox.useComboboxProps();

  const displayValue = useMemo(() => {
    return props.options.find(option => option.id === props.value)?.value;
  }, [props.value, props.options]);

  return (
    <div>
      <Label className="mb-2">{props.label}</Label>

      <Combobox
        {...combobox}
        name={props.name}
        value={props.value ? String(props.value) : ''}
        displayValue={displayValue}
      >
        <Combobox.TriggerInput />

        <Combobox.Content onClick={e => e.stopPropagation()}>
          <Combobox.List>
            {props.options.map(option => {
              return (
                <Combobox.CheckboxItem key={option.id} value={option.id} onClick={() => props.onChange(option.id)}>
                  {option.value}
                </Combobox.CheckboxItem>
              );
            })}
          </Combobox.List>
        </Combobox.Content>
      </Combobox>
    </div>
  );
}
