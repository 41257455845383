import { Oasis } from '@oasis/sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationManager } from '~/shared/components/base/notification-manager';

export const projectsMutations = {
  useGrantAdminAccess() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Projects.grantAdminAccess>[0]) {
        const res = await Oasis.Projects.grantAdminAccess(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to grant admin access. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to grant admin access.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['projects', params.projectId, 'admins'],
        });
      },
    });
  },

  useRevokeAdminAccess() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Projects.revokeAdminAccess>[0]) {
        const res = await Oasis.Projects.revokeAdminAccess(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to revoke admin access. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to revoke admin access.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['projects', params.projectId, 'admins'],
        });
      },
      onError() {
        NotificationManager.push({
          status: 'error',
          content: 'Failed to revoke admin access.',
        });
      },
    });
  },
};
