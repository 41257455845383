import { ReactNode } from 'react';
import { fluidErrorVRUrl } from '~/shared/utils/const.asset-urls';

export function FluidErrorState(): ReactNode {
  return (
    <div id="oasis-fluid-error" className="flex flex-col items-center justify-center">
      <div className="flex justify-center w-60 h-60">
        <img src={fluidErrorVRUrl} alt="Fluid error illustration" />
      </div>

      <h1 className="text-heading-2 mb-5 text-center pt-6">An error has occurred</h1>

      <p className="text-body-md text-center">
        We&apos;re currently experiencing technical difficulties. <br />
        Please try again later.
      </p>
    </div>
  );
}
