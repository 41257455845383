import clsx from 'clsx';
import { ComponentProps, useState } from 'react';
import Dropzone, { Accept } from 'react-dropzone';

interface Props extends ComponentProps<'div'> {
  onFileDrop(files: File[]): void;
  inactiveClassname?: string;
  activeClassname?: string;
  maxFiles?: number;
  noClick?: boolean;
  accept?: Accept;
  disabled?: boolean;
}

export function UploadDropWrapper({
  onFileDrop,
  inactiveClassname = 'border-transparent',
  activeClassname = 'border-blue-500 bg-blue-100 bg-opacity-10',
  disabled,
  maxFiles,
  noClick = true,
  accept,
  children,
  ...props
}: Props) {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Dropzone
      disabled={disabled}
      noClick={noClick}
      maxFiles={maxFiles}
      accept={accept}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDrop={acceptedFiles => {
        setIsDragging(false);
        onFileDrop(acceptedFiles);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...props}
          {...getRootProps()}
          className={clsx(
            'border border-dashed rounded-sm',
            isDragging ? activeClassname : inactiveClassname,
            props.className
          )}
          tabIndex={-1}
        >
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
}
