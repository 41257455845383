import { CloudUpArrowIcon, FolderArrowRightIcon, PencilIcon, PlusIcon, TrashCanIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import { UseQueryResult } from '@tanstack/react-query';
import { UploadManager } from '~/features/files/components/upload-manager';
import { Confirmation } from '~/shared/components/base/confirmation';
import { Popover } from '~/shared/components/base/popover';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';

interface Props {
  projectId: string;
  folderId: string;
  setAddSubFolder: (addSubFolder: boolean) => void;
  setIsEditing: (isEditing: boolean) => void;
  setMoveToFolder: (moveToFolder: boolean) => void;
  target: UseQueryResult<
    {
      id: string;
      name: string;
      hasChildren: boolean;
      path: string;
      children: {
        id: string;
        name: string;
        hasChildren: boolean;
      }[];
      permissionActions: string[];
    },
    unknown
  >;
}

export function FolderTreeContextMenu({
  projectId,
  folderId,
  setAddSubFolder,
  setIsEditing,
  setMoveToFolder,
  target,
}: Props) {
  const $env = Oasis.Env.useStore();
  const $uploads = UploadManager.useStore();
  const deleteFolders = Mutations.Files.useDeleteFolders();
  const topFolders = Queries.Projects.useListTopFolders({ projectId });
  const isRootFolder = topFolders.data?.rootFolderUrn === folderId;

  if (!target) {
    return null;
  }

  const canUpload = target?.data?.permissionActions.includes('publish');

  async function handleDeleteFolder() {
    Confirmation.show({
      title: 'Delete Folder?',
      description:
        'The selected item will be deleted from the project. You can restore this file through ACC Docs by following the link to Deleted items.',
      confirmLabel: 'Delete',
      confirm() {
        deleteFolders.mutate({
          projectId,
          folderIds: [folderId],
        });
      },
    });
  }

  function handleMoveFolder() {
    Confirmation.show({
      title: 'Move Folder?',
      description:
        "The folder will inherit permissions and subscribers from the destination folder. Subscribers to the current folder aren't retained.",
      confirmLabel: 'Continue',
      isNonDestructive: true,
      confirm() {
        setMoveToFolder(true);
      },
    });
  }

  return (
    <aside className="bg-white shadow-high min-w-[220px] rounded-md overflow-hidden">
      <>
        {[
          {
            label: 'Add subfolder',
            icon: <PlusIcon />,
            onClick: () => setAddSubFolder(true),
          },
          {
            label: 'Rename',
            icon: <PencilIcon />,
            onClick: () => {
              setIsEditing(true);
            },
          },
          {
            label: 'Move',
            icon: <FolderArrowRightIcon />,
            onClick: handleMoveFolder,
          },
          {
            label: 'Delete',
            icon: <TrashCanIcon />,
            onClick: handleDeleteFolder,
            isLoading: deleteFolders.isPending,
          },
        ]
          .filter(buttonObject => {
            return isRootFolder
              ? !(buttonObject.label === 'Move' || buttonObject.label === 'Rename' || buttonObject.label === 'Delete')
              : true;
          })
          .map(button => (
            <Popover.Button
              key={button.label}
              icon={button.icon}
              onClick={button.onClick}
              className="w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50"
              isLoading={button.isLoading || false}
            >
              {button.label}
            </Popover.Button>
          ))}

        {!$env.isVr && canUpload && (
          <>
            <hr className="border-t border-charcoal-100 my-1" />
            <button
              key="Upload files"
              onClick={() => {
                $uploads.state = 'MODAL';
              }}
              className="w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50"
            >
              <CloudUpArrowIcon className="mr-2" /> Upload files
            </button>
          </>
        )}
      </>
    </aside>
  );
}
