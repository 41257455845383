import { Viewer } from '../globals';
import { Store } from './Store';

// Can't resolve the correct types "show" | "hide" | "isolate" from Global events variable
type VisibilityChangedType = string;

/*
  A helper class to manage share and react to the visibility changes of the model nodes in the viewer.
*/
export class VisibilityManager {
  oldIsolateNone: any;
  private isAttached = false;
  constructor(
    private readonly viewer: Viewer,
    private readonly store: Store,
    private readonly useHierarchicalVisibilityOnly: boolean
  ) {
    this.onIsolatedChanged = this.onIsolatedChanged.bind(this);
    this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
    this.onShowAll = this.onShowAll.bind(this);
  }

  private attach() {
    if (this.isAttached) {
      return;
    }
    this.viewer.impl.api.addEventListener(Autodesk.Viewing.SHOW_EVENT, this.onVisibilityChanged);
    this.viewer.impl.api.addEventListener(Autodesk.Viewing.HIDE_EVENT, this.onVisibilityChanged);
    // this.viewer.impl.api.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onIsolatedChanged);
    this.viewer.impl.api.addEventListener(Autodesk.Viewing.SHOW_ALL_EVENT, this.onShowAll);

    this.isAttached = true;
  }

  private detach() {
    if (!this.isAttached) {
      return;
    }
    this.viewer.impl.api.removeEventListener(Autodesk.Viewing.SHOW_EVENT, this.onVisibilityChanged);
    this.viewer.impl.api.removeEventListener(Autodesk.Viewing.HIDE_EVENT, this.onVisibilityChanged);
    // this.viewer.impl.api.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onIsolatedChanged);
    this.viewer.impl.api.removeEventListener(Autodesk.Viewing.SHOW_ALL_EVENT, this.onShowAll);

    this.isAttached = false;
  }

  private onIsolatedChanged({ nodeIdArray }: { nodeIdArray: number[] }): void {
    this.detach();
    nodeIdArray.forEach(node => {
      this.store.isolate(node);
    });
    this.attach();
  }

  private onShowAll(): void {
    this.detach();
    this.store.reset();
    this.attach();
  }

  private onVisibilityChanged({ type, nodeIdArray }: { type: VisibilityChangedType; nodeIdArray: number[] }): void {
    this.detach();
    const visibility = type === Autodesk.Viewing.SHOW_EVENT;
    const nodesToUpdate: Record<string, boolean> = {};
    const instanceTree = this.viewer.model.getInstanceTree();
    const traverseRecursively = true;

    this.store.pushNotificationDelayScope();

    nodeIdArray.forEach(node => {
      this.store.setNodeVisibility(node, visibility);
      if (!this.useHierarchicalVisibilityOnly) {
        function callback(dbid: number) {
          nodesToUpdate[dbid] = !visibility;
        }

        instanceTree.enumNodeChildren(node, callback, traverseRecursively);
        this.store.setNodesVisibility(nodesToUpdate);
      }
    });
    this.store.commit();

    this.store.popNotificationDelayScope();

    this.attach();
  }

  public initialize(): void {
    const { viewer, store } = this;
    store.defineAndActivateVisibilityManagerBinding(
      (node: number, isHidden = false) => {
        const localIsHidden = !viewer.model.visibilityManager.isNodeVisible(node);
        // Toggle visibility dispatch an event which re-render the UI correctly, so we toggle the visibility only when it should be toggled.
        if (localIsHidden !== isHidden) {
          this.detach();
          viewer.model.visibilityManager.toggleVisibility(node);
          this.attach();
        }
      },
      () => {
        this.detach();
        if ((viewer as any).impl?.layers !== undefined) {
          console.info('No active workshop: Showing all layers');
          viewer.showAll();
        } else {
          console.warn('Viewer object already deiniitialized.');
        }
        this.attach();
      }
    );

    this.attach();
  }

  public destroy(): void {
    this.detach();
  }
}
