import { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

export function HeadsetPlusIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 11.098C0 8.284 2.355 6 5.26 6h11.48C19.644 6 22 8.283 22 11.098v3.94c0 2.304-1.927 4.172-4.304 4.172h-1.769a4.842 4.842 0 0 1-2.743-.848 3.389 3.389 0 0 0-3.824 0 4.842 4.842 0 0 1-2.743.848H4.304C1.927 19.21 0 17.342 0 15.038v-3.94ZM5.26 7.39c-2.112 0-3.825 1.66-3.825 3.708v3.94c0 1.536 1.285 2.781 2.87 2.781h2.312c.685 0 1.353-.206 1.912-.591a4.861 4.861 0 0 1 5.486 0 3.375 3.375 0 0 0 1.912.591h1.769c1.584 0 2.87-1.245 2.87-2.78v-3.94c0-2.048-1.714-3.709-3.827-3.709H5.261Z"
          clipRule="evenodd"
        />
        <circle cx={18} cy={8} r={6.5} fill="#fff" stroke="#fff" />
        <path
          fill="currentColor"
          d="M24 8a6 6 0 1 1-6-6 6.006 6.006 0 0 1 6 6Zm-2.923-.615h-2.462V4.923a.615.615 0 1 0-1.23 0v2.462h-2.462a.616.616 0 0 0 0 1.23h2.462v2.462a.616.616 0 0 0 1.23 0V8.615h2.462a.616.616 0 1 0 0-1.23Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
