import { Err, Ok } from '../../lib/result';
import { Oasis } from '../../oasis';
import { Device } from '../../types';
import { TokenManager } from '../token-manager/token-manager.service';

export const NetworkCommands = {
  /**
   * @name openWorkshopOnDevice
   * Notifies a user's app (running on vr or desktop) to open a workshop.
   */
  async openWorkshopOnDevice(params: { device: Device; id: string }) {
    Oasis.Logger.info({ params, msg: '[NetworkCommands.openWorkshopOnDevice] Sending command...' });

    // Don't allow opening a workshop if they arent logged in.
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.error(
        '[NetworkCommands.openWorkshopOnDevice] Attempted to open a workshop without being logged in.'
      );
      return Err({ code: 'UNAUTHORIZED' });
    }

    const workshop = await Oasis.Workshops.findWorkshopById(params.id);

    if (!workshop.ok) {
      Oasis.Logger.error('[NetworkCommands.openWorkshopOnDevice] Workshop not found.');
      return Err({ code: 'WORKSHOP_NOT_FOUND' });
    }

    // Emit the mqtt command so the device can react to it when it opens.
    if (Oasis.Session.store.user?.id) {
      await Oasis.MessagesProvider.publish({
        topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${params.device}`,
        payload: [
          {
            type: 'OpenWorkshop',
            args: {
              id: params.id,
              name: workshop.value.name || '',
              platform: Oasis.Projects.store.metaCache.get(workshop.value.projectId)?.platform,
              consoleContext: Oasis.Env.store.context,
            },
          },
        ],
        opts: {
          retained: true,
        },
      });
    }

    // If we're opening on desktop from the desktop app, and the unreal viewer isnt
    // already running, just launch the unreal viewer.
    if (
      Oasis.Bridge &&
      Oasis.Bridge.store.unrealProcessStatus !== 'RUNNING' &&
      Oasis.Env.store.isDesktop &&
      params.device === 'desktop'
    ) {
      // fork token - this is required since viewer is an application with its own lifecycle and will try to refresh token when it wants.
      const forkTokenResult = await TokenManager.forkForgeXrTokens();

      if (!forkTokenResult.ok) {
        Oasis.Logger.error('[NetworkCommands.openWorkshopOnDevice] Failed to fork session.');
        return Err({ code: 'FORK_FAILURE' });
      }

      const unixTime: number = Math.floor(Date.now() / 1000);
      const writeTokenResult = await Oasis.Bridge.writeTokenForUnreal({
        oxygenToken: forkTokenResult.value.oxygenToken,
        forgeXrToken: forkTokenResult.value.forgeXrToken,
        forgeXrRefreshToken: forkTokenResult.value.forgeXrRefreshToken,
        refreshDate: unixTime.toString(),
      });
      if (!writeTokenResult.ok) {
        Oasis.Logger.error('[NetworkCommands.openWorkshopOnDevice] Failed to write session to game.ini file.');
        return Err({ code: 'TOKEN_WRITE_FAILURE' });
      }

      const launchViewerResult = await Oasis.Bridge.launchUnreal();
      if (!launchViewerResult.ok) {
        Oasis.Logger.error('[NetworkCommands.openWorkshopOnDevice] Failed to invoke `launch_unreal` command session.');
        return Err({ code: 'VIEWER_LAUNCH_ERROR' });
      }
    }

    Oasis.Segment.track('Workshop Open on Device Command Sent', params);

    return Ok(true);
  },

  /**
   * @name shareToWorkshop
   * Tells the user's VR app to open a version of a file in the workshop.
   * VR handles forwarding this change to other users in the workshop.
   */
  async shareToWorkshop(params: { fileVersionUrn: string; viewGuid?: string; workshopId: string; projectId: string }) {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.error('[NetworkCommands.shareToWorkshop] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    const workshop = await Oasis.Workshops.findWorkshopById(params.workshopId);

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${Oasis.Env.store.vrTargetDevice}`,
      payload: [
        {
          type: 'PushFileToStage',
          args: {
            id: params.fileVersionUrn,
            urn: params.fileVersionUrn,
            viewId: params.viewGuid,
            projectId: params.projectId,
            platform: Oasis.Projects.store.metaCache.get(params.projectId)?.platform,
            workshopId: params.workshopId,
            workshopName: workshop.ok ? workshop.value.name : 'Workshop',
          },
        },
      ],
      opts: {
        retained: true,
        expirationSeconds: 15,
      },
    });

    Oasis.Segment.track('File Shared to Stage Command Sent', params);

    return Ok(true);
  },

  /**
   * @name createIssueOnDevice
   * Notifies a user's app (running on vr or desktop) to start create issue flow.
   */
  async createIssueOnDevice(projectId: string) {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.error('[NetworkCommands.createIssueOnDevice] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${Oasis.Env.store.vrTargetDevice}`,
      payload: [
        {
          type: 'InitIssueCreation',
          args: {
            projectId,
            platform: Oasis.Projects.store.metaCache.get(projectId)?.platform,
          },
        },
      ],
      opts: {
        retained: true,
        expirationSeconds: 30,
      },
    });

    Oasis.Segment.track('Create Issue on Device Command Sent', { projectId });

    return Ok(true);
  },

  /**
   * @name openIssueOnDevice
   * Notifies a user's app (running on vr or desktop) to open an issue.
   */
  async openIssueOnDevice(params: { device: Device; projectId: string; issueId: string }) {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.error('[NetworkCommands.openIssueOnDevice] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${params.device}`,
      payload: [
        {
          type: 'OpenIssue',
          args: {
            projectId: params.projectId,
            platform: Oasis.Projects.store.metaCache.get(params.projectId)?.platform,
            id: params.issueId,
          },
        },
      ],
      opts: {
        retained: true,
        expirationSeconds: 30,
      },
    });

    Oasis.Segment.track('Issue Open on Device Command Sent', params);

    return Ok(true);
  },

  /**
   * @name placeIssueDot
   * Tells a users VR app that we want to place an issue dot.
   */
  async placeIssueDot(params: { projectId: string; issueId: string }) {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.error('[NetworkCommands.placeIssueDot] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${Oasis.Env.store.vrTargetDevice}`,
      payload: [
        {
          type: 'PlaceIssueDot',
          args: {
            projectId: params.projectId,
            platform: Oasis.Projects.store.metaCache.get(params.projectId)?.platform,
            id: params.issueId,
          },
        },
      ],
      opts: {
        retained: true,
        expirationSeconds: 30,
      },
    });

    Oasis.Segment.track('Issue Dot Placement Command Sent', params);

    return Ok(true);
  },

  /**
   * @name emitWorkshopIssueEvent
   * Tells workshop users something happened with an issue.
   */
  async emitWorkshopIssueEvent(params: { event: 'add' | 'update' | 'del'; projectId: string; issueId: string }) {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.error('[NetworkCommands.emitWorkshopIssueEvent] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    const workshops = await Oasis.Users.listActiveWorkshops(params.projectId);

    if (workshops.ok) {
      for (const workshop of workshops.value.results) {
        await Oasis.MessagesProvider.publish({
          topic: `wsq/v1/ws1/${workshop.id}/${params.event}i`,
          payload: {
            projectId: params.projectId,
            platform: Oasis.Projects.store.metaCache.get(params.projectId)?.platform,
            id: params.issueId,
          },
        });
      }
    }

    Oasis.Segment.track('Issue Event Sent', params);

    return Ok(true);
  },

  /**
   * @name emitSignedOut
   * Let VR know that the user has logged out in the console and they should re-pair.
   */
  async emitSignedOut() {
    const userId = Oasis.Session.store.user?.id || Oasis.Storage.get('currentUserId');

    if (!userId) {
      Oasis.Logger.debug('[NetworkCommands.emitSignedOut] No `userId` available to send command.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${userId}/${Oasis.Env.store.vrTargetDevice}`,
      payload: [{ type: 'SignedOut' }],
    });

    Oasis.Segment.track('Sign Out Command Sent');

    return Ok(true);
  },

  /**
   * @name emitNavigationProcessed
   * Let VR know that the Navigate command has been processed by the web so they can hide the loading screen.
   */
  async emitNavigationProcessed(params: { path: string }) {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.debug('[NetworkCommands.emitNavigationProcessed] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${Oasis.Env.store.vrTargetDevice}`,
      payload: [
        {
          type: 'NavigationProcessed',
          args: {
            path: params.path,
          },
        },
      ],
    });

    Oasis.Segment.track('Navigation Processed Command Sent', params);

    return Ok(true);
  },

  /**
   * @name emitResetModelTransform
   * Let VR know that the user has initiated Reset Model Transform command from web.
   */
  async emitResetModelTransform() {
    if (!Oasis.Session.store.user?.id) {
      Oasis.Logger.debug('[NetworkCommands.emitResetModelTransform] Unauthorized.');
      return Err({ code: 'UNAUTHORIZED' });
    }

    await Oasis.MessagesProvider.publish({
      topic: `wsq/v1/cmd/${Oasis.Session.store.user?.id}/${Oasis.Env.store.vrTargetDevice}`,
      payload: [{ type: 'ResetModelTransform' }],
    });

    Oasis.Segment.track('Reset Model Transform Command Sent');

    return Ok(true);
  },
};
