import { Oasis } from '@oasis/sdk';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '~/shared/components/base/button';
import { SearchInput } from '~/shared/components/forms/search-input';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';
import { Queries } from '~/shared/hooks/queries';
import { ProjectsTable } from '../../components/projects-table';

export default function ProjectsIndexPage() {
  const $env = Oasis.Env.useStore();
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hadOpenModal = useRef(searchParams.has('dialog'));

  const projects = Queries.Projects.useListProjects({
    opts: { networkMode: 'offlineFirst' },
  });

  useEffect(() => {
    const currentProjectId = Oasis.Projects.retrieveCurrentProjectId();

    // We don't redirect if there is a modal open (probably onboarding)
    if (hadOpenModal.current) {
      return;
    }

    // If we have a `currentProjectId` we redirect to it
    if (currentProjectId) {
      navigate(`/projects/${currentProjectId}`, { replace: true });
      return;
    }

    // If there is only a single project in the list, we redirect to it
    if (projects.data && projects.data.results.length === 1) {
      const projectId = projects.data.results[0]?.id;

      if (projectId) {
        navigate(`/projects/${projectId}`, { replace: true });
      }
    }
  }, [projects.data, navigate]);

  return (
    <section className="flex flex-1 overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden px-5">
        <div className="pt-12 pb-10 short:py-6 flex items-center border-b border-charcoal-200">
          <h1 className="text-title">Projects</h1>

          {!$env.isVr && (
            <p className="ml-auto">
              <Button variant="link" asChild>
                <a
                  href="https://acc.autodesk.com/projects"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center"
                >
                  ACC Projects
                  <ExternalLinkIcon className="ml-2" />
                </a>
              </Button>
            </p>
          )}
        </div>

        {projects.data && projects.data.results.length > 0 && (
          <div className="flex my-4">
            <div className="ml-auto w-full max-w-xs">
              <SearchInput className="ml-auto" value={query} onTextChange={setQuery} />
            </div>
          </div>
        )}

        <div className="w-full h-full flex-1 overflow-hidden">
          <ProjectsTable query={query} />
        </div>
      </div>
    </section>
  );
}
