import { ReactNode, useMemo } from 'react';
import { Combobox } from '~/shared/components/forms/combobox';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  value?: string | null;
  onChange(value: string): void;
  required?: boolean;
}

export function CreateIssueRootCauseField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const combobox = Combobox.useComboboxProps();

  const rootCauseCategoryQuery = Queries.Issues.useListRootCauseCategories({
    projectId,
    platform,
  });

  const label = useMemo(() => {
    let active: ReactNode = null;

    if (rootCauseCategoryQuery.data?.results) {
      for (const rootCauseCategory of rootCauseCategoryQuery.data.results) {
        const rootCause = rootCauseCategory.rootCauses.find(rootCause => {
          return rootCause.id === props.value && rootCause.isActive && rootCauseCategory.isActive;
        });

        if (rootCause) {
          active = (
            <>
              {rootCauseCategory.title} <span className="mx-1">&gt;</span> {rootCause.title}
            </>
          );
        }
      }
    }

    if (!active) {
      active = <p className="text-charcoal-500">Unspecified</p>;
    }

    return active;
  }, [rootCauseCategoryQuery.data, props.value]);

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        Root Cause
      </Label>

      <Combobox {...combobox} name="root-cause" value={props.value ?? ''} onChange={id => props.onChange(id)}>
        <Combobox.TriggerButton>{label}</Combobox.TriggerButton>
        <Combobox.Content>
          {rootCauseCategoryQuery.data?.results.map(rootCauseCategory => {
            if (!rootCauseCategory.isActive) {
              return null;
            }

            return (
              <Combobox.Section key={rootCauseCategory.id} title={rootCauseCategory.title}>
                {rootCauseCategory.rootCauses.map(rootCause => {
                  if (combobox.filterItem(`${rootCauseCategory.title} ${rootCause.title}`)) {
                    return null;
                  }

                  return (
                    <Combobox.Item key={rootCause.id} value={rootCause.id}>
                      {rootCause.title}
                    </Combobox.Item>
                  );
                })}
              </Combobox.Section>
            );
          })}
        </Combobox.Content>
      </Combobox>
    </div>
  );
}
