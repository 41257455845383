import { Oasis } from '@oasis/sdk';
import { useMutation } from '@tanstack/react-query';
import { NotificationManager } from '~/shared/components/base/notification-manager';

export const authMutations = {
  useUpdateUserProfile() {
    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Users.updateUserProfile>[0]) {
        const res = await Oasis.Users.updateUserProfile(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to update your profile. Please try again or contact support',
              errorMessages: {
                BAD_REQUEST: 'Bad request. Please check your inputs and try again.',
                FORBIDDEN: "You don't have permission to update this user's profile.",
              },
            },
          });
        }

        return res.value;
      },
    });
  },

  useCreateTrial() {
    return useMutation({
      async mutationFn() {
        const res = await Oasis.Users.createTrial();

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'We were unable to start your trial. Please try again or contact support.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to create a trial.",
                UNAUTHORIZED: 'You must be logged in to create a trial.',
              },
            },
          });
        }

        if (!res.value.eligible) {
          return NotificationManager.push({
            status: 'error',
            content: res.value.reason,
          });
        }

        return res.value;
      },
    });
  },
};
