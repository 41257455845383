import { useMemo } from 'react';

export function useLookupTable<T extends object[], R>(params: {
  data?: T;
  getId: (item: T[number]) => string | null;
  getValue: (item: T[number]) => R;
}) {
  return useMemo(() => {
    const lookup: Map<string, R> = new Map();

    if (params.data && params.data.length) {
      for (const item of params.data) {
        const id = params.getId(item);
        const value = params.getValue(item);

        if (id && value) {
          lookup.set(id, value);
        }
      }
    }

    return lookup;
  }, [params]);
}
