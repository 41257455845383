import Dropdown from '@adsk/alloy-react-dropdown/es/Dropdown';
import { DownloadIcon } from '@adsk/alloy-react-icon';
import { Oasis, type ReleaseApp, type ReleasePlatform } from '@oasis/sdk';
import { useEffect, useState } from 'react';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { TextInput } from '~/shared/components/forms/text-input';
import { useIsSuperUser } from '~/shared/hooks/use-super-user';

const RELEASE_CHANNELS = [
  { value: 'develop', label: 'develop' },
  { value: 'alpha', label: 'alpha' },
  { value: 'beta', label: 'beta' },
  { value: 'main', label: 'main' },
];

const DOWNLOAD_TARGETS = [
  { title: 'Windows Viewer', app: 'WorkshopXR_Viewer', platform: 'windows-x86_64' },
  { title: 'Android Viewer', app: 'WorkshopXR_Viewer', platform: 'android' },
  { title: 'Android Symbols', app: 'WorkshopXR_Viewer_Symbols', platform: 'android' },
  { title: 'Unreal Build', app: 'WorkshopXR_Unreal', platform: 'windows-x86_64' },
  { title: 'Unreal Build Symbols', app: 'WorkshopXR_Unreal_Symbols', platform: 'windows-x86_64' },
  { title: 'Tauri App', app: 'WorkshopXR_Launcher', platform: 'windows-x86_64' },
] as const;

export default function DownloadPage() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const isSuperUser = useIsSuperUser(true);

  const [allowed, setAllowed] = useState(false);
  const [activeTarget, setActiveTarget] = useState<(typeof DOWNLOAD_TARGETS)[number]>(DOWNLOAD_TARGETS[0]);
  const [download, setDownload] = useState<{ app: string; channel: string; version: string; filename: string } | null>(
    null
  );
  const [channel, setChannel] = useState('');
  const [customChannel, setCustomChannel] = useState('');
  const [releaseVersion, setReleaseVersion] = useState('');

  useEffect(() => {
    if ($env.releaseChannel !== 'develop') {
      return;
    }

    if ($session.status === 'AUTHENTICATED' && isSuperUser) {
      setAllowed(true);
    }
  }, [$env.releaseChannel, $session.status, isSuperUser]);

  if (!allowed) {
    return <OasisErrorState code="NOT_FOUND" />;
  }

  async function downloadRelease(channel: string, app: ReleaseApp, platform: ReleasePlatform, releaseVersion?: string) {
    if (!channel) {
      return;
    }

    const release = await Oasis.Releases.getReleaseMeta({
      channel,
      app,
      platform,
      version: releaseVersion || 'latest',
    });

    if (!release.ok || typeof release.value === 'string') {
      const jenkinsJobsBaseUrl = 'https://master-1.jenkins.autodesk.com/job/xr-core-team/job/oasis-client/job/';
      // Jenkins double-encodes the branch name
      const encodedChannel = encodeURIComponent(encodeURIComponent(channel));
      const jenkinsJobUrl = `${jenkinsJobsBaseUrl}/${encodedChannel}/`;

      // Display the error message to the user
      return NotificationManager.push({
        status: 'error',
        content: (
          <>
            Something went wrong. Make sure there are no errors in the{' '}
            <a href={jenkinsJobUrl} target="_blank" rel="noreferrer" className="underline">
              Jenkins job
            </a>
            .
          </>
        ),
      });
    }

    window.open(release.value.url, '_blank');

    setDownload({
      app: app === 'WorkshopXR_Launcher' ? 'Tauri App' : app,
      channel,
      version: release.value.version,
      filename: release.value.url.split('/').pop()?.split('?').shift() ?? 'Unknown',
    });
  }

  return (
    <>
      <div className="flex w-full max-w-lg m-auto">
        <ul className="w-1/2 space-y-4">
          {DOWNLOAD_TARGETS.map(target => (
            <li key={target.title}>
              <label htmlFor={target.title} className="flex items-center">
                <input
                  id={target.title}
                  type="radio"
                  onChange={() => setActiveTarget(target)}
                  checked={target.title === activeTarget.title}
                />
                <span className="ml-2">{target.title}</span>
              </label>
            </li>
          ))}
        </ul>

        {activeTarget.app.includes('WorkshopXR_Unreal') ? (
          <ul className="w-1/2">
            <li className="my-2">
              <div className="py-2 flex items-center">
                <button
                  className="text-blue-700 hover:underline flex items-center"
                  onClick={() => downloadRelease('develop', activeTarget.app, activeTarget.platform)}
                >
                  <DownloadIcon className="mr-2" /> Download
                </button>
              </div>
            </li>

            <li className="my-2">
              <div className="py-2 flex items-center">
                <button
                  className="text-blue-500"
                  onClick={() => downloadRelease('develop', activeTarget.app, activeTarget.platform, releaseVersion)}
                >
                  <DownloadIcon className="mr-2" />
                </button>
                <TextInput
                  placeholder="Release Version"
                  type="text"
                  value={releaseVersion}
                  onTextChange={value => setReleaseVersion(value)}
                />
              </div>
            </li>
          </ul>
        ) : (
          <ul className="w-1/2">
            {/*== <dt className="font-bold border-b border-charcoal-100 w-full pb-3">Download {props.title}</dt> */}
            <li className="my-2">
              <div className="py-2 flex items-center">
                <button
                  className="py-2 text-blue-700 hover:underline flex items-center"
                  onClick={() => downloadRelease(channel, activeTarget.app, activeTarget.platform)}
                >
                  <DownloadIcon className="mr-2" />
                </button>
                <Dropdown
                  items={RELEASE_CHANNELS}
                  placeholder="Release Channel"
                  style={{ width: '100%' }}
                  onSelectedItemChange={event => {
                    const releaseChannel = RELEASE_CHANNELS.find(item => item.value === event.selectedItem?.value);
                    setChannel(releaseChannel?.value || 'develop');
                  }}
                />
              </div>
            </li>

            <li className="my-2">
              <div className="py-2 flex items-center">
                <button
                  className="text-blue-500"
                  onClick={() => downloadRelease(customChannel, activeTarget.app, activeTarget.platform)}
                >
                  <DownloadIcon className="mr-2" />
                </button>
                <TextInput
                  placeholder="Branch Name"
                  type="text"
                  value={customChannel}
                  onTextChange={value => setCustomChannel(value)}
                />
              </div>
            </li>

            <li className="my-2">
              <div className="py-2 flex items-center">
                <button
                  className="text-blue-500"
                  onClick={() => downloadRelease(channel, activeTarget.app, activeTarget.platform, releaseVersion)}
                >
                  <DownloadIcon className="mr-2" />
                </button>
                <TextInput
                  placeholder="Release Version"
                  type="text"
                  value={releaseVersion}
                  onTextChange={value => setReleaseVersion(value)}
                />
              </div>
            </li>
          </ul>
        )}
      </div>

      {download && (
        <div className="p-4 rounded shadow-high absolute top-10 right-10 text-body-sm space-y-1">
          <p className="font-bold">Downloaded:</p>
          <table>
            <tbody>
              <tr>
                <td>App:</td>
                <td className="pl-2">{download.app}</td>
              </tr>
              <tr>
                <td>Channel:</td>
                <td className="pl-2">{download.channel}</td>
              </tr>
              <tr>
                <td>Version:</td>
                <td className="pl-2">{download.version}</td>
              </tr>
              <tr>
                <td>Filename:</td>
                <td className="pl-2">{download.filename}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
