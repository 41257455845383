import type { ComponentProps } from 'react';

export function LargeFolderIcon(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 114" fill="none" {...props}>
      <path
        fill="#E8E8E8"
        d="M11.676 90.845v-67.23a3.022 3.022 0 0 1 3.022-3.021H37.18a1.51 1.51 0 0 1 1.352.835l2.941 5.883c.256.512.78.835 1.352.835h56.476a3.022 3.022 0 0 1 3.022 3.022v59.676a3.022 3.022 0 0 1-3.022 3.022H14.698a3.022 3.022 0 0 1-3.022-3.022Z"
      />
      <path
        fill="#DCDCDC"
        d="M102.324 90.845V38.723a3.022 3.022 0 0 0-3.022-3.022H14.698a3.022 3.022 0 0 0-3.022 3.022v52.122a3.022 3.022 0 0 0 3.022 3.022h84.604a3.022 3.022 0 0 0 3.022-3.022Z"
      />
    </svg>
  );
}
