import { MagnifyingGlassIcon, XCircleFilledIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { useEffect, useId, useRef } from 'react';
import { TextInput, TextInputProps } from '../text-input';

interface Props extends TextInputProps {
  focused?: boolean;
}

export function SearchInput({ ref: _, ...props }: Props) {
  const id = useId();
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.focused && input.current) {
      input.current.focus();
    }
  }, [props.focused]);

  function clear() {
    if (input.current) {
      input.current.value = '';
      input.current.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
      input.current.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));
    }
  }

  return (
    <div className="relative">
      <label
        htmlFor={id}
        className="absolute top-0 bottom-0 left-0 flex w-10 text-charcoal-500 hover:text-blue-500 cursor-pointer"
      >
        <MagnifyingGlassIcon className="m-auto w-5 h-5" />
      </label>

      <TextInput ref={input} id={id} placeholder="Search..." {...props} className={clsx('pl-10', props.className)} />

      {props.value && (
        <button className="absolute top-0 right-0 bottom-0 w-10 flex items-center justify-center" onClick={clear}>
          <XCircleFilledIcon className="w-5 h-5 text-charcoal-600 hover:text-blue-500" />
        </button>
      )}
    </div>
  );
}
