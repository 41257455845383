import { ConnectionManager } from '../../connection/connectionManager';
import { PROPERTY_NAMES } from './Constants';
import { Viewer } from '../globals';
import { Store } from './Store';
import { Tools } from './Tools';
import { VisibilityManager } from './VisibilityManager';
import { StringProperty, PropertyFactory } from '@fluid-experimental/property-properties';

/**
 * A helper function to setup state for the different viewer controls.
 * Here we setup the store and other state related initializations.
 */
export function setupViewerState(
  connectionManager: ConnectionManager,
  viewer: Viewer,
  modelUrn: string,
  useHierarchicalVisibilityOnly: boolean
): { destroy: () => void } {
  // We setup store to write and read state
  const store = new Store(connectionManager.propertyTree);

  // If the modelUrn doesn't exist, we insert it
  if (!connectionManager.propertyTree.root.has(PROPERTY_NAMES.MODEL_URN)) {
    connectionManager.propertyTree.root.insert(
      PROPERTY_NAMES.MODEL_URN,
      PropertyFactory.create('String', 'single', modelUrn)
    );
    connectionManager.propertyTree.commit();
  } else {
    const previousUrn = connectionManager.propertyTree.root.get<StringProperty>(PROPERTY_NAMES.MODEL_URN)!.getValue();
    // if it exists and is different, we reset visibility and update modelUrn in fluid e.g. store.reset();
    // if it exists and is the same, we do nothing
    if (previousUrn !== modelUrn) {
      console.warn('[Fluid] Model urn changed, resetting visibility');
      // we reset visibility settings
      store.reset();

      // we update the modelUrn in fluid
      connectionManager.propertyTree.root.get<StringProperty>(PROPERTY_NAMES.MODEL_URN)!.setValue(modelUrn);
      store.commit();
    }
  }

  store.initialize();

  // We setup visibility manager to handle the shared visibility state.
  const visibilityManager = new VisibilityManager(viewer, store, useHierarchicalVisibilityOnly);
  visibilityManager.initialize();

  // We initialize ToolsBinding to handle two-way binding between Store and Viewer.
  const tools = new Tools(viewer, store);
  tools.initialize();

  return {
    destroy: () => {
      store.destroy();
      tools.destroy();
      visibilityManager.destroy();
    },
  };
}
