import Button from '@adsk/alloy-react-button/es/Button';
import { ArrowMinimizeIcon, LaptopIcon } from '@adsk/alloy-react-icon';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { useCallback } from 'react';
import { UploadManager } from '..';
import { UploadDropArea } from '../../upload-drop-area';
import { UploadDropWrapper } from '../../upload-drop-wrapper';
import { usePushToUploadQueue } from '../hooks/use-push-to-upload-queue';
import { QueueListItem } from './queue-list-item';

interface Props {
  projectId?: string;
  folderId?: string;
}

export function UploadModal({ projectId, folderId }: Props) {
  const $uploads = UploadManager.useStore();
  const queue = UploadManager.useQueue();
  const pushToUploadQueue = usePushToUploadQueue();

  const close = useCallback(() => {
    $uploads.state = 'HIDDEN';
  }, [$uploads]);

  const done = useCallback(() => {
    if ($uploads.activeUploads > 0) {
      $uploads.state = 'MINI';
    } else {
      $uploads.state = 'HIDDEN';
    }
  }, [$uploads]);

  function queueFiles(files: File[]) {
    if (projectId && folderId) {
      for (const file of files) {
        pushToUploadQueue({
          file,
          projectId,
          parentFolderUrn: folderId,
        });
      }
    }
  }

  return (
    // @ts-expect-error
    <Modal open size="large" className="!h-auto">
      <Modal.Header className="flex items-center">
        <h2 className="flex-1">Upload files</h2>
        <div className="flex items-center gap-4">
          <button className="text-charcoal-700 hover:text-blue-500" onClick={() => ($uploads.state = 'MINI')}>
            <ArrowMinimizeIcon size={20} />
          </button>
          <Modal.Close showEscapeLabel={false} onClick={close} className="!mt-0" />
        </div>
      </Modal.Header>

      <Modal.Body className="!pt-4 !pb-6">
        <div>
          <Button
            as="div"
            variant="secondary"
            className="w-full flex items-center relative Button--allow-pointer-events"
          >
            <input
              type="file"
              multiple
              className="absolute inset-0 z-10 block opacity-0 cursor-pointer"
              onChange={e => {
                if (e.currentTarget.files?.length) {
                  queueFiles(Array.from(e.currentTarget.files));
                  e.currentTarget.value = '';
                }
              }}
            />
            <LaptopIcon className="w-5 mr-2" /> From your computer
          </Button>
        </div>

        <div className="mt-4">
          {queue.length > 0 ? (
            <UploadDropWrapper onFileDrop={queueFiles} className="p-2 min-h-[24rem]">
              <p>
                {queue.length} item{queue.length > 1 && 's'}
              </p>
              <ul className="mt-4 border-t border-charcoal-100">
                {queue.map(item => (
                  <QueueListItem key={item.uploadUid} {...item} />
                ))}
              </ul>
            </UploadDropWrapper>
          ) : (
            <div className="flex items-center justify-center h-96 max-h-full">
              <UploadDropArea onFileDrop={queueFiles} className="flex-1 h-full" />
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={done} disabled={queue.length === 0} className="ml-auto">
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
