import clsx from 'clsx';

interface Props {
  query?: string;
  disabled?: boolean;
  children: string;
}

export function QueryHighlighter({ query, disabled, children }: Props) {
  if (!query || disabled) {
    return children;
  }

  const queryRegExp = new RegExp(`(${query})`, 'gi');

  return children.split(queryRegExp).map((part, index) => {
    const highlight = part.toLowerCase() === query.toLowerCase();

    return (
      <span key={index} className={clsx(highlight && 'font-bold text-charcoal-900')}>
        {part}
      </span>
    );
  });
}
