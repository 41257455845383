import Modal from '@adsk/alloy-react-modal/es/Modal';
import { useModal } from '~/shared/hooks/use-modal';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { pairedDeviceIlloUrl } from '~/shared/utils/const.asset-urls';

export function SharedToWorkshopModal() {
  const modal = useModal('shared-to-workshop');
  const branding = useTemporaryBranding();

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} className="!h-auto" width={468}>
      <Modal.Header>
        <h2>Content sent to workshop</h2>
        <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} />
      </Modal.Header>
      <Modal.Body className="!pt-6 !pb-10 flex flex-col items-center justify-center text-center">
        <img src={pairedDeviceIlloUrl} alt="VR headset illustartion" className="ml-5" />
        <p className="text-charcoal-700 px-10">
          Put on your headset and open the {branding.productName} app to view this content in VR.
        </p>
      </Modal.Body>
    </Modal>
  );
}
