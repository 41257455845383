import { AlertWarningFilledIcon, CheckmarkCircleFilledIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis, UnrealUpdateStatus } from '@oasis/sdk';

const statusContent = {
  CHECKING: {
    message: 'Looking for Desktop Viewer updates.',
    action: (
      <>
        Checking... <ProgressRing size="xsmall" className="ml-2" />
      </>
    ),
  },
  UPTODATE: {
    message: 'Desktop Viewer is up to date.',
    action: <CheckmarkCircleFilledIcon className="text-green-500 ml-2" />,
  },
  PENDING: {
    message: 'Update available for Desktop Viewer.',
    action: null,
  },
  DOWNLOADING: {
    message: 'Update available for Desktop Viewer.',
    action: <>Downloading...</>,
  },
  UNPACKING: {
    message: 'Update available for Desktop Viewer.',
    action: (
      <>
        Installing... <ProgressRing size="xsmall" className="ml-2" />
      </>
    ),
  },
  DONE: {
    message: 'Finished installing Desktop Viewer.',
    action: (
      <>
        Complete <CheckmarkCircleFilledIcon className="text-green-500 ml-2" />
      </>
    ),
  },
  ERROR: {
    message: 'Couldnt update Desktop Viewer.',
    action: <AlertWarningFilledIcon className="text-red-500 ml-2" />,
  },
} satisfies Record<UnrealUpdateStatus, object>;

export function UnrealReleaseStatus() {
  const $bridge = Oasis.Bridge?.useStore();

  if (!$bridge) {
    return null;
  }

  const content = statusContent[$bridge.unrealUpdateStatus];

  return (
    <div className="text-body-md mt-5">
      <div className="flex items-center min-h-5 w-full">
        <p>{content.message}</p>
        <div className="flex items-center ml-auto font-semibold">
          {content.action}

          {$bridge.unrealUpdateStatus === 'DOWNLOADING' && (
            <ProgressRing size="xsmall" className="ml-2" now={$bridge.unrealUpdateProgress} />
          )}
        </div>
      </div>
    </div>
  );
}
