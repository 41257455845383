import clsx from 'clsx';
import { useEffect, useState } from 'react';

export function FadingEllipsis() {
  const [ellipsis, setEllipsis] = useState(3);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEllipsis(ellipsis === 3 ? 0 : ellipsis + 1);
    }, 300);

    return () => clearTimeout(timeout);
  }, [ellipsis]);

  return (
    <>
      <span className={clsx('transition-opacity duration-300', ellipsis <= 0 && 'opacity-0')}>.</span>
      <span className={clsx('transition-opacity duration-300', ellipsis <= 1 && 'opacity-0')}>.</span>
      <span className={clsx('transition-opacity duration-300', ellipsis <= 2 && 'opacity-0')}>.</span>
    </>
  );
}
