import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { ComponentProps, ReactNode, forwardRef } from 'react';

interface Props extends ComponentProps<'button'> {
  asChild?: boolean;
  size?: 'sm' | 'md';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'link' | 'primary-destructive' | 'tertiary-destructive';
  className?: string;
  isLoading?: boolean;
  children?: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  { asChild, size = 'md', variant = 'primary', isLoading, children, ...props }: Props,
  ref
) {
  const Component = asChild ? Slot : 'button';

  return (
    <span className={clsx('relative inline-flex', props.className)}>
      <Component
        {...props}
        ref={ref}
        disabled={props.disabled || isLoading}
        className={buttonClasses({ variant, size })}
      >
        {children}
      </Component>

      {isLoading && (
        <span className="absolute inset-0 flex items-center justify-center !cursor-default">
          <ProgressRing
            size="xsmall"
            variant={['primary', 'destructive'].includes(variant) ? 'alternative' : 'default'}
          />
        </span>
      )}
    </span>
  );
});

const buttonClasses = cva(
  'w-full inline-flex items-center justify-center border outline-0 cursor-pointer hover:disabled:shadow-transparent hover:disabled:cursor-default',
  {
    variants: {
      variant: {
        primary: clsx(
          'whitespace-nowrap text-white bg-blue-500 border-blue-500',
          'hover:shadow-halo-sm hover:shadow-charcoal-100',
          'focus:shadow-halo-sm focus:shadow-blue-100',
          'active:shadow-halo-lg active:shadow-charcoal-100 active:bg-blue-600 active:border-blue-600',
          'disabled:text-charcoal-500 disabled:bg-charcoal-100 disabled:border-charcoal-100'
        ),
        secondary: clsx(
          'border-charcoal-500',
          'hover:shadow-halo-sm hover:shadow-charcoal-100',
          'focus:shadow-halo-sm focus:shadow-blue-100',
          'active:shadow-halo-lg active:shadow-charcoal-100',
          'disabled:text-charcoal-500 disabled:bg-charcoal-100 disabled:border-charcoal-100'
        ),
        tertiary: clsx(
          'text-blue-700 border-white',
          'hover:bg-charcoal-50 hover:border-charcoal-50',
          'focus:shadow-halo-sm focus:shadow-blue-100',
          'active:bg-charcoal-100 active:border-charcoal-100',
          'disabled:text-charcoal-500 disabled:bg-transparent disabled:border-transparent'
        ),
        link: clsx(
          'text-blue-700 border-transparent',
          'hover:text-blue-500',
          'focus:shadow-halo-sm focus:shadow-blue-300',
          'active:text-blue-700',
          'disabled:text-charcoal-500'
        ),
        'primary-destructive': clsx(
          'text-white bg-red-500 border-red-500',
          'hover:shadow-halo-sm hover:shadow-charcoal-100',
          'focus:shadow-halo-sm focus:shadow-red-100',
          'active:shadow-halo-lg active:shadow-charcoal-100 active:bg-red-600 active:border-red-600',
          'disabled:text-charcoal-500 disabled:bg-charcoal-100 disabled:border-charcoal-100'
        ),
        'secondary-destructive': clsx(
          'border-red-500 text-red-700',
          'hover:shadow-halo-sm hover:shadow-charcoal-100',
          'focus:shadow-halo-sm focus:shadow-blue-100',
          'active:shadow-halo-lg active:shadow-charcoal-100',
          'disabled:text-charcoal-500 disabled:bg-charcoal-100 disabled:border-charcoal-100'
        ),
        'tertiary-destructive': clsx(
          'text-red-700 border-white',
          'hover:bg-charcoal-50 hover:border-charcoal-50',
          'focus:shadow-halo-sm focus:shadow-blue-100',
          'active:bg-charcoal-100 active:border-charcoal-100',
          'disabled:text-charcoal-500 disabled:bg-transparent disabled:border-transparent'
        ),
      },
      size: {
        sm: 'min-h-[1.5rem] px-2 rounded-sm text-label-sm',
        md: 'min-h-[2.25rem] px-4 rounded-sm text-label-md',
      },
    },
  }
);
