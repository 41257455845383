import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { OasisInspectorTable, registerOnRootUpdates } from '@oasis/fluid-interop';
import { useEffect, useState } from 'react';
import { useWorkshopState } from '~/shared/hooks/use-workshop-state';

export default function InspectorPage() {
  const { workshopConnection } = useWorkshopState();
  const { isConnected } = workshopConnection ?? { isConnected: false };

  const [, setState] = useState<any>();
  useEffect(() => {
    const { unregister } = registerOnRootUpdates(workshopConnection, root => setState(root));
    return () => {
      // We want to unregister only when the connection is explicitly closed. E.g. when existing the workshop.
      // useWorkshopState will take care of setting workshopConnection to undefined when the console shouldn't connect to the workshop.
      if (!workshopConnection) {
        unregister();
      }
    };
  }, [isConnected, workshopConnection]);

  if (!workshopConnection || !isConnected) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <ProgressRing size="large" />
        </div>
      </div>
    );
  }

  return (
    <section className="flex flex-1 overflow-hidden">
      <div className="w-full h-full flex-1 overflow-hidden">
        <OasisInspectorTable connection={workshopConnection} />
      </div>
    </section>
  );
}
