import { ArrayUtils } from '@oasis/utils';
import { useEffect, useMemo } from 'react';
import { IssueSubtypeCircle } from '~/features/issues/components/issue-subtype-circle';
import { Combobox } from '~/shared/components/forms/combobox';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { QueryResultItem } from '~/types';

interface Props {
  issueSubtypeId: string;
  status: string;
  onChange(issueSubtypeId: string): void;
}

type IssueType = QueryResultItem<typeof Queries.Issues.useListIssueTypes>;
type IssueSubtype = IssueType['subtypes'][number];

export function CreateIssueTypeField({ issueSubtypeId, onChange, ...props }: Props) {
  const { projectId, platform } = useProjectContext();
  const combobox = Combobox.useComboboxProps();

  const issueTypes = Queries.Issues.useListIssueTypes({ projectId, platform });

  const { sortedIssueTypes, issueSubtypeLookup } = useMemo(() => {
    const lookup = new Map<string, IssueSubtype>();

    if (issueTypes.data?.results) {
      for (const issueType of issueTypes.data.results) {
        for (const subtype of issueType.subtypes) {
          if (subtype.isActive) {
            lookup.set(subtype.id, subtype);
          }
        }
      }
    }

    return {
      issueSubtypeLookup: lookup,
      sortedIssueTypes: !issueTypes.data
        ? []
        : issueTypes.data.results
            .filter(item => item.isActive)
            .sort((a, b) => ArrayUtils.sortAlphanumeric(a.title, b.title))
            .map(type => {
              type.subtypes = type.subtypes
                .filter(item => item.isActive)
                .sort((a, b) => ArrayUtils.sortAlphanumeric(a.title, b.title));
              return type;
            }),
    };
  }, [issueTypes.data]);

  const { label, issueType, issueSubtype } = useMemo(() => {
    if (!issueTypes.data) {
      return { label: '', issueType: null, issueSubtype: null };
    }

    const issueSubtype = issueSubtypeLookup.get(issueSubtypeId);
    const issueType =
      issueSubtype && issueTypes.data.results.find(issueType => issueType.id === issueSubtype.issueTypeId);

    return {
      issueType,
      issueSubtype,
      label: (
        <div className="flex items-center">
          {issueSubtype && <IssueSubtypeCircle status={props.status} code={issueSubtype.code} />}
          {issueType?.title} <span className="mx-1">&gt;</span> {issueSubtype?.title}
        </div>
      ),
    };
  }, [issueSubtypeLookup, issueTypes.data, issueSubtypeId, props.status]);

  useEffect(() => {
    if (!issueSubtypeId) {
      const id = sortedIssueTypes[0]?.subtypes[0]?.id;
      if (id) onChange(id);
    }
  }, [sortedIssueTypes, issueSubtypeId, onChange]);

  return (
    <Combobox
      {...combobox}
      name="issue-type"
      value={issueSubtypeId ?? ''}
      displayValue={[issueType?.title, issueSubtype?.title].filter(Boolean).join(' > ')}
    >
      <Combobox.TriggerButton searchWhenOpen>{label}</Combobox.TriggerButton>
      <Combobox.Content>
        {sortedIssueTypes.map(issueType => {
          return (
            <Combobox.Section key={issueType.id} title={issueType.title}>
              {issueType.subtypes.map(subtype => {
                if (combobox.filterItem(`${issueType.title} ${subtype.title}`)) {
                  return null;
                }

                return (
                  <Combobox.Item key={subtype.id} value={subtype.id} onClick={() => onChange(subtype.id)}>
                    <div className="flex items-center">
                      <IssueSubtypeCircle status={props.status} code={subtype.code} />
                      <span className="ml-2">{subtype.title}</span>
                    </div>
                  </Combobox.Item>
                );
              })}
            </Combobox.Section>
          );
        })}
      </Combobox.Content>
    </Combobox>
  );
}
