import { Oasis } from '@oasis/sdk';
import { NetworkMode, useQuery } from '@tanstack/react-query';
import { useProjectContext } from '~/shared/contexts/project-context';
import { OasisError } from '~/shared/utils/oasis-error';
import { OasisParams } from '~/types';

export const projectsQueries = {
  useListProjects(
    params?: OasisParams<'Projects', 'listProjects'> & {
      opts?: {
        networkMode?: NetworkMode;
        enabled?: boolean;
        staleTime?: number;
        refetchOnWindowFocus?: boolean;
        refetchInterval?: number;
      };
    }
  ) {
    const $env = Oasis.Env.useStore();

    return useQuery({
      queryKey: ['projects', { isDevMode: $env.isDevMode }],
      queryFn: async () => {
        const res = await Oasis.Projects.listProjects(params);

        if (!res.ok) {
          // While debugging an unlikely flow where users sign up before going through the trial
          // flow we were seeing 404s from this response. Instead of throwing an error, we want to
          // just return "No projects"
          if (res.error.code === 'NOT_FOUND') {
            return {
              pagination: { limit: 0, offset: 0, totalResults: 0 },
              results: [],
            };
          }

          throw new OasisError(res.error, '[Projects.useListProjects]');
        }

        if (res.value.pagination.totalResults > res.value.pagination.limit) {
          const totalPages = Math.ceil(res.value.pagination.totalResults / res.value.pagination.limit);
          const pageNumbers = Array.from(Array(totalPages).keys()).slice(1);

          for (const page of pageNumbers) {
            const moreProjects = await Oasis.Projects.listProjects({
              pagination: {
                limit: res.value.pagination.limit,
                offset: page * (res.value.pagination.limit ?? 0),
              },
            });

            if (moreProjects.ok) {
              res.value.results = [...res.value.results, ...moreProjects.value.results];
            }
          }
        }

        return {
          ...res.value,
          results: res.value.results.filter(project => ($env.isDevMode ? true : project.platform !== 'bim360')),
        };
      },
      staleTime: 10_000,
      refetchOnWindowFocus: false,
      ...params?.opts,
    });
  },

  useListTopFolders(params: { projectId: string }) {
    const { hubId } = useProjectContext();

    return useQuery({
      queryKey: ['projects', params.projectId, 'top-folders'],
      queryFn: async () => {
        const res = await Oasis.Projects.listTopFolders({
          hubId,
          projectId: params.projectId,
        });

        if (!res.ok) {
          throw new OasisError(res.error, '[Projects.useFindProjectById]');
        }

        return res.value;
      },
      networkMode: 'offlineFirst',
      refetchOnWindowFocus: false,
    });
  },

  useListAdminUsers(params: OasisParams<'Projects', 'listAdminUsers'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'admins', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listAdminUsers(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListAdminUsers]');
        return res.value;
      },
    });
  },

  useListProjectUsers(params: OasisParams<'Projects', 'listProjectUsers'> & { opts?: { enabled?: boolean } }) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'users', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listProjectUsers(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListProjectUsers]');
        return res.value;
      },
      enabled: params.opts?.enabled,
    });
  },

  useListLocations(params: OasisParams<'Projects', 'listLocations'> & { opts?: { enabled?: boolean } }) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'locations', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listLocations(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListLocations]');
        return res.value;
      },
      enabled: params.opts?.enabled,
    });
  },

  useListRoles(params: OasisParams<'Projects', 'listRoles'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'roles', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listRoles(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListRoles]');
        return res.value;
      },
    });
  },

  useListCompanies(params: OasisParams<'Projects', 'listCompanies'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'companies', params],
      queryFn: async () => {
        const res = await Oasis.Projects.listCompanies(params);
        if (!res.ok) throw new OasisError(res.error, '[Projects.useListCompanies]');
        return res.value;
      },
    });
  },
};
