import { filesQueries } from './files.queries';
import { hubsQueries } from './hubs.queries';
import { issuesQueries } from './issues.queries';
import { projectsQueries } from './projects.queries';
import { usersQueries } from './users.queries';
import { workshopsQueries } from './workshops.queries';

export const Queries = {
  Hubs: hubsQueries,
  Files: filesQueries,
  Issues: issuesQueries,
  Projects: projectsQueries,
  Users: usersQueries,
  Workshops: workshopsQueries,
};
