import { Mqtt } from '../mqtt/mqtt.provider';

/**
 * Feb 2023, NQ
 * Commented out unused functions that were causing errors. This should probably be reworked
 * and we can remove Results from some of these methods.
 */
export const MessagesProvider = {
  async connect() {
    Mqtt.connect();
  },

  async publish({
    topic,
    payload,
    opts,
  }: {
    topic: string;
    payload: any;
    opts?: {
      retained?: boolean;
      expirationSeconds?: number;
    };
  }) {
    return Mqtt.publish(topic, payload, {
      retain: opts?.retained,
      properties: {
        messageExpiryInterval: opts?.expirationSeconds ? 60 * opts.expirationSeconds : undefined,
      },
    });
  },
};
