import { z } from 'zod';
import { CommonSchemas } from '../../lib/common.schemas';
import { ModelSchemas } from '../../lib/model.schemas';

export const createIssueAttrs = z.object({
  title: z.string().min(1, 'Required'),
  issueSubtypeId: z.string().min(1, 'Required'),
  status: z.string().min(1, 'Required'),
  description: z.string().optional(),
  snapshotUrn: z.string().optional(),
  assignedTo: z.string().optional().nullable(),
  assignedToType: z.string().optional().nullable(),
  dueDate: z.string().optional(),
  startDate: z.string().optional(),
  locationId: z.string().optional(),
  locationDetails: z.string().optional(),
  rootCauseId: z.string().optional(),
  published: z.boolean().optional(),
  permittedActions: z.string().array().optional(),
  watchers: z.string().array().optional(),
  customAttributes: z
    .array(
      z.object({
        attributeDefinitionId: z.string(),
        value: z.union([z.string(), z.number()]).nullable(),
      })
    )
    .optional(),
});

export const issueStatusEnum = z.enum(['open', 'pending', 'in_review', 'closed']);
export const issueAssignedToTypeEnum = z.enum(['user', 'company', 'role']);
export const issuePermittedAttributes = z.enum([
  'title',
  'description',
  'issueTypeId',
  'issueSubtypeId',
  'status',
  'assignedTo',
  'assignedToType',
  'dueDate',
  'locationId',
  'locationDetails',
  'linkedDocuments',
  'links',
  'ownerId',
  'rootCauseId',
  'officialResponse',
  'customAttributes',
  'snapshotUrn',
  'startDate',
  'published',
  'deleted',
  'watchers',
  'gpsCoordinates',
]);
export const issuePermittedActions = z.enum([
  'assign_all',
  'assign_same_company',
  'clear_assignee',
  'delete',
  'add_comment',
  'add_attachment',
  'remove_attachment',
]);

export const IssuesSchemas = {
  getUserPermissions: z.object({
    id: z.string(),
    isProjectAdmin: z.boolean(),
    permissionLevels: z.array(z.union([z.literal('create'), z.literal('read'), z.literal('write')])),
    issues: z.object({
      new: z.object({
        permittedActions: z.string().array(),
        // permittedActions: z.array(
        //   z.union([
        //     z.literal('assign_all'),
        //     z.literal('assign_same_company'),
        //     z.literal('clear_assignee'),
        //     z.literal('delete'),
        //     z.literal('add_comment'),
        //     z.literal('add_attachment'),
        //     z.literal('remove_attachment'),
        //     z.literal('upsert_pin'),
        //     z.literal('unlink_pin'),
        //   ])
        // ),
        // permittedAttributes: z.array(
        //   z.union([
        //     z.literal('title'),
        //     z.literal('description'),
        //     z.literal('issueTypeId'),
        //     z.literal('issueSubtypeId'),
        //     z.literal('status'),
        //     z.literal('assignedTo'),
        //     z.literal('assignedToType'),
        //     z.literal('dueDate'),
        //     z.literal('locationId'),
        //     z.literal('locationDetails'),
        //     z.literal('linkedDocuments'),
        //     z.literal('links'),
        //     z.literal('ownerId'),
        //     z.literal('rootCauseId'),
        //     z.literal('officialResponse'),
        //     z.literal('customAttributes'),
        //     z.literal('snapshotUrn'),
        //     z.literal('startDate'),
        //     z.literal('published'),
        //     z.literal('deleted'),
        //     z.literal('watchers'),
        //   ]),
        // ),
        permittedStatuses: z.array(
          z.union([
            z.literal('draft'),
            z.literal('open'),
            z.literal('pending'),
            z.literal('in_progress'),
            z.literal('completed'),
            z.literal('in_review'),
            z.literal('not_approved'),
            z.literal('in_dispute'),
            z.literal('closed'),
          ])
        ),
      }),
    }),
  }),

  createIssue: z.object({
    id: z.string(),
  }),

  listIssues: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(ModelSchemas.issue),
  }),

  findIssueById: ModelSchemas.issue,

  updateIssue: ModelSchemas.issue,

  deleteIssue: ModelSchemas.issue,

  listIssueTemplates: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        isActive: z.boolean(),
        issueAttributes: z.object({
          title: z.string(),
          issueTypeId: z.string(),
          issueSubtypeId: z.string(),
          status: z.string().optional(),
          assignedToType: z.string().optional(),
          assigneeId: z.string().optional(),
          daysTilDueDate: z.number().optional(),
          locationDetails: z.string().optional(),
          rootCauseId: z.string().optional(),
          watchers: z.array(z.string()).optional(),
          customAttributes: z
            .array(
              z.object({
                attributeDefinitionId: z.string(),
                value: z.union([z.string(), z.number()]).nullable(),
              })
            )
            .optional(),
        }),
      })
    ),
  }),

  listIssueTypes: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        createdAt: z.string(),
        createdBy: z.string(),
        deletedAt: z.string().nullable(),
        deletedBy: z.string().nullable(),
        id: z.string(),
        isActive: z.boolean(),
        orderIndex: z.number(),
        title: z.string(),
        subtypes: z.array(
          z.object({
            code: z.string().optional(), // ACC only
            createdAt: z.string(),
            createdBy: z.string(),
            deletedAt: z.string().nullable(),
            deletedBy: z.string().nullable(),
            id: z.string(),
            isActive: z.boolean(),
            issueTypeId: z.string(),
            title: z.string(),
            updatedAt: z.string(),
            updatedBy: z.string(),
            fieldsMetadata: z
              .array(
                z.object({
                  id: z.string(),
                  required: z.boolean().optional(),
                  visible: z.boolean().optional(),
                })
              )
              .optional()
              .nullable(),
          })
        ),
        updatedAt: z.string(),
        updatedBy: z.string(),
      })
    ),
  }),

  listRootCauseCategories: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        title: z.string(),
        isActive: z.boolean(),
        createdAt: z.string(),
        createdBy: z.string(),
        updatedAt: z.string().nullable(),
        updatedBy: z.string().nullable(),
        deletedAt: z.string().nullable(),
        deletedBy: z.string().nullable(),
        rootCauses: z.array(
          z.object({
            id: z.string(),
            rootCauseCategoryId: z.string(),
            title: z.string(),
            isActive: z.boolean(),
            createdAt: z.string(),
            createdBy: z.string(),
            updatedAt: z.string().nullable(),
            updatedBy: z.string().nullable(),
            deletedAt: z.string().nullable(),
            deletedBy: z.string().nullable(),
          })
        ),
      })
    ),
  }),

  listIssueAttributeDefinitions: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        containerId: z.string(),
        title: z.string(),
        description: z.string().nullable(),
        dataType: z.string(), // list | text | paragraph | numeric
        metadata: z.object({
          list: z
            .object({
              options: z.array(
                z.object({
                  id: z.string(),
                  value: z.string(),
                })
              ),
            })
            .optional(),
        }),
        createdAt: z.string(),
        createdBy: z.string(),
        updatedAt: z.string().nullable(),
        updatedBy: z.string().nullable(),
        deletedAt: z.string().nullable(),
        deletedBy: z.string().nullable(),
      })
    ),
  }),

  listIssueAttributeMappings: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        attributeDefinitionId: z.string(),
        containerId: z.string(),
        mappedItemType: z.union([z.literal('issueType'), z.literal('issueSubtype')]),
        mappedItemId: z.string(),
        order: z.number(),
        createdAt: z.string(),
        createdBy: z.string(),
        updatedAt: z.string().nullable(),
        updatedBy: z.string().nullable(),
        deletedAt: z.string().nullable(),
        deletedBy: z.string().nullable(),
      })
    ),
  }),

  listIssueActiveStatuses: z.array(z.string()),
};
