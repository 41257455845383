import { z } from 'zod';

export const ModelSchemas = {
  issue: z.object({
    assignedTo: z.string().nullable(),
    assignedToType: z.string().nullable(),
    attachmentCount: z.number(),
    closedAt: z.string().datetime().nullable(),
    closedBy: z.string().nullable(),
    commentCount: z.number(),
    containerId: z.string(),
    createdAt: z.string().datetime(),
    createdBy: z.string(),
    customAttributes: z.array(
      z.object({
        attributeDefinitionId: z.string(),
        title: z.string(),
        type: z.string(), // list | text | paragraph | numeric
        value: z.union([z.string(), z.number()]).nullable(),
      })
    ),
    deleted: z.boolean().optional(), // ACC only
    description: z.string().nullable(),
    displayId: z.union([z.string(), z.number()]),
    dueDate: z.string().nullable(),
    id: z.string(),
    issueSubtypeId: z.string(),
    issueTypeId: z.string(),
    linkedDocuments: z.array(z.object({ urn: z.string() })),
    locationDetails: z.string().nullable(),
    locationId: z.string().nullable(),
    openedAt: z.string().datetime().nullable(), // ACC only
    openedBy: z.string().nullable(), // ACC only
    ownerId: z.string(),
    permittedActions: z.array(z.string()), // z.array(IssuePermittedActions),
    permittedAttributes: z.array(z.string()), // z.array(IssuePermittedAttributes),
    permittedStatuses: z.array(z.string()), // z.array(IssueStatusEnum),
    published: z.boolean().optional(), // ACC only
    rootCauseId: z.string().nullable(),
    snapshotUrn: z.string().nullable(),
    startDate: z.string().nullable().optional().default(null), // ACC Only
    status: z.string(), // IssueStatusEnum
    title: z.string(),
    updatedAt: z.string().datetime(),
    updatedBy: z.string(),
    watchers: z.array(z.string()).optional().default([]), // ACC only
  }),
};
