import { Oasis } from '@oasis/sdk';
import { useMemo } from 'react';
import { Queries } from '~/shared/hooks/queries';

export function usePermittedAssignees(projectId: string, canAssignSameCompany: boolean) {
  const $session = Oasis.Session.useStore();

  const projectUsersQuery = Queries.Projects.useListProjectUsers({
    projectId,
  });
  const projectRolesQuery = Queries.Projects.useListRoles({
    projectId,
  });
  const projectCompaniesQuery = Queries.Projects.useListCompanies({
    projectId,
  });

  const currentUser = projectUsersQuery.data?.results.find(user => user.autodeskId === $session.user?.id);

  return useMemo(() => {
    return [
      {
        title: 'Member',
        items: projectUsersQuery.data
          ? projectUsersQuery.data.results.filter(item =>
              canAssignSameCompany ? item.companyId === currentUser?.companyId : true
            )
          : [],
      },
      {
        title: 'Role',
        items: projectRolesQuery.data && !canAssignSameCompany ? projectRolesQuery.data.results : [],
      },
      {
        title: 'Company',
        items: projectCompaniesQuery.data
          ? canAssignSameCompany
            ? projectCompaniesQuery.data.results.filter(item => item.id === currentUser?.companyId)
            : projectCompaniesQuery.data.results
          : [],
      },
    ] as const;
  }, [
    canAssignSameCompany,
    currentUser?.companyId,
    projectUsersQuery.data,
    projectRolesQuery.data,
    projectCompaniesQuery.data,
  ]);
}
