import Button from '@adsk/alloy-react-button';
import Checkbox from '@adsk/alloy-react-checkbox';
import Dropdown from '@adsk/alloy-react-dropdown';
import FormField from '@adsk/alloy-react-form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { Oasis } from '@oasis/sdk';
import { useState, type ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { TextInput } from '~/shared/components/forms/text-input';
import { Mutations } from '~/shared/hooks/mutations';
import { useNavigateToModal } from '~/shared/hooks/use-modal';
import { INDUSTRIES, JOB_TITLES, SEGMENTS } from './const';

const formSchema = z.object({
  companyName: z.string().min(1, 'Required'),
  jobTitle: z.string().min(1, 'Required'),
  industry: z.string().min(1, 'Required'),
  industrySpecified: z.string(),
  industrySegment: z.string(),
  industrySegmentSpecified: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
  isTrial?: boolean;
  renderSubmitButton?: (buttonProps: { disabled?: boolean; loading?: boolean }) => ReactNode;
  onSuccess?: () => void;
}

// Auth and trials is still a mess. Once we flip the switch and do not allow
// trial creation we can delete this component entirely.
export function ProfileForm(props: Props) {
  const $session = Oasis.Session.useStore();
  const navigate = useNavigate();
  const navigateToModal = useNavigateToModal();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyName: '',
      jobTitle: '',
      industry: '',
      industrySpecified: '',
      industrySegment: '',
      industrySegmentSpecified: '',
    },
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [communications, setCommunications] = useState(false);

  const updateProfile = Mutations.Auth.useUpdateUserProfile();
  const createTrial = Mutations.Auth.useCreateTrial();

  async function handleSubmit(values: FormValues) {
    if (!acceptedTerms) {
      return NotificationManager.push({
        status: 'error',
        content: 'You must agree to the disclosure notice.',
      });
    }

    if (props.isTrial && !communications) {
      return NotificationManager.push({
        status: 'error',
        content: 'You must agree to receive communications while starting a trial.',
      });
    }

    if ($session.user?.profile && ($session.license.type === 'PAID' || $session.license.type === 'TRIAL')) {
      navigateToModal('/projects', 'onboarding');
      return;
    }

    const normalizedValues = {
      companyName: values.companyName,
      jobTitle: values.jobTitle,
      industry: values.industrySpecified || values.industry,
      industrySegment: values.industrySegmentSpecified || values.industrySegment,
    };

    updateProfile.mutate(
      {
        displayName: `${$session.user?.firstName} ${$session.user?.lastName}`,
        consentToComms: props.isTrial,
        ...normalizedValues,
      },
      {
        async onSuccess() {
          const user = await Oasis.Session.me();

          if (!user.ok) {
            NotificationManager.push({
              status: 'error',
              content: 'Something went wrong verifying your license. Please try again.',
            });

            navigate('/login');
            return;
          }

          if (user.value.license?.valid) {
            navigateToModal('/projects', 'onboarding');
            return;
          }

          // Check if we allow self serve trials or if this is mounted in the /trial-agreement page
          if (props.isTrial) {
            // If they're eligible actually create the trial
            if (Oasis.Session.isTrialEligible()) {
              try {
                const res = await createTrial.mutateAsync();

                if (res && !res.errorCode) {
                  Oasis.Session.setLicense({ type: 'TRIAL', trialEligible: false, daysRemaining: 30 });
                  Oasis.Storage.set('gsh', Date.now().toString());
                  return navigateToModal('/projects', 'onboarding');
                }

                throw new Error('Failed to start trial.');
              } catch {
                NotificationManager.push({
                  status: 'error',
                  content: 'Something went wrong starting your trial. Please try again.',
                });

                return navigate('/trial', { replace: true });
              }
            }

            // If they're not eligible, show the error message
            NotificationManager.push({
              status: 'warning',
              content: 'You are not eligible for a trial at this time.',
            });
          }

          Oasis.Pendo.identify({ visitor: normalizedValues });
          Oasis.Session.setLicense({ type: 'FREE_VIEWER', trialEligible: false });
          navigateToModal('/projects', 'onboarding');
        },
      }
    );
  }

  const industry = form.watch('industry');
  const industrySegment = form.watch('industrySegment');
  const segments = SEGMENTS[industry];
  const cannotSubmit = !acceptedTerms || (props.isTrial && !communications);

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
      <Controller
        control={form.control}
        name="companyName"
        render={({ field, fieldState }) => (
          <FormField label="Company" labelVariant="top" error={fieldState.error?.message} required>
            <TextInput placeholder="Company name" {...field} />
          </FormField>
        )}
      />

      <Controller
        control={form.control}
        name="jobTitle"
        render={({ field, fieldState }) => (
          <FormField label="Job title" labelVariant="top" error={fieldState.error?.message} required>
            <Dropdown
              items={JOB_TITLES}
              placeholder="Job title"
              style={{ width: '100%' }}
              inputValue={field.value}
              onSelectedItemChange={value => {
                field.onChange(value.selectedItem?.value);
              }}
            />
          </FormField>
        )}
      />

      <Controller
        control={form.control}
        name="industry"
        render={({ field, fieldState }) => (
          <FormField label="Industry" labelVariant="top" error={fieldState.error?.message} required>
            <Dropdown
              items={INDUSTRIES}
              placeholder="Industry"
              style={{ width: '100%' }}
              inputValue={field.value}
              onSelectedItemChange={value => {
                field.onChange(value.selectedItem?.value);
              }}
            />
          </FormField>
        )}
      />

      {industry === 'Other (Please specify)' && (
        <Controller
          control={form.control}
          name="industrySpecified"
          render={({ field }) => (
            <FormField label="Specify industry" labelVariant="top">
              <TextInput {...field} placeholder="Specify industry" />
            </FormField>
          )}
        />
      )}

      {segments && industry && industry !== 'Other (Please specify)' && (
        <Controller
          control={form.control}
          name="industrySegment"
          render={({ field }) => (
            <FormField label="Industry segment" labelVariant="top">
              {!segments.length ? (
                <TextInput {...field} />
              ) : (
                <Dropdown
                  items={segments}
                  placeholder="Industry segment"
                  style={{ width: '100%' }}
                  inputValue={field.value}
                  onSelectedItemChange={value => {
                    field.onChange(value.selectedItem?.value);
                  }}
                />
              )}
            </FormField>
          )}
        />
      )}

      {industrySegment === 'Other (Please specify)' && (
        <Controller
          control={form.control}
          name="industrySegmentSpecified"
          render={({ field }) => (
            <FormField label="Specify industry segment" labelVariant="top">
              <TextInput {...field} placeholder="Specify industry segment" />
            </FormField>
          )}
        />
      )}

      <div className="flex items-center">
        <Checkbox
          checked={acceptedTerms}
          onChange={checked => {
            if (typeof checked === 'boolean') {
              setAcceptedTerms(checked);
            }
          }}
        />
        <p className="ml-4">
          I acknowledge the{' '}
          <a href="/about#headset-data-collection" target="_blank" className="text-blue-700 hover:underline">
            Headset Data Collection Notice&nbsp;Disclosure
          </a>
          .
          <span className="text-red-500" aria-label="required">
            *
          </span>
        </p>
      </div>

      {props.isTrial && (
        <div className="mb-12 flex items-center">
          <Checkbox
            checked={communications}
            onChange={checked => {
              if (typeof checked === 'boolean') {
                setCommunications(checked);
              }
            }}
          />
          <p className="ml-4">
            I agree to receive communications in regards to my Workshop XR trial.{' '}
            <span className="text-red-500" aria-label="required">
              *
            </span>
          </p>
        </div>
      )}

      {props.renderSubmitButton ? (
        props.renderSubmitButton({ disabled: cannotSubmit, loading: updateProfile.isPending })
      ) : (
        <Button type="submit" className="w-full mb-2" disabled={cannotSubmit}>
          Get started
        </Button>
      )}
    </form>
  );
}
