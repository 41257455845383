import { HTTPError, TimeoutError } from 'ky';
import { ZodSchema, z } from 'zod';
import { Oasis } from '../oasis';
import { Err } from './result';

export const HttpUtils = {
  /**
   * @name parse
   * Parse some data with a given zod schema. In dev mode it will throw an error if the
   * data is invalid, but in production it will log a warning and return the data as is.
   */
  parse<T extends ZodSchema>(
    schema: T,
    data: unknown,
    loggingScope: string,
    message = 'Failed to parse data'
  ): z.infer<T> {
    if (Oasis.Env.store.releaseChannel === 'develop') {
      schema.parse(data);
    } else {
      const result = schema.safeParse(data);

      if (!result.success) {
        Oasis.Logger.warn({ error: result.error, msg: `[${loggingScope}] ${message}` });
      }
    }

    return data;
  },

  /**
   * @name createScopedParser
   * Creates a `HttpUtils.parse` function that is prefixed with a given logging scope. This is most
   * useful in modules that have a schemas object and you want to prefix all logging scopes.
   */
  createScopedParser<T extends Record<string, ZodSchema>>(loggingScope: string, schemas: T) {
    return function scopedParse<K extends keyof T>(schemaKey: K, data: unknown) {
      return HttpUtils.parse<T[K]>(schemas[schemaKey], data, `${loggingScope}.${String(schemaKey)}`);
    };
  },

  async handleError(error: unknown, method: `[${string}]`) {
    Oasis.Logger.debug({ error, msg: method });

    try {
      if (error instanceof HTTPError) {
        const json = await error.response.json();

        // BIM errors are returned in a different format
        const bimErrorArray = json?.errors ?? [];
        const bimStatus = bimErrorArray[0]?.status;
        const bimError = bimStatus ? Number(bimStatus) : null;

        const status = json.status || error.response.status || bimError;

        if ([401, 403, 409].indexOf(status) < 0) {
          Oasis.Logger.warn({ error, json, msg: `${method} ${status}` });
        }
        if (status === 400) {
          return Err({ code: 'BAD_REQUEST' });
        }
        if (status === 401) {
          return Err({ code: 'UNAUTHORIZED' });
        }
        if (status === 403) {
          return Err({ code: 'FORBIDDEN' });
        }
        if (status === 404) {
          return Err({ code: 'NOT_FOUND' });
        }
        if (status === 409) {
          return Err({ code: 'CONFLICT' });
        }
        if (status === 429) {
          return Err({ code: 'TOO_MANY_REQUESTS' });
        }
        if (status === 500) {
          return Err({ code: 'INTERNAL_SERVER_ERROR' });
        }
        if (status === 502) {
          return Err({ code: 'BAD_GATEWAY' });
        }
        if (status === 503) {
          return Err({ code: 'SERVICE_UNAVAILABLE' });
        }
        if (status === 504) {
          return Err({ code: 'GATEWAY_TIMEOUT' });
        }
      }

      if (error instanceof DOMException && error.name === 'AbortError') {
        return Err({ code: 'ABORTED' });
      }

      if (error instanceof TypeError) {
        // One of our libraries is throwing exceptions when we abort requests while navigating away
        // from specific pages. TODO: Figure out what library is causing this and contribute.
        if (error.message.includes('e.throwIfAborted')) {
          return Err({ code: 'ABORTED' });
        }
      }

      if (error instanceof TimeoutError) {
        Oasis.Logger.error({ error, msg: `${method} Timeout.` });
        return Err({ code: 'TIMEOUT' });
      }

      throw error;
    } catch (error) {
      Oasis.Logger.error({ error, msg: `${method} Unhandled HttpUtils exception.` });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async uploadWithProgress(params: {
    url: string;
    file: Blob;
    onProgress?: (percentDecimal: number, xhr: XMLHttpRequest) => void;
  }): Promise<XMLHttpRequest> {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          resolve(xhr);
        }
      };

      if (params.onProgress) {
        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            const percentDecimal = e.loaded / params.file.size;
            params.onProgress?.(percentDecimal, xhr);
          }
        };
      }

      xhr.open('PUT', params.url);
      xhr.send(params.file);
    });
  },
};
