import Toggle from '@adsk/alloy-react-toggle/es/Toggle';
import { Oasis } from '@oasis/sdk';
import { useState } from 'react';
import { Button, ButtonIcon } from '~/shared/components/base/button';
import { Tooltip } from '~/shared/components/base/tooltip';
import { SearchInput } from '~/shared/components/forms/search-input';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useOpenModal } from '~/shared/hooks/use-modal';
import { SidePanelLayout } from '~/shared/layouts/side-panel';
import { CreateWorkshopModal } from '../../components/create-workshop-modal';
import { WorkshopPanel } from '../../components/workshop-panel';
import { WorkshopsList } from '../../components/workshops-list';
import { useWorkshopDetails } from '../../hooks/use-workshop-details';

export default function WorkshopsIndexPage() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const { projectId } = useProjectContext();
  const { isLoading, data, error } = Queries.Workshops.useListAllWorkshops(projectId);

  const openModal = useOpenModal();
  const { isOpen } = useWorkshopDetails();
  const [showMine, setShowMine] = useState(localStorage.getItem('oasis.only-my-workshops') === 'true');
  const [query, setQuery] = useState('');

  const isFreeViewer = $session.license.type === 'FREE_VIEWER';

  return (
    <>
      <SidePanelLayout className="overflow-auto">
        <div className="flex flex-col w-full h-full px-5 pb-10 overflow-auto">
          <div className="pt-12 pb-10 short:py-6 flex items-center">
            <h1 className="text-title">Workshops</h1>
          </div>

          <div className="flex items-center mb-10">
            <Tooltip
              content={isFreeViewer && 'Your account is limited by a free license.'}
              placement="bottom"
              className="mt-2"
            >
              <Button onClick={() => openModal('create-workshop')} disabled={isFreeViewer}>
                <ButtonIcon icon="plus" position="left" /> Create Workshop
              </Button>
            </Tooltip>

            {$env.isDevMode && (
              <div className="ml-5">
                <p className="text-[10px] uppercase text-yellorange-500 leading-tight">Dev mode only</p>
                <div className="flex items-center">
                  <p>Only show my workshops</p>
                  <div className="mx-2">
                    <Toggle
                      checked={showMine}
                      onChange={value => {
                        setShowMine(value);
                        localStorage.setItem('oasis.only-my-workshops', String(value));
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {!isLoading && !error && data && data.results.length > 0 && (
              <div className="ml-auto w-full max-w-xs">
                <SearchInput
                  className="ml-auto"
                  value={query}
                  onTextChange={setQuery}
                  placeholder="Search for workshop name"
                />
              </div>
            )}
          </div>

          <WorkshopsList onlyMyWorkshops={showMine} query={query} setQuery={setQuery} />
        </div>

        <SidePanelLayout.Panel isOpen={isOpen} widthPx={640} fixed>
          <WorkshopPanel />
        </SidePanelLayout.Panel>
      </SidePanelLayout>

      <CreateWorkshopModal />
    </>
  );
}
