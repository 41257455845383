import { LDClient, initialize, type LDContext } from 'launchdarkly-js-client-sdk';
import { proxy, useSnapshot } from 'valtio';
import { Oasis } from '../../oasis';
import { LaunchDarkly } from '../../providers/launchdarkly/launchdarkly.provider';
import { Env } from '../env/env.service';
import { FeatureFlagNames } from './feature-flags.types';

interface FeatureFlagsStore {
  flags: Record<FeatureFlagNames, boolean | string | undefined> | Record<string, boolean | string | undefined>;
}

let client: LDClient | undefined;
export const FeatureFlags = {
  initPromise: undefined as Promise<void> | undefined,

  store: proxy<FeatureFlagsStore>({
    flags: {},
  }),
  useStore: () => useSnapshot(FeatureFlags.store),

  async init(releaseChannel = Env.store.releaseChannel) {
    this.initPromise = new Promise((resolve, reject) => {
      client = initialize(
        LaunchDarkly.getClientId(releaseChannel),
        { kind: 'user', key: 'anonymous' },
        { streaming: true }
      );

      client.on('initialized', resolve);
      client.on('change', FeatureFlags.updateFlags);

      setTimeout(reject, 10_000);
    });

    return this.initPromise;
  },

  updateFlags() {
    const flags = Object.keys(FeatureFlags.store.flags) as FeatureFlagNames[];

    for (const flag of flags) {
      FeatureFlags.getFlag(flag);
    }

    Oasis.Logger.debug({ flags, msg: '[FeatureFlags.updateFlags] Updating flags' });
  },

  getFlag<T extends boolean | string = boolean | string>(flag: FeatureFlagNames, defaultValue?: T): T {
    const value = client?.variation(flag, defaultValue);

    FeatureFlags.store.flags[flag] = value as T;

    return value as T;
  },

  // This function is used to get the flag value asynchronously, it will wait for the client to be initialized
  // before returning the value.
  async getFlagValueAsync<T extends boolean | string>(flag: FeatureFlagNames, defaultValue?: T): Promise<T> {
    await this.initPromise;
    return this.getFlag<T>(flag, defaultValue);
  },

  async identify(context: LDContext) {
    return client?.identify(context);
  },
};
