import debounce from 'lodash/debounce';
import { Viewer } from '../globals';
import { Store } from './Store';

export class Tools {
  constructor(
    private readonly viewer: Viewer,
    private readonly store: Store
  ) {
    this.viewer = viewer;
    this.store = store;
  }

  attach = (): void => {
    this.viewer.addEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.handleExplode);
    this.viewer.addEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.handleCutPlanes);
  };

  detach = (): void => {
    this.viewer.removeEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.handleExplode);
    this.viewer.removeEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.handleCutPlanes);
  };

  handleCutPlanes = debounce((e: { planes: never[] }) => this.store.setCutPlanes(e.planes || []), 100);

  handleExplode = (e: { scale: number }): void => {
    if (this.store.getExplodeValue() !== e.scale) this.store.setExplodeValue(e.scale);
  };

  initialize(): void {
    const { viewer, store, attach, detach } = this;
    store.defineAndActivateToolsBinding({ viewer, store, attach, detach });
    attach();
  }

  destroy(): void {
    this.detach();
  }
}
