import type { ComponentProps } from 'react';

export function Doctype3dViewIcon(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path fill="currentColor" d="M2.393.945v22.163H21.6V6.12h-5.175V.945H2.393Z" />
      <path fill="#fff" d="M3.127 1.688V16.5h17.73V6.12h-4.432V1.687H3.127Z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m7.665 10.943 4.095 2.25 4.103-2.25V6.638l-4.103-1.89-4.095 1.89v4.305Zm4.095 3.307-5.01-2.775V6.06l5.01-2.31 5.018 2.31v5.415L11.76 14.25Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.76 9.48 6.99 6.72l.443-.825L11.768 8.4l4.327-2.452.443.825L11.76 9.48Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.76 14.25a.473.473 0 0 1-.472-.465v-5.04a.467.467 0 0 1 .472-.458.472.472 0 0 1 .48.458v5.04a.474.474 0 0 1-.48.465"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M16.425.945 21.6 6.12h-5.175V.945Z" />
    </svg>
  );
}
