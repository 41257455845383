import { Oasis } from '@oasis/sdk';
import { reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { App } from './app';
import './shared/styles/index.css';

export async function createApp(params?: { releaseChannel?: string; sentryDsn?: string }) {
  if (params?.sentryDsn) {
    Oasis.Sentry.init({
      dsn: params.sentryDsn,
      environment: params.releaseChannel || 'develop',
      integrations: [
        replayIntegration(),
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  return <App />;
}
