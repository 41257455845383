import Button from '@adsk/alloy-react-button';
import { AlertInformationFilledIcon } from '@adsk/alloy-react-icon';
import { Tooltip } from '~/shared/components/base/tooltip';
import { useModal } from '~/shared/hooks/use-modal';
import { useWorkshopFluidControls } from '../../hooks/use-workshop-fluid-controls';
import { useWorkshopPermissions } from '../../hooks/use-workshop-permissions';
import { ClearAnnotationsModal } from './clear-annotations-modal';

interface Props {
  projectId: string;
  workshopId: string;
  workshopName: string;
}

export function WorkshopSettingsTab(props: Props) {
  const permissions = useWorkshopPermissions(props.workshopId);
  const modal = useModal('clear-annotations');
  const fluidControls = useWorkshopFluidControls(props.workshopId);

  const noFluidData = !fluidControls.hasMeasurements && !fluidControls.hasAnnotations;

  return (
    <div className="p-4">
      <h3 className="text-heading-2">Workshop data</h3>
      <p className="text-charcoal-700 mt-1">
        Only people with <span className="font-bold">manage permissions</span> can change these settings.
      </p>

      <div className="flex items-center justify-between my-4">
        <p className="text-body-md font-bold">Clear workshop annotations</p>
        <Tooltip
          content={
            !permissions.canManage
              ? 'Manage permissions required to clear annotations.'
              : fluidControls.isConnecting
                ? 'Connecting to workshop...'
                : noFluidData
                  ? 'There are no annotations in this workshop yet.'
                  : undefined
          }
          placement="left"
          className="mr-2"
        >
          <Button
            variant="primary"
            disabled={!permissions.canManage || noFluidData}
            onClick={() => modal.open({ workshopId: props.workshopId })}
          >
            Manage
          </Button>
        </Tooltip>
      </div>

      <div className="flex">
        <div className="mr-2">
          <AlertInformationFilledIcon className="text-blue-500" />
        </div>
        <div>
          <p>
            Manage annotations by clearing measurements and/or drawings created in models shared in this workshop, both
            currently and previously.
          </p>
          <ul className="adsk-list">
            <li>Clears annotations created by anyone in this workshop.</li>
            <li>
              If a model is shared in multiple workshops, this will only clear the annotations created in this workshop.
            </li>
          </ul>
        </div>
      </div>

      <ClearAnnotationsModal workshopId={props.workshopId} workshopName={props.workshopName} />
    </div>
  );
}
