export function LargeDocumentIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 114" fill="none" {...props}>
      <path
        fill="#E8E8E8"
        d="M26.784 90.306v-67.23a3.022 3.022 0 0 1 3.022-3.022h54.388a3.022 3.022 0 0 1 3.022 3.022v67.23a3.022 3.022 0 0 1-3.022 3.021H29.806a3.022 3.022 0 0 1-3.022-3.021Z"
      />
      <path
        fill="#CCC"
        d="M34.338 61.6v-3.02h45.324v3.02H34.338ZM34.338 54.802v-3.021h30.216v3.021H34.338ZM34.338 68.4v-3.022h45.324v3.021H34.338ZM34.338 75.198v-3.022h45.324v3.022H34.338ZM34.338 81.996v-3.021h45.324v3.021H34.338Z"
      />
    </svg>
  );
}
