import { DatePicker } from '@adsk/alloy-react-date-picker';
import { DateUtils } from '@oasis/utils';
import { toDate } from 'date-fns-tz';
import { useMemo } from 'react';
import { Label } from '~/shared/components/forms/label';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  name: string;
  label: string;
  value?: string;
  onChange(date: string): void;
  required?: boolean;
}

export function CreateIssueDateField(props: Props) {
  const dateValue = useMemo(() => {
    if (props.value) {
      return toDate(props.value);
    }

    return null;
  }, [props.value]);

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        {props.label}
      </Label>

      <DatePicker
        value={dateValue}
        triggerProps={{
          className: '!w-full',
        }}
        onChange={({ date }) => {
          props.onChange(date ? DateUtils.getDateAsValue(date) : '');
        }}
      />
    </div>
  );
}
