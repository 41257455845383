import { useEffect, useRef } from 'react';
import { useFeatureFlags } from './use-feature-flags';
import { ConnectionManager } from '@oasis/fluid-interop';
import { Oasis } from '@oasis/sdk';

type signalInput = {
  content: {
    playerPosition: {
      X: number;
      Y: number;
      Z: number;
    };
    playerForwardVector: {
      X: number;
      Y: number;
      Z: number;
    };
  };
};

export const useUnrealLmvCamerasSync = ({
  viewer,
  fluidConnection,
}: {
  viewer: Autodesk.Viewing.GuiViewer3D | undefined;
  fluidConnection: ConnectionManager | undefined;
}) => {
  const [isCameraSync] = useFeatureFlags(['241015-7221-unreal-lmv-cameras-sync']);
  const $debug = Oasis.Debug.useStore();
  const lastIsCameraSyncMode = useRef(isCameraSync);
  const $session = Oasis.Session.useStore();

  useEffect(() => {
    if (isCameraSync !== lastIsCameraSyncMode.current) {
      // reload
      window.location.reload();
    }

    lastIsCameraSyncMode.current = isCameraSync;
  }, [isCameraSync]);

  useEffect(() => {
    if (!isCameraSync || !$session.user?.id) {
      return;
    }

    if (viewer) {
      const topic = `debug/camera/${$session.user.id}`;
      const onCameraSignal = (_clientId: string, _local: boolean, payload: signalInput) => {
        if (!payload.content || !$debug.lmvCameraSync) {
          return;
        }
        const { content: cameraInfo } = payload;
        const { playerPosition, playerForwardVector } = cameraInfo;

        if (!playerPosition || !playerForwardVector) {
          return;
        }

        if (!viewer.navigation.getCamera().isPerspective) {
          viewer.navigation.toPerspective();
        }

        const { X, Y, Z } = playerPosition;
        const { X: FX, Y: FY, Z: FZ } = playerForwardVector;

        const camera = viewer.navigation.getCamera();

        camera.position.set(X, Y, Z);
        camera.target.set(X + FX, Y + FY, Z + FZ);

        viewer.impl.invalidate(true, true, true);
      };

      if (fluidConnection && $debug.lmvCameraSync) {
        fluidConnection.onSignal(topic, onCameraSignal);
      }

      return () => {
        if (fluidConnection) {
          fluidConnection.offSignal(topic, onCameraSignal);
        }
      };
    }
  }, [viewer, fluidConnection, isCameraSync, $debug.lmvCameraSync, $session.user?.id]);
};
