import Button, { ButtonGroup, LinkButton } from '@adsk/alloy-react-button';
import {
  CloudUpArrowIcon,
  GridIcon,
  MagnifyingGlassIcon,
  MenuIcon,
  TrashCanIcon,
  XCircleFilledIcon,
} from '@adsk/alloy-react-icon';
import Tabs, { Tab } from '@adsk/alloy-react-tabs';
import { FileSearchFilters, Oasis } from '@oasis/sdk';
import { ProjectUtils } from '@oasis/utils';
import { RowSelectionState } from '@tanstack/react-table';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useSearchParams } from 'react-router-dom';
import { UploadDropWrapper } from '~/features/files/components/upload-drop-wrapper';
import { UploadManager } from '~/features/files/components/upload-manager';
import { FolderTree } from '~/shared/components/base/folder-tree';
import { Folder } from '~/shared/components/base/folder-tree/context';
import { OasisErrorBoundary } from '~/shared/components/base/oasis-error-boundary';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { SidePanelLayout } from '~/shared/layouts/side-panel';
import { OasisError } from '~/shared/utils/oasis-error';
import { FileDetailsPanel } from '../../components/file-details-panel';
import { FileSearchFiltersPanel } from '../../components/file-search-filters-panel';
import { FilesTable } from '../../components/files-table';
import { usePushToUploadQueue } from '../../components/upload-manager/hooks/use-push-to-upload-queue';

export default function FilesIndexPage() {
  const $env = Oasis.Env.useStore();
  const { projectId, platform } = useProjectContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeFolderId = Oasis.Storage.getTemporary('activeFolderUrn') ?? searchParams.get('folder') ?? undefined;
  const activeFileDetailsUrn = searchParams.get('fileDetailsUrn') ?? undefined;
  const $uploads = UploadManager.useStore();

  const [filterSupported, setFilterSupported] = useState(
    searchParams.get('filterSupported') !== null
      ? searchParams.get('filterSupported') === 'true'
        ? true
        : false
      : $env.isVr
  );
  const [folderPermissions, setFolderPermissions] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [mode, setMode] = useState(localStorage.getItem('files-table-mode') || 'list');
  const [query, setQuery] = useState(
    searchParams.get('fileSearchQuery') !== null ? String(searchParams.get('fileSearchQuery')) : ''
  );
  const [showSearch, setShowSearch] = useState(false);
  const [filters, setFilters] = useState<FileSearchFilters>({
    sourceIdType:
      searchParams.get('sourceIdType') === null || searchParams.get('sourceIdType') === ''
        ? []
        : String(searchParams.get('sourceIdType')).split(','),
    fileType:
      searchParams.get('fileType') === null || searchParams.get('fileType') === ''
        ? []
        : String(searchParams.get('fileType')).split(','),
    includeTopFolders: searchParams.get('includeTopFolders') ? Boolean(searchParams.get('includeTopFolders')) : false,
    recursive: searchParams.get('recursive') ? Boolean(searchParams.get('recursive')) : true,
  });
  const filterKey = useRef(0);

  const topFolders = Queries.Projects.useListTopFolders({ projectId });
  const deleteFiles = Mutations.Files.useDeleteFiles();
  const deleteFolders = Mutations.Files.useDeleteFolders();
  const pushToUploadQueue = usePushToUploadQueue();

  const folderId = activeFolderId || topFolders.data?.rootFolderUrn || '';

  const folderContents = Queries.Files.useListFolderContents({
    projectId,
    platform,
    folderId,
    opts: { enabled: Boolean(folderId) },
  });

  const handleFolderSelect = useCallback(
    (folder: Folder) => setFolderPermissions(folder.permissionActions),
    [setFolderPermissions]
  );

  const deletedUrl = useMemo(() => {
    const urlId = ProjectUtils.formatId(projectId);

    if (platform === 'acc') {
      const subdomain = $env.releaseChannel === 'alpha' ? 'acc-staging' : 'acc';
      const params = `?folderUrn=${folderId}&viewModel=detail&moduleId=deleted`;

      return `https://${subdomain}.autodesk.com/docs/files/projects/${urlId}${params}`;
    }

    return '';
  }, [projectId, platform, $env.releaseChannel, folderId]);

  function handleMultiDelete() {
    try {
      if (!folderContents.data) return;

      const selectedIndexes = Object.keys(rowSelection).map(Number);
      const files: { id: string; index: number }[] = [];
      const folders: { id: string; index: number }[] = [];

      for (const index of selectedIndexes) {
        const row = folderContents.data[index];

        if (row) {
          const container = row.type === 'items' ? files : folders;
          container.push({ id: row.id, index });
        }
      }

      function onSuccess(ids: { id: string; index: number }[]) {
        setRowSelection(prev => {
          for (const { index } of ids) delete prev[String(index)];
          return prev;
        });
      }

      if (files.length) {
        deleteFiles.mutate(
          { projectId, fileIds: files.map(file => file.id) },
          {
            onSuccess: () => onSuccess(files),
          }
        );
      }

      if (folders.length) {
        deleteFolders.mutate(
          { projectId, folderIds: folders.map(folder => folder.id) },
          {
            onSuccess: () => onSuccess(folders),
          }
        );
      }
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[FilesListPage.handleMultiDelete]' });
    }
  }

  function queueFiles(files: File[]) {
    if (activeFolderId) {
      for (const file of files) {
        pushToUploadQueue({
          file,
          projectId,
          parentFolderUrn: activeFolderId,
        });
      }

      if ($uploads.state === 'HIDDEN') {
        $uploads.state = 'MODAL';
      }
    }
  }

  function getFiltersCount() {
    let filtersCount = 0;

    for (const condition of [query.length, filters.fileType.length, filters.sourceIdType.length, filterSupported]) {
      if (condition) filtersCount++;
    }

    return filtersCount;
  }

  function clearSettings() {
    filterKey.current++;
    const defaultFilters = {
      sourceIdType: [],
      fileType: [],
      includeTopFolders: false,
      recursive: true,
    };
    setQuery('');
    setFilters(defaultFilters);
    setFilterSupported($env.isVr);
    searchParams.delete('fileSearchQuery');
    searchParams.delete('sourceIdType');
    searchParams.delete('fileType');
    searchParams.delete('includeTopFolders');
    searchParams.delete('recursive');
    searchParams.delete('filterSupported');
    setSearchParams(searchParams);
  }

  const canUpload = folderPermissions.includes('publish');
  const savedPanelSize = Oasis.Storage.get('filePanelSize');

  const savePanelSize = debounce((panelSize: number) => {
    Oasis.Storage.set('filePanelSize', panelSize);
  }, 250);

  return (
    <SidePanelLayout>
      <section className="flex flex-col flex-1 overflow-hidden">
        <div className="pt-12 pb-10 short:py-6 px-5 flex items-center">
          <h1 className="text-title">Files</h1>
        </div>

        {topFolders.error ? (
          <>
            {topFolders.error instanceof OasisError && topFolders.error.code === 'NOT_FOUND' ? (
              <OasisErrorState
                code="NOT_FOUND"
                title={
                  platform === 'acc' ? 'Enable ACC Docs to view Files' : 'Enable Document Management to view Files'
                }
                description={`To access your files, your Project Admin will need to enable ${platform === 'acc' ? 'ACC Docs' : 'Document Management'} for this project.`}
              />
            ) : (
              <OasisErrorState oasisError={topFolders.error} />
            )}
          </>
        ) : (
          <OasisErrorBoundary>
            <Tabs
              className="!flex flex-col flex-1 pl-4 overflow-hidden"
              renderExtraItems={
                $env.isVr || !deletedUrl
                  ? undefined
                  : () => (
                      <div className="mr-5">
                        <LinkButton
                          as="a"
                          href={deletedUrl}
                          target="_blank"
                          className="flex items-center"
                          rel="noreferrer"
                        >
                          Deleted items
                          <ExternalLinkIcon className="ml-2" />
                        </LinkButton>
                      </div>
                    )
              }
            >
              <Tab label="Folders" tab="folders" className="-ml-4 flex flex-1 overflow-hidden">
                <PanelGroup direction="horizontal" className="h-full">
                  <Panel
                    defaultSize={savedPanelSize ? Number(savedPanelSize) : 20}
                    minSize={15}
                    maxSize={50}
                    onResize={savePanelSize}
                  >
                    <div className="overflow-auto h-full pb-10">
                      <FolderTree onFolderSelect={handleFolderSelect} />
                    </div>
                  </Panel>

                  <PanelResizeHandle className="h-full bg-red flex items-center justify-center border-l border-charcoal-200 hover:border-blue-300">
                    <div className="flex space-x-[2px] w-full px-1">
                      <div className="h-3 w-[1px] rounded-lg bg-charcoal-200" />
                      <div className="h-3 w-[1px] rounded-lg bg-charcoal-200" />
                    </div>
                  </PanelResizeHandle>

                  <Panel>
                    <div className="flex flex-col flex-1 h-full overflow-hidden pl-1">
                      <div className="flex items-center my-4 pl-4 pr-5 h-10">
                        <div className="flex items-center space-x-3">
                          {$env.isWeb && canUpload && (
                            <Button
                              variant="primary"
                              onClick={() => ($uploads.state = 'MODAL')}
                              renderIcon={() => <CloudUpArrowIcon className="mr-2" />}
                            >
                              Upload files
                            </Button>
                          )}

                          {Object.keys(rowSelection).length > 0 && (
                            <Button
                              variant="tertiary"
                              alert
                              onClick={handleMultiDelete}
                              loading={deleteFiles.isPending || deleteFolders.isPending}
                              renderIcon={() => <TrashCanIcon className="mr-2" />}
                            >
                              Delete
                            </Button>
                          )}
                        </div>

                        <div className="flex items-center ml-auto mr-2">
                          <Button
                            className={clsx(showSearch && '!p-none !pl-[0.3125rem] !pr-[0.3125rem]')}
                            onClick={() => setShowSearch(true)}
                            renderIcon={() => <MagnifyingGlassIcon />}
                          >
                            {showSearch && getFiltersCount() > 0 && <p className="ml-1">({getFiltersCount()})</p>}
                            {!showSearch && getFiltersCount() == 0 && <p className="ml-2">Search and filter</p>}
                            {!showSearch && getFiltersCount() > 0 && (
                              <p className="ml-2">Search and filter ({getFiltersCount()})</p>
                            )}
                          </Button>
                          {getFiltersCount() > 0 && (
                            <Button
                              className="!text-charcoal-700 !border-l-0"
                              onClick={clearSettings}
                              renderIcon={() => <XCircleFilledIcon />}
                            ></Button>
                          )}
                        </div>

                        <ButtonGroup
                          value={mode}
                          onChange={(value: unknown) => {
                            if (typeof value === 'string') {
                              setMode(value);
                              localStorage.setItem('files-table-mode', value);
                            }
                          }}
                        >
                          {(
                            [
                              ['grid', GridIcon],
                              ['list', MenuIcon],
                            ] as const
                          ).map(([buttonMode, Icon]) => {
                            const active = mode === buttonMode;
                            return (
                              <Button
                                key={buttonMode}
                                value={buttonMode}
                                className={clsx('w-9 h-9 text-charcoal-700', active && 'bg-charcoal-50')}
                              >
                                <Icon />
                              </Button>
                            );
                          })}
                        </ButtonGroup>
                      </div>

                      <div className="flex-1 overflow-hidden">
                        <UploadDropWrapper onFileDrop={queueFiles} className="h-full" disabled={!canUpload}>
                          <FilesTable
                            folderId={folderId}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            filterSupported={filterSupported}
                            mode={mode}
                            query={query}
                            filters={filters}
                          />
                        </UploadDropWrapper>
                      </div>
                    </div>
                  </Panel>
                </PanelGroup>
              </Tab>
            </Tabs>
          </OasisErrorBoundary>
        )}
      </section>

      <SidePanelLayout.Panel isOpen={!!activeFileDetailsUrn}>
        {activeFileDetailsUrn && <FileDetailsPanel projectId={projectId} fileUrn={activeFileDetailsUrn} />}
      </SidePanelLayout.Panel>

      <SidePanelLayout.Panel isOpen={!!showSearch}>
        <FileSearchFiltersPanel
          setQuery={setQuery}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          setFilters={setFilters}
          setFilterSupported={setFilterSupported}
          filters={filters}
          query={query}
          key={filterKey.current}
          folderId={folderId}
          filterSupported={filterSupported}
        />
      </SidePanelLayout.Panel>

      <UploadManager projectId={projectId} folderId={activeFolderId} />
    </SidePanelLayout>
  );
}
