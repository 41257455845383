import type { Object } from 'ts-toolbelt';

export const DEFAULT_FIELDS_MAPPING = [
  { id: 'description', required: false, visible: true },
  { id: 'assignedTo', required: false, visible: true },
  { id: 'watchers', required: false, visible: true },
  { id: 'locationDetails', required: false, visible: true },
  { id: 'location', required: false, visible: true },
  { id: 'linkedDocuments', required: false, visible: true },
  { id: 'rootCauseId', required: false, visible: true },
  { id: 'startDate', required: false, visible: true },
  { id: 'dueDate', required: false, visible: true },
];

export type IssueField = Object.Optional<(typeof DEFAULT_FIELDS_MAPPING)[number], 'required' | 'visible'>;

export function getStatusLabel(status: string) {
  status = status.replaceAll('_', ' ');
  return status.charAt(0).toUpperCase() + status.slice(1);
}

export const STATUS_COLOR_CLASSES: Record<string, string> = {
  // ACC
  pending: 'bg-blue-500 text-white',
  in_progress: 'bg-[#a3bcdc] text-white',
  in_review: 'bg-purple-500 text-white',
  closed: 'bg-charcoal-200',
  completed: 'bg-green-500',
  // BIM360
  ready_to_inspect: 'bg-purple-500 text-white',
  work_completed: 'bg-purple-500 text-white',
  answered: 'bg-blue-500 text-white',
  // BOTH
  draft: 'bg-charcoal-800 text-white',
  open: 'bg-yellorange-500',
  in_dispute: 'bg-red-500 text-white',
  not_approved: 'bg-red-500 text-white',
  default: 'bg-charcoal-200',
};

export function getStatusColorClasses(status: string) {
  return STATUS_COLOR_CLASSES[status] || STATUS_COLOR_CLASSES.default;
}
