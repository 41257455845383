import { DatePicker } from '@adsk/alloy-react-date-picker';
import { DateUtils } from '@oasis/utils';
import { toDate } from 'date-fns-tz';
import { useMemo, type MouseEvent, type TouchEvent } from 'react';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useUpdateIssueContext } from '../context';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  name: string;
  label: string;
  value: string | null;
  onSubmit(date: Date | null): void;
  required?: boolean;
}

export function UpdateIssueDateField(props: Props) {
  const { permittedAttributes } = useUpdateIssueContext();

  const canEdit = permittedAttributes.includes(props.name);

  const dateValue = useMemo(() => {
    if (!props.value) {
      return null;
    }

    return toDate(props.value);
  }, [props.value]);

  const label = useMemo(() => {
    if (!props.value || !dateValue) {
      return <p className="text-charcoal-500">Unspecified</p>;
    }

    return <p>{DateUtils.getFriendlyFormat(dateValue)}</p>;
  }, [props.value, dateValue]);

  function stopProp(e: MouseEvent | TouchEvent) {
    e.stopPropagation();
  }

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        {props.label}
      </Label>

      <InlineEditable canEdit={canEdit}>
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <DatePicker
              open
              value={dateValue}
              triggerProps={{ className: '!w-full' }}
              calendarProps={{ onMouseDown: stopProp, onTouchStart: stopProp }}
              overlayProps={{ onMouseDown: stopProp, onTouchStart: stopProp }}
              onChange={({ date }) => {
                props.onSubmit(date ?? null);
                setIsEditing(false);
              }}
            />
          ) : (
            label
          );
        }}
      </InlineEditable>
    </div>
  );
}
