import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { Oasis } from '@oasis/sdk';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';

export default function OauthTrialHandlerPage() {
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    /**
     * Here we are checking the users license and if they're trial eligible.
     * 1. Check if the user has a valid license. If they do then just let them in.
     * 2. Check if the user is trial eligible. If they are, send them to the trial agreement page.
     * 3. They aren't eligible for a trial, so deny them access.
     *
     * We used to have 4 steps:
     * "Check if the user has a waitlist token. If they dont then deny them access." was #2 and has
     * been removed as we no longer have a waitlist and we've officially opened for GA.
     */
    async function checkAuth() {
      const currentUser = await Oasis.Session.handleOauthSearchParams();

      // Show an error if we cant authenticate a user.
      if (!currentUser.ok) {
        NotificationManager.push({
          status: 'error',
          content: 'Unable to authenticate user. Please try logging in again.',
        });

        return navigate('/trial', { replace: true });
      }

      const unlicensedEnv = ['beta', 'develop'].includes(Oasis.Env.store.releaseChannel);

      // 1. If the user has a valid license and have agreed to terms just let them in.
      if (currentUser.value.license?.valid || unlicensedEnv) {
        return navigate('/projects', { replace: true });
      }

      // 2. Check if the user is trial eligible. If they are, send them to the trial agreement page.
      setVerifying(true);
      const trialEligible = Oasis.Session.isTrialEligible();

      if (trialEligible) {
        return navigate('/trial-agreement', { replace: true });
      }

      // 3. They aren't eligible for a trial, so deny them access.
      NotificationManager.push({
        status: 'error',
        content: 'You are not eligible for a trial at this time.',
      });

      Oasis.Session.setLicense({ type: 'NO_ENTITLEMENT', trialEligible });
      return navigate('/trial', { replace: true });
    }

    checkAuth();
  }, [navigate]);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <ProgressRing size="large" />
        <p className="text-body-lg mt-5">{verifying ? 'Verifying trial eligibility...' : 'Signing you in...'}</p>
      </div>
    </div>
  );
}
