import { z } from 'zod';
import { CommonSchemas } from '../../lib/common.schemas';

export const UsersSchemas = {
  listActiveWorkshops: z.object({
    pagination: CommonSchemas.pagination,
    results: z
      .array(
        z.object({
          id: z.string(),
          permission: CommonSchemas.workshopPermission.optional().transform(value => value ?? 'VIEW'),
          fluidState: CommonSchemas.fluidState,
        })
      )
      .nullable(),
  }),

  listUsersDevices: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        name: z.string(),
        id: z.string(),
        userId: z.string(),
        type: z.string(),
        status: z.string(),
        deviceId: z.string(),
        lastUser: z.number().optional(),
        createdDate: z.number(),
      })
    ),
  }),

  trials: z.object({
    errorCode: z.number().nullable(),
    eligible: z.boolean(),
    reason: z.string().nullable(),
  }),
};
