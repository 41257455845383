import { z } from 'zod';
import { CommonSchemas } from '../../lib/common.schemas';

const _statusSchema = z.union([
  z.literal('pending'),
  z.literal('success'),
  z.literal('inprogress'),
  z.literal('failed'),
  z.literal('timeout'),
]);

export type ModelStatus = z.infer<typeof _statusSchema> | 'unknown';

export interface ManifestChild {
  guid: string;
  role: string;
  name?: string;
  urn?: string;
  children?: ManifestChild[];
}

const _derivativeChildSchema: z.ZodType<ManifestChild> = z.lazy(() =>
  z.object({
    guid: z.string(),
    role: z.string(),
    name: z.string().optional(),
    urn: z.string().optional(),
    children: _derivativeChildSchema.array().optional(),
  })
);

export const FilesSchemas = {
  getModelManifest: z.object({
    urn: z.string(),
    progress: z.string(),
    status: _statusSchema,
    hasThumbnail: z
      .string()
      .transform(x => x === 'true')
      .optional(),
    derivatives: z.array(
      z.object({
        name: z.string().optional(),
        hasThumbnail: z
          .string()
          .transform(x => x === 'true')
          .optional(),
        status: _statusSchema,
        children: _derivativeChildSchema.array(),
      })
    ),
  }),

  findDocumentById: z.object({
    data: z.object({
      id: z.string(),
      attributes: z.object({
        displayName: z.string(),
        createTime: z.string(),
        createUserName: z.string(),
        createUserId: z.string(),
        lastModifiedTime: z.string(),
        lastModifiedUserName: z.string(),
      }),
      relationships: z.object({
        parent: z.object({
          data: z.object({
            type: z.literal('folders'),
            id: z.string(),
          }),
        }),
        tip: z.object({
          data: z.object({
            type: z.literal('versions'),
            id: z.string(),
          }),
        }),
      }),
    }),
    included: z.array(
      z.object({
        id: z.string(),
        type: z.literal('versions'),
        attributes: z.object({
          fileType: z.string().optional(),
          extension: z.object({
            data: z.object({
              processState: CommonSchemas.processingState.optional(),
            }),
          }),
        }),
      })
    ),
  }),

  listDocumentsById: z.object({
    data: z.object({
      relationships: z.object({
        resources: z.object({
          data: z.array(
            z.object({
              id: z.string(),
              meta: z.object({
                attributes: z.object({
                  displayName: z.string(),
                }),
              }),
            })
          ),
        }),
      }),
    }),
  }),

  renameDocuments: z.object({
    urn: z.string(),
  }),

  moveDocuments: z.object({
    errors: z.array(z.unknown()),
    results: z.array(
      z.object({
        urn: z.string(),
      })
    ),
  }),

  copyDocuments: z.object({
    errors: z.array(z.unknown()),
    results: z.array(
      z.object({
        urn: z.string(),
      })
    ),
  }),

  findDocumentVersionById: z.object({
    data: z.object({
      id: z.string(),
      attributes: z.object({
        name: z.string(),
        fileType: z.string().optional(),
        extension: z.object({
          data: z.object({
            processState: z.string(),
            originalItemUrn: z.string().optional().nullable(),
          }),
        }),
      }),
      relationships: z.object({
        item: z.object({
          data: z.object({
            id: z.string(),
          }),
        }),
        derivatives: z.optional(
          z.object({
            data: z.object({
              id: z.string(),
            }),
          })
        ),
      }),
    }),
  }),

  findItemProcessingStatus: z.object({
    results: z.array(
      z.object({
        urn: z.string(),
        parentFolderUrn: z.string(),
        includedVersion: z.object({
          name: z.string(),
          processingState: CommonSchemas.processingState,
        }),
      })
    ),
  }),

  deleteFiles: z.object({
    errors: z.array(z.unknown()),
    results: z.array(
      z.object({
        urn: z.string(),
        isDeleted: z.boolean(),
      })
    ),
  }),

  createFolder: z.object({
    urn: z.string(),
  }),

  renameFolder: z.object({
    urn: z.string(),
  }),

  moveFolder: z.object({
    urn: z.string(),
  }),

  deleteFolders: z.object({
    errors: z.array(z.unknown()),
    results: z.array(
      z.object({
        urn: z.string(),
        isDeleted: z.boolean(),
      })
    ),
  }),

  fileTypes: z.object({
    results: z.array(
      z.object({
        name: z.literal('fileType'),
        values: z.array(
          z.object({
            value: z.string(),
          })
        ),
      })
    ),
  }),

  search: z.object({
    folders: z.array(
      z.object({
        urn: z.string(),
        name: z.string(),
        updatedAt: z.string(),
        updatedBy: z.string(),
        updatedByName: z.string(),
        storageSize: z.number().optional(),
      })
    ),

    documents: z.array(
      z.object({
        urn: z.string(),
        name: z.string(),
        updatedAt: z.string(),
        updatedBy: z.string(),
        updatedByName: z.string(),
        storageSize: z.number().optional(),
        includedVersion: z.object({
          urn: z.string(),
          name: z.string(),
          fileType: z.string().nullable().default(null),
          processingState: CommonSchemas.processingState,
        }),
        parentFolder: z.object({
          urn: z.string(),
          name: z.string(),
          parents: z.array(
            z.object({
              urn: z.string(),
              name: z.string(),
            })
          ),
        }),
      })
    ),
    pagination: z.object({
      totalPages: z.number(),
      pageNumber: z.number(),
    }),
  }),

  _createStorageLocation: z.object({
    jsonapi: z.object({
      version: z.literal('1.0'),
    }),
    data: z.object({
      type: z.literal('objects'),
      id: z.string(),
      relationships: z.object({
        target: z.object({
          data: z.object({
            type: z.literal('folders'),
            id: z.string(),
          }),
        }),
      }),
    }),
  }),

  _getSignedUploadUrls: z.object({
    uploadKey: z.string(),
    uploadExpiration: z.string(),
    urlExpiration: z.string(),
    urls: z.array(z.string()),
  }),

  _finalizeUpload: z.object({
    bucketKey: z.string(),
    objectId: z.string(),
    objectKey: z.string(),
    size: z.number(),
    contentType: z.string(),
    location: z.string(),
  }),

  _createDocument: z.object({
    document: z.object({
      id: z.string(),
      urn: z.string(),
      tipVersionUrn: z.string(),
    }),
  }),
};
