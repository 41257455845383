import { z } from 'zod';

export const TokenManagerSchemas = {
  createForgeXrToken: z.object({
    id: z.string(),
    name: z.string(),
    forgeXrToken: z.string(),
    forgeXrRefreshToken: z.string(),
    lastUsedMilli: z.number(),
  }),

  exchangeForgeXrToken: z.object({
    id: z.string(),
    name: z.string().optional(),
    oxygenToken: z.string(),
    lastUsedMilli: z.number(),
  }),

  refresh: z.object({
    forgeXrSessionId: z.string(),
    userId: z.string(),
    oxygenSessionId: z.string(),
    oxygenToken: z.string(),
    forgeXrToken: z.string(),
    forgeXrRefreshToken: z.string(),
  }),

  forkForgeXrTokens: z.object({
    id: z.string(),
    name: z.string(),
    oxygenToken: z.string(),
    forgeXrToken: z.string(),
    forgeXrRefreshToken: z.string(),
    lastUsedMilli: z.number(),
  }),
};
