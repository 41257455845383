import { z } from 'zod';

export const CommonSchemas = {
  userProfileSettings: z
    .object({
      version: z.string().optional(),
      displayName: z.string().optional(),
      companyName: z.string().optional(),
      companyType: z.string().optional(),
      jobTitle: z.string().optional(),
      phone: z.string().optional(),
    })
    .optional(),

  tauriUpdateStatus: z.union([
    z.literal('CHECKING'),
    z.literal('UPTODATE'),
    z.literal('PENDING'),
    z.literal('DOWNLOADED'),
    z.literal('DONE'),
    z.literal('ERROR'),
  ]),

  unrealUpdateStatus: z.union([
    z.literal('CHECKING'),
    z.literal('UPTODATE'),
    z.literal('PENDING'),
    z.literal('DOWNLOADING'),
    z.literal('UNPACKING'),
    z.literal('DONE'),
    z.literal('ERROR'),
  ]),

  unrealProcessStatus: z.union([z.literal('CLOSED'), z.literal('RUNNING'), z.literal('ERRORED')]),

  pagination: z.object({
    limit: z.number(),
    offset: z.number(),
    totalResults: z.number(),
  }),

  processingState: z.union([
    z.literal('NEEDS_PROCESSING'),
    z.literal('EXTRACTION_PENDING'),
    z.literal('PROCESSING'),
    z.literal('PROCESSING_COMPLETE'),
    z.literal('PROCESSING_ABORTED'),
  ]),

  workshopPermission: z.union([z.literal('VIEW'), z.literal('EDIT'), z.literal('MANAGE')]),

  fluidState: z
    .object({
      id: z.string().optional(),
    })
    .optional(),
};
