import { z } from 'zod';

export const HubsSchemas = {
  list: z.object({
    data: z.array(
      z.object({
        id: z.string(),
        attributes: z.object({
          name: z.string(),
          region: z.string(),
        }),
      })
    ),
  }),
};
