import { setup } from './main';

export default function (av) {
  console.log('registering the concurrent collaboration extension');
  /**
   * Extension description
   *
   * The extension id is: 'Autodesk.ConcurrentCollaboration'
   *
   * @example
   *   viewer.loadExtension('Autodesk.ConcurrentCollaboration', options)
   *
   * @memberof Autodesk.Viewing.Extensions
   * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration
   * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
   * @class
   */
  class ConcurrentCollaborationExtension extends av.Extension {
    /**
     * Autodesk.ConcurrentCollaborationExtension constructor
     * @constructor
     * @param {Autodesk.Viewing.Viewer3D} viewer - Viewer instance.
     * @param {Object} [options] Options for the ConcurrentCollaborationExtension
     * @param {boolean} [options.someOption=false] This is how options should be documented
     */
    constructor(viewer, options) {
      super(viewer, options);
      this.name = 'ConcurrentCollaboration';

      this._sessionID = undefined;
      this._sessionStartedCallbacks = new Set();
      this._sessionEndedCallbacks = [];
      this._updateUsersCallbacks = new Set();
      this._showLaserPointers = true;
      this._users = new Map();
      this._followedClientId = undefined;

      this.connectionManager = undefined;
      // this._geometries = options.getManifest().geometries;
    }

    // Pre-defined functions ===========================================================================================

    /**
     * Called by the viewer once for the lifetime of the extension (unload() ends it)
     * Perform all initializations here.
     * The viewer sends `EXTENSION_LOADED_EVENT` after the load succeeds.
     * @returns {boolean} True if the load was successful. Optionally, the function can return a Promise to indicate success (resolve) or failure (reject).
     * @memberof Autodesk.Viewing.Extensions.ConcurrentCollaboration
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#deactivate
     * @private
     */
    load() {
      super.load();

      // This function should not create any UI. That should be done inside of the onToolbarCreated method.
      return true;
    }

    /**
     * Override the unload method to perform some cleanup of operations that were done in load.
     * This function will be invoked when viewer.unloadExtension("Autodesk.ConcurrentCollaboration") is called.
     * @returns {boolean} True if the load was successful. Optionally, the function can return a Promise to indicate success (resolve) or failure (reject).
     * @memberof Autodesk.Viewing.Extensions.ConcurrentCollaboration
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#deactivate
     * @private
     */
    unload() {
      super.unload();
      return true;
    }

    /**
     * Override the deactivate method to disable the functionality of the extension.
     * @returns {boolean} True if the extension deactivation was successful.
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#deactivate
     */
    deactivate() {
      super.deactivate();
      return true;
    }

    /**
     * Gets the extension state as a plain object. Intended to be called when viewer state is requested.
     * @param {object} viewerState - Object to inject extension values.
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#getState
     * @private
     */
    getState(viewerState) {
      super.getState(viewerState);
    }

    /**
     * Restores the extension state from a given object.
     * @param {object} viewerState - Viewer state.
     * @param {boolean} immediate - Whether the new view is applied with (true) or without transition (false).
     * @returns {boolean} True if restore operation was successful.
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#restoreState
     * @private
     */
    restoreState(viewerState, immediate) {
      super.restoreState(viewerState, immediate);
      return true;
    }

    /**
     * Use this method to add new tools to the toolbar.
     * The method is called after viewer's {@link TOOLBAR_CREATED_EVENT} is fired or immediately if the Toolbar is already loaded in the viewer.
     *
     * Must be overriden by subclasses.
     *
     * @param {Autodesk.Viewing.UI.ToolBar} toolbar - toolbar instance.
     *
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#onToolbarCreated
     * @private
     */
    onToolbarCreated(toolbar) {
      super.onToolbarCreated(toolbar);
      return true;
    }

    // Custom functions ================================================================================================

    async startSession() {
      this._sessionID = await setup(this);
      console.log(`Started Session: ${this._sessionID}`);
      this._sessionStartedCallbacks.forEach(cb => cb(this._sessionID));
      return this._sessionID;
    }

    async joinSession(sessionID) {
      this._sessionID = sessionID;
      console.log(`Joining Session: ${this._sessionID}`);
      await setup(this, this._sessionID);
      this._sessionStartedCallbacks.forEach(cb => cb(this._sessionID));
    }

    async startFollow(clientId) {
      if (clientId && this._followedClientId !== clientId) {
        this._followedClientId = clientId;
        this.viewer.setActiveNavigationTool('followUser');
      } else {
        delete this._followedClientId;
        this.viewer.setActiveNavigationTool();
      }
    }

    get myself() {
      return this.connectionManager?.getMyself()?.userId;
    }

    getSessionId() {
      return this._sessionID;
    }

    updateUsers() {
      this._updateUsersCallbacks.forEach(cb => cb(this.users));
    }

    addUser(user, update = true) {
      // We shouldn't rely on this data to exist to determine the user's color
      // Collaborators in theory could join from a different app, which doesn't store this data in the same way and then the app will crash.
      // @TODO either we move this data to the shared state or we use signals to update the color.
      if (!this._users.has(user.userId) && user.additionalDetails && user.additionalDetails.hexColor) {
        this._users.set(user.userId, Object.assign(user, { rgbColor: user.additionalDetails.hexColor }));
        if (update) this.updateUsers();
      }
    }

    removeUser(user) {
      if (this._users.delete(user.userId)) {
        this.updateUsers();
        user.connections.forEach(connection => this.cursorsManager.remove(connection.id));
        this.viewer.impl.invalidate(/* needsClear */ true, /* needsRender */ false, /* overlayDirty */ true);
      }
    }

    get users() {
      return Array.from(this._users.values());
    }

    set showLaserPointers(show) {
      this._showLaserPointers = show;
      if (this.cursorsManager) {
        this.cursorsManager.showLaserPointers(show);
      }
    }

    get showLaserPointers() {
      return this._showLaserPointers;
    }

    addUpdateUsersCallback(callback) {
      this._updateUsersCallbacks.add(callback);
    }

    removeUpdateUsersCallback(callback) {
      this._updateUsersCallbacks.delete(callback);
    }

    addSessionStartedCallback(callback) {
      this._sessionStartedCallbacks.add(callback);
    }

    removeSessionStartedCallback(callback) {
      this._sessionStartedCallbacks.delete(callback);
    }
  }

  av.theExtensionManager.registerExtension('Autodesk.ConcurrentCollaboration', ConcurrentCollaborationExtension);
}
