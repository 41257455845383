import { NavigateFunction, SetURLSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

const navigate: NavigateFunction = () => {
  console.error('AppHistory.navigate is not initialized');
};

const setSearchParams: SetURLSearchParams = () => {
  console.error('AppHistory.setSearchParams is not initialized');
};

export const AppHistory = {
  navigate,
  setSearchParams,
  searchParams: new URLSearchParams(),
};

export function AppHistoryProvider() {
  const [searchParams, setSearchParams] = useSearchParams();

  AppHistory.navigate = useNavigate();
  AppHistory.setSearchParams = setSearchParams;
  AppHistory.searchParams = searchParams;

  return null;
}
