import { Oasis } from '@oasis/sdk';
import { onlineManager } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import DevPanel from '~/features/dev-mode/components/dev-panel';
import { Confirmation } from '~/shared/components/base/confirmation';
import { OasisErrorBoundary } from '~/shared/components/base/oasis-error-boundary';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { AppHistoryProvider } from '~/shared/utils/app-history';

// const UpdateLauncherModal = lazy(() => import('./components/modal.update-launcher'));
// const DesktopBridgeProvider = lazy(() => import('./components/desktop-bridge-provider'));

export default function RootLayout() {
  return (
    <OasisErrorBoundary>
      {/* Providers & Listeners */}
      <AppHistoryProvider />
      <_AuthStateProvider />
      <_VrNavigateCommandListener />
      <_DeviceContextListener />

      {/* Content */}
      <PageContent />
    </OasisErrorBoundary>
  );
}

function PageContent() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const online = useOnlineListener();
  const $mqttStore = Oasis.Mqtt.useStore();

  if (!online) {
    return <OasisErrorState code="OFFLINE" />;
  }

  /** If we're online and the mqtt status is disconnected, show an error state.
  /* @TODO - We should show show more specific error states for different mqtt scenarios for disconnection.
  /* E.g. when broker is down, when user is not authenticated, etc.
  /* @TODO -Similarly, we need to handle different fluid related errors.
  */
  if (online && $mqttStore.status === 'DISCONNECTED') {
    return <OasisErrorState code="RECONNECTING" />;
  }

  if (online && $session.status !== 'PENDING') {
    return (
      <>
        <Outlet />
        <Confirmation />

        {/* {Oasis.Bridge && (
          <>
            <Suspense fallback={null}>
              <DesktopBridgeProvider />
            </Suspense>
            <Suspense fallback={null}>
              <UpdateLauncherModal />
            </Suspense>
          </>
        )} */}

        {$env.isDevMode && <DevPanel />}
      </>
    );
  }

  return null;
}

function useOnlineListener() {
  const $env = Oasis.Env.useStore();
  const $mqtt = Oasis.Mqtt.useStore();
  const $session = Oasis.Session.useStore();

  useEffect(() => {
    onlineManager.setOnline($env.online);

    if (!$env.online) {
      Oasis.Env.pollForInternetConnection();
      return;
    }

    if ($mqtt.status === 'DISCONNECTED') {
      const interval = setInterval(() => Oasis.Mqtt.reconnect(), 1000);
      return () => clearInterval(interval);
    }

    if ($mqtt.status === 'PENDING') {
      const interval = setInterval(() => Oasis.Mqtt.connect(), 5000);
      return () => clearInterval(interval);
    }
  }, [$env.online, $mqtt.status]);

  // To prevent a flash of the offline message, wait to determine auth status first.
  if ($session.status === 'PENDING') {
    return true;
  }

  return $env.online;
}

/**
 * For the most part auth is state driven. There are 3 states, PENDING, AUTHENTICATED, and UNAUTHENTICATED.
 * When the state becomes UNAUTHENTICATED, the user is redirected to the login page via <ProtectedRoute />
 */
function _AuthStateProvider() {
  const $env = Oasis.Env.useStore();
  const [searchParams] = useSearchParams();
  const initAuth = useRef(true);

  const forgeXrToken = searchParams.get('forgeXrToken');
  const forgeXrRefreshToken = searchParams.get('forgeXrRefreshToken');

  useEffect(() => {
    let urlHasAuthTokenParams = false;

    if (forgeXrToken && forgeXrRefreshToken) {
      urlHasAuthTokenParams = true;
      Oasis.TokenManager.setForgeXrTokens({ forgeXrToken, forgeXrRefreshToken });
    }

    if (initAuth.current || urlHasAuthTokenParams) {
      initAuth.current = false;

      (async () => {
        // Oasis.Session.init will handle setting the session status after checking their token.
        const user = await Oasis.Session.init({ connect: true });

        // We need to remove the postAuthRedirect if the user is authenticated.
        // If they arent we'll store where they are so that we can redirect them after they login.
        if (user.ok) {
          Oasis.Storage.remove('postAuthRedirect');
        } else {
          Oasis.Storage.set('postAuthRedirect', window.location.href.replace(window.location.origin, ''));
        }
      })();
    }
  }, [forgeXrToken, forgeXrRefreshToken, $env.isVr]);

  return null;
}

function _VrNavigateCommandListener() {
  const $env = Oasis.Env.useStore();
  const location = useLocation();

  useEffect(() => {
    if ($env.isVr && location.state?.fromNavigateCommand) {
      Oasis.NetworkCommands.emitNavigationProcessed({
        path: location.state?.path,
      });
    }
  }, [$env.isVr, location]);

  return null;
}

function _DeviceContextListener() {
  const $env = Oasis.Env.useStore();

  useEffect(() => {
    document.body.classList.remove('context-web');
    document.body.classList.remove('context-desktop');
    document.body.classList.remove('context-vr');

    // It may be `vr-homespace` or `vr-workshop`
    if ($env.context.includes('vr')) {
      document.body.classList.add('context-vr');
    } else {
      document.body.classList.add(`context-${$env.context}`);
    }
  }, [$env.context]);

  return null;
}
