import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { ResetModelPositionIcon } from './reset-model-position-icon';

export function FileDetailsContextMenu() {
  return (
    <aside className="bg-white shadow-high min-w-[220px] rounded-md overflow-hidden" onClick={e => e.stopPropagation()}>
      <div className="border-b border-charcoal-100">
        <button
          className={clsx(
            'w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50',
            'disabled:hover:bg-transparent disabled:opacity-40 disabled:cursor-default'
          )}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            Oasis.NetworkCommands.emitResetModelTransform();
          }}
        >
          <div className="flex items-center justify-center w-8 h-6 mr-2">
            <ResetModelPositionIcon className="w-6 h-6" />
          </div>
          Reset position
        </button>
      </div>
    </aside>
  );
}
