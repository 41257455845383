import { ChevronRightIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { Key, ReactNode, useCallback, useMemo, useState } from 'react';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Combobox } from '~/shared/components/forms/combobox';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';

interface LocationItem {
  id: string;
  name: string;
  sublocations: LocationItem[];
}

interface Props {
  value?: string | null;
  onChange(value: string): void;
  required?: boolean;
}

export function CreateIssueLocationField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const combobox = Combobox.useComboboxProps();

  const locationsQuery = Queries.Projects.useListLocations({ projectId, platform });

  const locations = useMemo(() => {
    if (locationsQuery.data?.results) {
      const map: Record<string, LocationItem> = {};

      for (const location of locationsQuery.data.results) {
        map[location.id] = {
          id: location.id,
          name: location.name,
          sublocations: [],
        };
      }

      const root: LocationItem[] = [];

      for (const location of locationsQuery.data.results) {
        const locationWithChildren = map[location.id];

        if (!locationWithChildren) {
          continue;
        }

        if (location.parentId) {
          const parent = map[location.parentId];

          if (parent) {
            parent.sublocations.push(locationWithChildren);
          }
        } else {
          root.push(locationWithChildren);
        }
      }

      return root;
    }

    return [];
  }, [locationsQuery.data]);

  const findParentTree = useCallback(
    (id: string) => {
      if (!locationsQuery.data) {
        return [];
      }

      let parents: { id: string; name: string }[] = [];

      const parent = locationsQuery.data.results.find(location => {
        return location.id === id;
      });

      if (parent) {
        parents.push({ id: parent.id, name: parent.name });

        if (parent.parentId) {
          parents = [...findParentTree(parent.parentId), ...parents];
        }
      }

      return parents;
    },
    [locationsQuery.data]
  );

  const value = useMemo(() => {
    const location = locationsQuery.data?.results.find(location => location.id === props.value);
    const parents = location?.parentId ? findParentTree(location.id) : [];

    const [_rootParent, nonRootParent, ...restParents] = parents;
    const parentNodes: ReactNode[] = [];

    if (!location) {
      return <p className="text-charcoal-500">Select a location</p>;
    }

    if (nonRootParent && location?.id !== nonRootParent.id) {
      parentNodes.push(
        <span key={0} className="flex items-center">
          {nonRootParent?.name}
          {restParents.length > 0 && <ChevronRightIcon size={12} className="mx-2" />}
        </span>
      );
    }

    if (restParents.length > 1) {
      parentNodes.push(
        <span key={1} className="flex items-center">
          &hellip;
          <ChevronRightIcon size={12} className="mx-2" />
        </span>
      );
    }

    return (
      <Tooltip content={restParents.length ? parents.map(parent => parent.name).join(' > ') : undefined}>
        <div className="flex items-center text-left">
          {parentNodes}
          {location.name}
        </div>
      </Tooltip>
    );
  }, [findParentTree, locationsQuery.data, props.value]);

  // All projects will have a default "Project" location that we skip over.
  const locationCount = locations.reduce((acc, next) => {
    return (acc += 1 + next.sublocations.length);
  }, 0);

  if (!locationsQuery.isLoading && locationCount === 1) {
    return null;
  }

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        Location
      </Label>

      <Combobox {...combobox} name="locationId" placeholder="Select a location">
        <Combobox.TriggerButton className="flex items-center">{value}</Combobox.TriggerButton>

        <Combobox.Content>
          <Combobox.List>
            {locations.map(location => {
              return (
                <LocationListItem
                  key={location.id}
                  {...location}
                  activeId={props.value ?? ''}
                  onChange={value => {
                    combobox.setOpen(false);
                    props.onChange(value);
                  }}
                  isRoot
                />
              );
            })}
          </Combobox.List>
        </Combobox.Content>
      </Combobox>
    </div>
  );
}

interface LocationListItemProps extends LocationItem {
  activeId: string | null;
  key: Key;
  depth?: number;
  isRoot?: boolean;
  onChange(id: string): void;
}

function LocationListItem(props: LocationListItemProps) {
  const [isOpen, setIsOpen] = useState(props.isRoot);
  const isActive = props.id === props.activeId;
  const hasActive = props.sublocations.some(sublocation => sublocation.id === props.activeId);

  const effectiveDepth = props.depth || 0;

  return (
    <li className="flex flex-col flex-1">
      {!props.isRoot && (
        <div
          className={clsx(isActive && 'bg-blue-50 hover:bg-blue-50', !isActive && !hasActive && 'hover:bg-charcoal-50')}
          style={{ paddingLeft: `${effectiveDepth * 2}rem` }}
        >
          <Combobox.ItemBase
            value={props.id}
            isActive={isActive}
            onClick={() => props.onChange(props.id)}
            className="w-full"
          >
            <div className="flex items-center">
              {props.sublocations.length > 0 && (
                <div
                  role="button"
                  className="w-8 flex items-center justify-center pr-2 opacity-50 hover:opacity-100"
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                  }}
                >
                  <ChevronRightIcon size={16} className={clsx(isOpen && 'rotate-90')} />
                </div>
              )}

              <p>{props.name}</p>
            </div>
          </Combobox.ItemBase>
        </div>
      )}

      {isOpen && props.sublocations.length > 0 && (
        <ul className="w-full">
          {props.sublocations.map(sublocation => {
            return (
              <LocationListItem
                key={sublocation.id}
                {...sublocation}
                activeId={props.activeId}
                depth={props.isRoot ? 0 : effectiveDepth + 1}
                onChange={props.onChange}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
}
