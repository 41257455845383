import type { BrowserOptions } from '@sentry/react';
import * as sentry from '@sentry/react';

export const Sentry = {
  init(options: BrowserOptions) {
    return sentry.init({
      ...options,
      ignoreErrors: [
        /e.throwIfAborted/i, // Library AbortController error
      ],
    });
  },

  identify(analyticsId: string | null) {
    sentry.setUser(analyticsId ? { id: analyticsId } : null);
  },

  captureException: (...params: Parameters<typeof sentry.captureException>) => {
    sentry.captureException(...params);
    sentry.flush();
  },

  captureMessage: (...params: Parameters<typeof sentry.captureMessage>) => {
    sentry.captureMessage(...params);
    sentry.flush();
  },

  addIntegration: sentry.addIntegration,
  withScope: sentry.withScope,
};
