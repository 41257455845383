import { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

export function ExternalLinkIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={16} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14 11v3.221H2V2h3m9 5.333V2m0 0H9m5 0L8 8"
      />
    </svg>
  );
}
