import { CheckmarkIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { Combobox } from '~/shared/components/forms/combobox';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { getStatusColorClasses, getStatusLabel } from '~/shared/utils/const.issues';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  value: string;
  onChange(value: string): void;
}

export function CreateIssueStatusField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const combobox = Combobox.useComboboxProps();

  const label = getStatusLabel(props.value);
  const colorClasses = getStatusColorClasses(props.value);

  const userPermissions = Queries.Issues.useGetUserPermissions({ projectId, platform });
  const statusValues = userPermissions.data?.issues.new.permittedStatuses || [];

  return (
    <Combobox {...combobox} name="status" value={props.value} onChange={props.onChange}>
      <Combobox.TriggerButton>
        <div className="flex items-center">
          <div className={clsx('w-1 h-4 mr-2 rounded-full', colorClasses)} />
          {label}
        </div>
      </Combobox.TriggerButton>

      <Combobox.Content>
        <Combobox.List>
          {statusValues.map(status => {
            const isActive = status === props.value;

            return (
              <li key={status}>
                <Combobox.ItemBase value={status} className={clsx('flex items-center', isActive && 'bg-blue-50')}>
                  <div className={clsx('w-1 h-4 mr-2 rounded-full', getStatusColorClasses(status))} />
                  {getStatusLabel(status)}

                  <div className="w-20 ml-auto">{isActive && <CheckmarkIcon className="ml-auto text-blue-600" />}</div>
                </Combobox.ItemBase>
              </li>
            );
          })}
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  );
}
