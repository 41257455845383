import { CheckmarkIcon, PencilIcon, XIcon } from '@adsk/alloy-react-icon';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

interface Props {
  initialValue: string;
  onSubmit: (value: string) => void;
  disabled?: boolean;
  showBorder?: boolean;
  value?: string;
  setValue?: (value: string) => void;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  hideEditButton?: boolean;
  ignoreInitialValue?: boolean;
}

export function InlineEditText({ initialValue = '', disabled, onSubmit, ...props }: Props) {
  const containerRef = useRef<HTMLFormElement>(null);

  const [localValue, setLocalValue] = useState(initialValue);
  const [localIsEditing, setLocalIsEditing] = useState(false);

  const value = props.value || localValue;
  const setValue = props.setValue || setLocalValue;
  const isEditing = props.isEditing || localIsEditing;
  const setIsEditing = props.setIsEditing || setLocalIsEditing;

  useClickAway(containerRef, () => {
    if (isEditing) {
      setIsEditing(false);
      setValue(initialValue);
    }
  });

  if (!disabled && isEditing) {
    return (
      <form
        ref={containerRef}
        className="relative flex items-center flex-1"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onSubmit={e => {
          e.preventDefault();
          onSubmit(value);
          setIsEditing(false);
        }}
      >
        <input
          value={value}
          onInput={e => setValue(e.currentTarget.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSubmit(value);
              setIsEditing(false);
            }
          }}
          className="flex-1 pr-20 border border-charcoal-300 focus:border-blue-500 rounded-sm p-2 focus:outline-none"
          autoFocus
        />
        <div className="flex items-center absolute top-0 right-0 bottom-0 mr-2 space-x-1">
          <button
            type="button"
            onClick={() => {
              setIsEditing(false);
              setValue(initialValue);
            }}
            className="flex items-center justify-center w-6 h-6 text-blue-500 border border-charcoal-300 hover:border-charcoal-400 hover:shadow-halo-sm"
            title="Cancel"
          >
            <XIcon size={16} />
          </button>
          <button
            type="submit"
            disabled={props.ignoreInitialValue ? false : initialValue === value}
            onClick={() => {
              setIsEditing(false);
              onSubmit(value);
            }}
            className="flex items-center justify-center w-6 h-6 text-blue-500 border border-charcoal-300 hover:border-charcoal-400 hover:shadow-halo-sm disabled:opacity-25"
            title="Submit"
          >
            <CheckmarkIcon size={16} />
          </button>
        </div>
      </form>
    );
  }

  if (props.showBorder) {
    return (
      <button
        className="group inline-flex items-center max-w-full min-h-[2.375rem] border border-dashed border-transparent hover:border-blue-500 p-2"
        onClick={() => setIsEditing(true)}
      >
        <p className="max-w-full truncate">{value}</p>
        <div className="ml-3 w-5">
          <PencilIcon
            size={16}
            className="text-charcoal-700-500 group-hover:opacity-20 group-hover:hover:opacity-100"
          />
        </div>
      </button>
    );
  }

  if (props.hideEditButton) {
    return (
      <div className="group inline-flex items-center max-w-full min-h-[2.375rem]">
        <p className="max-w-full truncate">{value}</p>
      </div>
    );
  }

  return (
    <div className="group inline-flex items-center max-w-full min-h-[2.375rem]">
      <p className="max-w-full truncate">{value}</p>
      {!disabled && (
        <button
          onClick={e => {
            e.stopPropagation();
            setIsEditing(true);
          }}
          className="ml-3 w-5"
        >
          <PencilIcon
            size={16}
            className="text-charcoal-700-500 opacity-0 group-hover:opacity-20 group-hover:hover:opacity-100"
          />
        </button>
      )}
    </div>
  );
}
