import { ChevronDownIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { ComponentProps, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

interface Props {
  primaryAction: ComponentProps<'button'>;
  secondaryActions?: ComponentProps<'button'>[];
}

export function DropdownButtons({ primaryAction, secondaryActions }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickAway(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div ref={ref} className="relative flex z-0">
      <button
        {...primaryAction}
        className={clsx(
          'py-1 pr-4 pl-3 rounded-l-sm bg-blue-500 hover:bg-blue-600 text-white',
          primaryAction.className
        )}
        onClick={e => {
          e.stopPropagation();
          primaryAction.onClick?.(e);
        }}
      >
        {primaryAction.children}
      </button>

      <button
        className="ml-[1px] px-2 bg-blue-500 hover:bg-blue-600 text-white rounded-r-sm flex items-center"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <ChevronDownIcon className={clsx('transition-transform', isOpen && 'rotate-180')} />
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 pt-1 w-full">
          <div className="bg-white rounded-sm w-full">
            {secondaryActions?.map((action, index) => (
              <button
                key={index}
                {...action}
                className={clsx(
                  'w-full py-2 pr-4 pl-3 rounded-sm hover:bg-charcoal-100 disabled:opacity-20',
                  action.className
                )}
                onClick={e => {
                  e.stopPropagation();
                  action.onClick?.(e);
                }}
              >
                {action.children}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
