type SortDirection = 'asc' | 'desc';
type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export const ArrayUtils = {
  /**
   * @name sortAlphanumeric
   * Sorts a combination of string and number values
   */
  sortAlphanumeric(a: string | number, b: string | number, direction: SortDirection = 'asc') {
    const aVal = typeof a === 'string' ? a.toLowerCase() : a;
    const bVal = typeof b === 'string' ? b.toLowerCase() : b;

    if (aVal < bVal) return direction === 'asc' ? -1 : 1;
    if (aVal > bVal) return direction === 'asc' ? 1 : -1;
    return 0;
  },

  /**
   * @name sortBoolean
   * Sorts boolean values (true being 1, false being 0)
   */
  sortBoolean(a: boolean, b: boolean, direction: SortDirection = 'asc') {
    const aNum = Number(a);
    const bNum = Number(b);

    return direction === 'asc' ? bNum - aNum : aNum - bNum;
  },

  /**
   * @name sortDateString
   * Sorts two datestrings
   */
  sortDateString(a: string | number, b: string | number, direction: SortDirection = 'asc') {
    const aTime = new Date(a).getTime();
    const bTime = new Date(b).getTime();

    return direction === 'asc' ? bTime - aTime : aTime - bTime;
  },

  /**
   * @name truthy
   * Allows for typesafe filtering of truthy values.
   */
  truthy<T>(value: T): value is Truthy<T> {
    return !!value;
  },

  /**
   * @name containsSameItems
   * Determines if the two string arrays contain the same items in any order.
   */
  containsSameItems(a: string[], b: string[]) {
    if (a.length !== b.length) {
      return false;
    }

    a = a.sort(ArrayUtils.sortAlphanumeric);
    b = b.sort(ArrayUtils.sortAlphanumeric);

    return a.every((val, index) => val === b[index]);
  },
};
