import Illustration from '@adsk/alloy-react-illustration';
import clsx from 'clsx';
import { ComponentProps, useState } from 'react';
import Dropzone from 'react-dropzone';

interface Props extends ComponentProps<'div'> {
  onFileDrop(files: File[]): void;
}

export function UploadDropArea({ onFileDrop, ...props }: Props) {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Dropzone
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDrop={acceptedFiles => {
        setIsDragging(false);
        onFileDrop(acceptedFiles);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...props}
          {...getRootProps()}
          className={clsx(
            'flex flex-col border border-dashed rounded-sm flex items-center justify-center',
            isDragging ? 'border-blue-500 bg-blue-100 bg-opacity-10' : 'border-charcoal-600',
            props.className
          )}
          tabIndex={-1}
        >
          <input {...getInputProps()} />

          <Illustration
            type="pageClosedFistGrey"
            className={clsx('transition-transform duration-100', isDragging && 'scale-105')}
          />

          {isDragging ? (
            <p className="text-blue-700 font-bold">Drop files to upload</p>
          ) : (
            <p className="text-charcoal-500">
              Drag files or <span className="font-bold text-blue-500 cursor-pointer">click here</span>
            </p>
          )}
        </div>
      )}
    </Dropzone>
  );
}
