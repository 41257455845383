import { Oasis } from '@oasis/sdk';
import { useQuery } from '@tanstack/react-query';
import { OasisError } from '~/shared/utils/oasis-error';

export const hubsQueries = {
  useList(opts?: { enabled?: boolean; staleTime?: number; refetchOnWindowFocus?: boolean; refetchInterval?: number }) {
    return useQuery({
      queryKey: ['hubs'],
      queryFn: async () => {
        const res = await Oasis.Hubs.list();
        if (!res.ok) throw new OasisError(res.error, '[Hubs.useList]');
        return res.value;
      },
      ...opts,
    });
  },
};
