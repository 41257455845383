import { Fluid } from './providers/fluid/fluid.provider';
import { ApsHttp } from './providers/http/aps-http.provider';
import { MessagesProvider } from './providers/messages/messages.provider';
import { Mqtt } from './providers/mqtt/mqtt.provider';
import { Pendo } from './providers/pendo/pendo.provider';
import { Segment } from './providers/segment/segment.provider';
import { Sentry } from './providers/sentry/sentry.provider';
import { Storage } from './providers/storage/storage.provider';
import { Analytics } from './services/analytics/analytics.service';
import type { BridgeService } from './services/bridge/bridge.service';
import { Dcv } from './services/dcv/dcv.service';
import { Debug } from './services/debug/debug.service';
import { Env } from './services/env/env.service';
import { FeatureFlags } from './services/feature-flags/feature-flags.service';
import { Files } from './services/files/files.service';
import { Hubs } from './services/hubs/hubs.service';
import { Issues } from './services/issues/issues.service';
import { Logger } from './services/logger/logger.service';
import { NetworkCommands } from './services/network-commands/network-commands.service';
import { Projects } from './services/projects/projects.service';
import { Releases } from './services/releases/releases.service';
import { Session } from './services/session/session.service';
import { TokenManager } from './services/token-manager/token-manager.service';
import { Users } from './services/users/users.service';
import { Voice } from './services/voice/voice.service';
import { Workshops } from './services/workshops/workshops.service';

let Bridge: BridgeService | undefined;

export const Oasis = {
  // Services
  Analytics,
  Bridge,
  Dcv,
  Debug,
  Env,
  FeatureFlags,
  Files,
  Hubs,
  Issues,
  Logger,
  NetworkCommands,
  Projects,
  Releases,
  Session,
  TokenManager,
  Users,
  Voice,
  Workshops,

  // Providers (try to expose via service methods when possible)
  ApsHttp,
  Fluid,
  MessagesProvider,
  Mqtt,
  Pendo,
  Segment,
  Sentry,
  Storage,
};
