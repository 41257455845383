import Button from '@adsk/alloy-react-button/es/Button';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { Oasis } from '@oasis/sdk';
import { useEffect, type MouseEvent } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Mutations } from '~/shared/hooks/mutations';
import { useModal } from '~/shared/hooks/use-modal';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { pairedDeviceIlloUrl } from '~/shared/utils/const.asset-urls';

export function OpenWorkshopOnHeadsetModal() {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const modal = useModal('open-workshop-headset');
  const pairingModal = useModal('pair-headset');
  const branding = useTemporaryBranding();
  const openWorkshop = Mutations.Workshops.useOpenWorkshopOnDevice();

  const id = searchParams.get('workshopId');
  const emitOpenOnDevice = openWorkshop.mutate;

  useEffect(() => {
    if (!modal.isOpen) {
      return;
    }

    if (!id) {
      return NotificationManager.push({
        status: 'warning',
        content: 'Unable to determine workshop destination.',
      });
    }

    emitOpenOnDevice({ device: 'vr', id });
  }, [modal.isOpen, id, emitOpenOnDevice]);

  async function getPairingCode(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    const pairingCode = await Oasis.Session.getPairingCode();

    if (!pairingCode.ok) {
      NotificationManager.push({
        status: 'error',
        content: (
          <p>
            <span className="font-bold">Pairing code</span> failed to generate. Try again.
          </p>
        ),
      });
      return;
    }

    pairingModal.open({ code: pairingCode.value });
  }

  const hasVrDevice = Oasis.Storage.get('hasVrDevice') === 'true';

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} className="!h-auto" width={468}>
      <Modal.Header>
        <h2>Workshop sent to headset</h2>
        <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} />
      </Modal.Header>
      <Modal.Body className="!h-auto !pt-6 !pb-10 flex flex-col items-center justify-center text-center">
        <img src={pairedDeviceIlloUrl} alt="VR headset illustartion" className="ml-5" />

        {hasVrDevice ? (
          <p className="text-charcoal-700 px-10">
            {'Put on your headset and open the ' + branding.productName + ' app to enter this workshop in VR.'}
          </p>
        ) : (
          <>
            <p className="text-charcoal-700 px-10">
              If you haven&apos;t already, pair a headset to enter this workshop in VR.
            </p>
            <p className="mt-6">
              <Button
                as="a"
                href={`/pair?from=${encodeURIComponent(pathname + search)}`}
                variant="primary"
                onClick={getPairingCode}
              >
                Pair headset
              </Button>
            </p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
