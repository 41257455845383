import { Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { ApsHttp } from '../../providers/http/aps-http.provider';
import { HubsSchemas } from './hubs.schemas';

const _parse = HttpUtils.createScopedParser('Hubs', HubsSchemas);

export const Hubs = {
  async list() {
    try {
      const res = await ApsHttp.client.get('project/v1/hubs').json();
      const { data } = _parse('list', res);
      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Hubs.list]');
    }
  },
};
