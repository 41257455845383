import { Key, ReactNode } from 'react';

interface Props {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
  children: ReactNode;
  key?: Key;
}

export function ConditionalWrapper({ children, ...props }: Props) {
  return props.condition ? props.wrapper(children) : children;
}
