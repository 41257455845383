import Button from '@adsk/alloy-react-button/es/Button';
import FormField from '@adsk/alloy-react-form-field/es/FormField';
import { AlertInformationIcon } from '@adsk/alloy-react-icon';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import type { FolderTreeSelectionItem } from '~/shared/components/base/folder-tree/types';
import { ModelSelectionTable } from '~/shared/components/base/model-selection-table';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Tooltip } from '~/shared/components/base/tooltip';
import { TextInput } from '~/shared/components/forms/text-input';
import { Mutations } from '~/shared/hooks/mutations';
import { useModal } from '~/shared/hooks/use-modal';
import { workshopScene } from '~/shared/utils/const.asset-urls';
import { useWorkshopDetails } from '../hooks/use-workshop-details';

const FORM_NAME = 'create-workshop-form';

const formSchema = z.object({
  name: z.string().min(1, { message: 'Workshop name is required' }),
  environment: z.union([z.literal('Environment 1'), z.literal('Environment 2'), z.literal('Environment 3')]),
});

type FormValues = z.infer<typeof formSchema>;

export function CreateWorkshopModal() {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const params = useParams() as { projectId: string };
  const { showPanel } = useWorkshopDetails();
  const modal = useModal('create-workshop');

  const [selection, setSelection] = useState<FolderTreeSelectionItem | undefined>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      environment: 'Environment 1',
    },
  });

  const createWorkshop = Mutations.Workshops.useCreateWorkshop();
  const updateSettingsModel = Mutations.Workshops.useUpdateWorkshopSettingsModel();

  async function onSubmit(values: FormValues) {
    if (!params.projectId) {
      return NotificationManager.push({
        status: 'error',
        content: 'Must be in a project to create a workshop.',
      });
    }

    createWorkshop.mutate(
      {
        projectId: params.projectId,
        attrs: values,
      },
      {
        async onSuccess(data) {
          form.reset();

          if (!selection) {
            return showPanel(data.id, 'details');
          }

          updateSettingsModel.mutate(
            {
              projectId: params.projectId,
              workshopId: data.id,
              documentUrn: selection.urn,
              viewGuid: selection.viewGuid,
              currentSettingsVersion: 0,
            },
            {
              onSettled() {
                showPanel(data.id, 'details');
              },
            }
          );
        },
      }
    );
  }

  if (!modal.isOpen || $session.license.type === 'FREE_VIEWER') {
    return null;
  }

  function close() {
    modal.close();
    form.reset();
  }

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} width={576} className="!h-auto">
      <Modal.Header>
        <h2>Create workshop</h2>
        <Modal.Close showEscapeLabel={false} onClick={close} />
      </Modal.Header>
      <Modal.Body className={clsx('!py-6 !pb-10', $env.isVr ? 'max-h-[500px]' : 'max-h-[578px]')}>
        <form id={FORM_NAME} onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex items-center gap-4">
            <img
              src={workshopScene}
              alt="Default workshop scene"
              className="block w-32 rounded border border-charcoal-200"
            />

            <Controller
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormField label="Name" labelVariant="top" required error={fieldState.error?.message}>
                  <TextInput {...field} />
                </FormField>
              )}
            />
          </div>

          <div>
            <p className="text-label-sm font-medium text-charcoal-800 mb-0.5">
              Add table content <span className="text-charcoal-400">(RVT or NWD)</span>
            </p>
            <p className="flex text-label-sm text-charcoal-500 mb-4">
              Revit 3D views will be displayed after selecting a file.
              <Tooltip
                placement="right"
                content="Currently only Revit 3D View model geometry is supported. Camera position will be ignored."
                className="inline-flex max-w-52"
              >
                <AlertInformationIcon size={16} className="ml-1 mr-2 text-charcoal-700" />
              </Tooltip>
            </p>
            <ModelSelectionTable projectId={params.projectId} selectedItem={selection} onChange={setSelection} />
          </div>

          {/* <Label className="mt-4 mb-3">Select a workshop environment</Label>
          <Controller
            control={form.control}
            name="environment"
            render={({ field }) => (
              <ul role="radiogroup" className="grid grid-cols-3 items-center space-x-4">
                {ENVIRONMENTS.map(env => {
                  const isChecked = env.name === field.value;

                  return (
                    <li key={env.name}>
                      <button
                        type="button"
                        role="radio"
                        aria-checked={isChecked}
                        className="rounded-md bg-white border border-charcoal-200 group"
                        onClick={() => form.setValue('environment', env.name)}
                      >
                        <div className="relative border-b border-charcoal-200">
                          <img src={env.img} className="w-full h-full object-cover rounded-t" alt="" />
                        </div>

                        <div className="p-3 flex items-center text-body-sm">
                          <RadioButton checked={isChecked} className="mr-2" />
                          {env.name}
                        </div>
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
          /> */}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex items-center justify-between">
          {selection && (
            <p className="flex items-center">
              {selection.type === 'model' && <p>Model selected</p>}
              {selection.type === 'view' && <p>3D view selected</p>}
              <button className="ml-2 text-blue-700 hover:underline" onClick={() => setSelection(undefined)}>
                Clear
              </button>
            </p>
          )}

          <div className="ml-auto flex gap-1">
            <Button variant="tertiary" onClick={close}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" loading={createWorkshop.isPending} form={FORM_NAME}>
              Create
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
