import { z } from 'zod';
import { CommonSchemas } from '../../lib/common.schemas';

export const ProjectsSchemas = {
  listProjects: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        accountId: z.string(),
        accountName: z.string(),
        name: z.string(),
        createdAt: z.string().datetime(),
        updatedAt: z.string().datetime(),
        platform: z.union([z.literal('acc'), z.literal('bim360')]),
      })
    ),
  }),

  findProjectById: z.object({
    account_id: z.string(),
    account_display_name: z.string(),
    normal_folder_urn: z.string().nullable(),
    root_urn: z.string().nullable(),
    issue_container_id: z.string().nullable(),
  }),

  listTopFolders: z.object({
    data: z.array(
      z.object({
        id: z.string(),
        attributes: z.object({
          name: z.string(),
        }),
      })
    ),
  }),

  listFolders: z.object({
    folders: z.array(
      z.object({
        has_subfolders: z.boolean(),
        parent_urn: z.string(),
        path: z.string(),
        urn: z.string(),
        title: z.string(),
        parents: z
          .array(
            z.object({
              is_root: z.boolean(),
              parent_urn: z.string(),
              title: z.string(),
              urn: z.string(),
            })
          )
          .optional(),
      })
    ),
  }),

  findFolderById: z.object({
    attributes: z.object({
      urn: z.string(),
      title: z.string(),
      path: z.string(),
      has_subfolders: z.boolean(),
      permission_actions: z.array(z.string()),
    }),
    folders: z.array(
      z.object({
        urn: z.string(),
        title: z.string(),
        has_subfolders: z.boolean(),
      })
    ),
  }),

  listAdminUsers: z.object({
    pagination: CommonSchemas.pagination,
    results: z
      .array(
        z.object({
          id: z.string(),
          email: z.string(),
          name: z.string().nullable(),
          autodeskId: z.string().nullable(),
          companyName: z.string().nullable(),
          phone: z
            .object({
              number: z.string().optional(),
              phoneType: z.string().optional(),
            })
            .optional(),
        })
      )
      .nullable(),
  }),

  listFolderContents: z.object({
    data: z.array(
      z.union([
        z.object({
          id: z.string(),
          type: z.literal('folders'),
          attributes: z.object({
            displayName: z.string(),
            objectCount: z.number().default(0),
            lastModifiedTime: z.string(),
            lastModifiedUserName: z.string(),
            lastModifiedUserId: z.string(),
          }),
        }),
        z.object({
          id: z.string(),
          type: z.literal('items'),
          attributes: z.object({
            displayName: z.string(),
            fileType: z.string().nullable().default(null),
            lastModifiedTime: z.string(),
            lastModifiedUserName: z.string(),
            lastModifiedUserId: z.string(),
          }),
          relationships: z.object({
            tip: z.object({
              data: z.object({
                type: z.literal('versions'),
                id: z.string(),
              }),
            }),
          }),
        }),
      ])
    ),
    included: z
      .array(
        z.object({
          id: z.string(),
          type: z.literal('versions'),
          attributes: z.object({
            displayName: z.string(),
            storageSize: z.number().optional(),
            extension: z.object({
              data: z.object({
                processState: CommonSchemas.processingState,
              }),
            }),
          }),
          relationships: z.object({
            thumbnails: z.object({
              data: z.object({
                id: z.string(),
              }),
            }),
          }),
        })
      )
      .optional(),
  }),

  listProjectUsers: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        autodeskId: z.string().nullable(),
        companyId: z.string().nullable(),
        companyName: z.string().nullable(),
        email: z.string(),
        id: z.string(),
        imageUrl: z.string().nullable(),
        industry: z.string().nullable(),
        jobTitle: z.string().nullable(),
        name: z.string().nullable(),
      })
    ),
  }),

  listLocations: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        parentId: z.string().nullable(),
        name: z.string(),
        order: z.number(),
      })
    ),
  }),

  listRoles: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        accountId: z.string(),
        name: z.string(),
        memberGroupId: z.string(),
        status: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
      })
    ),
  }),

  listCompanies: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        accountId: z.string(),
        status: z.string(),
        memberGroupId: z.string(),
        name: z.string(),
      })
    ),
  }),
};
