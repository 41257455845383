import { Oasis } from '@oasis/sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationManager } from '~/shared/components/base/notification-manager';

export const filesMutations = {
  useCreateFolder() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.createFolder>[0]) {
        const res = await Oasis.Files.createFolder(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to create folder.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to create a folder.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },

  useDeleteFiles() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.deleteFiles>[0]) {
        const res = await Oasis.Files.deleteFiles(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to delete files. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to delete these files.",
                NOT_FOUND: 'These files were not found.',
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },

  useDeleteFolders() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.deleteFolders>[0]) {
        const res = await Oasis.Files.deleteFolders(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to delete folders. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to delete these folders.",
                NOT_FOUND: 'These folders were not found.',
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },

  useMoveDocuments() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.moveDocuments>[0]) {
        const res = await Oasis.Files.moveDocuments(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to move. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to move these documents.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        NotificationManager.push({
          status: 'success',
          content: 'Document moved successfully.',
        });

        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },

  useMoveFolder() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.moveFolder>[0]) {
        const res = await Oasis.Files.moveFolder(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to move folder. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to move this folder.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },

  useRenameFolder() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.renameFolder>[0]) {
        const res = await Oasis.Files.renameFolder(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to rename folder. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to rename this folder.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },

  useRenameDocuments() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Files.renameDocuments>[0]) {
        const res = await Oasis.Files.renameDocuments(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to move rename document. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to rename this document.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'items'] });
        queryClient.invalidateQueries({ queryKey: ['projects', params.projectId, 'folders'] });
      },
    });
  },
};
