import { FileSearchFilters, Oasis } from '@oasis/sdk';
import { Platforms } from '@oasis/utils';
import { skipToken, useQuery } from '@tanstack/react-query';
import { OasisError } from '~/shared/utils/oasis-error';
import { OasisParams } from '~/types';
import { useFeatureFlags } from '../use-feature-flags';

export const filesQueries = {
  useGetModelManifest(params: OasisParams<'Files', 'getModelManifest'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'versions', params.documentVersionId, 'manifest'],
      queryFn: async () => {
        const res = await Oasis.Files.getModelManifest(params);
        if (!res.ok) throw new OasisError(res.error, '[Files.useGetModelManifest]');
        return res.value;
      },
    });
  },

  useList3dViews(params: { projectId: string; documentId: string; enabled?: boolean }) {
    const file = filesQueries.useFindDocumentById(params);
    const enabled = typeof params.enabled === 'boolean' ? params.enabled : true;
    const documentVersionId = file.data?.latestVersionId;

    const query = useQuery({
      queryKey: ['projects', params.projectId, 'documents', params.documentId, 'manifest', '3d-views'],
      queryFn:
        enabled && documentVersionId
          ? async () => {
              const res = await Oasis.Files.list3dViews({ ...params, documentVersionId });
              if (!res.ok) throw new OasisError(res.error, '[Files.useList3dViews]');
              return res.value;
            }
          : skipToken,
      networkMode: 'offlineFirst',
    });

    return { ...query, isFetching: file.isFetching || query.isFetching };
  },

  useFindFolderById(
    params: OasisParams<'Projects', 'findFolderById'> & {
      opts?: { enabled?: boolean; active?: boolean };
    }
  ) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'folders', params.folderId],
      queryFn: async () => {
        const res = await Oasis.Projects.findFolderById(params);
        if (!res.ok) throw new OasisError(res.error, '[Files.useFindFolderById]');
        return res.value;
      },
      enabled: params.opts?.enabled,
      refetchOnWindowFocus: params.opts?.active,
    });
  },

  useListFolderContents(
    params: OasisParams<'Projects', 'listFolderContents'> & {
      opts?: { enabled?: boolean };
    }
  ) {
    const enableAllFileTypes = useFeatureFlags('231128-4667-enable-all-file-types');

    return useQuery({
      queryKey: ['projects', params.projectId, 'folders', params.folderId, 'content', params.filter],
      queryFn: async () => {
        const res = await Oasis.Projects.listFolderContents(params);

        if (!res.ok) {
          throw new OasisError(res.error, '[Files.useListFolderContents]');
        }

        if (enableAllFileTypes) {
          return res.value.map(file => ({ ...file, supportedInLMV: true, supportedInVR: true }));
        }

        return res.value;
      },
      enabled: params.opts?.enabled,
    });
  },

  useListDocumentsById(params: OasisParams<'Files', 'listDocumentsById'> & { opts?: { enabled?: boolean } }) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'items', params.urns],
      queryFn: async () => {
        const res = await Oasis.Files.listDocumentsById(params);
        if (!res.ok) throw new OasisError(res.error, '[Files.useListDocumentsById]');
        return res.value;
      },
      enabled: Boolean(params.urns.length),
    });
  },

  useFindDocumentById(params: OasisParams<'Files', 'findDocumentById'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'items', params.documentId],
      queryFn: params.documentId
        ? async () => {
            const res = await Oasis.Files.findDocumentById(params);
            if (!res.ok) throw new OasisError(res.error, '[Files.useFindDocumentById]');
            return res.value;
          }
        : skipToken,
    });
  },

  useFindDocumentVersionById(params: OasisParams<'Files', 'findDocumentVersionById'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'versions', params.documentVersionId],
      queryFn: params.documentVersionId
        ? async () => {
            const res = await Oasis.Files.findDocumentVersionById(params);
            if (!res.ok) throw new OasisError(res.error, '[Files.useFindDocumentVersionById]');
            return res.value;
          }
        : skipToken,
    });
  },

  useSearchFiles(
    params: OasisParams<'Files', 'search'> & {
      opts?: {
        page?: number;
        enabled?: boolean;
      };
    }
  ) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'items', params],
      queryFn: async () => {
        const res = await Oasis.Files.search(params);
        if (!res.ok) throw new OasisError(res.error, '[Files.useSearchFiles]');
        return res.value;
      },
      enabled: params.opts?.enabled,
    });
  },

  useListFilesFoldersUsingSearch(params: {
    projectId: string;
    platform: Platforms;
    folderId: string;
    filter?: {
      type?: ('folders' | 'items')[];
    };
    fileTypes?: string[];
    sort?: { field: string; order: 'asc' | 'desc' }[];
    query?: string;
    page?: number;
    filters: FileSearchFilters;
  }) {
    const enableAllFileTypes = useFeatureFlags('231128-4667-enable-all-file-types');

    return useQuery({
      queryKey:
        params.query != '' ||
        params.filters.sourceIdType.length != 0 ||
        params.filters.fileType.length != 0 ||
        params.filters.includeTopFolders
          ? ['projects', params.projectId, 'items', params]
          : ['projects', params.projectId, 'folders', params.folderId, 'content', params.filter],
      queryFn: params.folderId
        ? async () => {
            if (
              params.query != '' ||
              params.filters.sourceIdType.length != 0 ||
              params.filters.fileType.length != 0 ||
              params.filters.includeTopFolders
            ) {
              const res = await Oasis.Files.listFilesFoldersUsingSearch({
                projectId: params.projectId,
                folderUrns: params.folderId ? [params.folderId] : [],
                query: params.query,
                sort: params.sort,
                filters: params.filters,
              });
              if (!res.ok) throw new OasisError(res.error, '[Files.listFilesFoldersUsingSearch]');
              return res.value;
            }

            const res = await Oasis.Projects.listFolderContents(params);
            if (!res.ok) {
              throw new OasisError(res.error, '[Files.listFolderContents]');
            }
            if (enableAllFileTypes) {
              return res.value.map(file => ({ ...file, supportedInLMV: true, supportedInVR: true }));
            }
            return res.value;
          }
        : skipToken,
    });
  },

  useListFileTypesForSearch(params: OasisParams<'Files', 'listFileTypesForSearch'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'items', params],
      queryFn: params.folderUrns.length
        ? async () => {
            const res = await Oasis.Files.listFileTypesForSearch(params);
            if (!res.ok) throw new OasisError(res.error, '[Files.listFileTypesForSearch]');
            return res.value;
          }
        : skipToken,
    });
  },
};
