import { Oasis, WorkshopsSchemas } from '@oasis/sdk';
import { AsyncQueue } from '@oasis/utils';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { z } from 'zod';

type ActiveUsers = z.infer<typeof WorkshopsSchemas.listActiveUsers>['results'];

export const workshopUsers = proxyMap<string, ActiveUsers>();
const queue = new AsyncQueue(3, 50);

export function useWorkshopUsersFetcher(workshopId: string) {
  useEffect(() => {
    let controller: AbortController | null = null;

    function getActiveUsers() {
      queue.push(async () => {
        controller = new AbortController();

        const res = await Oasis.Workshops.listActiveUsers(workshopId, {
          signal: controller.signal,
        });

        if (res.ok && res.value.results.length) {
          workshopUsers.set(workshopId, res.value.results);
        } else if (workshopUsers.has(workshopId)) {
          workshopUsers.delete(workshopId);
        }
      });
    }

    getActiveUsers();
    // const interval = setInterval(getActiveUsers, 30_000);

    return () => {
      controller?.abort();
      // clearInterval(interval);
      queue.dump();
    };
  }, [workshopId]);
}

export function useWorkshopActiveUsers() {
  return useSnapshot(workshopUsers);
}
