import {
  createClient,
  createMicrophoneAudioTrack,
  type ConnectionState,
  type IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng/esm';

export const Agora = {
  client: createClient({ mode: 'rtc', codec: 'vp8' }),

  createMicrophoneTrack: createMicrophoneAudioTrack,

  init(params: { onStatusChange(status: ConnectionState): void }) {
    this.client.on('connection-state-change', params.onStatusChange);

    this.client.on('user-published', async (user: IAgoraRTCRemoteUser) => {
      const track = await this.client.subscribe(user, 'audio');
      track.play();
    });

    this.client.on('user-unpublished', (user: IAgoraRTCRemoteUser) => {
      this.client.unsubscribe(user, 'audio');
    });
  },

  async connect(params: { channel: string; channelToken: string; uid: string }) {
    const appId = '6b9b3304fe9f41dc97e028c512286f8e';
    return Agora.client.join(appId, params.channel, params.channelToken, params.uid);
  },

  disconnect() {
    return Agora.client.leave();
  },
};
