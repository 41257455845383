import { CheckmarkIcon, PencilIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Dropdown } from '~/shared/components/forms/dropdown';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useUpdateIssueContext } from './context';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  value: string | number | null;
  options: { id: string; value: string }[];
  name: string;
  label: string;
  onSubmit(value: string | number | null): void;
  customAttribute?: boolean;
}

export function UpdateIssueListField(props: Props) {
  const { permittedAttributes } = useUpdateIssueContext();

  const canEdit = props.customAttribute || permittedAttributes.includes(props.name);

  const label = useMemo(() => {
    const activeOption = props.options.find(option => option.id === props.value);

    if (activeOption) {
      return <p>{activeOption.value}</p>;
    }

    return <p className="text-charcoal-500">Unspecified</p>;
  }, [props]);

  return (
    <div>
      <Label className="mb-2">{props.label}</Label>

      <InlineEditable canEdit={canEdit}>
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <Dropdown open>
              <Dropdown.Trigger className="flex items-center p-2 border border-blue-500 shadow-halo-sm shadow-blue-100 rounded">
                {label}
                <PencilIcon className="ml-3" size={16} />
              </Dropdown.Trigger>

              <Dropdown.Content onClick={e => e.stopPropagation()}>
                <Dropdown.RadioGroup
                  onValueChange={nextValue => {
                    props.onSubmit(nextValue);
                    setIsEditing(false);
                  }}
                >
                  {props.options.map(option => {
                    const isActive = props.value && option.id === props.value;

                    return (
                      <Dropdown.RadioItem
                        key={option.id}
                        value={option.id}
                        className={clsx('flex items-center', isActive && 'bg-blue-50')}
                      >
                        {option.value}
                        <div className="w-20 ml-auto">
                          {isActive && <CheckmarkIcon className="ml-auto text-blue-600" />}
                        </div>
                      </Dropdown.RadioItem>
                    );
                  })}
                </Dropdown.RadioGroup>
              </Dropdown.Content>
            </Dropdown>
          ) : (
            label
          );
        }}
      </InlineEditable>
    </div>
  );
}
