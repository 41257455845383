import { ArrayUtils } from '@oasis/utils';
import { useMemo } from 'react';
import { Combobox } from '~/shared/components/forms/combobox';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { IssueTemplate } from '../types';

interface Props {
  value: string;
  displayValue?: string;
  onChange(template: IssueTemplate): void;
}

export function TemplateField(props: Props) {
  const combobox = Combobox.useComboboxProps();
  const { templateGroups, templateLookup } = useTemplateGroups();

  const disabled = templateGroups.length === 0;

  return (
    <div>
      <Label className="mb-2">Template</Label>

      <Combobox
        {...combobox}
        name="issue-templates"
        value={props.value}
        displayValue={props.displayValue}
        onChange={templateId => {
          const template = templateLookup.get(templateId);
          if (template) {
            combobox.setOpen(false);
            props.onChange(template);
          }
        }}
        disabled={disabled}
      >
        <Combobox.TriggerInput />
        <Combobox.Content>
          {templateGroups.map(group => {
            return (
              <Combobox.Section key={group.title} title={group.title}>
                {group.templates.map(template => {
                  if (combobox.filterItem(template.issueAttributes.title)) {
                    return null;
                  }

                  return (
                    <Combobox.Item key={template.id} value={template.id}>
                      <p>{template.issueAttributes.title}</p>
                    </Combobox.Item>
                  );
                })}
              </Combobox.Section>
            );
          })}
        </Combobox.Content>
      </Combobox>
    </div>
  );
}

interface TemplateGroups {
  issueTypeId: string;
  issueSubtypeId: string;
  title: string;
  templates: IssueTemplate[];
}

function useTemplateGroups() {
  const { projectId, platform } = useProjectContext();
  const templatesQuery = Queries.Issues.useListIssueTemplates({ projectId });
  const issueTypesQuery = Queries.Issues.useListIssueTypes({ projectId, platform });

  return useMemo(() => {
    const subtypeTemplates: Record<string, TemplateGroups> = {};
    const templateLookup = new Map<string, IssueTemplate>();

    if (!templatesQuery.data || !issueTypesQuery.data) {
      return {
        templateGroups: [],
        templateLookup,
      };
    }

    const issueTypeLookup = new Map<string, string>();

    for (const issueType of issueTypesQuery.data.results) {
      if (issueType.isActive) {
        issueTypeLookup.set(issueType.id, issueType.title);

        for (const subtype of issueType.subtypes) {
          if (subtype.isActive) {
            issueTypeLookup.set(subtype.id, subtype.title);
          }
        }
      }
    }

    for (const template of templatesQuery.data.results) {
      if (template.isActive) {
        const { issueTypeId, issueSubtypeId } = template.issueAttributes;

        const typeTitle = issueTypeLookup.get(issueTypeId);
        const subtypeTitle = issueTypeLookup.get(issueSubtypeId);
        const title = [typeTitle, subtypeTitle].filter(Boolean).join(' > ');

        if (!title) continue;

        templateLookup.set(template.id, template);

        const existingGroup = subtypeTemplates[issueSubtypeId];

        if (existingGroup) {
          existingGroup.templates.push(template);
          continue;
        }

        subtypeTemplates[issueSubtypeId] = {
          issueTypeId,
          issueSubtypeId,
          title,
          templates: [template],
        };
      }
    }

    return {
      templateLookup,
      templateGroups: Object.values(subtypeTemplates).sort((a, b) => ArrayUtils.sortAlphanumeric(a.title, b.title)),
    };
  }, [templatesQuery.data, issueTypesQuery.data]);
}
