import Avatar, { AvatarGroup, DefaultAvatar } from '@adsk/alloy-react-avatar';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useWorkshopActiveUsers, useWorkshopUsersFetcher } from '~/features/workshops/hooks/use-workshop-active-users';
import { Tooltip } from '../../../shared/components/base/tooltip';

export function WorkshopActiveUsers(props: { workshopId: string }) {
  const $env = Oasis.Env.useStore();
  const $workshopUsers = useWorkshopActiveUsers();

  useWorkshopUsersFetcher(props.workshopId);

  const users = $workshopUsers.get(props.workshopId);

  if (users && users.length > 0) {
    return (
      <div className="flex items-center ml-1">
        <AvatarGroup size="small" truncation="numeric" variant="compact" max={4}>
          {users.map(user => {
            const name = `${user.firstName} ${user.lastName}`;

            return (
              <Tooltip key={user.id} content={name}>
                <Avatar key={user.id} name={name} imageUrl={user.images.sizeX80} />
              </Tooltip>
            );
          })}
        </AvatarGroup>
      </div>
    );
  }

  return (
    <div className="flex items-center py-[2px]">
      <DefaultAvatar size="small" />
      <p className={clsx('text-charcoal-400 ml-2', $env.isVr ? 'text-body-md' : 'text-body-sm')}>Nobody is here yet</p>
    </div>
  );
}
