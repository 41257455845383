import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { UploadManager } from '..';

export function usePushToUploadQueue() {
  const queryClient = useQueryClient();
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId) {
    throw new Error('usePushToUploadQueue must be used within a project route');
  }

  return (params: Parameters<typeof UploadManager.pushFileToQueue>[0]) => {
    UploadManager.pushFileToQueue({
      ...params,
      onStateChange(state) {
        if (state.status === 'PROCESSING') {
          setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['projects', projectId, 'items'] });
            queryClient.invalidateQueries({ queryKey: ['projects', projectId, 'folders'] });
          }, 0);
        }
      },
    });
  };
}
