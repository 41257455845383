import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

export interface TextInputProps extends ComponentProps<'input'> {
  disabled?: boolean;
  error?: boolean;
  onTextChange?: (value: string) => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function _TextInput(
  { onTextChange, error, ...props },
  ref
) {
  return (
    <input
      {...props}
      value={props.value || ''}
      onInput={e => {
        props.onInput?.(e);
        onTextChange?.(e.currentTarget.value);
      }}
      className={clsx(
        'w-full border rounded-sm placeholder:text-charcoal-500 p-2 outline-0',
        'focus:shadow-halo-sm',
        error
          ? 'border-red-500 focus:border-red-700 focus:shadow-red-100'
          : 'border-charcoal-400 focus:border-blue-500 focus:shadow-blue-100',
        props.className,
        props.disabled && 'bg-charcoal-100'
      )}
      ref={ref}
      disabled={props.disabled}
      required={false}
    />
  );
});
