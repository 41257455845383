import { Oasis } from '@oasis/sdk';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { AppHistory } from '~/shared/utils/app-history';
import { Folder } from './components/folder';
import { FolderTreeContext, FolderTreeContextState } from './context';
import { FolderTreeMode, type FolderTreeSelectionItem } from './types';

interface Props {
  mode?: FolderTreeMode;
  selection?: FolderTreeSelectionItem[];
  shareableOnly?: boolean;
  onFolderSelect?: FolderTreeContextState['onFolderSelect'];
  onFileSelect?: FolderTreeContextState['onFileSelect'];
  disableContextMenu?: boolean;
  disableFolderSearchParam?: boolean;
  disableFolderPersistance?: boolean;
}

export const FolderTree = memo(function _FolderTree(props: Props) {
  const { projectId, platform } = useProjectContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeFolderUrn, setActiveFolderUrn] = useState(
    // `disableFolderPersistance` is typically used when we don't want any previous folder
    // to be selected like in the "move folder" and "add files" modals.
    props.disableFolderPersistance ? '' : (Oasis.Storage.getTemporary('activeFolderUrn') ?? searchParams.get('folder'))
  );

  const topFolders = Queries.Projects.useListTopFolders({ projectId });
  const activeFolder = Queries.Files.useFindFolderById({
    projectId,
    folderId: activeFolderUrn || '', // will only be called when there is `activeFolderUrn`
    opts: { enabled: Boolean(activeFolderUrn) },
  });

  const setAndStoreActiveFolderUrn = useCallback(
    (urn: string) => {
      setActiveFolderUrn(urn);

      if (!props.disableFolderPersistance) {
        Oasis.Storage.setTemporary('activeFolderUrn', urn);
      }
    },
    [props.disableFolderPersistance]
  );

  const rootFolderUrn = topFolders.data?.rootFolderUrn;

  useEffect(() => {
    let nextFolderUrn = searchParams.get('folder');

    if (rootFolderUrn && !activeFolderUrn) {
      nextFolderUrn = rootFolderUrn;
    }

    if (!nextFolderUrn) {
      return;
    }

    if (activeFolderUrn !== nextFolderUrn) {
      setAndStoreActiveFolderUrn(nextFolderUrn);
    }

    if (!props.disableFolderSearchParam) {
      searchParams.set('folder', nextFolderUrn);
      setSearchParams(searchParams);
    }
  }, [
    rootFolderUrn,
    activeFolderUrn,
    setAndStoreActiveFolderUrn,
    searchParams,
    setSearchParams,
    props.disableFolderSearchParam,
  ]);

  const contextValue = useMemo(() => {
    return {
      projectId,
      platform,
      shareableOnly: props.shareableOnly || false,
      mode: props.mode || 'FOLDERS_ONLY',
      selection: props.selection || [],
      onFolderSelect: props.onFolderSelect,
      onFileSelect: props.onFileSelect,
      activeFolderUrn: activeFolderUrn || '',
      setActiveFolder(urn: string) {
        setAndStoreActiveFolderUrn(urn);
        if (!props.disableFolderSearchParam) {
          AppHistory.searchParams.set('folder', urn);
          AppHistory.setSearchParams(AppHistory.searchParams);
        }
      },
      disableContextMenu: props.disableContextMenu,
      disableFolderPersistance: props.disableFolderPersistance,
      disableFolderSearchParam: props.disableFolderSearchParam,
    };
  }, [props, projectId, platform, activeFolderUrn, setAndStoreActiveFolderUrn]);

  if ((topFolders.isLoading || !activeFolder.isLoading) && !topFolders.data) {
    return <_FolderTreeSkeletonLoader />;
  }

  if (!topFolders.data || !activeFolderUrn) {
    return null;
  }

  return (
    <FolderTreeContext.Provider value={contextValue}>
      <ul className="inline-flex flex-col w-full">
        {topFolders.data.folders.map(root => {
          return (
            <Folder
              defaultExpanded={root.name === 'Project Files'}
              key={root.urn}
              urn={root.urn}
              name={root.name}
              depth={0}
              path={activeFolder.data?.path}
            />
          );
        })}
      </ul>
    </FolderTreeContext.Provider>
  );
});

function _FolderTreeSkeletonLoader() {
  return (
    <div className="p-4 space-y-6">
      <div className="rounded-xl h-3 w-2/3 bg-charcoal-400" />
      <div className="rounded-xl h-3 w-2/3 bg-charcoal-400 ml-10" />
      <div className="rounded-xl h-3 w-2/3 bg-charcoal-400 ml-10 opacity-80" />
      <div className="rounded-xl h-3 w-2/3 bg-charcoal-400 ml-10 opacity-60" />
      <div className="rounded-xl h-3 w-2/3 bg-charcoal-400 ml-10 opacity-40" />
      <div className="rounded-xl h-3 w-2/3 bg-charcoal-400 ml-10 opacity-20" />
    </div>
  );
}
