import { ReactNode, useMemo } from 'react';
import { Combobox } from '~/shared/components/forms/combobox';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useUpdateIssueContext } from '../context';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  rootCauseId?: string | null;
  onSubmit(value: string): void;
  required?: boolean;
}

export function UpdateIssueRootCauseField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const { permittedAttributes } = useUpdateIssueContext();
  const combobox = Combobox.useComboboxProps();

  const rootCauseCategoryQuery = Queries.Issues.useListRootCauseCategories({ projectId, platform });

  const label = useMemo(() => {
    let active: ReactNode = null;

    if (rootCauseCategoryQuery.data?.results) {
      for (const rootCauseCategory of rootCauseCategoryQuery.data.results) {
        const rootCause = rootCauseCategory.rootCauses.find(rootCause => {
          return rootCause.id === props.rootCauseId && rootCause.isActive && rootCauseCategory.isActive;
        });

        if (rootCause) {
          active = (
            <>
              {rootCauseCategory.title} <span className="mx-1">&gt;</span> {rootCause.title}
            </>
          );
        }
      }
    }

    if (!active) {
      active = <p className="text-charcoal-500">Unspecified</p>;
    }

    return active;
  }, [rootCauseCategoryQuery.data?.results, props.rootCauseId]);

  const canEdit = permittedAttributes.includes('rootCauseId');

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        Root Cause
      </Label>

      <InlineEditable canEdit={canEdit}>
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <Combobox
              {...combobox}
              name="root-cause"
              open={isEditing}
              setOpen={open => {
                combobox.setOpen(open);
                setIsEditing(open);
              }}
              value={props.rootCauseId ?? ''}
              onChange={id => props.onSubmit(id)}
            >
              <Combobox.TriggerInput />
              <Combobox.Content>
                {rootCauseCategoryQuery.data?.results.map(rootCauseCategory => {
                  if (!rootCauseCategory.isActive) {
                    return null;
                  }

                  return (
                    <Combobox.Section key={rootCauseCategory.id} title={rootCauseCategory.title}>
                      {rootCauseCategory.rootCauses.map(rootCause => {
                        if (combobox.filterItem(`${rootCauseCategory.title} ${rootCause.title}`)) {
                          return null;
                        }

                        return (
                          <Combobox.Item key={rootCause.id} value={rootCause.id}>
                            {rootCause.title}
                          </Combobox.Item>
                        );
                      })}
                    </Combobox.Section>
                  );
                })}
              </Combobox.Content>
            </Combobox>
          ) : (
            label
          );
        }}
      </InlineEditable>
    </div>
  );
}
