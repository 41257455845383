import jwt from 'jsonwebtoken';
import { HTTPError } from 'ky';
import { Err, Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { createDedupedFn } from '../../lib/utils.misc';
import { OasisHttp } from '../../providers/http/oasis-http.provider';
import { Storage } from '../../providers/storage/storage.provider';
import { Logger } from '../logger/logger.service';
import { Session } from '../session/session.service';
import { TokenManagerSchemas } from './token-manager.schemas';

const TOKEN_REFRESH_MARGIN = 1000 * 60 * 10; // 10 minutes

let accessToken = '';
let expiration = 0;
let refreshInterval: NodeJS.Timeout | undefined;

const _parse = HttpUtils.createScopedParser('TokenManager', TokenManagerSchemas);

/**
 * TokenManager is responsible for managing all of our auth tokens.
 * All use of the access token should go through this manager as it will handle
 * token refresh, request deduplication and verifying token expiration.
 */
export const TokenManager = {
  /**
   * @name getAccessToken
   * Get the access token, exchanging or refreshing it as necessary.
   * This function is deduped while in flight.
   */
  getAccessToken: createDedupedFn(async () => {
    // If the access token is not set, exchange ForgeXr token for access/refresh token pair.
    if (!accessToken) {
      const { ok } = await TokenManager.exchangeForgeXrToken();
      if (!ok) {
        return Err({ code: 'EXCHANGE_FAILED' });
      }
    }

    // If the access token is expired, refresh the token.
    if (expiration < Date.now()) {
      const { ok } = await TokenManager.refresh();
      if (!ok) {
        return Err({ code: 'REFRESH_FAILED' });
      }
    }

    if (!accessToken) {
      return Err({ code: 'NO_ACCESS_TOKEN' });
    }

    return Ok(accessToken);
  }),

  /**
   * @name getAccessTokenDangerously
   * Returns the current access token as last set.
   * This is not the preferred method as it will not refresh the token.
   */
  getAccessTokenDangerously() {
    TokenManager.startRefreshInterval();
    return accessToken;
  },

  /**
   * @name getExpiration
   * Returns the current access token's expiration
   */
  getExpiration() {
    return expiration;
  },

  /**
   * @name setAccessToken
   * Set the access token and calculate the expiration ahead of time.
   *
   * `decoded.exp` is in seconds
   * See NumericDate in https://www.rfc-editor.org/rfc/rfc7519#section-2
   */
  setAccessToken(token: string) {
    accessToken = token;
    const decoded = jwt.decode(token, { json: true });

    if (!decoded?.exp) {
      Logger.error('[TokenManager.setAccessToken] Token does not have an expiration');
      return Err({ code: 'INVALID_EXP' });
    }

    expiration = decoded.exp * 1000 - TOKEN_REFRESH_MARGIN;

    return Ok(true);
  },

  async createForgeXrTokens(tokens: { accessToken: string; refreshToken: string }) {
    try {
      TokenManager.setAccessToken(tokens.accessToken);

      const session = _parse(
        'createForgeXrToken',
        await OasisHttp.post('v1/users/@me/sessions', {
          headers: {
            AUTH_TOKEN: tokens.accessToken,
            REFRESH_TOKEN: tokens.refreshToken,
          },
          body: JSON.stringify({}),
        }).json()
      );

      Storage.set('sessionId', session.id);
      Storage.set('forgeXrToken', session.forgeXrToken);
      Storage.set('forgeXrRefreshToken', session.forgeXrRefreshToken);

      return Ok(session);
    } catch (error) {
      return HttpUtils.handleError(error, '[TokenManager.createForgeXrTokens]');
    }
  },

  /**
   * @name forkForgeXrTokens
   * Forks existing Forge XRsession. This is used if needing to share the related oxygen token/refresh token
   * with another application (like viewer or console within viewer).
   */
  async forkForgeXrTokens() {
    try {
      const token = Storage.get('forgeXrToken');

      const session = _parse(
        'forkForgeXrTokens',
        await OasisHttp.post('v1/users/@me/session/fork', {
          body: JSON.stringify({
            durationSeconds: 3600, // 1 hour
            refreshDurationSeconds: 86400, // 24 hours - chosen to make sure backend cleans up this transient token after 24 hours of none use
          }),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).json()
      );

      return Ok(session);
    } catch (error) {
      return HttpUtils.handleError(error, '[TokenManager.forkForgeXrTokens]');
    }
  },

  /**
   * @name exchangeForgeXrToken
   * Exchange the ForgeXr token for an access/refresh token pair.
   * This function is deduped while in flight.
   */
  exchangeForgeXrToken: createDedupedFn(async () => {
    try {
      const token = Storage.get('forgeXrToken');

      if (!token) {
        return Err({ code: 'MISSING_FORGEXR_TOKEN' });
      }

      const data = _parse(
        'exchangeForgeXrToken',
        await OasisHttp.post('v1/users/@me/session/exchange', {
          body: JSON.stringify({}),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).json()
      );

      TokenManager.setAccessToken(data.oxygenToken);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[TokenManager.exchangeForgeXrToken]');
    }
  }),

  /**
   * @name refresh
   * Refresh auth tokens.
   * This function is deduped while in flight.
   */
  refresh: createDedupedFn(async () => {
    try {
      const forgeXrAccessToken = Storage.get('forgeXrToken');
      const forgeXrRefreshToken = Storage.get('forgeXrRefreshToken');

      if (!forgeXrAccessToken || !forgeXrRefreshToken) {
        return Err({ code: 'MISSING_TOKENS' });
      }

      const data = _parse(
        'refresh',
        await OasisHttp.post('v1/users/@me/session/refresh', {
          headers: {
            Authorization: `Bearer ${forgeXrAccessToken}`,
          },
          body: JSON.stringify({
            forgeXrRefreshToken,
            refreshO: true,
            refreshF: true,
          }),
        }).json()
      );

      TokenManager.setAccessToken(data.oxygenToken);
      TokenManager.setForgeXrTokens(data);

      return Ok(data);
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 401) {
        await Session.logout({ skipAutodeskRedirect: true });
      }

      return HttpUtils.handleError(error, '[TokenManager.refresh]');
    }
  }),

  startRefreshInterval() {
    if (!refreshInterval) {
      refreshInterval = setInterval(TokenManager.getAccessToken, 1000 * 60);
    }
  },

  stopRefreshInterval() {
    clearInterval(refreshInterval);
  },

  setForgeXrTokens(tokens: { forgeXrToken: string; forgeXrRefreshToken: string }) {
    Storage.set('forgeXrToken', tokens.forgeXrToken);
    Storage.set('forgeXrRefreshToken', tokens.forgeXrRefreshToken);
  },

  getForgeXrAccessToken() {
    return Storage.get('forgeXrToken');
  },

  destroyTokens() {
    TokenManager.stopRefreshInterval();

    accessToken = '';
    expiration = 0;
    Storage.remove('forgeXrToken');
    Storage.remove('forgeXrRefreshToken');
  },
};
