import { CheckmarkIcon, ChevronDownIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { WorkshopPermissions } from '@oasis/sdk';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Mutations } from '~/shared/hooks/mutations';
import { PillBox } from './pill-box';

interface Props {
  value?: WorkshopPermissions | null;
  workshopId: string;
  userId: string;
  disabled?: boolean;
}

const PermissionsArr = ['VIEW', 'EDIT', 'MANAGE'] as const;

const PermissionsMap = {
  VIEW: {
    display: 'View',
    description: 'View models and workshop content.',
    level: 1,
  },
  EDIT: {
    display: 'Edit',
    description: 'Share and update models, guide meetings.',
    level: 2,
  },
  MANAGE: {
    display: 'Manage',
    description: 'Full administrative control.',
    level: 3,
  },
} as const;

export function InlinePermissionsSelect(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const updatePermissions = Mutations.Workshops.useUpdateWorkshopPermissions();

  useClickAway(containerRef, () => {
    if (isOpen) setIsOpen(false);
  });

  function handleUpdate(params: { permission: WorkshopPermissions; userId: string }) {
    updatePermissions.mutate({
      workshopId: props.workshopId,
      data: {
        action: 'grant',
        permission: params.permission,
        userIds: [params.userId],
      },
    });
  }

  return (
    <div ref={containerRef} className="relative" onContextMenu={e => e.stopPropagation()}>
      <button
        className="group relative flex items-center hover:bg-charcoal-50 rounded p-1 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
        disabled={props.disabled}
      >
        <PillBox
          value={props.value || ''}
          options={[
            { label: 'View', value: 'VIEW' },
            { label: 'Edit', value: 'EDIT' },
            { label: 'Manage', value: 'MANAGE' },
          ]}
          disabled={props.disabled}
        />

        {!props.disabled && (
          <div className={clsx('ml-2 pr-2', !isOpen && 'opacity-0 group-hover:opacity-100')}>
            <ChevronDownIcon className={clsx('w-5 h-5 transition-transform', isOpen && 'rotate-180')} />
          </div>
        )}
      </button>

      {isOpen && (
        <div className="absolute z-10 pt-1 top-full left-[1px] flex">
          <ul
            role="listbox"
            aria-multiselectable={true}
            aria-label="Options"
            className="w-80 bg-white border border-charcoal-200 shadow-high"
          >
            {PermissionsArr.map(permission => {
              const isSelected = props.value === permission;
              const item = PermissionsMap[permission];

              return (
                <li
                  tabIndex={0}
                  key={permission}
                  role="option"
                  aria-selected={isSelected}
                  className={clsx(
                    'w-full flex items-center p-3 cursor-pointer',
                    isSelected ? 'bg-blue-50' : 'hover:bg-charcoal-50'
                  )}
                  onClick={() => {
                    handleUpdate({
                      permission: permission as WorkshopPermissions,
                      userId: props.userId,
                    });
                  }}
                >
                  <div className="flex items-center w-full">
                    <div className="ml-2">
                      <p className="text-label-md">{item.display}</p>
                      <p className="text-label-sm">{item.description}</p>
                    </div>

                    <div className="w-8 h-6 flex items-center justify-center ml-auto">
                      {isSelected ? (
                        updatePermissions.isPending ? (
                          <ProgressRing size="xsmall" />
                        ) : (
                          <CheckmarkIcon className="w-5 h-5 text-blue-500" />
                        )
                      ) : null}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
