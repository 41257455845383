import clsx from 'clsx';
import { ComponentProps } from 'react';

export function SidePanelLayout({ children, ...props }: ComponentProps<'div'>) {
  return (
    <div {...props} className={clsx('relative flex flex-1 overflow-hidden', props.className)}>
      {children}
    </div>
  );
}

interface PanelProps extends ComponentProps<'section'> {
  widthPx?: number;
  isOpen?: boolean;
  fixed?: boolean;
}

SidePanelLayout.Panel = function SidePanelLayoutPanel({
  isOpen,
  fixed,
  widthPx = 420,
  children,
  ...props
}: PanelProps) {
  const widthRem = `${widthPx / 16}rem`;

  return (
    <section
      {...props}
      className={clsx(
        'bg-white transition-all ease-in-out max-w-full',
        fixed ? 'absolute top-0 right-0 bottom-0 z-10' : 'relative',
        isOpen ? 'duration-300' : 'duration-150',
        props.className
      )}
      style={{ width: isOpen ? widthRem : 0 }}
    >
      <div
        className="absolute top-0 left-0 bottom-0 max-w-full lg:max-w-none overflow-hidden flex-1 border-l border-charcoal-200"
        style={{ width: widthRem }}
      >
        {children}
      </div>
    </section>
  );
};
