import { proxy, useSnapshot } from 'valtio';
import { Oasis } from '../../oasis';
import { ActiveWorkshop } from '../session/session.types';

interface DebugStore {
  activeWorkshop: ActiveWorkshop | null;
  overrideMissingPrerequisites: boolean;
  lmvCameraSync: boolean;
}

export const Debug = {
  store: proxy<DebugStore>({
    activeWorkshop: null,
    overrideMissingPrerequisites: false,
    lmvCameraSync: true,
  }),

  useStore: () => useSnapshot(Debug.store),

  async setActiveWorkshop(workshopId: string) {
    const res = await Oasis.Workshops.findWorkshopById(workshopId);

    if (res.ok) {
      const workshop: ActiveWorkshop = {
        id: workshopId,
        name: res.value.name,
        projectId: res.value.projectId,
        permission: 'MANAGE',
      };

      Debug.store.activeWorkshop = workshop;
      Oasis.Session.store.activeWorkshop = workshop;
    }
  },

  clearActiveWorkshop() {
    Debug.store.activeWorkshop = null;
    Oasis.Session.store.activeWorkshop = null;
  },
};
