import { RadioButton } from '@adsk/alloy-react-radio-button';
import { Checkbox } from '~/shared/components/forms/checkbox';
import { Queries } from '~/shared/hooks/queries';
import { Doctype3dViewIcon } from '../../../icons/doctype-3d-view-icon';
import { useFolderTreeContext } from '../context';
import type { FolderTreeSelectionItem } from '../types';

interface Props {
  documentId: string;
  onChange?: (item: FolderTreeSelectionItem) => void;
}

export function FileReference3dViews(props: Props) {
  const context = useFolderTreeContext();

  const queryParams = { projectId: context.projectId, documentId: props.documentId };
  const file = Queries.Files.useFindDocumentById(queryParams);
  const views = Queries.Files.useList3dViews(queryParams);
  const isRevit =
    file.data?.fileType === 'rvt' || file.data?.filename.endsWith('.rvt') || file.data?.filename.endsWith('.RVT');

  if (file.data && isRevit && views.data?.length) {
    return (
      <ul className="ml-[1.75rem] mt-1.5">
        {views.data.map(view => {
          const isChecked = context.selection.some(item => item.type === 'view' && item.viewGuid === view.guid);

          return (
            <li key={view.guid}>
              <button
                type="button"
                className="flex items-center pl-[1.825rem] py-1.5 hover:bg-gray-100"
                onClick={() =>
                  context.onFileSelect?.({ type: 'view', urn: file.data.id, name: view.name, viewGuid: view.guid })
                }
              >
                <div className="inline-flex items-center justify-center w-7 h-7 mr-2">
                  {context.mode === 'FILE_REFERENCE_RADIO' && <RadioButton checked={isChecked} />}
                  {context.mode === 'FILE_REFERENCE_CHECKBOX' && <Checkbox checked={isChecked} />}
                </div>

                <Doctype3dViewIcon className="h-6 mr-2 text-[#5C7896]" />

                <div className="flex-1 flex overflow-hidden">
                  <p className="truncate">{view.name}</p>
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    );
  }

  return null;
}
