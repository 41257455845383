import { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

export function HeadsetIcon(props: Props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10.856C2 8.174 4.141 6 6.783 6h10.434C19.86 6 22 8.174 22 10.856v3.752c0 2.194-1.752 3.973-3.913 3.973H16.48a4.262 4.262 0 0 1-2.494-.808 2.966 2.966 0 0 0-3.477 0c-.729.525-1.6.808-2.494.808H5.913C3.752 18.58 2 16.8 2 14.608v-3.752Zm4.783-3.532c-1.921 0-3.479 1.581-3.479 3.532v3.752c0 1.463 1.168 2.648 2.609 2.648h2.102c.623 0 1.23-.196 1.739-.563a4.254 4.254 0 0 1 4.987 0 2.972 2.972 0 0 0 1.739.563h1.607c1.44 0 2.609-1.185 2.609-2.648v-3.752c0-1.95-1.558-3.532-3.479-3.532H6.783Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}
