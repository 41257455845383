import clsx from 'clsx';
import { getStatusColorClasses, getStatusLabel } from '~/shared/utils/const.issues';

interface Props {
  status: string; // IssueStatus;
}

export function IssueColoredStatus({ status }: Props) {
  return (
    <div className="inline-flex items-center">
      <div className={clsx('w-1 h-4 rounded-lg mr-2', getStatusColorClasses(status))} />
      <p className="capitalize text-label-md">{getStatusLabel(status)}</p>
    </div>
  );
}
