import ProgressRing from '@adsk/alloy-react-progress-ring';
import Toggle from '@adsk/alloy-react-toggle/es/Toggle';
import { Oasis } from '@oasis/sdk';
import { ProjectUtils } from '@oasis/utils';
import { useIsFetching } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { Button } from '~/shared/components/base/button';
import { Tooltip } from '~/shared/components/base/tooltip';
import { SearchInput } from '~/shared/components/forms/search-input';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { SidePanelLayout } from '~/shared/layouts/side-panel';
import { CreateIssueModal } from '../../components/create-issue-modal';
import { IssuesDetailPanel } from '../../components/issue-panel';
import { IssuesTable } from '../../components/issues-table';

export default function IssuesIndexPage() {
  const { projectId, platform } = useProjectContext();
  const $env = Oasis.Env.useStore();
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const [debouncedQuery, setDebouncedQuery] = useState('');
  useDebounce(() => setDebouncedQuery(query), 500, [query]);

  const [filterModel, setFilterModel] = useState(false);
  const isFetchingIssues = useIsFetching({ queryKey: ['projects', projectId, 'issues'] });
  const issueId = searchParams.get('issueId');

  const userPermissions = Queries.Issues.useGetUserPermissions({ projectId, platform });
  const activeModel = Queries.Users.useGetActiveModel(projectId);

  const allowModelFiltering = Boolean(activeModel.data?.inProject && activeModel.data?.model?.data.id);
  const filteredModelId = (filterModel && activeModel.data?.model?.data.id) || undefined;

  useEffect(() => {
    if (!$env.isDevMode && platform === 'bim360') {
      navigate(`/projects/${projectId}`, { replace: true });
    }
  }, [$env.isDevMode, navigate, projectId, platform]);

  useEffect(() => {
    setFilterModel(allowModelFiltering);
  }, [allowModelFiltering, activeModel.isLoading]);

  const settingsUrl = useMemo(() => {
    const urlId = ProjectUtils.formatId(projectId);

    if (platform === 'acc') {
      const subdomain = $env.releaseChannel === 'alpha' ? 'acc-staging' : 'acc';
      return `https://${subdomain}.autodesk.com/docs/issues/projects/${urlId}/types`;
    }

    return `https://docs.b360.autodesk.com/projects/${urlId}/admin/issues/permissions`;
  }, [projectId, platform, $env.releaseChannel]);

  const isAdmin = userPermissions.data?.isProjectAdmin;

  return (
    <SidePanelLayout>
      <section className="flex flex-col flex-1 overflow-hidden">
        <div className="flex items-center mx-5 border-b border-charcoal-100">
          <header>
            <div className="pt-12 pb-10 short:py-6 flex items-center">
              <h1 className="text-title">Issues</h1>
              {Boolean(isFetchingIssues) ||
                (activeModel.isLoading && <ProgressRing size="xsmall" className="ml-3 mt-0.5" />)}
            </div>
          </header>

          <div className="flex items-center ml-auto">
            {$env.isVr && (
              <div className={clsx('flex items-center ml-6', !allowModelFiltering && 'opacity-50')}>
                <label htmlFor="currentModel">Filter based on current model</label>
                <Tooltip
                  content={
                    !activeModel.data?.model?.data.id
                      ? 'You are not viewing a model in a workshop'
                      : !activeModel.data?.inProject
                        ? 'You are not in the same project that you are viewing the current model.'
                        : undefined
                  }
                >
                  <Toggle
                    checked={allowModelFiltering && filterModel}
                    onChange={setFilterModel}
                    id="currentModel"
                    className="ml-2"
                    disabled={!activeModel.data?.inProject || !allowModelFiltering}
                  />
                </Tooltip>
              </div>
            )}

            {isAdmin && !$env.isVr && (
              <Button variant="link" asChild>
                <a href={settingsUrl} target="_blank" className="flex items-center !pr-0" rel="noreferrer">
                  Issue settings
                  <ExternalLinkIcon className="ml-2" />
                </a>
              </Button>
            )}
          </div>
        </div>

        <div className="flex items-center pt-4 pb-3 mx-5 text-label-md">
          <CreateIssueModal />

          {!$env.isVr && (
            <div className={clsx('flex items-center ml-6 mr-2', !allowModelFiltering && 'opacity-50')}>
              <label htmlFor="currentModel">Filter based on current model</label>
              <Tooltip
                content={
                  !activeModel.data?.model?.data.id
                    ? 'You are not viewing a model in a workshop'
                    : !activeModel.data?.inProject
                      ? 'You are not in the same project that you are viewing the current model.'
                      : undefined
                }
              >
                <Toggle
                  checked={allowModelFiltering && filterModel}
                  onChange={setFilterModel}
                  id="currentModel"
                  className="ml-2"
                  disabled={!activeModel.data?.inProject || !allowModelFiltering}
                />
              </Tooltip>
            </div>
          )}

          <div className="ml-auto w-full max-w-xs">
            <SearchInput value={query} onTextChange={setQuery} placeholder="Search by issue title or ID" />
          </div>
        </div>

        {!activeModel.isInitialLoading && (
          <div className="flex-1 overflow-hidden pl-5">
            <IssuesTable filteredModelId={filteredModelId} query={debouncedQuery} />
          </div>
        )}
      </section>

      <SidePanelLayout.Panel isOpen={Boolean(issueId)} widthPx={480}>
        {issueId && <IssuesDetailPanel issueId={issueId} />}
      </SidePanelLayout.Panel>
    </SidePanelLayout>
  );
}
