import Modal from '@adsk/alloy-react-modal/es/Modal';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import type { Optionull } from '@oasis/sdk';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { OssThumbnail } from '~/shared/components/base/oss-thumbnail';
import { Label } from '~/shared/components/forms/label';

interface Props {
  snapshotUrn?: Optionull<string>;
  displayId: string | number;
  title: string;
}

export function UpdateIssueThumbnailField({ snapshotUrn, displayId, title }: Props) {
  const [loaded, setLoaded] = useState(false);
  const [errored, setErrored] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const setHasLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  const setHasErrored = useCallback(() => {
    setErrored(true);
  }, []);

  if (!snapshotUrn || errored) {
    return null;
  }

  return (
    <div>
      <Label className="mb-2">Issue Thumbnail</Label>

      <div className="flex items-center justify-center aspect-thumbnail rounded border border-charcoal-200 bg-charcoal-50">
        <button
          onClick={e => {
            e.preventDefault();
            setShowModal(true);
          }}
          aria-label="View larger thumbnail"
        >
          <OssThumbnail
            urn={snapshotUrn}
            onLoad={setHasLoaded}
            onError={setHasErrored}
            onOssError={setHasErrored}
            className={clsx('aspect-thumbnail object-cover rounded', !loaded && 'invisible')}
          />
        </button>

        {!loaded && <ProgressRing />}
      </div>

      {/* @ts-ignore */}
      <Modal open={showModal} className="!w-[fit-content] !h-[fit-content] !max-h-[unset] !max-w-[unset]">
        <Modal.Header>
          <h3 className="mr-5">
            Issue #{displayId} - {title}
          </h3>
          <Modal.Close showEscapeLabel={false} onClick={() => setShowModal(false)} />
        </Modal.Header>
        <Modal.Body className="!p-0 overflow-hidden">
          <OssThumbnail
            urn={snapshotUrn}
            className={clsx(
              'block flex-1 max-w-[95vw] max-h-[calc(-71px+90vh)] object-contain rounded-b',
              !loaded && 'invisible'
            )}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
