import { DataBinder } from '@fluid-experimental/property-binder';
import debounce from 'lodash/debounce';
import { ConnectionManager } from './connectionManager';

export function registerOnRootUpdates(
  workshopConnection: ConnectionManager | undefined,
  listener: (root: any) => void
): { unregister: () => void } {
  if (!workshopConnection || !workshopConnection.isConnected) return { unregister: () => {} };
  const dataBinder = new DataBinder();
  dataBinder.attachTo(workshopConnection.propertyTree);
  const dataBinderHandle = dataBinder.registerOnPath(
    '/',
    ['insert', 'remove', 'modify'],
    debounce(() => {
      listener(workshopConnection.propertyTree.root.getEntriesReadOnly());
    }, 20)
  );
  return {
    unregister: () => {
      dataBinderHandle.destroy();
      dataBinder.detach();
    },
  };
}
