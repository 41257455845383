import { Oasis } from '@oasis/sdk';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

export function useMonitorDocProcessingState(params: {
  documentVersionId: string;
  isProcessingDefault?: boolean; // If we already know the status, we can pass it in to avoid an extra query
}) {
  const { projectId } = useParams<{ projectId: string }>();
  const isProcessingDefault = useRef(params.isProcessingDefault ?? true);

  if (!projectId) {
    throw new Error('useMonitorProcessingState must be used within a project route');
  }

  const query = useQuery({
    queryKey: ['projects', projectId, 'versions', params.documentVersionId, 'processing-state'],
    queryFn:
      params.documentVersionId && !isProcessingDefault
        ? async () => {
            const processingState = await Oasis.Files.getProcessingState({
              projectId,
              documentVersionId: params.documentVersionId,
            });

            if (processingState.status === 'pending') {
              return {
                isProcessing: true,
                progress: '0% complete',
              };
            }

            if (processingState.status === 'inprogress') {
              return {
                isProcessing: true,
                progress: processingState.progress,
              };
            }

            return {
              isProcessing: false,
              progress: processingState.progress,
            };
          }
        : skipToken,
    initialData: {
      isProcessing: isProcessingDefault.current,
      progress: '0% complete',
    },
    refetchInterval({ state }) {
      return state.data?.isProcessing ? 5000 : false;
    },
  });

  return query.data;
}
